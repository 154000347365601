import React from "react";
import { PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { getApiClient } from "../../utils/Api";
import { useDispatch } from "react-redux";
import { bookingSuccess } from "../../store/actions/bookings";

// This value is from the props in the UI
const style = { layout: "vertical" };

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ booking, onSuccess, onCancel }) => {
  const dispatch = useDispatch();

  const createOrder = async () => {
    const updatedBooking = await getApiClient().bookingPayment(booking.id, "paypal");

    if (updatedBooking) {
      dispatch(bookingSuccess(updatedBooking));

      return updatedBooking.paypalOrderId;
    }
  };

  const onApprove = data => onSuccess(data);

  return (
    <>
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource={FUNDING.PAYPAL}
        createOrder={createOrder}
        onApprove={onApprove}
        onCancel={onCancel}
      />
    </>
  );
};

export default ButtonWrapper;
