import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { Container, Button, Heading3, Overlay, Icon } from "../../components";

const Wrapper = styled(Container)`
  width: auto;
  background-color: ${props => props.theme.white};
  min-height: 100%;
  text-align: left;
  position: relative;
  padding: 0.5rem;
  border-radius: ${props => props.theme.borderRadius};
  margin: 0.5rem;

  @media (${props => props.theme.tabletScreen}) {
    padding: 2rem;
    margin: 2rem auto;
  }
`;

const SuccessMessage = styled.article`
  padding: 1rem;
  margin: 2.5rem 0 0;
  text-align: center;

  & > button {
    width: 100%;
    @media (${props => props.theme.tabletScreen}) {
      width: auto;
    }
  }
`;

const Title = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-direction: column;

  & > i {
    margin-right: 1rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    flex-direction: row;
  }
`;

const BookingSummary = styled.div`
  display: block;
  text-align: center;
`;

const TitleImage = styled.div`
  background-image: url('${props => props.src}');
  background-color: ${props => props.theme.light};
  background-size: cover;
  width: 50%;
  margin: 0 auto 1rem;
  max-width: 320px;
  
  &:after {
    content: "";
    display: block;
    padding-top: 75%;
  }
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius};
`;

const Success = ({ children, image, onClose, ctaText }) => (
  <Overlay blackout onClose={onClose}>
    <Wrapper>
      <BookingSummary>
        {image && <TitleImage src={image} />}
        {children}
      </BookingSummary>
      <SuccessMessage>
        <Title>
          <Icon name="Success" color="success" size="huge" />
          <Heading3>
            <FormattedMessage id="components.Booking.success.title" />
          </Heading3>
        </Title>
        <FormattedMessage id="components.Booking.success.text" />
        <br />
        <br />
        <Button color="success" onClick={onClose} glow>
          {ctaText || <FormattedMessage id="components.Booking.success.close" />}
        </Button>
      </SuccessMessage>
    </Wrapper>
  </Overlay>
);

Success.propTypes = {
  onClose: PropTypes.func.isRequired,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

Success.defaultProps = {
  ctaText: null,
  children: null,
};

export default Success;
