import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Terms from "./Terms";
import { getCurrentProvider } from "../../store/selectors/provider";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
});

export default withRouter(connect(mapStateToProps)(Terms));
