import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Container, PageContentStandard } from "../../../components";
import Recommendations from "../Recommendations";

import Content from "../Content";

const ContentContainer = styled.div`
  padding: 0 0.5rem 2rem;
  @media (${props => props.theme.tabletScreen}) {
    padding: 2rem;
  }
`;

const Course = ({ provider, course, recommendations }) => {
  return (
    <PageContentStandard seemless>
      <Container>
        <ContentContainer>
          <Content provider={provider} course={course} />
          <Recommendations recommendations={recommendations} />
        </ContentContainer>
      </Container>
    </PageContentStandard>
  );
};

Course.propTypes = {
  course: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  recommendations: PropTypes.array,
};

Course.defaultProps = {
  recommendations: [],
};

export default Course;
