import { ApiClient, inlineRequest } from "../../utils/Api";
import { getToken } from "../selectors/user";
import { loadApplications } from "./waitList";

export const ActionTypes = {
  LOGIN_SUCCESS: "kikudoo/login/success",
  LOGIN_REQUEST: "kikudoo/login/request",
  LOGIN_FAILED: "kikudoo/login/failed",
  SIGNUP_SUCCESS: "kikudoo/signup/success",
  SIGNUP_REQUEST: "kikudoo/signup/request",
  SIGNUP_FAILED: "kikudoo/signup/failed",
  LOGOUT_SUCCESS: "kikudoo/logout/success",
  USER_REQUEST: "kikudoo/user/request",
  USER_FAILED: "kikudoo/user/failed",
  USER_SUCCESS: "kikudoo/user/success",
  UPDATE_USER_REQUEST: "kikudoo/update_user/request",
  UPDATE_USER_FAILED: "kikudoo/update_user/failed",
  UPDATE_USER_SUCCESS: "kikudoo/update_user/success",
  USER_SET_DATA: "kikudoo/user/set_data",
  CONTRACT_SUCCESS: "kikudoo/contract/success",
  CONTRACTS_SUCCESS: "kikudoo/contracts/success",
};

export const loginRequest = () => ({
  type: ActionTypes.LOGIN_REQUEST,
});

export const loginFailed = () => ({
  type: ActionTypes.LOGIN_FAILED,
});

export const loginSuccess = auth => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: auth,
});

export const login = credentials => async dispatch => {
  dispatch(loginRequest());

  try {
    const response = await new ApiClient().login(credentials);
    const userData = response.data;
    const token = response.headers.authorization;

    dispatch(loginSuccess({ userData, token }));

    // Load applications
    dispatch(loadApplications());
  } catch (e) {
    // If the log in fails with 300 and has a useRoute param, we assume
    // a backend user tried to login, so re-post to proper endpoint and leave app
    // TODO: CSRF token validation still fails
    if (e.response.status === 300 && e.response.data && e.response.data.useRoute) {
      const authenticityToken =
        document.querySelector("meta[name=csrf-token]") &&
        document.querySelector("meta[name=csrf-token]").getAttribute("content");

      return inlineRequest(e.response.data.useRoute, {
        "user[email]": credentials.email,
        "user[password]": credentials.password,
        authenticity_token: authenticityToken || e.response.data.token,
      });
    }
    dispatch(loginFailed());
    throw e;
  }
};

export const signupRequest = () => ({
  type: ActionTypes.SIGNUP_REQUEST,
});

export const signupFailed = () => ({
  type: ActionTypes.SIGNUP_FAILED,
});

export const signupSuccess = user => ({
  type: ActionTypes.SIGNUP_SUCCESS,
  payload: user,
});

export const signup = user => async dispatch => {
  dispatch(signupRequest());

  try {
    const response = await new ApiClient().signup({ ...user, passwordConfirmation: user.password });
    const userData = response.data;
    dispatch(signupSuccess(userData));
    dispatch(login({ email: user.email, password: user.password }));
  } catch (e) {
    dispatch(signupFailed());
    throw e;
  }
};

export const logoutSuccess = user => ({
  type: ActionTypes.LOGOUT_SUCCESS,
});

export const logout = () => async (dispatch, getState) => {
  await new ApiClient(getToken(getState())).logout();
  dispatch(logoutSuccess());
};

export const loadUserRequest = () => ({
  type: ActionTypes.USER_REQUEST,
});

export const loadUserFailed = () => ({
  type: ActionTypes.USER_FAILED,
});

export const loadUserSuccess = user => ({
  type: ActionTypes.USER_SUCCESS,
  payload: user,
});

export const loadUser = () => async (dispatch, getState) => {
  dispatch(loadUserRequest());

  try {
    const response = await new ApiClient(getToken(getState())).customer();
    dispatch(loadUserSuccess(response.data));
  } catch (error) {
    // Log out if its a proper response
    if (error && error.response) {
      dispatch(logoutSuccess());
    }
    dispatch(loadUserFailed());
  }
};

export const updateUserRequest = () => ({
  type: ActionTypes.UPDATE_USER_REQUEST,
});

export const updateUserFailed = () => ({
  type: ActionTypes.UPDATE_USER_FAILED,
});

export const updateUserSuccess = user => ({
  type: ActionTypes.UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUser = user => async (dispatch, getState) => {
  dispatch(updateUserRequest());

  try {
    const response = await new ApiClient(getToken(getState())).updateUser(user);
    dispatch(updateUserSuccess(response.data));
  } catch (error) {
    dispatch(updateUserFailed());
    throw error;
  }
};

export const loadPayments = () => async (dispatch, getState) => {
  try {
    const response = await new ApiClient(getToken(getState())).payments();
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setUserData = user => ({
  type: ActionTypes.USER_SET_DATA,
  payload: user,
});

export const resetPassword = email => async (dispatch, getState) => {
  const response = await new ApiClient().resetPassword({ email });
  return response.data;
};

export const updatePassword = (password, passwordConfirmation, resetPasswordToken) => async (
  dispatch,
  getState,
) => {
  const response = await new ApiClient().updatePassword({
    password,
    passwordConfirmation,
    resetPasswordToken,
  });
  return response.data;
};

export const contractsSuccess = contracts => ({
  type: ActionTypes.CONTRACTS_SUCCESS,
  payload: contracts,
});

export const contractSuccess = contract => ({
  type: ActionTypes.CONTRACTS_SUCCESS,
  payload: contract,
});

export const createContract = (productContract, user) => async (dispatch, getState) => {
  const token = getToken(getState());
  const contract = await new ApiClient(token).createContract(productContract, user);

  return contract;
};

export const loadContracts = () => async (dispatch, getState) => {
  try {
    const contracts = await new ApiClient(getToken(getState())).contracts();
    return dispatch(contractsSuccess(contracts));
  } catch (error) {
    throw error;
  }
};

export const loadContract = id => async (dispatch, getState) => {
  try {
    const contract = await new ApiClient(getToken(getState())).contract(id);
    dispatch(contractSuccess(contract));
    return contract;
  } catch (error) {
    throw error;
  }
};

export const cancelContract = contractId => async (dispatch, getState) => {
  try {
    await new ApiClient(getToken(getState())).cancelContract(contractId);
    return dispatch(loadContracts());
  } catch (error) {
    throw error;
  }
};
