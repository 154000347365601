import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutRedirect from "./CheckoutRedirect";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CheckoutRedirectWrapper = () => (
  <Elements stripe={stripePromise}>
    <CheckoutRedirect />
  </Elements>
);

export default CheckoutRedirectWrapper;
