import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentProvider } from "../../store/selectors/provider";
import { getCurrentCourse } from "../../store/selectors/courses";
import { getCurrentBooking } from "../../store/selectors/bookings";
import { loadCourse, loadRecommendations } from "../../store/actions/courses";
import Course from "./Course";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
  course: getCurrentCourse(state),
  booking: getCurrentBooking(state),
});

const mapDispatchToProps = {
  loadCourse,
  loadRecommendations,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Course));
