import { createSelector } from "reselect";

export const getPostsState = state => state.posts;

export const getPosts = createSelector(
  getPostsState,
  state => state.all,
);

export const isLoading = createSelector(
  getPostsState,
  state => state.isLoading,
);
