import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { FormattedDate, FormattedTime } from "react-intl";
import { formatNonHTML } from "../../utils/formatters";
import { Small } from "../../components";

import routes, { routeWithParams } from "../../routes";

const PREVIEW_LENGTH = 50;
const Wrapper = styled.a`
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Content = styled.p`
  text-decoration: none;
`;

const Footer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.strong`
  color: ${props => props.theme.black};
`;

const PostLink = ({ provider, post }) => {
  return (
    <Wrapper as={NavLink} to={routeWithParams(routes.post, { slug: provider.slug, id: post.slug })}>
      <Content>
        <Title>{post.title}</Title>
        <br />
        <Small>
          {formatNonHTML(post.content).substring(0, PREVIEW_LENGTH)}
          {post.content.length > PREVIEW_LENGTH && "..."}
        </Small>
        <Footer>
          <Small>
            <FormattedDate month="long" day="2-digit" value={Date.parse(post.publishedAt)} />
            {", "}
            <FormattedTime value={Date.parse(post.publishedAt)} />
          </Small>
        </Footer>
      </Content>
    </Wrapper>
  );
};

PostLink.propTypes = {
  provider: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
};

export default PostLink;
