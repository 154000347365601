import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { PageLayout, Loading } from "../../components/";
import CourseCard from "../../components/CourseCard";

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.white};
`;

const CardWrapper = styled.div`
  max-width: 320px;
  min-width: 280px;
  width: 100%;
  margin: 1rem auto;
  pointer-events: none;

  & > div {
    margin-bottom: 0;
  }
`;

const CourseCardPreview = ({ provider, course, loadCourse, match }) => {
  const [id] = useState(match.params.id);

  useEffect(() => {
    if (course || !id) {
      return;
    }
    loadCourse(provider.id, id);
  }, [course, loadCourse, id, provider]);

  return (
    <PageLayout>
      <Wrapper>
        {course ? (
          <CardWrapper>
            <CourseCard course={course} />
          </CardWrapper>
        ) : (
          <Loading text={<FormattedMessage id="loading" />} />
        )}
      </Wrapper>
    </PageLayout>
  );
};

CourseCardPreview.propTypes = {
  provider: PropTypes.object.isRequired,
  course: PropTypes.object,
};

CourseCardPreview.defaultProps = {};

export default CourseCardPreview;
