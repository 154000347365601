import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { formatMailto, formatEmail } from "../../utils/formatters";
import routes, { toUrlParams } from "../../routes";

import Map from "../../components/Map";
import Franchise from "../../components/Franchise";
import { InfoList, Link, SimpleFormat, Small } from "../../components";

const ZOOM = 16;
const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
const makeHref = url => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);

const Wrapper = styled.aside``;

const Address = styled.section`
  margin: 0rem 1rem 2rem;
  display: flex;
  cursor: pointer;
  flex-direction: column;

  & > div {
    border-radius: ${props => props.theme.borderRadius};
    min-height: 200px;
    flex: 1;
    overflow: hidden;
    transform: translateZ(0);
  }

  ${Small} {
    text-align: right;
  }

  @media (${props => props.theme.desktopScreen}) {
    margin-left: 0;
    margin-left: right;
  }
`;

const FranchiseAddress = styled.address`
  font-style: normal;
`;

const FranchiseAddresses = styled.div`
  text-align: right;
  margin-top: 1.5rem;
  font-family: ${props => props.theme.fontFamilyBase};
  color: ${props => props.theme.dark};
  cursor: pointer;

  & > ul {
    overflow: hidden;
    & > li {
      font-family: ${props => props.theme.fontFamilyMonospace};
      font-size: ${props => props.theme.fontSizes.small};
      ${Link} {
        color: ${props => props.theme.black};
      }
    }
  }
`;

const Details = ({ franchise }) => {
  const franchiseMarker = {
    key: "franchise",
    lat: parseFloat(franchise.address.lat),
    lng: parseFloat(franchise.address.lng),
    franchises: [{ name: "" }],
  };
  return (
    <Wrapper>
      {franchise.franchises &&
        franchise.franchises.map(franchise => (
          <Franchise key={franchise.slug} franchise={franchise} context="franchise" />
        ))}
      <InfoList
        items={[
          {
            icon: "envelope",
            label: <FormattedMessage id="provider.attributes.contact" />,
            value: franchise.contactEmail ? (
              <Link href={formatMailto(franchise.contactEmail)}>
                {formatEmail(franchise.contactEmail)}
              </Link>
            ) : (
              "-"
            ),
          },
          {
            icon: "mobile",
            label: <FormattedMessage id="provider.attributes.phone" />,
            value: franchise.address.phone ? (
              <Link href={`tel:${franchise.address.phone}`}>{franchise.address.phone}</Link>
            ) : (
              "-"
            ),
          },
          (franchise.website || franchise.url) && {
            icon: "Search",
            label: <FormattedMessage id="provider.attributes.url" />,
            value: (
              <Link href={makeHref(franchise.website || franchise.url)} target="_blank">
                {franchise.name}
              </Link>
            ),
          },
          {
            icon: "location-dot",
            label: <FormattedMessage id="provider.attributes.address" />,
            value: (
              <>
                <FranchiseAddress>
                  <SimpleFormat text={franchise.addressCombined || "-"} />
                  <Link
                    href={`https://${
                      iOS ? "maps.apple.com/?q=" : "www.google.com/maps/search/?api=1&query="
                    }${franchise.address.lat},${franchise.address.lng}&z=15`}
                    target="_blank"
                  >
                    <Small>
                      <FormattedMessage id="pages.Provider.openWithMaps" />
                    </Small>
                  </Link>
                </FranchiseAddress>
                {franchise.addresses && franchise.addresses.length > 0 && (
                  <FranchiseAddresses>
                    <FormattedMessage id="pages.Provider.addresses" />
                    <ul>
                      {franchise.addresses.map(({ street, postalCode, city, lat, lng }) => (
                        <li key={[lat, lng].join("")}>
                          <Link
                            href={`https://${
                              iOS
                                ? "maps.apple.com/?q="
                                : "www.google.com/maps/search/?api=1&query="
                            }${lat},${lng}&z=15`}
                            target="_blank"
                          >
                            {[street, [postalCode, city].join(" ").trim()]
                              .filter(Boolean)
                              .join(", ")}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </FranchiseAddresses>
                )}
              </>
            ),
          },
        ].filter(Boolean)}
      />
      {franchise.address.lat && franchise.address.lng && (
        <Address
          onClick={() =>
            window.open(
              `${routes.search}?${toUrlParams({
                lat: franchise.address.lat,
                lng: franchise.address.lng,
                term: franchise.name,
              })}`,
            )
          }
        >
          <Map
            defaultViewport={{
              center: [parseFloat(franchise.address.lat), parseFloat(franchise.address.lng)],
              zoom: ZOOM,
            }}
            locations={[franchiseMarker]}
            currentLocation={franchiseMarker}
            options={{
              minZoom: ZOOM,
              maxZoom: ZOOM,
              dragging: false,
              zoomControl: false,
            }}
          />
          <Small>
            <FormattedMessage id="pages.Franchise.providers.search" />
          </Small>
        </Address>
      )}
    </Wrapper>
  );
};

Details.propTypes = {
  franchise: PropTypes.object.isRequired,
};

export default Details;
