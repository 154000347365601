import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCenter, getAddress } from "../../store/selectors/search";
import { changeAddress, clearAddress } from "../../store/actions/search";

import FranchiseSchedule from "./FranchiseSchedule";

const mapStateToProps = state => ({
  center: getCenter(state),
  address: getAddress(state),
});

const mapDispatchToProps = {
  clearAddress,
  changeAddress,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FranchiseSchedule),
);
