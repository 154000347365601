import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUserData } from "../store/selectors/user";

const withUser = () => WrappedComponent => {
  const ApplyUser = ({ user, ...props }) => {
    return <WrappedComponent user={user} {...props} />;
  };

  ApplyUser.propTypes = {
    user: PropTypes.object,
  };

  const mapStateToProps = state => ({
    user: getUserData(state) || null,
  });

  return connect(mapStateToProps)(ApplyUser);
};

export default withUser;
