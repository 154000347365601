import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedRelative } from "react-intl";

import SimpleFormat from "./SimpleFormat";
import { Small, Heading2 } from "./";

const dateFromDays = days => {
  const d = new Date();
  d.setDate(d.getDate() - days);
  return d;
};

const Info = styled.article`
  margin-top: 1rem;
  flex: 2;

  @media (${props => props.theme.tabletScreen}) {
    margin-left: 1rem;
    margin-top: 0;
  }
`;

const Address = styled.address`
  margin-bottom: 0.5rem;
  font-style: normal;
`;

const AvatarWrapper = styled.div`
  display: block;
  min-width: 120px;
  max-width: 240px;
  flex: 1;
  margin-right: 1rem;
  @media (${props => props.theme.tabletScreen}) {
  }
`;

const Avatar = styled.div`
  width: 100%;
  background-size: cover;
  background-color: ${props => props.theme.light};
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  &:after {
    content: "";
    padding-top: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => (props.compact ? "0" : "1rem")};
  flex-wrap: ${props => (props.compact ? "nowrap" : "wrap")};
  max-width: 100%;
  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: ${props => (props.compact ? "0" : "2rem")};
  }

  ${Info} {
    margin-top: ${props => (props.compact ? "0  " : "1rem")};
  }

  ${AvatarWrapper} {
    min-width: ${props => (props.compact ? "1.75rem" : "120px")};
    max-width: ${props => (props.compact ? "1.75rem" : "240px")};
  }

  ${Heading2} {
    font-size: ${props => (props.compact ? props.theme.fontSizes.small : "2rem")};
    margin-bottom: ${props => (props.compact ? "0" : "1rem")};
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  ${Small} {
    display: ${props => (props.compact ? "none" : "inline")};
  }
`;

const ProviderInfo = ({ provider, className, compact }) => (
  <Wrapper className={className} compact={compact}>
    {provider.logo && (
      <AvatarWrapper>
        <Avatar style={{ backgroundImage: `url("${provider.logo}")` }} />
      </AvatarWrapper>
    )}
    {compact ? (
      <Heading2>{provider.name}</Heading2>
    ) : (
      <Info>
        <Heading2>{provider.name}</Heading2>
        {provider.addressCombined && (
          <SimpleFormat text={provider.addressCombined || "-"} wrapperTag={Address} />
        )}
        {provider.sinceDays > 0 && (
          <Small>
            <FormattedMessage
              id="components.ProviderInfo.since"
              values={{
                timeAgo:
                  provider.sinceDays > 30 ? (
                    <FormattedRelative value={dateFromDays(provider.sinceDays || 1)} />
                  ) : (
                    <FormattedMessage id="recently" />
                  ),
              }}
            />
          </Small>
        )}
      </Info>
    )}
  </Wrapper>
);

ProviderInfo.propTypes = {
  provider: PropTypes.object.isRequired,
  className: PropTypes.string,
  compact: PropTypes.bool,
};

ProviderInfo.defaultProps = {
  className: "",
  compact: false,
};

export default ProviderInfo;
