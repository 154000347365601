import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedTime, FormattedDate } from "react-intl";
import styled from "styled-components/macro";

import Gallery from "../../../components/Gallery";
import {
  PageContentStandard,
  Container,
  RichText,
  Heading1,
  Row,
  Col,
  Paragraph,
  Hidden,
} from "../../../components/";
import Post from "../Post";
import Links from "../Links";
import Details from "../Details";

const Content = styled.article`
  min-height: 85vh;
`;

const Empty = styled.p`
  padding: 3rem 2rem 4rem;
  overflow: hidden;
  &:after {
    content: "";
    padding-top: 66.6%;
    float: left;
  }
`;

const BlogContent = styled.div`
  max-width: 1024px;
`;

const Standard = ({ currentPost, provider, posts }) => (
  <PageContentStandard seemless={currentPost && currentPost.image}>
    <Container>
      {currentPost ? (
        <Content>
          <Row>
            <Col size="md" count={8}>
              {currentPost.image && <Gallery images={[currentPost.image]} />}
              <BlogContent>
                <Heading1>{currentPost.title}</Heading1>
                <Hidden size="md,lg">
                  <Paragraph>
                    <span>
                      <FormattedMessage
                        id="pages.Blog.postSub"
                        values={{ user: currentPost.author.fullName }}
                      />
                    </span>
                    <br />
                    <small>
                      <FormattedDate
                        month="long"
                        day="2-digit"
                        value={Date.parse(currentPost.publishedAt)}
                      />
                      {", "}
                      <FormattedTime value={Date.parse(currentPost.publishedAt)} />
                      {", "}
                      {currentPost.readingTime > 0 ? (
                        <FormattedMessage
                          id="pages.Blog.readingTime.aprox"
                          values={{ readingTime: currentPost.readingTime }}
                        />
                      ) : (
                        <FormattedMessage id="pages.Blog.readingTime.lessThenMinute" />
                      )}
                    </small>
                  </Paragraph>
                </Hidden>
                <RichText text={currentPost.content} />
              </BlogContent>
              <Links posts={posts} currentPost={currentPost} provider={provider} />
            </Col>
            <Col size="md" count={4}>
              <Details posts={posts} currentPost={currentPost} provider={provider} />
            </Col>
          </Row>
        </Content>
      ) : (posts || []).length > 0 ? (
        <Content>
          {posts.map(post => (
            <Post key={post.id} post={post} provider={provider} />
          ))}
        </Content>
      ) : (
        <Empty>
          <FormattedMessage id="pages.Blog.empty" />
        </Empty>
      )}
    </Container>
  </PageContentStandard>
);

Standard.propTypes = {
  provider: PropTypes.object.isRequired,
  posts: PropTypes.array,
  currentPost: PropTypes.object,
};

Standard.defaultProps = {
  posts: [],
  currentPost: null,
};

export default Standard;
