import React, { useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import { Button } from "../../../../components";
import Loading from "../../../../components/Loading";

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;

const Checkout = ({ sessionId, onError, onCancel }) => {
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe || !sessionId) {
      return;
    }

    stripe.redirectToCheckout({ sessionId }).then(result => {
      if (result.error) {
        return onError(result.error);
      }
    });
  }, [stripe, sessionId, onError]);

  return (
    <LoadingWrapper>
      <Loading text={<FormattedMessage id="components.Contract.redirecting" />} />
      <br />
      <br />
      <Button color="danger" onClick={onCancel} outline>
        <FormattedMessage id="components.Contract.failure.cancel" />
      </Button>
    </LoadingWrapper>
  );
};

export default Checkout;
