import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { Helmet } from "react-helmet";
import {
  startOfWeek,
  endOfWeek,
  addDays,
  subDays,
  setHours,
  setMinutes,
  isSameHour,
  isSameDay,
  endOfDay,
  isPast,
  setSeconds,
} from "date-fns";
import { first, last, sortBy } from "lodash";
import { getSearchParams } from "../../utils/params";

import { ApiClient } from "../../utils/Api";
import { scrollTop } from "../../utils/common";
import { Button, Icon } from "../../components";
import Loading from "../../components/Loading";
import SelectPill from "../../components/SelectPill";
import Event from "./Event";
import Address from "../../pages/SearchMap/Address";
import { DEFAULT_TIMEZONE } from "../../utils/calc";

const RANGE = {
  WEEK: 7,
  DAY: 1,
};

// To create api client scope for cancable requests
const apiClient = new ApiClient();

export const isMobileView = window.innerWidth < 768;

const dayRange = (date, range = RANGE.WEEK) => {
  if (range === RANGE.DAY) {
    return [date];
  }

  const days = [];
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  for (let i = 0; i < range; i++) {
    days.push(addDays(startDate, i));
  }

  return days;
};

const dayHourMap = (days, hours, events) => {
  const map = {};

  for (let i = 0; i < days.length; i++) {
    const day = days[i];

    for (let j = 0; j < hours.length; j++) {
      const hour = hours[j];
      const date = setSeconds(setMinutes(setHours(day, hour), 0), 0);

      map[date.toISOString()] = sortBy(
        events.filter(event =>
          (event.startDates || []).find(startDate => {
            startDate = new Date(startDate);

            if (isSameDay(startDate, date) && isSameHour(startDate, date)) {
              event.matchingStartDate = startDate;
              return true;
            }
            return false;
          }),
        ),
        ["matchingStartDate"],
      );
    }
  }

  return map;
};

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px;

  @media (${props => props.theme.tabletScreen}) {
    padding: 1rem;
  }
`;

const LoadingWrapper = styled(Wrapper)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const ScheduleControls = styled.aside`
  flex: 1;
  min-height: 3rem;
  max-height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
`;

const ScheduleDates = styled.section`
  flex: 5;
  display: flex;
  flex-direction: column;
`;

const ScheduleRow = styled.div`
  flex: 1;
  min-width: 100%;
  height: 100%;
  display: flex;
  min-height: 3rem;
`;

const ScheduleCol = styled.div`
  flex: 1;
  padding: 2px 4px;
  border-top: 1px dashed ${props => props.theme.gray200};
  color: ${props => (props.active ? props.theme.info : "inherit")};
`;

const ScheduleHeaderRow = styled(ScheduleRow)`
  min-height: 0;
  ${ScheduleCol} {
    border: none;
    text-align: center;
    margin-bottom: 1rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding-bottom: 0.5rem;
    ${ScheduleCol} {
      margin-bottom: 0;
    }
  }
`;

const ScheduleColTime = styled(ScheduleCol)`
  max-width: 54px;
  border: none;
  text-align: right;

  & > span {
    display: block;
    margin-top: -1rem;
    line-height: 2rem;
  }
`;

const InlineLoading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (${props => props.theme.tabletScreen}) {
    flex-direction: row;
  }
`;

const TodayButtonWrapper = styled.aside`
  margin-top: 0.25rem;

  @media (${props => props.theme.tabletScreen}) {
    margin-left: 0.5rem;
    margin-top: 0;
  }
`;

const ScheduleFilters = styled.section`
  display: flex;
  border-radius: 5rem;
  z-index: 11;
  width: 100%;
  max-width: 640px;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  box-shadow: ${props => props.theme.boxShadowLifted};
  flex-direction: row;
  margin: 0.25rem auto 0.5rem;

  @media (${props => props.theme.tabletScreen}) {
    width: auto;
    margin: 0.5rem auto 1.5rem;
  }
`;

const Current = styled.h2`
  margin: 0;

  @media (${props => props.theme.tabletScreen}) {
    margin-right: 1rem;
  }
`;

const SelectPillWrapper = styled.div`
  margin-right: 0.5rem;
`;

const FranchiseSchedule = ({ match, center, address, clearAddress, changeAddress }) => {
  const params = getSearchParams() || {};

  const [date, setDate] = useState(params.date || new Date());
  const [range, setRange] = useState(params.range || (isMobileView ? RANGE.DAY : RANGE.WEEK));
  const [franchise, setFranchise] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  // Initial set of center after incoming search request by params
  useEffect(() => {
    const { address, lat, lng } = getSearchParams() || {};

    if (lat && lng) {
      changeAddress(address, parseFloat(lat), parseFloat(lng));
    }
  }, [changeAddress]);

  useEffect(() => {
    scrollTop();

    async function getFranchise(id) {
      try {
        const franchiseData = await new ApiClient().franchise(id);
        setFranchise(franchiseData);
        setCategories(franchiseData.scheduleCategories || franchiseData.categories);
      } catch (e) {
        setError("Not found");
      }
    }

    if (!match.params.id) {
      return;
    }

    getFranchise(match.params.id);
    return () => {
      setFranchise(null);
    };
  }, [match, setFranchise, setError, setCategories]);

  useEffect(() => {
    if (!franchise || !date) {
      return;
    }

    async function getSchedule(id) {
      try {
        const startDate = range === RANGE.DAY ? date : startOfWeek(date, { weekStartsOn: 1 });
        const schedule = await apiClient.franchiseSchedule(
          id,
          startDate,
          addDays(startDate, range),
          address && center ? center : {},
        );
        setSchedule(schedule);
        setLoading(false);
      } catch (e) {
        setError("Not found");
      }
    }

    setLoading(true);
    getSchedule(franchise.id);

    return () => {
      setSchedule(null);
    };
  }, [date, range, franchise, setSchedule, setLoading, center, address]);

  if (!franchise) {
    return (
      <LoadingWrapper>
        {error ? (
          <FormattedMessage id={error === 404 ? "notFound" : "error"} />
        ) : (
          <Loading text={<FormattedMessage id="loading" />} />
        )}
      </LoadingWrapper>
    );
  }

  const days = dayRange(date, range);
  const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  const eventsMatrix =
    schedule && schedule.events ? dayHourMap(days, hours, schedule.events) : null;

  const availableCategories = franchise.scheduleCategories || franchise.categories || [];
  return (
    <Wrapper>
      <Helmet>
        <title>{franchise.name}</title>
        <link href={`https://kikudoo.com/loves/${franchise.slug}/schedule`} rel="canonical"></link>
      </Helmet>
      <Schedule>
        {loading && (
          <InlineLoading>
            <Loading text={<FormattedMessage id="loading" />} />
          </InlineLoading>
        )}
        <ScheduleControls>
          <Button
            onClick={() => setDate(subDays(date, range))}
            color="primary"
            glow={false}
            disabled={isPast(
              subDays(range === RANGE.DAY ? endOfDay(date) : endOfWeek(date), range),
            )}
          >
            <Icon name="chevron-up" direction="left" />
          </Button>

          <Nav>
            <Current onClick={() => setRange(range === RANGE.WEEK ? RANGE.DAY : RANGE.WEEK)}>
              <FormattedDate weekday="short" day="numeric" month="short" value={first(days)} />
              {range !== RANGE.DAY && (
                <>
                  {" - "}
                  <FormattedDate weekday="short" day="numeric" month="short" value={last(days)} />
                </>
              )}
            </Current>
            <TodayButtonWrapper>
              <Button
                color="info"
                size="small"
                glow={false}
                outline
                onClick={() => setDate(new Date())}
              >
                <FormattedMessage id="today" />
              </Button>
            </TodayButtonWrapper>
          </Nav>

          <Button onClick={() => setDate(addDays(date, range))} color="primary" glow={false}>
            <Icon name="chevron-up" direction="right" />
          </Button>
        </ScheduleControls>
        <ScheduleFilters>
          <SelectPillWrapper>
            {franchise && availableCategories.length > 1 && (
              <SelectPill
                name={<FormattedMessage id="components.Schedule.categorySelect" />}
                items={availableCategories}
                onChange={c => setCategories(c)}
              />
            )}
          </SelectPillWrapper>
          <Address
            onClear={clearAddress}
            onSelect={(address, { lat, lng }) => changeAddress(address, lat, lng)}
            onCenter={() => {}}
            address={address}
            defaultExpanded
          />
        </ScheduleFilters>
        <ScheduleDates>
          <ScheduleHeaderRow>
            <ScheduleColTime>
              <Icon
                name="Clock"
                color="dark"
                title={<FormattedMessage id="components.Schedule.time" />}
              />
            </ScheduleColTime>
            {range === RANGE.WEEK ? (
              days.map(day => (
                <ScheduleCol key={day} active={isSameDay(day, new Date())}>
                  <FormattedDate weekday="short" day="numeric" month="short" value={day} />
                </ScheduleCol>
              ))
            ) : (
              <ScheduleCol />
            )}
          </ScheduleHeaderRow>
          {hours.map(hour => (
            <ScheduleRow key={`${hour}-row`}>
              {[
                <ScheduleColTime key={hour}>
                  <FormattedTime
                    value={setMinutes(setHours(new Date(), hour), 0)}
                    timeZone={DEFAULT_TIMEZONE}
                  />
                </ScheduleColTime>,
              ].concat(
                days.map(day => (
                  <ScheduleCol key={`${day}-${hour}`}>
                    {eventsMatrix &&
                      eventsMatrix[
                        setSeconds(setMinutes(setHours(day, hour), 0), 0).toISOString()
                      ].map(event => (
                        <Event
                          key={event.id}
                          event={event}
                          categories={categories}
                          day={setHours(day, hour)}
                        />
                      ))}
                  </ScheduleCol>
                )),
              )}
            </ScheduleRow>
          ))}
        </ScheduleDates>
      </Schedule>
    </Wrapper>
  );
};

FranchiseSchedule.propTypes = {
  center: PropTypes.object,
  address: PropTypes.string,
  match: PropTypes.object.isRequired,
  clearAddress: PropTypes.func.isRequired,
  changeAddress: PropTypes.func.isRequired,
};

FranchiseSchedule.defaultProps = {
  center: null,
  address: null,
};

export default FranchiseSchedule;
