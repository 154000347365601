import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";

import { makeHref, trackForward } from "../Provider";

import Details from "../Details";
import Gallery from "../../../components/Gallery";
import CourseList from "../../../components/CourseList";
import Recommendations from "../Recommendations";

import {
  PageContentStandard,
  Container,
  Row,
  Col,
  Link,
  RichText,
  Separator,
  Small,
  Loading,
} from "../../../components/";

const ExternalIntro = styled.article`
  width: 100%;
  margin: 1rem 0 10rem;
  @media (${props => props.theme.desktopScreen}) {
    width: 85%;
    margin: 2rem 0 10rem;
  }
`;

const ExternalLink = styled(Link)`
  display: block;
  margin-top: 1.5rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const Provider = ({ provider, courses, recommendations }) => {
  return (
    <PageContentStandard seemless={provider.images.length > 0}>
      <Container>
        <Row>
          <Col size="md" count={8}>
            {provider.external ? (
              <ExternalIntro>
                <FormattedMessage
                  id="pages.Provider.externalDescription.intro"
                  values={{
                    name: provider.name,
                    city: provider.address.city,
                    categories: (provider.categories || [])
                      .map(categories => categories.name)
                      .join(", "),
                  }}
                />
                <br />
                <ExternalLink
                  as={Link}
                  href={makeHref(provider.website)}
                  onClick={() => trackForward(provider.slug)}
                  target="_blank"
                >
                  <FormattedMessage
                    id="pages.Provider.externalDescription.link"
                    values={{ name: provider.name }}
                  />
                </ExternalLink>
                <Small>
                  <FormattedMessage id="pages.Provider.externalDescription.linkWarning" />
                </Small>
                <br />
                <Separator />
                {provider.address.phone && (
                  <FormattedMessage
                    id="pages.Provider.externalDescription.phone"
                    values={{ name: provider.name, phone: provider.address.phone }}
                  />
                )}
                <br />
                <FormattedMessage id="pages.Provider.externalDescription.yourPage" />
              </ExternalIntro>
            ) : provider.justCreated && provider.description === "" ? (
              <ExternalIntro>
                <FormattedMessage
                  id="pages.Provider.justCreatedDescription"
                  values={{ provider: provider.name }}
                />
              </ExternalIntro>
            ) : (
              <>
                {provider.images.length > 0 && <Gallery images={provider.images} />}

                <RichText text={provider.description || ""} />
              </>
            )}
          </Col>
          <Col size="md" count={4}>
            <Details provider={provider} />
          </Col>
        </Row>
        {courses.length > 0 ? (
          <CourseList courses={courses} />
        ) : (
          provider.courseCount !== courses.length && (
            <LoadingWrapper>
              <Loading text={<FormattedMessage id="pages.Provider.loadingCourses" />} small />
            </LoadingWrapper>
          )
        )}

        <Recommendations recommendations={recommendations} />
      </Container>
    </PageContentStandard>
  );
};

Provider.propTypes = {
  provider: PropTypes.object.isRequired,
  courses: PropTypes.array,
  recommendations: PropTypes.array,
};

Provider.defaultProps = {
  courses: [],
};

export default Provider;
