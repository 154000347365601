import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Icon from "../../components/Icon";

const Wrapper = styled.ol`
  display: flex;
  flex-direction: column;
  list-stye: none;

  margin: 0;
  padding: 0;
`;

const TabName = styled.span`
  display: flex;
  align-items: center;
`;

const TabBadge = styled.span`
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${props => props.theme.info};
  color: ${props => props.theme.white};
  text-align: center;
  line-height: 1.25rem;
  font-size: ${props => props.theme.fontSizes.tiny};
  border-radius: 50%;
  margin-left: 0.5rem;
  margin-top: -${props => props.theme.fontSizes.tiny};
`;

const Tab = styled.li`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  position: relative;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.dark};

  transition: color 0.25s;

  &:hover {
    color: ${props => props.theme.info};
  }

  ${({ active, theme }) =>
    active &&
    `
      color: ${theme.info};
    `}
`;

const TabIcon = styled.span`
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const TabNav = ({ className, items, defaultItem, onClick }) => {
  const [active, setActive] = useState(defaultItem);

  useEffect(() => {
    setActive(defaultItem);
  }, [defaultItem]);

  const onTabClick = key => {
    setActive(key);
    onClick(key);
  };

  return (
    <Wrapper className={className}>
      {items.map(item => (
        <Tab
          key={item.key}
          onClick={() => onTabClick(item.key)}
          active={active && item.key === active}
        >
          <TabName>
            {item.icon && (
              <TabIcon>
                <Icon name={item.icon} size="small" />
              </TabIcon>
            )}
            {item.name}
            {item.badge && <TabBadge>{item.badge}</TabBadge>}
          </TabName>

          <Icon name="chevron-up" color="black" direction="right" size="small" />
        </Tab>
      ))}
    </Wrapper>
  );
};

TabNav.propTypes = {
  className: PropTypes.string,
  defaultItem: PropTypes.string,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

TabNav.defaultProps = {
  className: "",
  defaultItem: null,
};

export default TabNav;
