import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import { ApiClient } from "../../utils/Api";
import { presence, scrollTop } from "../../utils/common";
import templates from "./templates";
import { PageLayout, PageTitle, Container } from "../../components/";
import SinceDays from "./SinceDays";
import Ratings from "../../components/Ratings";

export const makeHref = url => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
export const trackForward = id => new ApiClient().providerForwarded(id);

const Header = styled.div`
  position: relative;
`;

const ProviderSinceDays = styled(SinceDays)`
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  transform: translateY(55%);
  z-index: 2;
  display: none;
  @media (${props => props.theme.tabletScreen}) {
    transform: translateY(25%);
  }
`;

const Address = styled.div`
  white-space: nowrap;
  text-align: right;
  margin: 0;
  display: none;
  opacity: 0.75;
  font-size: ${props => props.theme.fontSizes.small};

  @media (${props => props.theme.tabletScreen}) {
    display: block;
  }
`;

const Provider = ({ provider, courses, loadCourses, loadRecommendations }) => {
  const Template = templates[provider.template || "standard"];
  const [recommendations, setRecommendations] = useState(null);

  // Scroll on top of page
  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    loadCourses(provider.id);
  }, [provider, loadCourses]);

  // When course is loaded, try to fetch recommendations
  useEffect(() => {
    if (!provider || recommendations) {
      return;
    }

    async function getRecommendations(id) {
      const result = await loadRecommendations(provider.id, id);
      setRecommendations((result || {}).recommendations);
    }

    getRecommendations(provider.id);
  }, [provider, recommendations, setRecommendations, loadRecommendations]);

  return (
    <PageLayout>
      <Helmet>
        <title>{provider.name}</title>
        <link href={`https://kikudoo.com/${provider.slug}`} rel="canonical" />
        <meta property="og:url" content={`https://kikudoo.com/${provider.slug}`} />
      </Helmet>
      <Header>
        <PageTitle
          title={provider.name}
          subTitle={presence(provider.subTitle) || <FormattedMessage id="pages.Provider.intro" />}
          // contact={provider.contacts[0]}
          logo={provider.logo}
          sinceDays={provider.sinceDays || 0}
          clean={provider.template === "clean"}
          info={
            provider.addressCombined !== "" && (
              <Address>
                <strong>
                  <FormattedMessage id="provider.attributes.address" />:
                </strong>
                {presence(provider.address.street) && <p>{provider.address.street}</p>}
                {presence(provider.address.city) && (
                  <p>
                    {provider.address.postalCode} {provider.address.city}
                  </p>
                )}
              </Address>
            )
          }
        />
        <Ratings entity={provider} provider={provider} />
        {provider.sinceDays > 0 && (
          <Container style={{ position: "relative" }}>
            <ProviderSinceDays days={provider.sinceDays} />
          </Container>
        )}
      </Header>
      <Template provider={provider} courses={courses} recommendations={recommendations} />
    </PageLayout>
  );
};

Provider.propTypes = {
  provider: PropTypes.object.isRequired,
  courses: PropTypes.array,
};

Provider.defaultProps = {
  courses: [],
};

export default Provider;
