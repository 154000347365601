import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import routes, { routeWithParams } from "../../routes";

import { scrollTop } from "../../utils/common";
import { PageLayout, PageTitle } from "../../components";
import Loading from "../../components/Loading";

import templates from "./templates";
import Ratings from "../../components/Ratings";

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;

const Course = ({ match, provider, course, loadCourse, loadRecommendations }) => {
  const { id } = useParams();
  // const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [recommendations, setRecommendations] = useState(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    loadCourse(provider.id, id);
  }, [provider, loadCourse, id]);

  // When course is loaded, try to fetch recommendations
  useEffect(() => {
    if (!course || recommendations) {
      return;
    }

    async function getRecommendations(id) {
      const result = await loadRecommendations(provider.id, id);
      setRecommendations((result || {}).recommendations);
    }

    getRecommendations(course.id);
  }, [provider, course, recommendations, setRecommendations, loadRecommendations]);

  // Scroll on top of page
  useEffect(() => {
    scrollTop();
  }, [id]);

  const Template = templates[provider.template || "standard"];

  if (!course) {
    return (
      <PageLayout>
        <LoadingWrapper>
          <Loading text={<FormattedMessage id="loading" />} />
        </LoadingWrapper>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Helmet>
        <title>{`${course.name} - ${provider.name}`}</title>
        <link href={`https://kikudoo.com/${provider.slug}/courses/${course.id}`} rel="canonical" />
        <meta
          property="og:url"
          content={`https://kikudoo.com/${provider.slug}/courses/${course.id}`}
        />
      </Helmet>
      <PageTitle
        title={course.name}
        subTitle={course.subTitle || provider.name}
        contact={provider.contacts[0]}
        logo={provider.logo}
        sinceDays={provider.sinceDays || 0}
        wallpaper={provider.template !== "standard"}
        clean={provider.template === "clean"}
        onTitleClick={() => {
          history.push(
            routeWithParams(routes.courses, {
              slug: provider.slug,
            }),
          );
        }}
        onProviderClick={() => {
          history.push(
            routeWithParams(routes.provider, {
              slug: provider.slug,
            }),
          );
        }}
      />
      <Ratings entity={course} provider={provider} />
      <Template provider={provider} course={course} recommendations={recommendations} />
    </PageLayout>
  );
};

Course.propTypes = {
  match: PropTypes.object.isRequired,
  loadCourse: PropTypes.func.isRequired,
  loadRecommendations: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  course: PropTypes.object,
  booking: PropTypes.object,
};

export default Course;
