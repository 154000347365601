import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { MapContainer, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { isSameGeoLocation } from "../../utils/calc";
import Marker from "../../components/Map/Marker";

export const flatViewport = viewport => ({
  lat: viewport.center.lat,
  lng: viewport.center.lng,
  zoom: viewport.zoom,
});

export const flatBounds = bounds => ({
  from: { lat: bounds.getNorthWest().lat, lng: bounds.getNorthWest().lng },
  to: { lat: bounds.getSouthEast().lat, lng: bounds.getSouthEast().lng },
});

export const isInBounds = ({ lat, lng }, bounds, tolerance = 0.01) =>
  (lat - bounds.from.lat) * (lat - bounds.to.lat) < tolerance &&
  (lng - bounds.from.lng) * (lng - bounds.to.lng) < tolerance;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  flex: 1;
  background-color: ${props => props.theme.gray200};
  border-radius: ${props => props.theme.borderRadius};

  .leaflet-container {
    min-height: 100%;
    min-width: 100%;
    flex: 1;
    will-change: auto;

    .leaflet-map-pane {
      will-change: transform;
    }

    .leaflet-marker-icon:hover {
      z-index: 99999999 !important;
    }
  }

  .marker-cluster-small,
  .marker-cluster-medium,
  .marker-cluster-large {
    background-color: ${props => props.theme.info};
    box-shadow: 0 0.7rem 0.7rem -0.3rem rgba(0, 0, 0, 0.4);
    & > div {
      background-color: ${props => props.theme.white};
    }
  }
`;

const LocationsMap = ({
  locations,
  currentLocation,
  className,
  onViewportChanged,
  onMarkerClick,
  onMarkerNameClick,
  defaultViewport,
  defaultBounds,
  options,
}) => {
  const [viewport, setViewport] = useState({
    zoom: defaultViewport.zoom,
    center: defaultViewport.center,
    bounds: defaultBounds,
  });
  const [map, setMap] = useState(null);

  const onMoveEnd = useCallback(() => {
    setViewport({
      center: map.getCenter(),
      zoom: map.getZoom(),
      bounds: flatBounds(map.getBounds()),
    });
  }, [map]);

  useEffect(() => {
    if (!defaultViewport || !map) {
      return;
    }

    map.setView(defaultViewport.center, defaultViewport.zoom);
  }, [map, defaultViewport]);

  useEffect(() => {
    if (!map) {
      return;
    }

    map.on("moveend", onMoveEnd);
    return () => {
      map.off("moveend", onMoveEnd);
    };
  }, [map, onMoveEnd]);

  useEffect(() => {
    if (!defaultBounds || !map) {
      return;
    }

    try {
      map.fitBounds(
        [
          [defaultBounds.from.lat, defaultBounds.from.lng],
          [defaultBounds.to.lat, defaultBounds.to.lng],
        ],
        {
          padding: [24, 24],
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, [map, defaultBounds]);

  useEffect(() => {
    if (!map) {
      return;
    }

    onViewportChanged(viewport);
  }, [map, onViewportChanged, viewport]);

  useEffect(() => {
    if (!map) {
      return;
    }
    onMoveEnd();
  }, [map, onMoveEnd]);

  return (
    <Wrapper className={className}>
      <MapContainer {...options} center={viewport.center} zoom={viewport.zoom} whenCreated={setMap}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.mapbox.com/styles/v1/evok/ck1e0pzam18111cmvqpoawow8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZXZvayIsImEiOiJjaWw2aG14cDcwMDBweDVrc20xOTR4d2RoIn0.7REE69wwi9DpOHqKTG-MvQ"
        />
        <MarkerClusterGroup maxClusterRadius={20} chunkDelay={25} chunkInterval={50}>
          {locations.map(location => (
            <Marker
              key={location.key}
              location={location}
              zoom={viewport.zoom}
              onClick={onMarkerClick}
              onNameClick={onMarkerNameClick}
              focus={currentLocation && isSameGeoLocation(currentLocation, location)}
            />
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </Wrapper>
  );
};

LocationsMap.propTypes = {
  defaultViewport: PropTypes.object.isRequired,
  locations: PropTypes.array,
  currentLocation: PropTypes.object,
  onViewportChanged: PropTypes.func,
  onMarkerClick: PropTypes.func,
  onMarkerNameClick: PropTypes.func,
  options: PropTypes.object,
};

LocationsMap.defaultProps = {
  locations: [],
  currentLocation: null,
  onViewportChanged: () => {},
  onMarkerClick: () => {},
  onMarkerNameClick: () => {},
  defaultBounds: null,
  options: {},
};

export default LocationsMap;
