import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Slider from "react-slick";

const SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  min-width: 100%;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  @media (${props => props.theme.tabletScreen}) {
    margin-left: 0;
    margin-right: 0;
    & > div {
      border-radius: ${props => props.theme.borderRadius};
      overflow: hidden;
    }
  }

  & > div {
    position: relative;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-slide {
      float: left;
      min-height: 100%;

      & > div {
        height: 100%;
        width: 100%;
        position: relative;
        padding-top: 75%;
      }
    }

    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      transform: translate3d(0, 0, 0);
      border-radius: ${props => props.theme.borderRadius};
    }

    .slick-track {
      transform: translate3d(0, 0, 0);
      position: relative;
      top: 0;
      left: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      height: 2.5rem;
      width: 2.5rem;
      margin-top: -1.25rem;
      border-radius: 50%;
      z-index: 1;
      text-indent: -99999px;
      background-color: rgba(0, 0, 0, 0.2);

      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.5s, background-color 1s, min-width 0.5s;
      opacity: 0.5;
      border: 0;
      padding: 0;
      cursor: pointer;
      outline: none;

      &:after {
        content: '';
        width: 1rem;
        height: 1rem;
        border-width: .2rem .2rem 0 0;
        margin: -1.5rem 0 0 0.5rem;
        border-style: solid;
        border-color: white;
        display: block;
        transform: rotate(45deg);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
      }

      &.slick-prev {
        transform: rotate(180deg);
        left: 0.5rem;
      }

      &.slick-next {
        right: 0.5rem;
      }
    }
}


    & > ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
      & > li {
        transform-style: preserve-3d;
        backface-visibility: hidden;
      }
    }
  }
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: margin-left 0.5s;
  background-image: url('${props => props.src}');
  outline: none;
`;

const Gallery = ({ images, settings }) => {
  const sliderSettings = {
    ...SETTINGS,
    ...settings,
  };
  return (
    <Wrapper>
      <Slider {...sliderSettings}>
        {images.map(({ large, name }, i) => (
          <Image key={name} src={large} />
        ))}
      </Slider>
    </Wrapper>
  );
};

Gallery.propTypes = {
  settings: PropTypes.object,
  images: PropTypes.array.isRequired,
};

Gallery.defaultProps = {
  settings: {},
};

export default Gallery;
