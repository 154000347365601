import React from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import cryingBaby from "./baby-cry.svg";
import Button from "../../components/Button";

const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(-106deg, ${props => "#e16c6c, #fb6f3b"});
  background-size: "100% 100%";
  padding: 1rem;
`;

const MessageContainer = styled.div`
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: 0 2.4rem 1.9rem -0.5rem rgba(0, 0, 0, 0.2);
  max-width: 900px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;

  @media (${props => props.theme.tabletScreen}) {
    padding: 5rem;
  }
`;

const Message = styled.div`
  flex: 1;
  min-width: 250px;
  order: 1;

  text-align: center;
  @media (${props => props.theme.tabletScreen}) {
    text-align: left;
  }

  @media (${props => props.theme.tabletScreen}) {
    order: 0;
    margin-right: 1rem;
  }
`;

const Splash = styled.img`
  flex-basis: 50%;
  flex: 1;
  min-width: 200px;
  max-width: 256px;
  margin: 0 auto;
  padding: 2rem;
  order: 0;
  @media (${props => props.theme.tabletScreen}) {
    order: 1;
  }
`;

const Link = styled.a`
  font-size: small;
  color: #6c757d;
  display: block;
  margin-top: 1rem;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

const Error = () => (
  <Wrapper>
    <MessageContainer>
      <Message>
        <h2>Uppps!</h2>
        <br />
        <p>
          <FormattedMessage id="pages.Error.genericText" />
        </p>
        <br />
        <Button color="success" glow onClick={() => window.location.reload(false)}>
          <FormattedMessage id="pages.Error.cta" />
        </Button>
        <Link
          onClick={() => {
            window.history.back();
          }}
        >
          <FormattedMessage id="actions.back" />
        </Link>
      </Message>
      <Splash src={cryingBaby} />
    </MessageContainer>
  </Wrapper>
);

export default Error;
