import { connect } from "react-redux";
import { getCurrentProvider } from "../../store/selectors/provider";
import { getCourses, isLoading } from "../../store/selectors/courses";
import { getCurrentBooking } from "../../store/selectors/bookings";
import { loadCourses } from "../../store/actions/courses";
import Courses from "./Courses";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
  courses: getCourses(state),
  booking: getCurrentBooking(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {
  loadCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
