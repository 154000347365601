import { REHYDRATE } from "redux-persist";
import { ActionTypes } from "../actions/ui";

const getLang = () =>
  (window.navigator.language || window.navigator.userLanguage || "")
    .toLocaleLowerCase()
    .split(/[-_]/)[0] === "de"
    ? "de"
    : "en";

export const initialState = Object.freeze({
  locale: getLang(),
  listView: false,
});

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case ActionTypes.LOCALE_CHANGE: {
      return {
        ...initialState,
        locale: payload,
      };
    }

    case ActionTypes.LIST_VIEW_CHANGE: {
      return {
        ...initialState,
        listView: payload,
      };
    }

    case REHYDRATE: {
      const rehydratedState = payload.ui;
      if (!rehydratedState) {
        return initialState;
      }
      return {
        ...rehydratedState,
        locale: rehydratedState.locale || getLang(),
      };
    }

    default:
      return state;
  }
};
