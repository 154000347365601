import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import withProvider from "../hoc/withProvider";

import { Container, Row, Col, Small, Link, Address } from "./";
import routes, { routeWithParams } from "../routes";

const Wrapper = styled.footer`
  padding: 2rem 0;
  background-color: ${props => props.theme.gray700};
  color: ${props => props.theme.light};
  z-index: 1;
  position: relative;
`;

const FooterHeading = styled.h5`
  color: ${props => props.theme.light};
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
`;

const FooterSmall = styled(Small)`
  color: ${props => props.theme.gray500};
  text-transform: uppercase;
  font-weight: lighter;
`;

const FooterText = styled.article`
  margin: 1rem 0;
`;

const ProviderName = styled.span`
  word-break: break-word;
  hyphens: auto;
`;

const Footer = ({ provider }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col count={4}>
          <FooterHeading>Kontakt</FooterHeading>
          {provider && (
            <FooterText>
              <ProviderName>{provider.name}</ProviderName>
              <br />
              <FooterSmall>
                {provider.address && <Address address={provider.address} />}
              </FooterSmall>
            </FooterText>
          )}
        </Col>
        <Col count={4}>
          <FooterHeading>Seiten</FooterHeading>
          <FooterSmall>Weiterführende Links</FooterSmall>
          <FooterText>
            <Link href="https://kikudoo.com/pages/faq" target="_blank">
              FAQ
            </Link>
            <br />
            <Link href="https://kikudoo.com/posts" target="_blank">
              Blog
            </Link>
            <br />
            <Link href="https://kikudoo.com/pages/imprint" target="_blank">
              <FormattedMessage id="routes.imprint" />
            </Link>
            <br />
            {provider && provider.hasTerms && (
              <>
                <Link
                  as={NavLink}
                  to={routeWithParams(routes.terms, { slug: provider.slug })}
                  target="_blank"
                >
                  <FormattedMessage id="routes.terms.provider" />
                </Link>
                <br />
              </>
            )}
            <Link href="https://kikudoo.com/pages/terms" target="_blank">
              <FormattedMessage id="routes.terms.kikudoo" />
            </Link>
            <br />
            {provider && provider.hasPrivacyPolicy && (
              <>
                <Link
                  as={NavLink}
                  to={routeWithParams(routes.privacyPolicy, { slug: provider.slug })}
                  target="_blank"
                >
                  <FormattedMessage id="routes.privacyPolicy.provider" />
                </Link>
                <br />
              </>
            )}
            <Link href="https://kikudoo.com/pages/privacy-policy" target="_blank">
              <FormattedMessage id="routes.privacyPolicy.kikudoo" />
            </Link>
            <br />
            <Link href="https://kikudoo.com/pages/disclaimer" target="_blank">
              Disclaimer
            </Link>
          </FooterText>
        </Col>
        <Col count={4}>
          <FooterSmall>&copy; Copyright 2019-{new Date().getFullYear()} kikudoo</FooterSmall>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

Footer.propTypes = {
  provider: PropTypes.object,
};

export default withProvider()(Footer);
