import { ActionTypes } from "../actions/posts";

export const initialState = Object.freeze({
  isLoading: false,
  all: [],
});

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case ActionTypes.POSTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.POSTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        all: payload,
      };
    }

    default:
      return state;
  }
};
