import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl } from "react-intl";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { Loading, Input, Icon, FloatingContainer, ActionInput, Button } from "../../components";

const loadGoogleMaps = callback => {
  const existingScript = document.getElementById("googleMaps");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAztkNNa6zUhlj72lNLJquilghHBk6ahcw&libraries=places";
    script.id = "googleMaps";
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const searchOptions = () => ({
  location: new window.google.maps.LatLng(51.165691, 10.451526),
  radius: 600,
  // types: ["address"],
  componentRestrictions: {
    country: ["de", "at", "lu", "ch"],
  },
});

const ClearButton = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  user-select: none;
  word-break: normal;
  padding: 0.5rem 0.25rem;
`;

const Wrapper = styled(FloatingContainer)`
  flex: 2;
`;

const AddressLoading = styled.div`
  padding: 0.75rem;
  color: ${props => props.theme.purple};
`;

const AddressSearch = styled.section`
  position: relative;
  flex: 4;

  .autocomplete-dropdown-container {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: 0.5rem;
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.boxShadowLifted};
    background-color: ${props => props.theme.white};
    z-index: 1;
    overflow: hidden;
  }

  .suggestion-item {
    padding: 0.75rem;
    &.active {
      background-color: ${props => props.theme.blue};
      color: white;
    }

    &:last-child {
      border-radius: 0 0 ${props => props.theme.borderRadius} ${props => props.theme.borderRadius};
    }
  }
`;

const ServiceLoading = styled(Loading)`
  border-radius: 2rem;
  padding: 0.75rem;
  max-width: 120px;
`;

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      address: props.address || "",
      expanded: !props.address || props.address === "",
    };
  }

  componentDidMount() {
    const { address } = this.props;
    loadGoogleMaps(() => {
      this.setState({ ready: true });
      if (!address || address === "") {
        this.getDeviceLocation();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { address } = this.props;
    if (prevProps.address !== address) {
      this.handleChange(address);
    }
  }

  searchAddress = () => {
    const { address } = this.state;
    const { onClear } = this.props;
    if (address !== "") {
      onClear();
    }

    this.setState({ address: "", search: true });
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    const { onSelect } = this.props;

    geocodeByAddress(address)
      .then(results => {
        return getLatLng(results[0]);
      })
      .then(latLng => onSelect(address, latLng))
      .catch(error => console.error("Error", error));

    this.setState({ address: address, expanded: false });
  };

  getDeviceLocation = (interaction = false) => {
    const { intl, onSelect } = this.props;

    // TODO: add loading state
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        geoLocation => {
          const { latitude, longitude } = geoLocation.coords;
          const address = intl.formatMessage({ id: "components.Address.currentPosition" });
          this.setState({ address });
          onSelect(address, { lat: latitude, lng: longitude });
        },
        e => {
          if (interaction) {
            alert(intl.formatMessage({ id: "components.Address.currentPositionError" }));
          }

          console.log("Couldn't get user's location", e);
        },
        {
          enableHighAccuracy: false,
          timeout: interaction ? 10000 : 5000,
          maximumAge: Infinity,
        },
      );
    } else {
      // TODO: show error
    }
  };

  render() {
    const { ready, address } = this.state;
    const { onCenter, onClear, defaultExpanded } = this.props;

    if (!ready) {
      return (
        <ServiceLoading small title={<FormattedMessage id="pages.SearchMap.loadingService" />} />
      );
    }

    return (
      <Wrapper defaultExpanded={defaultExpanded}>
        <PlacesAutocomplete
          value={address || ""}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={searchOptions()}
          highlightFirstSuggestion
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <ActionInput>
              <AddressSearch>
                <FormattedMessage id="pages.SearchMap.addressPlaceholder">
                  {placeholder => {
                    const inputProps = getInputProps({
                      placeholder: placeholder,
                      className: "location-search-input",
                      autoFocus: !address,
                    });
                    const onKeyDown = event => {
                      inputProps.onKeyDown(event);

                      if (suggestions.length > 0 && (event.keyCode === 9 || event.keyCode === 13)) {
                        // if this is enter key, submit form
                        this.handleSelect(suggestions[0].description);
                      }
                    };

                    return (
                      <Input
                        {...inputProps}
                        onKeyDown={onKeyDown}
                        onFocus={e => e.target.select()}
                      />
                    );
                  }}
                </FormattedMessage>
                <div className="autocomplete-dropdown-container">
                  {loading && (
                    <AddressLoading>
                      <FormattedMessage id="pages.SearchMap.addressLoading" />
                    </AddressLoading>
                  )}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "suggestion-item active"
                      : "suggestion-item";

                    return (
                      <div
                        key={`suggestion-${suggestion.placeId}`}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
                <ClearButton className="hide-collapsed" type="button" onClick={() => onClear()}>
                  <Icon name="add" size="small" color="dark" direction={45} />
                </ClearButton>
              </AddressSearch>
              <Button
                type="button"
                color="white"
                onClick={e => {
                  e.preventDefault();
                  address ? onCenter() : this.getDeviceLocation(true);
                }}
              >
                <Icon name="location-dot" size="small" color={address ? "info" : "primary"} />
              </Button>
            </ActionInput>
          )}
        </PlacesAutocomplete>
      </Wrapper>
    );
  }
}

Address.propTypes = {
  defaultExpanded: PropTypes.bool,
  address: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onCenter: PropTypes.func,
  intl: PropTypes.object.isRequired,
};

Address.defaultProps = {
  defaultExpanded: true,
  onCenter: () => {},
};

export default injectIntl(Address);
