import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData, getToken, isLoading } from "../../store/selectors/user";

import Account from "./Account";

const mapStateToProps = state => ({
  userData: getUserData(state),
  token: getToken(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
