import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentProvider } from "../../store/selectors/provider";
import { getCurrentCourse } from "../../store/selectors/courses";
import { getCurrentBooking } from "../../store/selectors/bookings";
import { getUserData } from "../../store/selectors/user";
import { cancelBooking, confirmBooking } from "../../store/actions/bookings";
import { loadCourse } from "../../store/actions/courses";
import Event from "./Event";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
  course: getCurrentCourse(state),
  booking: getCurrentBooking(state),
  userData: getUserData(state),
});

const mapDispatchToProps = {
  loadCourse,
  cancelBooking,
  confirmBooking,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Event));
