import { DEFAULT_TIMEZONE } from "./calc";

export const scrollTop = () => (document.body.scrollTop = document.documentElement.scrollTop = 0);

export const empty = value => {
  if (
    value === null ||
    value === false ||
    typeof value === "undefined" ||
    value === "" ||
    value === 0 ||
    value === "0"
  ) {
    return true;
  }

  return false;
};

export const presence = str => (empty(str) ? false : str);

export const preventEvent = e => {
  e.preventDefault();
  e.stopPropagation();
  return false;
};

export const containsOnlyNumbers = str => /^\d+$/.test(str);

export const clientTimeZone = () => Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

export const differentTimeZone = () => clientTimeZone() !== DEFAULT_TIMEZONE;
