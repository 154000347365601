import React from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components/macro";
import routes from "../../routes";

import UserNav from "../../components/UserNav";

import ContractConfirm from "./Contracts/Confirm";
import ContractSuccess from "./Contracts/Success";
import ContractFailure from "./Contracts/Failure";

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
`;

const Account = () => {
  return (
    <Wrapper>
      <UserNav />
      <Switch>
        {/* <Route path={routes.contracts.list} component={CheckoutRedirect} exact />
        <Route path={routes.contracts.show} component={CheckoutRedirect} exact /> */}
        <Route path={routes.contracts.new} component={ContractConfirm} exact />
        <Route path={routes.contracts.success} component={ContractSuccess} exact />
        <Route path={routes.contracts.cancel} component={ContractFailure} exact />
        {/* <Route path={routes.contracts.cancel} component={ContractCancel} exact /> */}
      </Switch>
    </Wrapper>
  );
};

export default Account;
