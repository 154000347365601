import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getVisited } from "../../store/selectors/provider";
import {
  isLoading,
  getTerm,
  getCoursesResults,
  getCoursesMetaData,
  getCenter,
  getAddress,
  getFilters,
} from "../../store/selectors/search";
import { searchCourses, changeAddress, setFilters, setFilter } from "../../store/actions/search";

import Search from "./Search";

const mapStateToProps = state => ({
  visitedProviders: getVisited(state),
  term: getTerm(state),
  isLoading: isLoading(state),
  results: getCoursesResults(state),
  metadata: getCoursesMetaData(state),
  center: getCenter(state),
  address: getAddress(state),
  filters: getFilters(state),
});

const mapDispatchToProps = {
  searchCourses,
  changeAddress,
  setFilters,
  setFilter,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
