export const COLOR_VARIATION = 0.12;
export const COLOR_NAMES = [
  "blue",
  "indigo",
  "purple",
  "pink",
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "cyan",
];
export const randomColor = () => COLOR_NAMES[Math.floor(Math.random() * COLOR_NAMES.length)];
export const isMobileView = window.innerWidth < 641;

const colors = {
  // blue: "#74cee4",
  blue: "#77C5F4",
  indigo: "#c389ce",
  purple: "#6f42c1",
  pink: "#e83e8c",
  red: "#e16c6c",
  orange: "#fb6f3b",
  yellow: "#f5be1b",
  green: "#6fc191",
  teal: "#20c997",
  cyan: "#17a2b8",
  white: "#fff",
  gray100: "#f8f9fa",
  gray200: "#e9ecef",
  gray300: "#dee2e6",
  gray400: "#ced4da",
  gray500: "#adb5bd",
  gray600: "#6c757d",
  gray700: "#495057",
  gray800: "#343a40",
  gray900: "#212529",
  black: "#000",
  dark: "#06063F",
};

const fontSize = "1rem";
const fonts = {
  fontSizes: {
    tiny: `${parseInt(fontSize) * 0.6875}rem`,
    small: `${parseInt(fontSize) * 0.875}rem`,
    default: fontSize,
    large: `${parseInt(fontSize) * 1.25}rem`,
  },
  fontFamilyBase: '"Raleway", "Helvetica Neue", Arial, "Noto Sans", sans-serif',
  fontFamilyBrand: '"Quicksand", "Helvetica Neue", Arial, "Noto Sans", sans-serif',
  fontFamilyMonospace:
    'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
};

const screens = {
  tabletScreen: "min-width: 768px",
  desktopScreen: "min-width: 1201px",
  hugeScreen: "min-width: 1441px",
};

export default {
  ...colors,
  primary: colors.blue,
  secondary: colors.gray400,
  success: colors.green,
  info: colors.pink,
  warning: colors.yellow,
  danger: colors.red,
  light: colors.gray100,
  dark: colors.dark,

  ...fonts,
  ...screens,

  borderRadius: "0.6rem",
  borderRadiusSmall: "0.3rem",
  borderRadiusLarge: "1.8rem",
  borderRadiusHuge: "2.4rem",

  boxShadowSmall: "0 0.8rem 0.75rem -0.5rem rgba(0,0,0,0.1);",
  boxShadow: "0 0.8rem 1.2rem -0.7rem rgba(0,0,0,0.5)",
  boxShadowLifted: "0 2.4rem 1.9rem -0.5rem rgba(0,0,0,0.2);",
  boxShadowInset: "inset 0 0.25rem 0.8rem -1rem rgba(0, 0, 0, 0.5);",
};
