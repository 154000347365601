import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { injectIntl, intlShape } from "react-intl";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

import {
  Small,
  Paragraph,
  Row,
  Col,
  Link,
  Input,
  Separator,
  Select,
  InputError,
  InputWrapper,
} from "../";
import { SubHeading } from "./Booking";

export const COUNTRIES = [
  "DE",
  "AT",
  "CH",
  "LU",
  "DK",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "EE",
  "FI",
  "FR",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "GB",
  "NO",
];

const Wrapper = styled.div``;

const ParagraphWithLink = styled(Paragraph)`
  a {
    color: ${props => props.theme.primary};
  }

  strong {
    color: ${props => props.theme.black};
  }
`;

const SignupFields = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;
`;

const ToggleLink = styled(Link)`
  display: block;
  cursor: pointer;
  margin: 1rem 0;
  color: ${props => props.theme.dark};
  text-decoration: underline;
`;

const FormWrap = styled.div`
  margin-bottom: 9rem;

  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 0;
  }
`;

const User = ({ user, onUpdate, intl, forceSignup, validations }) => {
  const [signup, setSignup] = useState(forceSignup);
  const elementRef = useRef(null);

  const onUserChange = ({ target: { name, value } }) =>
    onUpdate(currentUser => ({ ...currentUser, [name]: value }));

  useEffect(() => {
    // Trigger booking component re-validation of user if signup was true
    if (!signup) {
      onUpdate(currentUser => ({ ...currentUser, password: "" }));
    }
  }, [signup, onUpdate]);

  useEffect(() => {
    // Scroll input with validation error into view
    if (!validations || Object.keys(validations || {}).length < 1) {
      return;
    }

    if (!elementRef.current) {
      return;
    }

    const key = Object.keys(validations)[0];
    const inputElement = elementRef.current.querySelector(`input[name="${key}"]`);
    inputElement && inputElement.scrollIntoView(true);
  }, [validations]);

  const existingUser = user.id != null;
  const addressRequired = true;

  return (
    <Wrapper ref={elementRef}>
      <SubHeading>
        <FormattedMessage id="components.Booking.user.title" />
      </SubHeading>
      <FormWrap>
        <Row>
          <Col count={6}>
            <InputWrapper>
              <Input
                name="firstName"
                hasError={validations["firstName"]}
                value={user.firstName}
                placeholder={intl.formatMessage({ id: "user.firstName" })}
                onChange={onUserChange}
                disabled={existingUser}
                autocomplete="given-name"
                required
              />
              {validations["firstName"] && <InputError>{validations["firstName"]}</InputError>}
            </InputWrapper>
          </Col>
          <Col count={6}>
            <InputWrapper>
              <Input
                name="lastName"
                hasError={validations["lastName"]}
                value={user.lastName}
                placeholder={intl.formatMessage({ id: "user.lastName" })}
                onChange={onUserChange}
                disabled={existingUser}
                autocomplete="family-name"
                required
              />
              {validations["lastName"] && <InputError>{validations["lastName"]}</InputError>}
            </InputWrapper>
          </Col>
        </Row>
        <InputWrapper>
          <Input
            name="phone"
            hasError={validations["phone"]}
            type="tel"
            value={user.phone || ""}
            placeholder={intl.formatMessage({ id: "user.phone" })}
            onChange={onUserChange}
            autocomplete="tel"
            required
          />
          {validations["phone"] && <InputError>{validations["phone"]}</InputError>}
        </InputWrapper>
        <InputWrapper>
          <Input
            name="email"
            hasError={validations["email"]}
            type="email"
            value={user.email}
            placeholder={intl.formatMessage({ id: "user.email" })}
            onChange={onUserChange}
            disabled={existingUser}
            autocomplete="email"
            required
          />
          {validations["email"] && <InputError>{validations["email"]}</InputError>}
        </InputWrapper>
        {addressRequired && (
          <>
            <SubHeading>
              <FormattedMessage id="components.Booking.user.address" />
            </SubHeading>
            <InputWrapper>
              <Input
                name="company"
                hasError={validations["company"]}
                value={user.company || ""}
                placeholder={intl.formatMessage({ id: "user.company" })}
                onChange={onUserChange}
                autocomplete="organization"
              />
              {validations["phone"] && <InputError>{validations["phone"]}</InputError>}
            </InputWrapper>
            <InputWrapper>
              <Input
                name="street"
                hasError={validations["street"]}
                value={user.street}
                placeholder={intl.formatMessage({ id: "user.street" })}
                onChange={onUserChange}
                autocomplete="street-address"
                required
              />
              {validations["street"] && <InputError>{validations["street"]}</InputError>}
            </InputWrapper>
            <Row>
              <Col count={6}>
                <InputWrapper>
                  <Input
                    name="postalCode"
                    hasError={validations["postalCode"]}
                    value={user.postalCode}
                    placeholder={intl.formatMessage({ id: "user.postalCode" })}
                    onChange={onUserChange}
                    autocomplete="postal-code"
                    required
                  />
                  {validations["postalCode"] && (
                    <InputError>{validations["postalCode"]}</InputError>
                  )}
                </InputWrapper>
              </Col>
              <Col count={6}>
                <InputWrapper>
                  <Input
                    name="city"
                    hasError={validations["city"]}
                    value={user.city}
                    placeholder={intl.formatMessage({ id: "user.city" })}
                    onChange={onUserChange}
                    autocomplete="locality"
                    required
                  />
                  {validations["city"] && <InputError>{validations["city"]}</InputError>}
                </InputWrapper>
              </Col>
            </Row>
            <Select
              name="country"
              hasError={validations["country"]}
              value={user.country || "DE"}
              placeholder={intl.formatMessage({ id: "user.country" })}
              onChange={onUserChange}
              autocomplete="country"
              required
            >
              {COUNTRIES.map(country => (
                <option key={country} value={country}>
                  {intl.formatMessage({ id: `countries.${country.toLowerCase()}` })}
                </option>
              ))}
            </Select>
          </>
        )}
        {!existingUser ? (
          signup ? (
            <SignupFields>
              <Separator />
              <InputWrapper>
                <Input
                  name="password"
                  type="password"
                  value={user.password}
                  placeholder={intl.formatMessage({ id: "user.password" })}
                  onChange={onUserChange}
                  minLength={8}
                  required
                />
                {validations["password"] && <InputError>{validations["password"]}</InputError>}
              </InputWrapper>
              <Small>
                {forceSignup ? (
                  <FormattedMessage id="components.Booking.user.onDemandSignup" />
                ) : (
                  <ToggleLink onClick={() => setSignup(!signup)}>
                    <FormattedHTMLMessage id="components.Booking.user.noSignup" />
                  </ToggleLink>
                )}
              </Small>
            </SignupFields>
          ) : (
            <Paragraph>
              <ToggleLink onClick={() => setSignup(!signup)}>
                <FormattedHTMLMessage id="components.Booking.user.signup" />
              </ToggleLink>
            </Paragraph>
          )
        ) : (
          <ParagraphWithLink>
            <FormattedHTMLMessage
              id="components.Booking.user.existingUser"
              values={{ username: [user.firstName, user.lastName].join(" ") }}
            />
          </ParagraphWithLink>
        )}
      </FormWrap>
    </Wrapper>
  );
};

User.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  forceSignup: PropTypes.bool,
  validations: PropTypes.object,
};

User.defaultProps = {
  forceSignup: false,
  validations: {},
};

export default injectIntl(User);
