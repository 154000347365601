import { ApiClient } from "../../utils/Api";
import { getToken } from "../selectors/user";

export const ActionTypes = {
  BOOKING_SUCCESS: "kikudoo/booking/success",
  BOOKING_REQUEST: "kikudoo/booking/request",
  BOOKING_FAILURE: "kikudoo/booking/failure",
  BOOKINGS_SUCCESS: "kikudoo/bookings/success",
  BOOKINGS_REQUEST: "kikudoo/bookings/request",
  BOOKINGS_FAILURE: "kikudoo/bookings/failure",
  UPDATE_CURRENT: "kikudoo/bookings/current",
  BOOKING_CONFIRM_REQUEST: "kikudoo/confirm-bookin/request",
  BOOKING_CONFIRM_FAILURE: "kikudoo/confirm-bookin/failure",
  BOOKING_CONFIRM_SUCCESS: "kikudoo/confirm-bookin/success",
};

export const bookingRequest = () => ({
  type: ActionTypes.BOOKING_REQUEST,
});

export const bookingFailed = () => ({
  type: ActionTypes.BOOKING_FAILURE,
});

export const bookingSuccess = booking => ({
  type: ActionTypes.BOOKING_SUCCESS,
  payload: booking,
});

export const createBooking = (booking, user) => async (dispatch, getState) => {
  dispatch(bookingRequest());

  try {
    const persistedBooking = await new ApiClient(getToken(getState())).createBooking(
      {
        costs: booking.costs,
        note: booking.note,
        eventId: booking.event.id,
        participantsAttributes: booking.participants,
        bookingItemValuesAttributes: booking.bookingItemValues?.map(bookingItemValue => ({
          value: bookingItemValue.value,
          quantity: bookingItemValue.quantity,
          bookingItemOptionId: bookingItemValue.bookingItemOption.id,
        })),
        questions: booking.questions,
        voucherCode: booking.voucher && booking.voucher.code,
        password: booking.password,
        origin: booking.origin || "kikudoo",
        priceOptionId: booking.priceOption.id,
      },
      user,
    );
    dispatch(bookingSuccess(persistedBooking));

    return persistedBooking;
  } catch (e) {
    dispatch(bookingFailed());
    throw e;
  }
};

export const bookingConfirmRequest = () => ({
  type: ActionTypes.BOOKING_CONFIRM_REQUEST,
});

export const bookingConfirmFailed = () => ({
  type: ActionTypes.BOOKING_CONFIRM_FAILURE,
});

export const bookingConfirmSuccess = booking => ({
  type: ActionTypes.BOOKING_CONFIRM_SUCCESS,
  payload: booking,
});

// export const chargeBooking = (booking, token) => async (dispatch, getState) => {
//   dispatch(bookingChargeRequest());

//   const persistedBooking = await new ApiClient(getToken(getState())).chargeBooking(booking, token);

//   dispatch(bookingChargeSuccess(persistedBooking));

//   return persistedBooking;
// };

export const confirmBooking = (booking, paymentObject) => async (dispatch, getState) => {
  dispatch(bookingConfirmRequest());

  try {
    const persistedBooking = await new ApiClient(getToken(getState())).confirmBooking(
      booking,
      paymentObject,
    );

    dispatch(bookingConfirmSuccess(persistedBooking));
    return persistedBooking;
  } catch (e) {
    dispatch(bookingConfirmFailed());
    throw e;
  }
};

export const cancelBooking = booking => async (dispatch, getState) => {
  if (booking && booking.id) {
    new ApiClient(getToken(getState())).cancelBooking(booking.id);
  }

  dispatch(updateCurrent(null));
};

export const updateCurrent = booking => async dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_CURRENT,
    payload: booking,
  });
};

export const bookingsRequest = () => ({
  type: ActionTypes.BOOKINGS_REQUEST,
});

export const bookingsFailed = () => ({
  type: ActionTypes.BOOKINGS_FAILURE,
});

export const bookingsSuccess = bookings => ({
  type: ActionTypes.BOOKINGS_SUCCESS,
  payload: bookings,
});

export const loadBookings = () => async (dispatch, getState) => {
  dispatch(bookingsRequest());
  const bookings = await new ApiClient(getToken(getState())).bookings();
  dispatch(bookingsSuccess(bookings));
};

export const loadBooking = id => async dispatch => {
  dispatch(bookingRequest());
  try {
    const booking = await new ApiClient().booking(id);

    dispatch(bookingSuccess(booking));
    return booking;
  } catch (e) {
    dispatch(bookingFailed());
    throw e;
  }
};
