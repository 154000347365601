import { REHYDRATE } from "redux-persist";
import { LOCATION_CHANGE } from "react-router-redux";
import { matchPath } from "react-router";
import { ActionTypes } from "../actions/courses";
import { ActionTypes as BookingActionTypes } from "../actions/bookings";
import routes from "../../routes";

const getCourseId = path => {
  const parts = matchPath(path, {
    path: routes.course,
  });

  if (parts) {
    return parts.params.id;
  }
  return null;
};

export const initialState = Object.freeze({
  isLoading: false,
  current: null,
  all: [],
  updatedAt: null,
});

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case ActionTypes.COURSES_REQUEST:
    case ActionTypes.COURSE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.COURSES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        all:
          state.current && state.all.length > 0
            ? payload
                .filter(c => c.id !== state.current)
                .concat(state.all.find(c => c.id === state.current) || [])
            : payload,
        updatedAt: Date.now(),
      };
    }

    case ActionTypes.COURSE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        all: state.all.filter(c => c.id !== payload.id).concat(payload),
        updatedAt: Date.now(),
      };
    }

    case BookingActionTypes.BOOKING_SUCCESS: {
      return {
        ...state,
        all: (state.all || []).slice().map(course => {
          course.events = course.events.map(event => {
            if (event.id === payload.event.id) {
              event.participantsLeft = event.participantsLeft - payload.participantCount;
            }
            return event;
          });
          return course;
        }),
      };
    }

    case LOCATION_CHANGE: {
      return {
        ...state,
        current: getCourseId(payload.location.pathname),
      };
    }

    case REHYDRATE: {
      const rehydratedState = payload.courses;
      return {
        ...state,
        ...rehydratedState,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
