import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { currencyFormat } from "../utils/formatters";

const Wrapper = styled.article`
  flex: 1;
  min-width: 240px;
  margin: 1rem;
`;

const Facts = styled.ul`
  list-style: bullets;
  padding: 1rem;
  font-size: ${props => props.theme.fontSizes.small};
  background-color: ${props => props.theme.light};
  border-radius: ${props => props.theme.borderRadius};
  color: ${props => props.theme.black};
  margin: 1rem 0;
  max-width: 360px;
`;

const Fact = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FactValue = styled.span`
  color: ${props => props.theme.info};
`;

const Product = ({ product }) => (
  <Wrapper>
    <Facts>
      <Fact>
        <FormattedMessage id="booking.attributes.costs" />
        <FactValue>
          <FormattedNumber value={product.price / 100} {...currencyFormat(product)} />
        </FactValue>
      </Fact>
      {product.typeOf === "plan" && product.priceTotal && (
        <Fact>
          <FormattedMessage id="booking.attributes.totalCosts" />
          <FactValue>
            <FormattedNumber value={product.priceTotal / 100} {...currencyFormat(product)} />
          </FactValue>
        </Fact>
      )}
      <Fact>
        <FormattedMessage id="product.attributes.interval" />
        <FactValue>
          {product.intervalCount < 2 && product.intervalType ? (
            <FormattedMessage id={`per.${product.intervalType}`} />
          ) : (
            <FormattedMessage
              id={`every.${product.intervalType}`}
              values={{ count: product.intervalCount }}
            />
          )}
        </FactValue>
      </Fact>
      {product.invoiceAnchor && product.invoiceAnchor !== "start_date" && (
        <Fact>
          <FormattedMessage id="product.attributes.invoiceAnchor" />
          <FactValue>
            <FormattedMessage
              id={`product.attributes.invoiceAnchors.${product.invoiceAnchor}`}
              values={{ intervalType: <FormattedMessage id={`${product.intervalType}.one`} /> }}
            />
          </FactValue>
        </Fact>
      )}
      {product.periodCount ? (
        <Fact>
          <FormattedMessage id="contract.attributes.period" />
          <FactValue>
            <span>{product.periodCount + " "}</span>
            {product.typeOf === "plan" ? (
              <FormattedMessage id="contract.attributes.rates" />
            ) : (
              <FormattedMessage
                id={`${product.intervalType}.${product.periodCount > 1 ? "many" : "one"}`}
              />
            )}
          </FactValue>
        </Fact>
      ) : (
        <Fact>
          <FormattedMessage id="product.attributes.cancelation" />
          <FactValue>
            <FormattedMessage id={`${product.intervalType}.ly`} />
          </FactValue>
        </Fact>
      )}
    </Facts>
  </Wrapper>
);

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

Product.defaultProps = {};

export default Product;
