import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { ApiClient } from "../../utils/Api";
import { DEFAULT_TIMEZONE, isToday } from "../../utils/calc";
import { FormattedMessage, FormattedTime, FormattedDate } from "react-intl";
import { Container, Button, Heading3, Input } from "../../components";

const Wrapper = styled(Container)`
  background-color: ${props => props.theme.white};
  min-height: 100%;
  text-align: center;
  position: relative;
  padding: 0.5rem;
  @media (${props => props.theme.tabletScreen}) {
    padding: 2rem;
  }
`;

const Hint = styled.p`
  padding: 1rem;
  margin: 0 2.5rem 1rem;
`;

const PasswordInput = styled(Input)`
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  outline: none;
  text-align: center;
  letter-spacing: 0.3rem;
  color: ${props => props.theme.info};
  &:focus,
  &:active {
    font-size: 2rem;
  }
`;

const FormContainer = styled.div`
  max-width: 400px;
  margin: 1rem auto;

  & > form {
    display: flex;
    flex-direction: column;
  }
`;

const Password = ({ event, onSuccess }) => {
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await new ApiClient().authorizeBooking({ eventId: event.id, password });
      setIsLoading(false);
      onSuccess(password);
    } catch (e) {
      if ((e.response || {}).status === 500) {
        alert("Es ist ein unerwartetes Problem aufgetreten. Bitte wende dich an deinen Anbieter");
      }
      setIsLoading(false);
      setHasError(true);
    }
  };

  return (
    <Wrapper>
      <Heading3>
        <FormattedMessage id="components.Booking.password.title" />
      </Heading3>
      {event.protectedUntil && (
        <p>
          <FormattedMessage
            id="components.Course.protectedUntil"
            values={{
              date: isToday(new Date(event.protectedUntil)) ? (
                <FormattedTime
                  value={Date.parse(event.protectedUntil)}
                  timeZone={DEFAULT_TIMEZONE}
                />
              ) : (
                <FormattedDate
                  month="short"
                  day="numeric"
                  value={Date.parse(event.protectedUntil)}
                />
              ),
            }}
          />
        </p>
      )}
      <Hint>
        {event.protectedHint !== "" ? (
          event.protectedHint
        ) : (
          <FormattedMessage id="components.Booking.password.hint" />
        )}
      </Hint>
      <FormContainer>
        <form onSubmit={onSubmit}>
          <PasswordInput
            value={password}
            onChange={e => setPassword(e.target.value)}
            name="password"
            minLength={1}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect="off"
            spellCheck="false"
            disabled={isLoading}
            hasError={hasError}
            autoFocus
          />
          <Button
            type="submit"
            color="primary"
            busy={isLoading}
            size="large"
            disabled={isLoading || password.length < 1}
          >
            <FormattedMessage id="actions.submit" />
          </Button>
        </form>
      </FormContainer>
    </Wrapper>
  );
};

Password.propTypes = {
  event: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Password;
