import { ApiClient } from "../../utils/Api";

export const ActionTypes = {
  COURSE_SUCCESS: "kikudoo/course/success",
  COURSE_REQUEST: "kikudoo/course/request",
  COURSE_FAILED: "kikudoo/course/failed",
  COURSES_SUCCESS: "kikudoo/courses/success",
  COURSES_REQUEST: "kikudoo/courses/request",
  COURSES_FAILED: "kikudoo/courses/failed",
};

export const courseRequest = () => ({
  type: ActionTypes.COURSE_REQUEST,
});

export const courseFailed = () => ({
  type: ActionTypes.COURSE_FAILED,
});

export const courseSuccess = course => ({
  type: ActionTypes.COURSE_SUCCESS,
  payload: course,
});

export const loadCourse = (providerId, id, token = null) => async (dispatch, getState) => {
  dispatch(courseRequest());

  const course = await new ApiClient().course(providerId, id, token);
  dispatch(courseSuccess(course));

  return course;
};

export const coursesRequest = () => ({
  type: ActionTypes.COURSES_REQUEST,
});

export const coursesFailed = () => ({
  type: ActionTypes.COURSES_FAILED,
});

export const coursesSuccess = courses => ({
  type: ActionTypes.COURSES_SUCCESS,
  payload: courses,
});

export const loadCourses = providerId => async (dispatch, getState) => {
  dispatch(coursesRequest());

  const courses = await new ApiClient().courses(providerId);
  dispatch(coursesSuccess(courses));
};

export const loadRecommendations = (providerId, id) => async (dispatch, getState) => {
  try {
    const recommendations = await new ApiClient().courseRecommendations(providerId, id);
    return recommendations;
  } catch (e) {
    console.error(e);
    return [];
  }
};
