import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";

import { Label, Input, Mono, Select } from "..";
import { SubHeading } from "./Booking";
import { currencyFormat } from "../../utils/formatters";

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const BookingItemInline = styled.div`
  display: flex;

  ${Select} {
    flex: 2;
  }
`;

const BookingQuestion = styled(Label)`
  min-width: 100%;
  display: flex;
  flex-direction: ${props => (props.checkbox ? "row-reverse" : "column")};
  justify-content: ${props => (props.checkbox ? "flex-end" : "")};
  align-items: ${props => (props.checkbox ? "center" : "baseline")};
  margin-bottom: 1rem;

  & > input {
    width: ${props => (props.checkbox ? "auto" : "100%")};
    margin-bottom: 0;
    margin-right: ${props => (props.checkbox ? "1rem" : "0")};
  }
`;

const BookingQuestionCheckbox = styled(BookingQuestion)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  & > input {
    width: auto;
    margin-right: 1rem;
  }
`;

const BookingQuestionNumeric = styled(BookingQuestion)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  & > input {
    width: auto;
    max-width: 4rem;
    padding-right: 0.5rem;
    margin-right: 1rem;
  }
`;

const QuestionLabel = styled.span`
  display: inline-block;
  margin-bottom: 1rem;
  flex: 1;
`;

const PriceLabel = styled.small`
  color: ${props => props.theme.info};

  &:before {
    content: "+";
    margin-left: 0.5rem;
    margin-right: 0.25rem;
    font-size: 1.5em;
    line-height: 1em;
    vertical-align: bottom;
  }
`;

const BookedOutHint = styled.small`
  margin-left: 0.5rem;
  color: ${props => props.theme.danger};
`;

const BookingItems = ({ items, values, onUpdate, intl }) => {
  const questionValue = (id, type = "text") => {
    const value = values.find(({ bookingItemOption }) => bookingItemOption.id === id)?.value;

    if (value) {
      return value;
    } else if (type === "checkbox") {
      return "true";
    } else if (type === "number") {
      return 0;
    }

    return "";
  };

  const onChange = ({ target: { name, value, checked, type } }) => {
    const bookingItemValue = values.find(({ bookingItemOption }) => bookingItemOption.id === name);

    if (type === "checkbox") {
      bookingItemValue.value = checked;
    } else {
      bookingItemValue.value = value;
    }

    return onUpdate(
      [bookingItemValue].concat(
        values.filter(({ bookingItemOption }) => bookingItemOption.id !== name),
      ),
    );
  };

  // As selects only give the current value onchange we have to alter the behavior
  // to match our result better. We want to have all booking item options to be present
  // with their current "value". So, if we select an item, we want to de-select other options.
  const onSelectChange = ({ target: { name, value } }) => {
    const bookingItem = items.find(({ id }) => id === name);
    const bookingItemValue = values.find(({ bookingItemOption }) => bookingItemOption.id === value);

    // If none selected, de-select all
    if (!bookingItemValue) {
      return onUpdate(
        values
          .filter(({ bookingItemOption }) => bookingItemOption.bookingItemId === bookingItem.id)
          .map(bookingItemValue => ({ ...bookingItemValue, value: false }))
          .concat(
            values.filter(
              ({ bookingItemOption }) => bookingItemOption.bookingItemId !== bookingItem.id,
            ),
          ),
      );
    }

    bookingItemValue.value = true;

    // De-select other options
    const otherBookingItemValues = values
      .filter(({ bookingItemOption }) => bookingItemOption.bookingItemId === bookingItem.id)
      .filter(
        ({ bookingItemOption }) => bookingItemOption.id !== bookingItemValue.bookingItemOption.id,
      )
      .map(bookingItemValue => ({ ...bookingItemValue, value: false }));

    onUpdate(
      [bookingItemValue].concat(
        otherBookingItemValues,
        values.filter(
          ({ bookingItemOption }) => bookingItemOption.bookingItemId !== bookingItem.id,
        ),
      ),
    );
  };

  const itemInput = ({ id, label, type, mandatory, options }) => {
    switch (type) {
      case "text":
        return (
          <li key={id}>
            <BookingQuestion>
              <span>{`${label} ${mandatory ? "*" : ""}`}</span>
              {options.map(option => (
                <Input
                  key={option.id}
                  name={option.id}
                  type="text"
                  required={mandatory}
                  onChange={onChange}
                  value={questionValue(option.id)}
                />
              ))}
            </BookingQuestion>
          </li>
        );
      case "checkbox": {
        const { id, price, currency, available } = options[0];
        return (
          <li key={id}>
            <BookingQuestionCheckbox>
              <span>
                {`${label} ${mandatory ? "*" : ""}`}
                {!available && (
                  <BookedOutHint>
                    <FormattedMessage id="components.Booking.bookedOut" />
                  </BookedOutHint>
                )}
                {price > 0 && (
                  <PriceLabel>
                    <Mono>
                      <FormattedNumber value={price / 100} {...currencyFormat({ currency })} />
                    </Mono>
                  </PriceLabel>
                )}
              </span>
              <Input
                key={id}
                name={id}
                type="checkbox"
                required={mandatory}
                onChange={onChange}
                value="true"
                checked={questionValue(id) === true}
                disabled={!available}
              />
            </BookingQuestionCheckbox>
          </li>
        );
      }
      case "multiple":
        return (
          <li key={id}>
            <QuestionLabel>{`${label} ${mandatory ? "*" : ""}`}</QuestionLabel>
            {options.map(({ id, name, price, currency, vatRate, available }) => (
              <BookingQuestionCheckbox key={id}>
                {price > 0 && (
                  <PriceLabel>
                    <Mono>
                      <FormattedNumber value={price / 100} {...currencyFormat({ currency })} />
                    </Mono>
                  </PriceLabel>
                )}
                <span>
                  {name}
                  {!available && (
                    <BookedOutHint>
                      <FormattedMessage id="components.Booking.bookedOut" />
                    </BookedOutHint>
                  )}
                </span>
                <Input
                  name={id}
                  type="checkbox"
                  onChange={onChange}
                  value="true"
                  defaultChecked={questionValue(id) === true}
                  readOnly={!available}
                  disabled={!available}
                />
              </BookingQuestionCheckbox>
            ))}
          </li>
        );
      case "radio":
        const selectedOptionId = options.find(({ id }) => questionValue(id) === true)?.id;

        return (
          <li key={id}>
            <BookingItemInline>
              <QuestionLabel>{`${label}${mandatory ? "*" : ""}`}</QuestionLabel>
              <Select
                key={id}
                name={id}
                required={mandatory}
                onChange={onSelectChange}
                defaultValue={selectedOptionId}
              >
                <option value="">{intl.formatMessage({ id: "pleaseChoose" })}</option>
                {options.map(({ id, name, price, currency, vatRate, available }) => (
                  <option key={id} value={id} disabled={!available}>
                    {`${[
                      name,
                      " ",
                      price > 0
                        ? intl.formatNumber(price / 100, { style: "currency", currency })
                        : "",
                    ].join("")}
                  `}
                  </option>
                ))}
              </Select>
            </BookingItemInline>
          </li>
        );
      case "quantity":
        // TODO: Not properly implementd
        return (
          <li key={id}>
            <QuestionLabel>{`${label} ${mandatory ? "*" : ""}`}</QuestionLabel>
            {options.map(({ id, name, price, currency, vatRate }) => (
              <BookingQuestionNumeric key={id}>
                <span>
                  {name}
                  {price > 0 && (
                    <PriceLabel>
                      <Mono>
                        {" + "}
                        <FormattedNumber value={price / 100} {...currencyFormat({ currency })} />
                        {" pro"}
                      </Mono>
                    </PriceLabel>
                  )}
                </span>
                <Input name={id} type="number" onChange={onChange} value={questionValue(id) || 0} />
              </BookingQuestionNumeric>
            ))}
          </li>
        );
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <SubHeading>
        <FormattedMessage id="booking.attributes.questions" />
      </SubHeading>
      <ol>{items.map(itemInput)}</ol>
    </Wrapper>
  );
};

BookingItems.propTypes = {
  items: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default injectIntl(BookingItems);
