import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";
import styled from "styled-components/macro";

import { Paragraph, Hidden } from "../../components/";
import { Wrapper } from "../Provider/Details";
import PostLink from "./PostLink";
import { DEFAULT_TIMEZONE } from "../../utils/calc";

const PostsWrapper = styled(Wrapper)`
  padding-top: 1rem;
  margin-bottom: 1rem;
`;

const Posts = styled.div`
  margin: 1rem;
`;

const Details = ({ currentPost, posts, provider }) => (
  <PostsWrapper>
    <Hidden size="sm">
      <Paragraph>
        <span>
          <FormattedMessage
            id="pages.Blog.postSub"
            values={{ user: currentPost.author.fullName }}
          />
        </span>
        <br />
        <small>
          <FormattedDate month="long" day="2-digit" value={Date.parse(currentPost.publishedAt)} />
          {", "}
          <FormattedTime value={Date.parse(currentPost.publishedAt)} timeZone={DEFAULT_TIMEZONE} />
        </small>
        <br />
        <small>
          {currentPost.readingTime > 0 ? (
            <FormattedMessage
              id="pages.Blog.readingTime.aprox"
              values={{ readingTime: currentPost.readingTime }}
            />
          ) : (
            <FormattedMessage id="pages.Blog.readingTime.lessThenMinute" />
          )}
        </small>
      </Paragraph>
    </Hidden>
    <Posts>
      {posts
        .filter(post => post.id !== currentPost.id)
        .map(post => (
          <PostLink key={post.id} post={post} provider={provider} />
        ))}
    </Posts>
  </PostsWrapper>
);

Details.propTypes = {
  posts: PropTypes.array,
  currentPost: PropTypes.object,
  provider: PropTypes.object.isRequired,
};

Details.defaultProps = {
  posts: [],
  currentPost: null,
};

export default Details;
