import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactGA from "react-ga4";
import { NavLink } from "react-router-dom";
import routes from "../../routes";
import { latLng, featureGroup, marker } from "leaflet";
import { isEmpty } from "lodash";

import { DEFAULT_VIEWPORT } from "../../store/reducers/search";
import { gradientColors } from "../../utils/formatters";
import { getSearchParams } from "../../utils/params";
import { distanceGeo } from "../../utils/calc";

import Address from "./Address";
import Incentive from "./Incentive";
import Lead from "./Lead";
import LocationList from "./LocationList";
import { Button, Icon, Heading3, Loading, Paragraph } from "../../components";
import UserNav from "../../components/UserNav";
import FloatingInput from "../../components/FloatingInput";
import Map, { flatBounds } from "../../components/Map";
import mapBlur from "../../assets/map-blur.jpg";
import searchImage from "../../assets/search-icon.png";
import theklaLogo from "../../assets/thekla-logo.png";
import bibebaLogo from "../../assets/bibeba-logo.png";
import babystepsLogo from "../../assets/baby-steps-logo.png";
import mawibaLogo from "../../assets/mawiba-logo.png";
import guldenLogo from "../../assets/gulden-logo.png";
import trageschuleLogo from "../../assets/kikudoo-trageschule-hamburg.png";
import pekipLogo from "../../assets/pekip-logo.png";

const DEFAULT_ZOOM = 13;
const MIN_ZOOM = 9;
const MAX_ZOOM = 16;
const MAX_LOCATION_ZOOM = 14;
const ZOOM_CHANGE_SEARCH = 1.25;
const REQUEST_TIMING = 2000;

export const isSameLocation = (l0, l1) => l0 && l1 && l0.lat === l1.lat && l0.lng === l1.lng;
export const zoomRadius = zoom => {
  if (zoom > 15) {
    return 1;
  } else if (zoom > 14) {
    return 2;
  } else if (zoom > 13) {
    return 3;
  } else if (zoom > 12) {
    return 5;
  } else if (zoom > 11) {
    return 10;
  } else if (zoom > 10) {
    return 20;
  } else if (zoom > 8) {
    return 100;
  }
  return 250;
};

export const SUGGESTIONS = [
  { name: "Elke Gulden Institut", image: guldenLogo },
  { name: "Trageschule Hamburg", image: trageschuleLogo },
  { name: "BiBeBa", image: bibebaLogo },
  { name: "Einfach Eltern", image: babystepsLogo },
  { name: "MAWIBA", image: mawibaLogo },
  { name: "PEKiP", image: pekipLogo },
  { name: "THEKLA - Thüringer Eltern Kind Lern- & Aktivkurs", image: theklaLogo },
  { name: "Rückbildung" },
  { name: "Entwicklungsbegleitung" },
  { name: "Kinder" },
  { name: "Skating" },
  { name: "Bildung" },
  { name: "Gymnastik" },
  { name: "Beikostberatung" },
  { name: "Schlafcoaching" },
  { name: "Faszien" },
  { name: "Digital" },
  { name: "CANTIENICA®" },
  { name: "Feldenkrais" },
  { name: "Meditation" },
  { name: "Hypnose" },
  { name: "Ernährungsberatung" },
  { name: "Osteopathie" },
  { name: "Wochenbett" },
  { name: "Hypnobirthing" },
  { name: "Doula" },
  { name: "Kindersicherheit" },
  { name: "Beckenboden" },
  { name: "Sternenkinder" },
  { name: "Babymassage" },
  { name: "Baby" },
  { name: "Basteln" },
  { name: "Body-Mind Centering®" },
  { name: "Beratung" },
  { name: "DELFI" },
  { name: "Draußen" },
  { name: "Early English" },
  { name: "Coaching" },
  { name: "ELBA" },
  { name: "Entspannung" },
  { name: "Erziehungsbegleitung" },
  { name: "Ernährung" },
  { name: "Familie" },
  { name: "Fabel" },
  { name: "FENKID" },
  { name: "Förderung" },
  { name: "Geburtsvorbereitung" },
  { name: "Hebammen" },
  { name: "Krabbelgruppe" },
  { name: "Kreativität" },
  { name: "Massage" },
  { name: "Erste-Hilfe" },
  { name: "Geburtstag" },
  { name: "Gesundheit" },
  { name: "KANGA" },
  { name: "Kita" },
  { name: "Kunst" },
  { name: "Lernen" },
  { name: "Motopädie" },
  { name: "Malen" },
  { name: "Musik" },
  { name: "Online-Seminar" },
  { name: "Physiologie" },
  { name: "Psychologie" },
  { name: "Pilates" },
  { name: "Säugling" },
  { name: "Schwangere" },
  { name: "Schwimmen" },
  { name: "Selbstverteidigung" },
  { name: "Singen" },
  { name: "Pikler" },
  { name: "Spa" },
  { name: "Sport" },
  { name: "Sprachen" },
  { name: "Stillberatung" },
  { name: "Tanzen" },
  { name: "Theater" },
  { name: "Spielen" },
  { name: "Töpfern" },
  { name: "Turnen" },
  { name: "Veranstaltung" },
  { name: "Verstehen" },
  { name: "Walking" },
  { name: "Werkstatt" },
  { name: "Yoga" },
  { name: "Yoga mit Kind" },
  { name: "Trageberatung" },
  { name: "Workshop" },
  { name: "Wasserspaß" },
  { name: "Sprachförderung" },
  { name: "THEKLA" },
  { name: "Unfallprävention" },
  { name: "Geschwister" },
  { name: "PreKanga" },
  { name: "Mawiba" },
  { name: "Kindersport" },
  { name: "Musikgarten" },
  { name: "BabySteps" },
  { name: "Babyschwimmen" },
  { name: "Akupunktur" },
  { name: "Kinderschwimmen" },
  { name: "Shiatsu" },
  { name: "Sonstiges" },
  { name: "Babyfotos" },
  { name: "nappydancers®" },
  { name: "babySignal" },
  { name: "FamilySteps" },
  { name: "BiBeBa" },
  { name: "Stoffwindelberatung" },
  { name: "Babyzeichensprache" },
  { name: "artgerecht®" },
  { name: "Musikalische Früherziehung" },
  { name: "Wobbelturnen" },
  { name: "Fit mit Kind" },
  { name: "Dunsten" },
  { name: "Babyschlaf" },
  { name: "Picky Eaters" },
];

export const isMobileView = window.innerWidth < 641;

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.primary};
  background-image: linear-gradient(-108deg, ${props => gradientColors(props.theme.primary)});

  @media (${props => props.theme.desktopScreen}) {
    padding-top: 5rem;
    min-height: 100%;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (${props => props.theme.desktopScreen}) {
    min-width: 100%;
    flex-direction: row;
  }
`;

const ToggleButton = styled(Button)`
  border: none;
  background-color: ${props => props.theme.white};
  border-radius: 50%;
  margin-left: 0.5rem;
  width: 2.625rem;
  height: 2.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${props => props.theme.boxShadowLifted};

  @media (${props => props.theme.desktopScreen}) {
    opacity: 0.75;
    transition: transform 0.5s, opacity 0.5s;
    display: none;

    &:hover {
      border: 0;
      transform: scale(1.2);
      background-color: ${props => props.theme.white};
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  max-width: auto;

  @media (${props => props.theme.desktopScreen}) {
    width: 760px;
    padding: 1rem;
  }
`;

const LiftedContent = styled.section`
  position: absolute;
  box-shadow: 0 -0.4rem 1.9rem rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.light};
  width: 100%;
  height: 85%;
  max-height: 100%;
  padding: 1rem 0.5rem 0;
  z-index: 9;
  order: 1;
  overflow-y: scroll;
  bottom: 0;
  border-radius: ${props =>
    `${props.theme.borderRadiusLarge} ${props.theme.borderRadiusLarge} 0 0`};

  ${({ collapsed, theme }) =>
    collapsed &&
    `
    position: absolute;
    padding: 0;
    height: auto;
    border-radius: ${theme.borderRadius};
    background-color: transparent;
    box-shadow: none;
    overflow-y: hidden;
    pointer-events: none;
  `}

  @media (${props => props.theme.tabletScreen}) {
    ${({ collapsed, theme }) =>
      collapsed &&
      `
        pointer-events: all;
      `}
  }

  @media (${props => props.theme.desktopScreen}) {
    position: relative;
    padding: 0;
    margin: 0;
    z-index: 0;
    flex: 2;
    min-height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

const MapWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex: 1;
  background-image: url('${mapBlur}');
  background-size: cover;
  opacity: ${props => (props.showMap ? "1" : "0.4")};
  transition: opacity 1s;
  z-index: 0;

  @media (${props => props.theme.desktopScreen}) {
    position: fixed;
    top: 5rem;
    width: calc(100% - 774px);
    min-width: 0;
    height: calc(100vh - 7rem);
    min-height: 0;
    margin: 1rem auto; 
    border-radius: ${props => props.theme.borderRadius};
    overflow: hidden;
    z-index: 9;
    background-color: green;
  }
`;

const NoResults = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowLifted};
  border-radius: ${props => props.theme.borderRadius};
  margin: 1rem;
  max-width: 90%;
  text-align: center;
  margin: 0.5rem auto;
  display: block;

  ${Heading3} {
    font-size: ${props => props.theme.fontSizes.default};
    color: ${props => props.theme.info};
    margin-bottom: 0;
  }

  & > div {
    display: none;
  }

  @media (${props => props.theme.desktopScreen}) {
    & > div {
      display: block;
    }

    ${Heading3} {
      font-size: 1.75rem;
      margin-bottom: 0.5rem;
    }
  }
`;

const ActionRow = styled.div`
  display: flex;
  margin-top: 0.25rem;

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 0;
    margin-left: 0.5rem;
    flex: 3;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem;
  pointer-events: none;
  flex-shrink: 0;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;

  & > * {
    pointer-events: all;
  }

  ${({ sticky, theme }) =>
    sticky &&
    `
      position: fixed;
      top: 4rem;
      z-index: 1;
      border-radius: 5rem;
      padding: 0.5rem;
      z-index: 11;
      width: 100%;
      max-width: 640px;
      margin: 0;
      
      @media (${theme.tabletScreen}) {
        left: 50%;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(8px);
        box-shadow: ${theme.boxShadowLifted};
        transform: translateX(-50%);
        flex-direction: row;
      }

      @media (${theme.desktopScreen}) {
        top: 3rem;
      }
    `}

  @media (${props => props.theme.tabletScreen}) {
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: wrap;
    max-width: 640px;
  }
`;

const ResultsDisplay = styled.div`
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowLifted};
  border-radius: 2rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 85px;
  padding: 0 0.5rem;
  line-height: 1rem;
  color: ${props => props.theme.black};
  cursor: pointer;

  & > div {
    padding: 0;
    margin-left: 0.5rem;
    & > div {
      margin: 0;
    }
  }
`;

const Intro = styled.div`
  padding: 0.5rem;
  text-align: center;
  max-width: 640px;
  margin: 0 auto 1rem;
  color: ${props => props.theme.white};
  margin-top: 4rem;

  ${Heading3} {
    color: ${props => props.theme.white};
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 5%;
  }
`;

const SearchIcon = styled.img`
  width: 100%;
  height: auto;
  max-width: 120px;
  min-width: 60px;
  display: inline-block;
  margin: 0.25rem auto;
`;

const OnlineSearchLink = styled(NavLink)`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.white};
  text-decoration: none;
  box-shadow: ${props => props.theme.boxShadow};
  background-color: ${props => props.theme.warning};
  overflow: hidden;
  white-space: nowrap;
  pointer-events: all;

  width: auto;
  max-width: 54px;
  height: 54px;
  padding: 0.8rem;
  margin-left: 0.25rem;
  border-radius: 50%;
  transition: max-width 0.25s ease-out, height 0.25s;
  z-index: 12;

  & > i {
    margin-right: 1rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    max-width: 320px;
    border-radius: 2rem;
    height: 48px;
  }

  @media (${props => props.theme.desktopScreen}) {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  &:hover {
    max-width: 320px;
    border-radius: 2rem;
    height: 48px;
  }
`;

const Search = ({
  address,
  center,
  term,
  changeAddress,
  clearAddress,
  searchProviders,
  listView,
  isLoading,
  changeListView,
  results,
  metadata,
  intl,
  clearSearch,
}) => {
  const params = getSearchParams() || {};

  const [defaultViewport, setDefaultViewport] = useState(null);
  const [viewport, setViewport] = useState(null);
  const [stateTerm, setStateTerm] = useState(params.term || term || "");
  const [searched, setSearched] = useState(false);
  const [location, setLocation] = useState(null);
  const [bounds, setBounds] = useState(null);

  // Save origin viewport for search optimization on map move
  const origin = useRef(null);

  // Throttle search requests when map is moved quickly
  const lastRequest = useRef(null);

  // Initial set of center after incoming search request by params
  useEffect(() => {
    const params = getSearchParams() || {};
    const { address, lat, lng } = params;

    if (lat && lng) {
      changeAddress(address, parseFloat(lat), parseFloat(lng));
    }
  }, [changeAddress, changeListView]);

  // Update viewport when center (redux) has changed
  useEffect(() => {
    if (!center || isEmpty(center)) {
      setDefaultViewport(null);
      setSearched(false);
      return;
    }

    const zoom =
      center.lat === DEFAULT_VIEWPORT.center.lat && center.lng === DEFAULT_VIEWPORT.center.lng
        ? DEFAULT_VIEWPORT.zoom
        : DEFAULT_ZOOM;

    setDefaultViewport(currentViewport => {
      const params = getSearchParams() || {};
      const lat = params.lat && parseFloat(params.lat);
      const lng = params.lng && parseFloat(params.lng);
      if (!currentViewport && lat && lng) {
        return { center: { lat, lng }, zoom };
      }
      return { ...currentViewport, center, zoom };
    });
  }, [center, setDefaultViewport, setSearched]);

  // Main search effect. Should be called on any change to viewport or search term
  useEffect(() => {
    // Do not search if location is needed but blank
    if (!viewport) {
      clearSearch();
      return;
    }

    // Check when the last search request was and reject if
    // necessary (prevent search requests on fast zoom)
    if (lastRequest.current && Date.now() - lastRequest.current < REQUEST_TIMING) {
      return;
    }

    // Do not search if radius hasn't exceeded
    if (origin.current && viewport) {
      const distance = distanceGeo(origin.current.center, viewport.center);
      const radius = zoomRadius(viewport.zoom) * 1.25;
      const zoom = origin.current.zoom - viewport.zoom;

      if (distance < radius && zoom < ZOOM_CHANGE_SEARCH) {
        console.log("Skip search, distance moved too low", distance, radius, zoom);
        return;
      } else {
        console.log("Search again");
      }
    }
    origin.current = viewport;

    searchProviders(stateTerm, zoomRadius(viewport.zoom), viewport.center, false);
    lastRequest.current = Date.now();
  }, [stateTerm, viewport, searchProviders, clearSearch]);

  // Sets the viewport whenever a location changed (e.g. after Marker select)
  useEffect(() => {
    if (!location) {
      return;
    }

    setDefaultViewport(currentViewport => ({
      ...currentViewport,
      center: latLng(location.lat, location.lng),
      zoom: Math.min(MAX_LOCATION_ZOOM),
    }));
  }, [location, setDefaultViewport]);

  // NOTE: unfortunantely this gets triggered after router event, but still leave it
  // in case the component unmounts of other reasons
  useEffect(() => {
    // Nothing to prepare
    return () => {
      clearSearch();
    };
  }, [clearSearch]);

  // NOTE: only set bounds on initial map entry, but not on updated searches
  useEffect(() => {
    if (searched) {
      return;
    }

    if (!metadata || !metadata.bounds) {
      return;
    }

    if (!center || isEmpty(center) || !origin.current) {
      return;
    }

    if (center.lat !== origin.current.lat || center.lng !== origin.current.lng) {
      return;
    }

    setBounds(metadata.bounds);
    setSearched(true);
  }, [metadata, center, searched]);

  // Trigger new search
  const onCategoryClick = ({ name }) => {
    if (
      name &&
      window.confirm(
        intl.formatMessage({ id: "pages.SearchMap.searchCategory" }, { category: name }),
      )
    ) {
      onSubmit(name);
      ReactGA.event({
        category: "Category",
        action: name || "",
      });
    }
  };

  const showAllResults = () => {
    if (!results || results.length < 1) {
      return;
    }

    const markers = featureGroup(results.map(l => marker([l.lat, l.lng])));
    const bounds = flatBounds(markers.getBounds());

    setBounds(bounds);
  };

  // Open the provider page from list or makler click
  const onLocationOpen = slug => {
    const win = window.open(`/${slug}`, "_blank");
    if (win && win.focus) {
      win.focus();
    }

    ReactGA.event({
      category: "SearchMarkerClick",
      action: slug || "-",
    });
  };

  // Set location from either list or marker
  const onLocationSelect = newLocation => {
    if (location === newLocation && (newLocation.providers || []).length > 0) {
      return onLocationOpen(newLocation.providers[0].slug);
    }
    setLocation(newLocation);
  };

  // Update search term and reset origin to trigger new search
  const onSubmit = value => {
    origin.current = null;
    setStateTerm(value);
  };

  // A new address was selected from auto complete field
  const onAddressSelect = (address, lat, lng) => {
    clearSearch();
    origin.current = null;
    changeAddress(address, lat, lng);
  };

  const showMap = defaultViewport && Object.keys(defaultViewport.center).length > 0;

  return (
    <Wrapper>
      <UserNav />
      <Incentive />
      <OnlineSearchLink
        onClick={() => clearSearch()}
        to={routes.search}
        style={
          listView || (!showMap && !searched)
            ? {
                maxWidth: "320px",
                borderRadius: "2rem",
                height: "48px",
              }
            : {}
        }
      >
        <Icon name="display" size="large" />
        <FormattedMessage id="pages.SearchMap.search" />
      </OnlineSearchLink>

      {(!center || isEmpty(center)) && !searched && (
        <Intro>
          <SearchIcon src={searchImage} />
          <Heading3>
            <FormattedMessage id="pages.SearchMap.title" />
          </Heading3>
          <Paragraph>
            <FormattedMessage id="pages.SearchMap.intro" />
          </Paragraph>
        </Intro>
      )}

      <Actions sticky={center && !isEmpty(center)}>
        <Address
          onClear={clearAddress}
          onSelect={(address, { lat, lng }) => onAddressSelect(address, lat, lng)}
          onCenter={() => {
            center &&
              center.lat &&
              center.lng &&
              setDefaultViewport(currentViewport => ({
                ...currentViewport,
                ...center,
              }));
          }}
          address={address}
          defaultExpanded
        />

        <ActionRow>
          <FloatingInput
            name="search"
            defaultValue={stateTerm}
            busy={isLoading}
            onSubmit={onSubmit}
            defaultExpanded={listView || !searched || (results || []).length < 1}
            placeholder={intl.formatMessage({ id: "pages.SearchMap.all" })}
            suggestions={SUGGESTIONS}
          />

          {center && !isEmpty(center) && (
            <>
              <ResultsDisplay onClick={showAllResults}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <FormattedMessage
                    id="pages.SearchMap.results"
                    values={{ count: results.length }}
                  />
                )}
              </ResultsDisplay>
              <ToggleButton
                onClick={e => {
                  e.preventDefault();
                  changeListView(!listView);
                }}
                disabled={!center}
              >
                <Icon name={listView ? "Map" : "List"} size="small" />
              </ToggleButton>
            </>
          )}
        </ActionRow>
      </Actions>
      <SearchWrapper>
        <LiftedContent collapsed={!listView}>
          <Content>
            {results.length > 0 ? (
              <LocationList
                results={results || []}
                center={center}
                currentCenter={viewport || defaultViewport}
                currentLocation={location}
                onSelect={onLocationSelect}
                onOpen={onLocationOpen}
                onCategoryClick={onCategoryClick}
                onZoomOut={showAllResults}
                listView={listView}
                franchise={metadata && metadata.franchise}
              />
            ) : (
              searched && (
                <NoResults>
                  <Heading3>
                    <FormattedMessage id="pages.SearchMap.noResults.title" />
                  </Heading3>
                  <Lead />
                </NoResults>
              )
            )}
          </Content>
        </LiftedContent>

        {showMap && (
          <MapWrapper showMap={showMap} onClick={() => listView && changeListView(false)}>
            <Map
              defaultViewport={defaultViewport}
              locations={results}
              currentLocation={location}
              onViewportChanged={setViewport}
              onMarkerClick={onLocationSelect}
              onMarkerNameClick={onLocationOpen}
              defaultBounds={bounds}
              options={{
                minZoom: MIN_ZOOM,
                maxZoom: MAX_ZOOM,
                boundsOptions: { padding: [-100, 0] },
              }}
            />
          </MapWrapper>
        )}
      </SearchWrapper>
    </Wrapper>
  );
};

Search.propTypes = {
  visitedProviders: PropTypes.object,
  center: PropTypes.object,
  address: PropTypes.string,
  term: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  listView: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  results: PropTypes.array,
  metadata: PropTypes.object,

  /* Dispatchable actions*/
  changeListView: PropTypes.func.isRequired,
  clearAddress: PropTypes.func.isRequired,
  changeAddress: PropTypes.func.isRequired,
  searchProviders: PropTypes.func.isRequired,
};

Search.defaultProps = {
  visitedProviders: null,
  center: null,
  address: null,
  results: [],
  metadata: {},
  term: null,
};

export default injectIntl(Search);
