import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { darken, rgba } from "polished";
import { COLOR_VARIATION } from "../theme";

const ToggleOption = styled.button`
  position: relative;
  min-width: 50%;
  flex: 1;
  color: ${props => props.theme.gray600};
  z-index: 1;

  background: transparent;
  cursor: pointer;
  user-select: none;
  outline: none;
  word-break: normal;
  border: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  transition: color 0.5s;
  &:hover {
    color: ${props => props.theme.success};
  }

  @media (${props => props.theme.tabletScreen}) {
    display: block;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 320px;
  min-height: 2.625rem;
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => props.theme.light};
  margin-bottom: 1rem;
  display: flex;
  background-color: ${props => props.theme.light};
  position: relative;
  box-shadow: ${props => props.theme.boxShadowInset}

  &:after {
    content: "";
    width: 50%;
    height: 100%;
    position: absolute;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => props.theme.success};
    margin-left: ${props => (props.checked ? "50%" : 0)};
    box-shadow: ${props =>
      "0 0.6rem 1.5rem " + rgba(darken(COLOR_VARIATION, props.theme.success), 0.5)};
    transition: margin-left 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }

  ${ToggleOption} {
    &:first-child {
      color: ${props => (!props.checked ? props.theme.white : false)};
    }

    &:last-child {
      color: ${props => (props.checked ? props.theme.white : false)};
    }
  }
`;

const Toggle = ({ className, checked, leftHand, rightHand, onChange }) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onChange(!checked);
  };
  return (
    <Wrapper checked={checked} className={className}>
      <ToggleOption type="button" onClick={handleClick}>
        {leftHand}
      </ToggleOption>
      <ToggleOption type="button" onClick={handleClick}>
        {rightHand}
      </ToggleOption>
    </Wrapper>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  leftHand: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  rightHand: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Toggle.defaultProps = {
  checked: false,
  onChange: () => {},
  className: "",
};

export default Toggle;
