import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedHTMLMessage } from "react-intl";
import { formatAgeSpan } from "../../utils/formatters";
import { sortBy } from "lodash";

import {
  Heading3,
  Category,
  Icon,
  Small,
  RichText,
  Hidden,
  Container,
  Address,
} from "../../components";
import Lead from "./Lead";
import Franchise from "./Franchise";

import wordmark from "../../assets/kikudoo-wordmark-desat.png";
import { isSameLocation, isMobileView } from "./SearchMap";
import { isInBounds } from "../../components/Map";

const BOUNDS_PADDING = 0.0015;

const HiddenSM = styled.div`
  display: none;
  @media (${props => props.theme.desktopScreen}) {
    display: block;
  }
`;

const LocationsContainer = styled(Container)`
  height: 100%;
  overflow-y: scroll;
  padding: 0;

  @media (${props => props.theme.desktopScreen}) {
    overflow-y: unset;
  }
`;

const Location = styled.li`
  border-radius: ${props => props.theme.borderRadius};
  cursor: pointer;
  background-color: ${props => props.theme.gray100};
  min-width: 90%;
  max-width: 90%;
  min-height: 100%;
  margin-right: 0.5rem;
  position: relative;
  border: 2px solid ${props => props.theme[props.focus ? "pink" : "white"]};
  box-shadow: ${props => props.theme.boxShadow};
  display: flex;
  transition: transform 1s, box-shadow 1s, height 0.5s;

  &:hover {
    transform: scale(1.02);
    box-shadow: ${props => props.theme.boxShadowLifted};
  }

  @media (${props => props.theme.tabletScreen}) {
    max-width: 18rem;
    min-width: 18rem;
  }

  @media (${props => props.theme.desktopScreen}) {
    min-width: 100%;
    min-height: 10rem;
    margin: 0.5rem 0;

    &:before {
      position: static;
      flex: 1;
      min-width: 150px;
    }
  }
`;

const LocationImage = styled.div`
  background-position: center;
  background-size: cover;
  background-color: ${props => props.theme.light};
  border-radius: ${props => props.theme.borderRadius} 0 0 ${props => props.theme.borderRadius};
  flex: 0 0 8rem;

  @media (${props => props.theme.desktopScreen}) {
    flex: 1;
    min-width: 120px;
    max-width: 240px;
  }
`;

const LocationName = styled.div`
  padding: 0.5rem 0.5rem 0.1rem;
  background-color: ${props => props.theme.white}
  margin-bottom: 0;
  flex: 1;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > ${Small} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
  }

  @media (${props => props.theme.desktopScreen}) {
    background-color: ${props => props.theme.light};
    color: ${props => props.theme.dark};
    margin-left: 1rem;
    width: auto;
    flex: 2;
    justify-content: flex-start;
  }
`;

const Categories = styled.aside`
  position: static;
  top: -8px;
  right: 0.2rem;

  display: flex;
  flex-wrap: wrap;
  max-height: 4rem;
  transition: max-height 0.5s;
  &:hover {
    max-height: 5rem;
  }

  & > div {
    margin-right: 0.15rem;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size ${props => props.theme.fontSizes.tiny};

    &:last-child {
      display: none;
    }
  }


  @media (${props => props.theme.desktopScreen}) {
    overflow: hidden;
    & > div {
      font-size ${props => props.theme.fontSizes.small};
      box-shadow: ${props => props.theme.boxShadowSmall};

      &:last-child {
        display: none;
      }
    }
  }
`;

const LocationTitle = styled(Heading3)`
  font-size: 1rem;
  color: ${props => props.theme.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 3.5rem;
  margin-bottom: 0;

  @media (${props => props.theme.desktopScreen}) {
    font-size: 1.175rem;
    max-height: 100%;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Bubble = styled.span`
  border-radius: 1rem;
  padding: 0.1rem 0.625rem 0.15rem;
  color: ${props => props.theme.white};
  font-weight: bold;
  margin-right: 0.5rem;
  background-color: ${props => (props.color ? props.theme[props.color] : props.theme.info)};
`;

const LocationInfo = styled.div`
  display: none;
  flex-direction: column;
  padding: 0.5rem;
  min-width: 120px;
  max-width: 260px;

  @media (${props => props.theme.desktopScreen}) {
    display: flex;
  }
`;

const MoreLocations = styled(Location)`
  text-align: center;
  line-height: 1.3rem;
  padding: 0 1rem;
  align-items: center;
  &:before {
    display: none;
  }

  & > strong {
    font-size: 3.85rem;
    line-height: 3.85rem;
    display: block;
  }

  @media (${props => props.theme.desktopScreen}) {
    display: none;
  }
`;

const Distance = styled.span`
  background-color: ${props => props.theme.info};
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.fontSizes.tiny};
  font-family: ${props => props.theme.fontFamilyMonospace};

  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.2rem 0.25rem 0.1rem;
  border-radius: 2rem;
  line-height: ${props => props.theme.fontSizes.tiny};

  @media (${props => props.theme.desktopScreen}) {
    font-size: ${props => props.theme.fontSizes.small};
    padding: 0.5rem;
  }
`;

const Wrapper = styled.ol`
  list-type: none;
  display: flex;
  overflow: auto;
  margin-top: 0.5rem;
  padding: 0.5rem 0.25rem;
  min-width: 100%;
  /* background-color: ${props => props.theme.gray100}; */
  height: 8rem;
  pointer-events: all;

  ${({ listView, theme }) =>
    listView &&
    `
    display: block;
    height: auto;

    & > li {
      margin-bottom: 0.5rem;
      min-width: 100%;
      max-width: 100%;
      height: 8rem;
    }
  `}

  @media (${props => props.theme.desktopScreen}) {
    height: auto;
    padding: 0;
    flex-wrap: wrap;
    overflow: visible;
  }
`;

const LocationList = ({
  results,
  currentCenter,
  currentLocation,
  onSelect,
  onOpen,
  onCategoryClick,
  onZoomOut,
  listView,
  franchise,
}) => {
  const [allProviders, setAllProviders] = useState([]);
  const [providers, setProviders] = useState([]);

  const listRef = useRef(null);

  const orderList = useCallback(() => {
    const filtered = sortBy(
      allProviders.filter(({ lat, lng, franchise }) =>
        isInBounds({ lat, lng }, currentCenter.bounds, BOUNDS_PADDING),
      ),
      // Sort externals down always
      p => p.distance + (p.external ? 999999 : 0),
    );

    setProviders(filtered);
  }, [allProviders, setProviders, currentCenter]);

  useEffect(() => {
    const providers = (results || []).reduce(
      (a, l) =>
        a.concat(l.providers || []).map(p => ({
          city: l.city,
          distance: l.distance,
          lat: l.lat,
          lng: l.lng,
          postalCode: l.postalCode,
          street: l.street,
          ...p,
        })),
      [],
    );

    setAllProviders(providers);
    return () => {
      setAllProviders([]);
    };
  }, [results, setAllProviders]);

  useEffect(() => {
    if (!results || results.length < 1) {
      setProviders([]);
      setAllProviders([]);
      return;
    }

    if (!currentCenter || !currentCenter.bounds) {
      return;
    }

    orderList();

    // Flatten locations to providers
  }, [results, setProviders, setAllProviders, orderList, currentCenter]);

  useEffect(() => {
    if (!currentLocation) {
      return;
    }

    if (providers.length < 1) {
      return;
    }

    if (listRef && listRef.current) {
      const element = listRef.current.querySelector(
        `[data-location="${currentLocation.lat},${currentLocation.lng}"]`,
      );
      if (element) {
        element.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [currentLocation, providers]);

  const categoriesList = categories => (
    <Categories>
      {categories.slice(0, 5).map((category, index) => (
        <Category
          onClick={onCategoryClick}
          key={`${index}-${category.name}`}
          category={category}
          inverted={isMobileView}
        />
      ))}
      {categories.length > 5 && (
        <Category category={{ name: " ... ", description: "" }} inverted={isMobileView} />
      )}
    </Categories>
  );

  if (allProviders.length < 1) {
    return (
      <LocationsContainer>
        <Wrapper ref={listRef} listView={listView}>
          {franchise && <Franchise franchise={franchise} />}
        </Wrapper>
      </LocationsContainer>
    );
  }

  // <FormattedMessage
  //   id="pages.SearchMap.resultsNear"
  //   values={{ count: providers.length, radius: zoomRadius(currentCenter.zoom) * 2 }}
  // />

  const count = allProviders.length;
  return (
    <LocationsContainer>
      <Wrapper ref={listRef} listView={listView}>
        {franchise && <Franchise franchise={franchise} />}
        {providers.map(
          ({
            lat,
            lng,
            street,
            postalCode,
            description,
            city,
            id,
            name,
            logo,
            image,
            external,
            categories,
            slug,
            ageSpan,
            courseCount,
            onlineCourseCount,
            distance,
            color,
            franchise,
          }) => (
            <Location
              key={[id, lat].join()}
              data-location={[lat, lng].join(",")}
              onClick={() =>
                currentLocation && isSameLocation(currentLocation, { lat, lng })
                  ? onOpen(slug)
                  : onSelect({ lat, lng })
              }
              focus={currentLocation && isSameLocation(currentLocation, { lat, lng })}
            >
              <LocationImage
                style={{
                  backgroundImage: external
                    ? `url('${wordmark}')`
                    : `url('${logo || image || wordmark}')`,
                }}
              />
              <LocationName>
                {distance > 0 && <Distance>{`${distance.toFixed(2)}km`}</Distance>}
                <LocationTitle>{name}</LocationTitle>
                <Small>
                  <Address address={{ street, postalCode, city }} inline />
                </Small>
                <HiddenSM>
                  <RichText text={description || ""} />
                </HiddenSM>
                {isMobileView && categoriesList(categories)}
              </LocationName>
              <LocationInfo>
                {!isMobileView && categoriesList(categories)}
                {courseCount > 0 && (
                  <InfoItem>
                    <Bubble>{courseCount}</Bubble>
                    <FormattedHTMLMessage id={"course.many"} />
                  </InfoItem>
                )}
                {onlineCourseCount > 0 && (
                  <InfoItem>
                    <Bubble color="warning">{onlineCourseCount}</Bubble>
                    <FormattedHTMLMessage id={"pages.SearchMap.onlineCourses"} />
                  </InfoItem>
                )}
                {ageSpan && ageSpan.max > 0 && (
                  <InfoItem>
                    <Icon name="baby" />
                    <Icon name="child-reaching" />
                    <span style={{ marginLeft: "1rem" }}>
                      {formatAgeSpan(ageSpan.min, ageSpan.max, true)}
                    </span>
                  </InfoItem>
                )}
              </LocationInfo>
            </Location>
          ),
        )}
        {count - providers.length > 0 && (
          <MoreLocations onClick={onZoomOut}>
            <strong>{count - providers.length}</strong>
            <FormattedHTMLMessage id="pages.SearchMap.moreResultsNear" />
          </MoreLocations>
        )}

        <Hidden size={["sm", "md"]} as="li" style={{ minWidth: "100%" }}>
          <Lead />
        </Hidden>
      </Wrapper>
    </LocationsContainer>
  );
};

LocationList.propTypes = {
  results: PropTypes.array.isRequired,
  currentLocation: PropTypes.object,
  center: PropTypes.object,
  currentCenter: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onCategoryClick: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  listView: PropTypes.bool,
  franchise: PropTypes.object,
};

LocationList.defaultProps = {
  center: {},
  currentCenter: {},
  currentLocation: null,
  listView: false,
  franchise: null,
};

export default LocationList;
