import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import provider from "./reducers/provider";
import courses from "./reducers/courses";
import user from "./reducers/user";
import ui from "./reducers/ui";
import bookings from "./reducers/bookings";
import posts from "./reducers/posts";
import waitList from "./reducers/waitList";
import search from "./reducers/search";

export default history =>
  combineReducers({
    router: connectRouter(history),
    provider,
    courses,
    ui,
    user,
    bookings,
    posts,
    waitList,
    search,
  });
