import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { Input, Button, Alert } from "..";
import { NavLink } from "react-router-dom";
import routes from "../../routes";

const Form = styled.form``;

const Actions = styled.div`
  display: flex;
  margin-top: 1.5rem;
  & > button {
    flex: 1;
  }
`;

const LinkActions = styled.section`
  text-align: right;
  font-size: ${props => props.theme.fontSizes.small};
`;

const LoginForm = ({ intl, isLoading, login }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [failed, setFailed] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    setFailed(false);

    try {
      await login({ email, password });
    } catch {
      setFailed(true);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {failed && (
        <Alert type="danger">
          <FormattedMessage id="components.LoginForm.failed" />
        </Alert>
      )}
      <Input
        name="email"
        type="email"
        value={email}
        placeholder={intl.formatMessage({ id: "user.email" })}
        onChange={({ target: { value } }) => setEmail(value)}
        hasError={failed}
      />
      <Input
        name="password"
        type="password"
        value={password}
        placeholder={intl.formatMessage({ id: "user.password" })}
        onChange={({ target: { value } }) => setPassword(value)}
        hasError={failed}
      />
      <LinkActions>
        <NavLink to={routes.resetPassword}>
          <FormattedMessage id="actions.forgotPassword" />
        </NavLink>
      </LinkActions>
      <Actions>
        <Button color="success" type="submit" busy={isLoading} glow>
          <FormattedMessage id="actions.login" />
        </Button>
      </Actions>
    </Form>
  );
};

LoginForm.propTypes = {
  token: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(LoginForm);
