import { ApiClient } from "../../utils/Api";

export const ActionTypes = {
  POSTS_SUCCESS: "kikudoo/posts/success",
  POSTS_REQUEST: "kikudoo/posts/request",
  POSTS_FAILED: "kikudoo/posts/failed",
};

export const postsRequest = () => ({
  type: ActionTypes.POSTS_REQUEST,
});

export const postsFailed = () => ({
  type: ActionTypes.POSTS_FAILED,
});

export const postsSuccess = posts => ({
  type: ActionTypes.POSTS_SUCCESS,
  payload: posts,
});

export const loadPosts = providerId => async (dispatch, getState) => {
  dispatch(postsRequest());

  const posts = await new ApiClient().posts(providerId);
  dispatch(postsSuccess(posts));
};
