import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedDate, FormattedTime, FormattedHTMLMessage } from "react-intl";
import { formatAddress, gradientColors } from "../../utils/formatters";
import { EVENT_TYPES } from "../../utils/courses";

import PriceOptions from "./PriceOptions";
import Icon from "../../components/Icon";
import { InfoList } from "../../components";
import { NavLink, useHistory } from "react-router-dom";
import routes, { routeWithParams } from "../../routes";
import { getBookedEventIds } from "../../store/selectors/user";
import { DEFAULT_TIMEZONE } from "../../utils/calc";

export const PRODUCT_MODEL = "Product";

const validForTranslation = (days = 0) => {
  if (days === 7) {
    return "onDemandEventsOneWeek";
  } else if (days === 14) {
    return "onDemandEventsTwoWeeks";
  } else if (days === 30) {
    return "onDemandEventsOneMonth";
  } else if (days === 90) {
    return "onDemandEventsThreeMonths";
  } else if (days === 180) {
    return "onDemandEventsHalfYear";
  } else if (days === 365) {
    return "onDemandEventsYear";
  }
  return "onDemandEventsUnlimited";
};

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 1rem;
  & > ul {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const EventName = styled.span`
  display: inline-block;
  color: ${props => props.theme.black};
`;

const EventInfo = styled.span`
  display: block;
  text-align: right;
  margin-bottom: 0.25rem;
  color: ${props => props.theme.dark};
`;

const EventAdditionalInfo = styled.small`
  display: block;
  color: ${props => props.theme.info};
`;

const Address = styled.address`
  font-size: ${props => props.theme.fontSizes.small};

  & > i {
    margin-right: 0.25rem;
  }
`;

const Trainers = styled.p`
  text-align: right;
  margin-bottom: 0.5rem;
`;

const BookedHint = styled.a`
  position: absolute;
  bottom: -0.5rem;
  right: 0.5rem;
  padding: 0.35rem 1rem;
  background-color: ${props => props.theme.success};
  background-image: linear-gradient(64deg, ${props => gradientColors(props.theme.success, 12)});
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.fontSizes.small};
  z-index: 1;
  border-radius: ${props => props.theme.borderRadiusLarge};
  box-shadow: ${props => props.theme.boxShadowSmall};
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  opacity: 0.8;
  transition: opacity 0.5s;

  & > i {
    margin-right: 0.5rem;
  }

  @media (${props => props.theme.desktopScreen}) {
    bottom: -0.75rem;
  }

  &:hover {
    opacity: 1;
  }
`;

const Event = ({ event, course }) => {
  const history = useHistory();
  const eventIds = useSelector(getBookedEventIds);

  const [isBooked, setIsBooked] = useState(false);

  useEffect(() => {
    setIsBooked((eventIds || []).includes(event.id));
  }, [eventIds, event, setIsBooked]);

  const getAppointment = appointment => (
    <p key={appointment.id}>
      {event.typeOf === EVENT_TYPES.continuous ? (
        <FormattedDate
          weekday="short"
          value={Date.parse(appointment.startDate)}
          timeZone={DEFAULT_TIMEZONE}
        />
      ) : (
        <FormattedDate
          weekday="short"
          month="short"
          day="2-digit"
          year="2-digit"
          value={Date.parse(appointment.startDate)}
          timeZone={DEFAULT_TIMEZONE}
        />
      )}
      {", "}
      <FormattedTime value={Date.parse(appointment.startDate)} timeZone={DEFAULT_TIMEZONE} />
      -
      <FormattedTime value={Date.parse(appointment.endDate)} timeZone={DEFAULT_TIMEZONE} />
    </p>
  );

  const handlePriceOptionClick = priceOption => {
    if (!priceOption) {
      return;
    }

    if (priceOption.model === PRODUCT_MODEL) {
      return history.push(
        routeWithParams(
          routes.contracts.new,
          {
            id: priceOption.id,
          },
          {
            from: routeWithParams(routes.course, {
              slug: course.provider.slug,
              id: course.id,
            }),
            courseId: course.id,
            eventId: event.id,
          },
        ),
      );
    }

    history.push(
      routeWithParams(
        routes.event,
        {
          slug: course.provider.slug,
          courseId: course.id,
          id: event.id,
        },
        { priceOption: priceOption.id },
      ),
    );
  };

  const multipleTrainers = (course.contacts || []).length > 1;
  const trainers = event.trainers || [];

  const isBookable =
    course.isBookable &&
    event &&
    ((event.priceOptions || []).length > 0 || (event.products || []).length > 0);

  return (
    <Wrapper>
      {isBooked && (
        <BookedHint as={NavLink} to={routes.profile}>
          <Icon name="check" size="small" color="white" />
          <FormattedMessage id="pages.Course.alreadyBooked" />
        </BookedHint>
      )}
      <InfoList
        items={[
          {
            label: (
              <EventName>
                <strong>{event.name}</strong>
                {((event.description || "") !== "" ||
                  event.typeOf === EVENT_TYPES.flexible ||
                  event.typeOf === EVENT_TYPES.onDemand ||
                  event.typeOf === EVENT_TYPES.continuous) && (
                  <EventInfo>
                    {(event.description || "") !== "" ? (
                      <span>
                        {event.typeOf === EVENT_TYPES.onDemand && (
                          <>
                            <FormattedHTMLMessage id={`components.Course.${event.typeOf}Events`} />
                            <br />
                          </>
                        )}
                        {event.description}
                      </span>
                    ) : (
                      <FormattedHTMLMessage id={`components.Course.${event.typeOf}Events`} />
                    )}
                    {event.typeOf === EVENT_TYPES.onDemand && event.validDays > 0 && (
                      <EventAdditionalInfo>
                        <FormattedMessage
                          id="components.Course.onDemandEventsValidFor"
                          values={{
                            timing: (
                              <FormattedMessage
                                id={`components.Course.${validForTranslation(event.validDays)}`}
                              />
                            ),
                          }}
                        />
                      </EventAdditionalInfo>
                    )}
                    {event.typeOf === EVENT_TYPES.continuous && event.endDate && (
                      <EventAdditionalInfo>
                        <FormattedMessage
                          id="components.Course.period"
                          values={{
                            endDate: (
                              <FormattedDate
                                month="short"
                                day="numeric"
                                value={Date.parse(event.endDate)}
                              />
                            ),
                            startDate: (
                              <FormattedDate
                                month="short"
                                day="numeric"
                                value={Date.parse(event.startDate)}
                              />
                            ),
                          }}
                        />
                      </EventAdditionalInfo>
                    )}
                  </EventInfo>
                )}
                {course.manyLocations && event.typeOf !== EVENT_TYPES.onDemand && (
                  <Address>
                    <Icon name="location-dot" size="small" color="gray500" />
                    <span>{formatAddress(event.address || course.address)}</span>
                  </Address>
                )}
              </EventName>
            ),
            value: (
              <article>
                {(event.appointments || []).map(appointment => getAppointment(appointment))}
              </article>
            ),
          },
        ]}
      />
      {multipleTrainers && trainers.length > 0 && (
        <Trainers>
          <FormattedMessage id="components.Course.trainers" />
          {trainers.join(", ")}
        </Trainers>
      )}
      <PriceOptions
        event={event}
        course={course}
        isBookable={isBookable}
        onClick={handlePriceOptionClick}
      />
    </Wrapper>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
};

export default Event;
