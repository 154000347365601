import { REHYDRATE } from "redux-persist";
import { ActionTypes as UserActionTypes } from "../actions/user";
import { ActionTypes } from "../actions/bookings";

// Should be valid for max. 5 hours
const bookingExpired = ({ status, createdAt }) =>
  !["created", "pending"].includes(status) ||
  (Date.now() - (typeof createdAt == "number" ? createdAt : Date.parse(createdAt))) /
    (1000 * 60 * 60) >
    5;

const currentBooking = state => {
  if (state && state.current && !bookingExpired(state.current)) {
    return state.current;
  }

  return null;
};

export const initialState = Object.freeze({
  isLoading: false,
  updatedAt: null,
  current: null,
  userBookings: [],
});

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case UserActionTypes.LOGOUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.UPDATE_CURRENT: {
      return {
        ...state,
        current: payload,
        updatedAt: Date.now(),
      };
    }

    case ActionTypes.BOOKINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userBookings: payload,
      };
    }

    case ActionTypes.BOOKING_CONFIRM_REQUEST:
    case ActionTypes.BOOKINGS_REQUEST:
    case ActionTypes.BOOKING_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.BOOKING_CONFIRM_FAILURE:
    case ActionTypes.BOOKING_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.BOOKING_CONFIRM_SUCCESS:
    case ActionTypes.BOOKING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        current: { ...payload, password: state.current && state.current.password },
      };
    }

    case REHYDRATE: {
      const rehydratedState = payload.bookings;
      if (!rehydratedState) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        ...rehydratedState,
        isLoading: false,
        userBookings: [],
        current: currentBooking(rehydratedState),
      };
    }

    default:
      return state;
  }
};
