import { createSelector } from "reselect";
import { CACHE_TTL } from "../index";

export const getCoursesState = state => state.courses;
export const getCourses = state => state.courses.all;

export const isLoading = createSelector(
  getCoursesState,
  state => state.isLoading,
);

export const getCurrentCourse = createSelector(
  getCoursesState,
  state => state.current && (state.all || []).find(c => c.id === state.current),
);

// No yet enabled
export const isExpired = createSelector(
  getCoursesState,
  state => {
    const { updatedAt } = state;

    return !updatedAt || Date.now() - updatedAt > CACHE_TTL;
  },
);

export const getFlexibleEvents = createSelector(
  getCurrentCourse,
  current => (current.events || []).filter(event => event.typeOf === "flexible"),
);

export const getBlockEvents = createSelector(
  getCurrentCourse,
  current => (current.events || []).filter(event => event.typeOf === "block"),
);

export const getContinuousEvents = createSelector(
  getCurrentCourse,
  current => (current.events || []).filter(event => event.typeOf === "continuous"),
);
