import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.ul`
  display: flex;
  margin: 1rem 0;
  padding: 0;
  flex-wrap: wrap;

  @media (${props => props.theme.tabletScreen}) {
    margin: 1rem;
  }
`;

const Logo = styled.div`
  flex: 1;
  min-width: 68px;
  max-width: 68px;
  min-height: 68px;
  margin-right: 1rem;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
`;

const Title = styled.h4`
  color: ${props => props.theme.black};
  flex: 2;
  transition: color 0.5s;
`;

const Address = styled.small`
  flex: 1;
  white-space: pre-wrap;
`;

const Provider = styled.li`
  display: flex;
  flex: 1;
  min-width: 100%;
  align-items: center;
  box-shadow: ${props => props.theme.boxShadowSmall};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 1rem;
  transition: transform 1s, box-shadow 1s;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    box-shadow: ${props => props.theme.boxShadowLifted};
    ${Title} {
      color: ${props => props.theme.info};
    }
  }

  @media (${props => props.theme.tabletScreen}) {
    flex: 1 1 calc(50% - 1rem);
    margin-right: 1rem;
    min-width: 0;
    max-width: calc(50% - 1rem);
  }
`;

const ProviderList = ({ providers, onClick, franchiseLogo }) => {
  return (
    <Wrapper>
      {providers.map(({ id, name, slug, logo, address }) => (
        <Provider key={id} onClick={() => onClick({ id, name, slug, logo })}>
          <Logo style={{ backgroundImage: `url('${logo || franchiseLogo}` }} />
          <section>
            <Title>{name}</Title>
            <Address>
              {[address.street, [address.postalCode, address.city].join(" ")]
                .filter(p => p && p !== "")
                .join(", ")}
            </Address>
          </section>
        </Provider>
      ))}
    </Wrapper>
  );
};

ProviderList.propTypes = {
  providers: PropTypes.array,
  onClick: PropTypes.func,
  franchiseLogo: PropTypes.string,
};

ProviderList.defaultProps = {
  providers: [],
  onClick: () => {},
  franchiseLogo: "",
};
export default ProviderList;
