import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getVisited } from "../../store/selectors/provider";
import { getUserData } from "../../store/selectors/user";

import LandingPage from "./LandingPage";

const mapStateToProps = state => ({
  providers: getVisited(state),
  user: getUserData(state),
});

export default withRouter(connect(mapStateToProps)(LandingPage));
