import React from "react";
import PropTypes from "prop-types";
import CourseList from "../../../components/CourseList";
import { PageContentStandard, Container } from "../../../components/";

const Courses = ({ courses, isLoading }) => (
  <PageContentStandard>
    <Container>
      <CourseList courses={courses} isLoading={isLoading} />
    </Container>
  </PageContentStandard>
);

Courses.propTypes = {
  courses: PropTypes.array,
  isLoading: PropTypes.bool,
};

Courses.defaultProps = {
  courses: [],
  isLoading: false,
};

export default Courses;
