import { REHYDRATE } from "redux-persist";
import { ActionTypes } from "../actions/provider";

const minify = ({ id, slug, name, logo }) => ({
  id,
  slug,
  name,
  logo,
});

export const initialState = Object.freeze({
  isLoading: false,
  current: null,
  updatedAt: null,
  visited: {},
});

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case ActionTypes.PROVIDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        current: payload,
        updatedAt: Date.now(),
        visited: { ...state.visited, [payload.id]: { ...minify(payload), visitedAt: Date.now() } },
      };
    }

    case REHYDRATE: {
      const rehydratedState = payload.provider;
      return {
        ...state,
        ...rehydratedState,
        isLoading: false,
        current: null,
      };
    }

    default:
      return state;
  }
};
