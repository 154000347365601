import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { IntlProvider, addLocaleData } from "react-intl";
import { getLocale } from "./store/selectors/ui";
import { loadUser } from "./store/actions/user";
import { getToken } from "./store/selectors/user";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";

import ErrorPage from "./pages/Error";
import Pages from "./pages";
import LandingPage from "./pages/LandingPage/";
import Profile from "./pages/Profile/";
import ConfirmSuccess from "./pages/ConfirmSuccess/";
import ConfirmError from "./pages/ConfirmError/";
import Search from "./pages/Search/";
import SearchMap from "./pages/SearchMap/";
import Preview from "./pages/Preview/";
import Franchise from "./pages/Franchise/";
import FranchiseMap from "./pages/FranchiseMap/";
import FranchiseSchedule from "./pages/FranchiseSchedule/";
import ResetPassword from "./pages/ResetPassword/";
import EditPassword from "./pages/EditPassword/";
import Account from "./pages/Account";

import defaultEn from "react-intl/locale-data/en";
import en from "./locales/en.json";
import defaultDe from "react-intl/locale-data/de";
import de from "./locales/de.json";

import routes from "./routes";
import theme from "./theme";

const rollbarConfig = {
  accessToken: "cfc58c6a2d614868b306240f0c1028cf",
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== "development",
};

const messages = { en, de };

addLocaleData([...defaultEn, ...defaultDe]);

const App = ({ loadUser, token, locale }) => {
  // Check for user session
  useEffect(() => {
    if (!token) {
      return;
    }

    loadUser();
  }, [loadUser, token]);

  return (
    <IntlProvider
      locale={locale || "de"}
      messages={messages[locale || "de"]}
      timezone={"Europe/Berlin"}
    >
      <ThemeProvider theme={theme}>
        <RollbarProvider config={rollbarConfig}>
          <ErrorBoundary fallback={<ErrorPage />}>
            <Switch>
              <Route path={routes.search} component={Search} exact />
              <Route path={routes.searchMap} component={SearchMap} exact />
              <Route path={routes.profile} component={Profile} exact />
              <Route path={routes.confirmSuccess} component={ConfirmSuccess} exact />
              <Route path={routes.confirmError} component={ConfirmError} exact />
              <Route path={routes.resetPassword} component={ResetPassword} exact />
              <Route path={routes.editPassword} component={EditPassword} exact />
              <Route path={routes.preview} component={Preview} exact />
              <Route path={routes.franchiseSchedule} component={FranchiseSchedule} exact />
              <Route path={routes.franchiseMap} component={FranchiseMap} exact />
              <Route path={routes.franchise} component={Franchise} exact />
              <Route path={routes.profile} component={Account} />
              <Route path={routes.provider} component={Pages} />
              <Route path="*" component={LandingPage} />
            </Switch>
          </ErrorBoundary>
        </RollbarProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

const mapStateToProps = state => ({
  locale: getLocale(state),
  token: getToken(state),
});

const mapDispatchToProps = {
  loadUser,
};

App.propTypes = {
  locale: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
