import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate, FormattedNumber } from "react-intl";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { uniqBy } from "lodash";

import { currencyFormat, titleImage } from "../../utils/formatters";
import routes, { routeWithParams } from "../../routes";
import {
  Card,
  Button,
  Mono,
  Badge,
  ListItems,
  ListItem,
  ListItemName,
  Avatar,
  Separator,
} from "../../components/";

const BookingCard = styled(Card)`
  margin-bottom: 1rem;
`;

const NumberValue = styled(Mono)`
  color: ${props => props.theme.black};
`;

const ContractInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

const Timing = styled.span`
  color: ${props => props.theme.info};
  margin-left: 0.5rem;
`;

const Contract = ({ contract, cancel }) => {
  const courses = uniqBy(
    contract.product.events.map(e => e.course),
    "id",
  );
  const isPlan = contract.product.typeOf === "plan";

  const statusInfo = () => {
    if (contract.active) {
      // Still active but canceled
      if (contract.status === "canceled" || contract.cancelAt) {
        return (
          <span>
            {isPlan ? (
              <FormattedMessage id="contract.attributes.endsAt" />
            ) : (
              <FormattedMessage id="contract.attributes.cancelsAt" />
            )}
            <Timing>
              <FormattedDate value={contract.cancelAt} />
            </Timing>
          </span>
        );
      }
      return (
        <span>
          <FormattedMessage id="contract.attributes.nextPeriodStart" />
          <Timing>
            <FormattedDate value={contract.currentPeriodEnd} />
          </Timing>
        </span>
      );
    }

    return (
      <span>
        <FormattedMessage id="contract.attributes.statuses.ended" />
        <Timing>
          <FormattedDate value={contract.currentPeriodEnd} />
        </Timing>
      </span>
    );
  };

  const product = contract.product;

  // Temp deactivate user-side cancelation
  contract.canCancel = false;

  return (
    <BookingCard
      header={
        <>
          <strong>{`${product.name} / ${contract.event.name}`}</strong>
          <aside>
            {isPlan ? (
              <Badge color="info">
                <FormattedMessage id={"product.attributes.typeOfs.plan"} />
              </Badge>
            ) : contract.active ? (
              <Badge
                color={contract.status === "canceled" || contract.cancelAt ? "warning" : "success"}
              >
                <FormattedMessage id={`contract.attributes.statuses.${contract.status}`} />
              </Badge>
            ) : (
              <Badge color="dark">
                <FormattedMessage id={"contract.attributes.statuses.expired"} />
              </Badge>
            )}
          </aside>
        </>
      }
    >
      <ContractInfo>
        <div>
          <FormattedMessage
            id="contract.attributes.startedAt"
            values={{ date: <FormattedDate value={contract.startedAt} /> }}
          />
          <br />
          <NumberValue>
            <FormattedNumber value={product.price / 100} {...currencyFormat(product)} />{" "}
            {product.intervalType && <FormattedMessage id={`per.${product.intervalType}`} />}
          </NumberValue>
          <p>{statusInfo()}</p>
        </div>
        {contract.canCancel && (
          <aside>
            <Button size="small" onClick={cancel} color="danger" outline>
              <FormattedMessage id="actions.terminate" />
            </Button>
          </aside>
        )}
      </ContractInfo>

      <Separator />

      <ListItems>
        {courses.map(course => (
          <ListItem key={course.id}>
            {(titleImage(course) || titleImage(course.provider)) && (
              <Avatar
                small
                style={{
                  backgroundImage: `url('${titleImage(course) || titleImage(course.provider)}')`,
                }}
              />
            )}
            <ListItemName>
              <NavLink
                to={routeWithParams(routes.course, {
                  slug: course.provider.slug,
                  id: course.id,
                })}
              >
                {course.name}
                <br />
                <small>{course.provider.name}</small>
              </NavLink>
            </ListItemName>
          </ListItem>
        ))}
      </ListItems>
    </BookingCard>
  );
};

Contract.propTypes = {
  contract: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default Contract;
