import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import Loading from "../../components/Loading";

const VIMEO_OPTIONS = {
  background: false,
  byline: false,
  transparent: true,
  responsive: true,
};

const LoadingWrapper = styled.div`
  padding: 25%;
  text-align: center;
`;

const Wrapper = styled.div`
  & > iframe {
    border: 0;
    outline: 0;
    margin: 1rem 0;
  }

  @media (${props => props.theme.tabletScreen}) {
    & > iframe {
      margin: 1rem;
    }
  }
`;

const VimeoVideo = ({ link }) => {
  const [oEmbed, setOEmbed] = useState(null);

  useEffect(() => {
    if (!link || (oEmbed && link.match(oEmbed.video_id))) {
      return;
    }

    const params = Object.keys(VIMEO_OPTIONS)
      .reduce(function(a, k) {
        a.push(k + "=" + encodeURIComponent(VIMEO_OPTIONS[k]));
        return a;
      }, [])
      .join("&");
    axios
      .get(`https://vimeo.com/api/oembed.json?url=${link}?${params}`)
      .then(response => {
        setOEmbed(response.data);
      })
      .catch(error => {
        window.alert(error);
      });
  }, [link, setOEmbed, oEmbed]);

  if (oEmbed) {
    return <Wrapper dangerouslySetInnerHTML={{ __html: oEmbed.html }}></Wrapper>;
  }

  return (
    <LoadingWrapper>
      <Loading text={<FormattedMessage id="loading" />} />
    </LoadingWrapper>
  );
};

VimeoVideo.propTypes = {
  link: PropTypes.string.isRequired,
};

export default VimeoVideo;
