import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import { ContactAvatar, Container } from ".";

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: ${props => (props.clean ? props.theme.dark : props.theme.white)};
  margin: 0.5rem 0.25rem 0.75rem;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-image: url('${props => props.backgroundSrc}');

  @media (${props => props.theme.tabletScreen}) {
    margin: 2rem 0;
    flex-direction: row;
  }
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${props => (props.wallpaper ? "100vh" : "50%")};
  background-size: cover;
  background-position: center;
  background-image: url('${props => props.src}');
  opacity: 0.9;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    content: "";
    background: linear-gradient(178deg,rgba(34,5,51,0.68) 0%,rgba(34,5,51,0.3) 38%,rgba(34,5,51,0) 78%);
  }

  &:after {
    display: ${props => (props.wallpaper ? "block" : "none")};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    content: "";
    background: linear-gradient(-6deg, rgba(73,81,87,0.42) 0%, rgba(34,5,51,0.35) 48%, rgba(34,5,51,0) 75%);
  }
`;

const SubTitle = styled.small`
  display: block;
  font-weight: normal;
  white-space: break-spaces;
`;

const Title = styled.h1`
  font-family: ${props => props.theme.fontFamilyBrand};
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  font-weight: normal;
  word-break: break-word;
  hyphens: auto;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  flex: 1;

  ${SubTitle} {
    font-size: ${props => props.theme.fontSizes.small};
    text-transform: uppercase;
    line-height: 1.4;
    margin-top: 0.25rem;
    opacity: 0.8;
  }

  @media (${props => props.theme.tabletScreen}) {
    letter-spacing: 0.025em;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1;
    text-shadow: 0px 4px 14px #3b3d3e52;
    text-align: left;
    margin-bottom: 0;
    padding-right: 1rem;

    ${SubTitle} {
      text-transform: uppercase;
      width: 75%;
      margin-top: 0.5rem;
    }
  }

  @media (${props => props.theme.desktopScreen}) {
    letter-spacing: 0.05em;

    ${SubTitle} {
      text-transform: uppercase;
      width: 75%;
    }

    &:after {
      display: block;
      width: 80px;
      height: 5px;
      border-radius: 4px;
      background-color: ${props => props.theme.white};
      margin-top: 0.5rem;
      // content: "";
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  max-width: 85%;
  margin: 0 auto;

  @media (${props => props.theme.tabletScreen}) {
    flex-direction: row;
    max-width: 100%;
  }
`;

const Profile = styled.div`
  height: auto;
  color: inherit;
  overflow: hidden;
  max-width: 85%;

  max-height: ${props => (props.visible ? "100px" : 0)};
  transition: max-height 1s;
`;

const Contact = styled.aside`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(60%);
  cursor: pointer;

  @media (${props => props.theme.tabletScreen}) {
    position: static;
    transform: none;
  }
`;

const Logo = styled.div`
  text-align: center;
  cursor: pointer;
  & > img {
    border-radius: ${props => props.theme.borderRadiusSmall};
    overflow: hidden;
    /* background-color: ${props => props.theme.white}; */
    display: block;
    max-width: 60%;
    max-height: ${props => (props.small ? "75px" : "150px")};
    width: auto;
    height: auto;
    margin: auto;
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-right: 1rem;
    & > img {
      max-width: ${props => (props.small ? "60px" : "120px")};
      max-height: 100%;
    }
  }
`;

const Info = styled.aside``;

const PageTitle = ({
  title,
  subTitle,
  contact,
  image,
  wallpaper,
  logo,
  info,
  className,
  onProviderClick,
  onTitleClick,
  clean,
  small,
}) => {
  const [showProfile] = useState(false);
  return (
    <Container className={className}>
      {image && <Cover src={image} wallpaper={wallpaper} />}
      <Wrapper clean={clean}>
        <TitleWrapper>
          {logo && (
            <Logo onClick={onProviderClick} small={small}>
              <img alt={`${title} - Logo`} src={logo} />
            </Logo>
          )}
          {showProfile ? (
            <Title onClick={onProviderClick}>
              <span>
                {contact.fullName}
                <SubTitle>
                  <FormattedMessage id="components.PageTitle.profile" />
                </SubTitle>
              </span>
            </Title>
          ) : (
            <Title onClick={onTitleClick}>
              <span>
                {title}
                {subTitle && <SubTitle>{subTitle}</SubTitle>}
              </span>
            </Title>
          )}
        </TitleWrapper>

        <Info>{info}</Info>

        {contact && contact.avatar && (
          // <Contact onClick={() => setShowProfile(!showProfile)}>
          <Contact onClick={onProviderClick}>
            <ContactAvatar avatar={contact.avatar} />
          </Contact>
        )}
      </Wrapper>
      <Profile visible={showProfile}>
        <p>
          <FormattedMessage id="components.PageTitle.profileTeaser" />
        </p>
        <br />
        <br />
      </Profile>
    </Container>
  );
};

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contact: PropTypes.object,
  image: PropTypes.string,
  wallpaper: PropTypes.bool,
  logo: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  clean: PropTypes.bool,
  small: PropTypes.bool,
  onProviderClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};

PageTitle.defaultProps = {
  subTitle: null,
  image: null,
  wallpaper: true,
  logo: null,
  contact: null,
  info: null,
  className: "",
  clean: false,
  small: false,
  onProviderClick: () => {},
  onTitleClick: () => {},
};

export default PageTitle;
