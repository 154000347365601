import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import Gallery from "../../components/Gallery";

import Details from "./Details";
import Categories from "./Categories";
import { Heading1, Paragraph, RichText, Row, Col } from "../../components";
import Loading, { LoadingContainer } from "../../components/Loading";

const CourseTitle = styled(Heading1)`
  display: none;

  @media (${props => props.theme.tabletScreen}) {
    display: block;
  }

  & > small {
    display: block;
    font-size: 0.5em;
  }
`;

const Content = ({ provider, course }) => {
  return (
    <Row>
      <Col size="md" count={8}>
        {course.images.length > 0 && <Gallery images={course.images} />}
        <CourseTitle>
          {course.name}
          {course.subTitle && course.subTitle !== "" && <small>{course.subTitle}</small>}
        </CourseTitle>
        <Categories course={course} />
        <Paragraph>{course.shortDescription}</Paragraph>
        {!course.index ? (
          <RichText text={course.description || ""} />
        ) : (
          <LoadingContainer>
            <Loading
              small
              shadow={false}
              text={<FormattedMessage id="components.Course.loadingContent" />}
            />
          </LoadingContainer>
        )}
      </Col>
      <Col size="md" count={4}>
        <Details course={course} contacts={course.contacts || provider.contacts || []} />
      </Col>
    </Row>
  );
};

Content.propTypes = {
  course: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
};

export default Content;
