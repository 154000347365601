import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import styled, { keyframes, createGlobalStyle } from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import routes from "../routes";
import { gradientColors } from "../utils/formatters";

import Footer from "../components/Footer";
import LoadingOverlay from "../components/LoadingOverlay";
import UserNav from "../components/UserNav";
import Provider from "./Provider/";
import Courses from "./Courses/";
import Course from "./Course/";
import Blog from "./Blog/";
import Terms from "./Terms/";
import PrivacyPolicy from "./PrivacyPolicy/";
import CourseCardPreview from "./CourseCardPreview/";
import Room from "./Room/";
import Event from "./Event/";
import Checkout from "./Checkout";
import CheckoutRedirect from "./CheckoutRedirect";

const aniBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-image: linear-gradient(151deg, ${props =>
      props.providerColor && props.providerColor !== "#000000"
        ? gradientColors(props.providerColor)
        : "#6f42c1, #e16c6c"});
    background-size: ${props => (props.providerColor ? "100% 100%" : "500% 500%")};
    background-color: ${props =>
      props.providerColor && props.providerColor !== "#000000"
        ? gradientColors(props.providerColor).split(", ")[0]
        : "#6f42c1"};
  }
`;

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
  // animation: ${aniBackground} 20s ease infinite;
`;

const Pages = ({ provider, loadProvider, match, location }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (match.url === routes.profile || !match.params.slug) {
      return;
    }

    if (provider && provider.slug === match.params.slug) {
      return;
    }

    try {
      loadProvider(match.params.slug);
    } catch (e) {
      if (e.isAxiosError) {
        setError(e.response ? e.response.status : 500);
      }
    }

    return () => {
      setError(null);
    };
  }, [loadProvider, match, provider]);

  if (error) {
    return (
      <LoadingOverlay show text={<FormattedMessage id={error === 404 ? "notFound" : "error"} />} />
    );
  }

  return (
    <Wrapper>
      {!location.pathname.match(/(card-preview)|(\/rooms\/)/) && <UserNav />}
      {provider && provider.template !== "clean" && (
        <GlobalStyle providerColor={provider.backgroundColor} />
      )}
      <LoadingOverlay show={!provider} />
      {provider && provider.slug === match.params.slug.toLowerCase() && (
        <Switch>
          <Route path={routes.booking.checkoutRedirect} component={CheckoutRedirect} exact />
          <Route path={routes.booking.checkout} component={Checkout} exact />
          <Route path={routes.courseCardPreview} component={CourseCardPreview} exact />
          <Route path={routes.event} component={Event} exact />
          <Route path={routes.courses} component={Courses} exact />
          <Route path={routes.course} component={Course} exact />
          <Route path={routes.blog} component={Blog} exact />
          <Route path={routes.post} component={Blog} exact />
          <Route path={routes.terms} component={Terms} exact />
          <Route path={routes.privacyPolicy} component={PrivacyPolicy} exact />
          <Route path={routes.provider} component={Provider} exact />
          <Route path={routes.room} component={Room} exact />
        </Switch>
      )}
      {provider && !location.pathname.match(/(card-preview)|(\/rooms\/)|(\/checkout\/)/) && (
        <Footer provider={provider} />
      )}
    </Wrapper>
  );
};

Pages.propTypes = {
  provider: PropTypes.object,
  loadProvider: PropTypes.func.isRequired,
};

export default Pages;
