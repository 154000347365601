import React from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { ApiClient } from "../../utils/Api";

import { Paragraph, Input, Button } from "../../components";

const LEAD_PARAMS = {
  name: "",
  email: "",
  url: "",
};

const Wrapper = styled.div`
  margin: 1rem auto;
  padding: 1rem;
  background-color: ${props => props.theme.gray200};
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  text-align: left;

  ${Paragraph} {
    margin-right: 0.5rem;
    flex: 1;
  }

  form {
    flex: 1;
  }

  @media (${props => props.theme.tabletScreen}) {
    display: block;
    max-width: 280px;
    text-align: center;
    ${Paragraph} {
      margin-right: 0;
    }
  }
`;

class Lead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      isLoading: false,
      ...LEAD_PARAMS,
    };
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onSubmit = async e => {
    e.preventDefault();
    const { isLoading, name, email, url } = this.state;

    if (isLoading || email === "" || url === "") {
      return;
    }

    this.setState({ isLoading: true });
    await new ApiClient().providerLead({ name, email, url });
    this.setState({ sent: true, isLoading: false, ...LEAD_PARAMS });
  };

  render() {
    const { sent, name, email, url, isLoading } = this.state;

    if (sent) {
      return (
        <Wrapper>
          <Paragraph>
            <FormattedMessage id="pages.SearchMap.lead.thanks" />
          </Paragraph>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Paragraph>
          <FormattedMessage id="pages.SearchMap.lead.intro" />
        </Paragraph>

        <form onSubmit={this.onSubmit} autoComplete="on">
          <FormattedMessage id="user.name">
            {placeholder => (
              <Input name="name" value={name} placeholder={placeholder} onChange={this.onChange} />
            )}
          </FormattedMessage>

          <FormattedMessage id="user.email">
            {placeholder => (
              <Input
                name="email"
                type="email"
                value={email}
                placeholder={placeholder}
                onChange={this.onChange}
                required
              />
            )}
          </FormattedMessage>

          <FormattedMessage id="user.url">
            {placeholder => (
              <Input
                name="url"
                value={url}
                placeholder={placeholder}
                onChange={this.onChange}
                required
              />
            )}
          </FormattedMessage>
          <Button color="success" type="submit" busy={isLoading}>
            <FormattedMessage id="pages.SearchMap.lead.submit" />
          </Button>
        </form>
      </Wrapper>
    );
  }
}

export default Lead;
