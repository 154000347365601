import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData, isLoading } from "../../store/selectors/user";
import { updateUser } from "../../store/actions/user";

import UserForm from "./UserForm";

const mapStateToProps = state => ({
  userData: getUserData(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {
  updateUser,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserForm),
);
