import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, matchPath } from "react-router-dom";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { rgba } from "polished";
import LoginForm from "../LoginForm";
import { Container, Icon, Hidden } from "..";
import minibrand from "../../assets/kikudoo-wordmark.png";

import routes, { routeWithParams } from "../../routes";

const MENU_ITEMS = [
  { name: "provider", icon: "home", color: "primary" },
  { name: "courses", icon: "child-reaching", color: "warning" },
  { name: "blog", icon: "newspaper", color: "success" },
];
const MENU_ITEMS_EXTERNAL_PROVIDER = [{ name: "provider", icon: "Home", color: "primary" }];

const activeRoute = (path, routes) =>
  routes.find(route => {
    const m = matchPath(path, route);
    return m && m.isExact;
  });

const Nav = styled.nav`
  align-items: center;
  position: relative;
  height: 3rem;
  z-index: 9;
  background: rgba(255, 255, 255, 0.8);
  background: linear-gradient(136deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5));

  @media (${props => props.theme.tabletScreen}) {
    height: 5rem;
    position: sticky;
    top: 0;
    width: 100%;
    backdrop-filter: blur(8px);
  }
`;

const NavContainer = styled(Container)`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 3;
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-left: ${props => (props.left ? "1rem" : "auto")};

  @media (${props => props.theme.tabletScreen}) {
    margin-left: 1rem;
  }
`;

const MenuItem = styled.li`
  padding: 0.3rem 0.5rem;
  margin-right: 0.75rem;
  display: block;
  position: relative;
  z-index: 1;
  color: ${props => props.theme.dark};

  &:last-child {
    margin-right: 0;
  }

  ${({ active, theme }) =>
    active &&
    ` 
      i {
        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2rem;
          height: 2rem;
          background-color: ${theme.pink};
          box-shadow: 0 0.5rem 1.4rem ${rgba(theme.pink, 0.65)};
          opacity: 0.75;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          content: '';
          z-index: -1;
        }
      }
  `}

  @media (${props => props.theme.tabletScreen}) {
    margin-right: 1.5rem;
    span {
      display: inline-block;
    }
  }
`;

const MenuLink = styled.a`
  cursor: pointer;
  font-size: 0.875rem;
  text-transform: none;
  text-decoration: none;
  font-weight: ${props => (props.active ? "bold" : "lighter")};
  transition: color 0.5s;
  color: ${props => (props.active ? props.theme.info : props.theme.dark)};

  ${Hidden} {
    margin-left: 0.8rem;
  }

  &:hover {
    color: ${props => props.theme.black};
  }

  &:before {
    content: "";
    display: ${props => (props.focus ? "inline-block" : "none")};
    background-color: ${props => props.theme.warning};
    border-radius: 50%;
    padding: 0.2rem;
    margin-right: 0.3rem;
    margin-bottom: 0.1rem;
    vertical-align: middle;
  }
`;

const MenuLinkUser = styled(MenuLink)`
  color: ${props => props.theme.dark};
`;

const DropDown = styled.div`
  position: absolute;
  top: 1.8rem;
  right: 1.3rem;
  padding: 0.8rem;
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowLifted};
  border-radius: ${props => props.theme.borderRadius} 0 ${props => props.theme.borderRadius}
    ${props => props.theme.borderRadius};
  z-index: 1;
  min-width: 240px;

  @media (${props => props.theme.tabletScreen}) {
    left: 1.3rem;
    right: auto;
    border-radius: 0 ${props => props.theme.borderRadius} ${props => props.theme.borderRadius};
  }
`;

const MiniBrand = styled.a`
  display: flex;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  opacity: 1;
  transition: opacity 0.5s;

  & > img {
    height: 100%;
    margin: 0;
    max-width: initial;
  }

  &:hover {
    opacity: 1;
  }

  @media (${props => props.theme.tabletScreen}) {
    width: 3.5rem;
    height: 3.5rem;
    border-width: 0.5rem;
    opacity: 0.8;
  }
`;

const ProviderMenu = styled.ul`
  min-width: 100%;

  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);

  position: fixed;
  left: 50%;
  bottom: 1rem;
  z-index: 9;
  box-shadow: 0 0.5rem 1.75rem -0.4rem rgba(0, 0, 0, 0.3);
  border-radius: ${props => props.theme.borderRadiusLarge};
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: auto;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.15rem 0;
  min-height: 3.5rem;
  min-width: 94%;

  @media (${props => props.theme.tabletScreen}) {
    min-width: 0;
    padding: 0.85rem 0 0;
    position: static;
    box-shadow: none;
    background-color: transparent;
    transform: none;
    backdrop-filter: none;
  }
`;

const ProviderMenuItem = styled.li`
  color: ${props => (props.active ? props.theme.black : props.theme.dark)};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: lighter;
  flex: 1;

  & > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: ${props => props.theme.fontSizes.small};

    text-decoration: none;
    transition: color 0.5s;
    color: inherit;
    -webkit-tap-highlight-color: transparent;

    & > i {
      margin: 0.15rem 0 0.25rem;
      position: relative;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3.5rem;
        height: 3.5rem;
        margin: -1.75rem 0 0 -1.75rem;
        border-radius: 50%;
        background-color: ${props => props.theme[props.color]};
        box-shadow: 0 0.5rem 2rem ${props => rgba(props.theme[props.color], 0.75)};
        opacity: ${props => (props.active ? "0.85" : "0")};
        content: "";
        z-index: -1;
        transition: opacity 0.75s;
      }
    }

    & > span {
      display: ${props => (props.active ? "inline-block" : "none")};
      white-space: nowrap;
      position: relative;
    }
  }

  &:hover {
    color: ${props => props.theme.black};
    & > a {
      & > i:after {
        opacity: 0.6;
      }
    }
  }

  &:last-child {
    margin-right: 0.5rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-right: 2rem;
    color: ${props => (props.active ? props.theme.dark : rgba(props.theme.dark, 0.75))};

    &:hover {
      color: ${props => props.theme.black};
    }
    & > a {
      & > span {
        display: inline-block;
      }
    }
  }
`;

const BackLink = styled(NavLink)`
  color: ${props => props.theme.black};
  background-color: ${props => props.theme.white};
  border-radius: 2rem;
  padding: 6px 10px;
  text-decoration: none;
  margin-left: auto;
  margin-top: -3px;

  & > span {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    font-size: ${props => props.theme.fontSizes.small};
  }

  & > i {
    vertical-align: middle;
    color: inherit;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding: 4px 12px 4px 14px;
    & > span {
      max-width: 240px;
      margin-right: 8px;
    }
  }
`;

const SignupLink = styled.small`
  display: block;
  text-align: center;
  margin-top: 0.5rem;
`;

const UserNav = ({
  userData,
  booking,
  provider,
  match,
  location,
  className,
  locale,
  changeLocale,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const allowSignup = false;
  const hideProviderMenu =
    !provider ||
    activeRoute(location.pathname, [
      routes.search,
      routes.searchMap,
      routes.franchise,
      routes.profile,
      routes.room,
    ]);

  const allRoutes = name =>
    [routes[name]]
      .concat(name === "courses" ? routes.course : [])
      .concat(name === "blog" ? routes.post : []);
  return (
    <Nav className={className}>
      <NavContainer>
        <MiniBrand href="https://kikudoo.com">
          <img src={minibrand} alt="kikudoo" />
        </MiniBrand>
        <Menu left={hideProviderMenu && provider}>
          <MenuItem>
            <MenuLink as={NavLink} to={routes.search}>
              <Icon name="magnifying-glass" color="dark" size="small" />
              <Hidden size={["sm"]} display="inline">
                <FormattedMessage id="routes.search" />
              </Hidden>
            </MenuLink>
          </MenuItem>
          {userData ? (
            <MenuItem active>
              <MenuLinkUser as={NavLink} to={routes.profile}>
                <Icon name="circle-user" color="white" size="small" />
                <Hidden size={["sm"]} display="inline">
                  <span>{[userData.firstName, userData.lastName].join(" ")}</span>
                </Hidden>
              </MenuLinkUser>
            </MenuItem>
          ) : (
            <>
              {allowSignup && (
                <MenuItem>
                  <MenuLink href={"https://kikudoo.com/anbieter?ref=from-app"}>
                    <Hidden size={["sm"]} display="inline">
                      <FormattedMessage id="actions.login" />
                    </Hidden>
                  </MenuLink>
                </MenuItem>
              )}
              <MenuItem>
                <MenuLink onClick={() => setShowMenu(!showMenu)}>
                  <Icon name="circle-user" color="dark" size="small" />
                  <Hidden size={["sm"]} display="inline">
                    <FormattedMessage id="actions.login" />
                  </Hidden>
                </MenuLink>
                {showMenu && (
                  <DropDown style={hideProviderMenu && provider ? { right: "auto" } : {}}>
                    <LoginForm />
                    <SignupLink>
                      <NavLink to={routes.resetPassword}>
                        <FormattedMessage id="actions.forgotPassword" />
                      </NavLink>
                    </SignupLink>
                    <SignupLink>
                      <NavLink to={routes.profile}>
                        <FormattedMessage id="components.UserNav.signup" />
                      </NavLink>
                    </SignupLink>
                  </DropDown>
                )}
              </MenuItem>
            </>
          )}

          <Hidden size={["sm"]}>
            <MenuItem>
              <MenuLink onClick={() => changeLocale("de")} active={!locale || locale === "de"}>
                De
              </MenuLink>
              {" | "}
              <MenuLink onClick={() => changeLocale("en")} active={locale === "en"}>
                En
              </MenuLink>
            </MenuItem>
          </Hidden>

          {booking && (
            <MenuItem>
              <MenuLink
                focus="true"
                as={NavLink}
                to={routeWithParams(routes.course, {
                  slug: booking.provider.slug,
                  id: booking.course.id,
                })}
              >
                <Hidden size={["sm"]} display="inline">
                  <FormattedMessage id="components.UserNav.pendingBooking" />
                </Hidden>
              </MenuLink>
            </MenuItem>
          )}
        </Menu>
        {!hideProviderMenu && (
          <ProviderMenu>
            {(provider.external ? MENU_ITEMS_EXTERNAL_PROVIDER : MENU_ITEMS).map(
              ({ name, icon, color }) => (
                <ProviderMenuItem
                  key={name}
                  active={activeRoute(location.pathname, allRoutes(name))}
                  color={color}
                >
                  <NavLink to={routeWithParams(routes[name], { slug: provider.slug, id: "" })}>
                    <Icon
                      name={icon}
                      size={activeRoute(location.pathname, allRoutes(name)) ? "large" : "default"}
                    />
                    <FormattedMessage id={`routes.${name}`} />
                  </NavLink>
                </ProviderMenuItem>
              ),
            )}
          </ProviderMenu>
        )}
        {hideProviderMenu && provider && (
          <BackLink to={routeWithParams(routes.provider, { slug: provider.slug })}>
            <FormattedMessage id={"backTo"} values={{ that: provider.name }} />
            <Icon name="chevron-up" color="black" direction="right" size="small" />
          </BackLink>
        )}
      </NavContainer>
    </Nav>
  );
};

UserNav.propTypes = {
  userData: PropTypes.object,
  booking: PropTypes.object,
  provider: PropTypes.object,
  backLink: PropTypes.string,
  className: PropTypes.string,
  changeLocale: PropTypes.func.isRequired,
  locale: PropTypes.string,
};

UserNav.defaultProps = {
  className: "",
  userData: null,
  bookin: null,
  provider: null,
  backLink: null,
  locale: null,
};

export default UserNav;
