import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Checkout = ({ booking, onSuccess, onProcessingStateChange, onCancel }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm
      booking={booking}
      onSuccess={onSuccess}
      onProcessingStateChange={onProcessingStateChange}
      onCancel={onCancel}
    />
  </Elements>
);

export default Checkout;
