export const EVENT_TYPES = {
  block: "block",
  flexible: "flexible",
  continuous: "continuous",
  onDemand: "on_demand",
};

export const courseTypes = ({ events }) =>
  (events || []).reduce(
    (unique, { typeOf }) => (unique.includes(typeOf) ? unique : [...unique, typeOf]),
    [],
  );

export const typeOfEvents = (events, typeOf) => events.filter(event => event.typeOf === typeOf);

export const blockEvents = (events = []) => typeOfEvents(events, EVENT_TYPES.block);

export const flexibleEvents = (events = []) => typeOfEvents(events, EVENT_TYPES.flexible);

export const continuousEvents = (events = []) => typeOfEvents(events, EVENT_TYPES.continuous);

export const onDemandEvents = (events = []) => typeOfEvents(events, EVENT_TYPES.onDemand);
