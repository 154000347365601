import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Icon from "./Icon";

const CheckboxButton = styled.button`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => props.theme[props.active ? "info" : "inherit"]};
  position: relative;
  border: none;
  transition: color 0.25s, opacity 0.25s;
  opacity: ${props => (props.active ? 1 : 0.5)};
  background: none;
  appearance: none;

  & > i {
    background-color: ${props => props.theme.white};
    box-shadow: ${props => props.theme.boxShadow};
    padding: 0.5rem;
    border-radius: 50%;
  }

  &:hover:not(:disabled) {
    opacity: 1;
  }
`;

const ToggleTitle = styled.small`
  white-space: nowrap;
  margin-left: 0.5rem;

  & > i {
    margin-right: 0.5rem;
  }

  color: ${props => props.theme.dark};
`;

const Checkbox = ({ className, active, children, disabled, onClick }) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <CheckboxButton
      withTitle
      active={active}
      onClick={handleClick}
      className={className}
      disabled={disabled}
    >
      <Icon name={active ? "square-check" : "square"} />
      {children && <ToggleTitle>{children}</ToggleTitle>}
    </CheckboxButton>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  active: false,
  onChange: () => {},
  className: "",
};

export default Checkbox;
