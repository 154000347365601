import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";

import { Small, Button, Input, ActionInput, Icon, Hidden } from "../";
import { SubHeading, defaultParticipants } from "./Booking";

export const MIN_PARTICIPANT_COUNT = 3;
export const MAX_PARTICIPANT_COUNT = 10;

const Wrapper = styled.div`
  @media (${props => props.theme.tabletScreen}) {
    padding-right: 1rem;
  }
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
`;

const ParticipantList = styled.ol`
  margin-bottom: 1rem;
`;

const ParticipantItem = styled.li`
  margin-bottom: 0.4rem;
`;
// Safari issue with ol / decimal and flex box children
const ParticipantNo = styled.span`
  margin-right: 0.5rem;
`;

const Hint = styled(Small)`
  margin-bottom: 0.3rem;
`;

const Participants = ({
  participants,
  participantsLeft,
  onUpdate,
  multiplier,
  maxParticipants,
}) => {
  const bookedOut = participantsLeft <= participants.length;
  const cantAddMore =
    bookedOut || participants.length >= (multiplier > 1 ? multiplier : maxParticipants);

  const onParticipantChange = ({ target }) => {
    onUpdate(participants.map(p => (p.key === target.name ? { ...p, name: target.value } : p)));
  };

  const addParticipant = e => {
    e.preventDefault();

    if (cantAddMore) {
      return;
    }

    onUpdate(
      participants
        .slice()
        .concat(defaultParticipants(multiplier - (participants.length % multiplier) || multiplier)),
    );
  };

  const removeParticipant = index => {
    onUpdate(
      participants.slice(0, index).concat(participants.slice(index + 1, participants.length)),
    );
  };

  return (
    <Wrapper>
      <SubHeading>
        <FormattedMessage id="booking.attributes.participants" />
      </SubHeading>

      {participants.length === 0 && (
        <Hidden size={["sm"]}>
          <Hint>
            <FormattedMessage id="components.Booking.noParticipants" />
          </Hint>
        </Hidden>
      )}

      <Section>
        <ParticipantList>
          <FormattedMessage id="booking.attributes.participantName">
            {placeholder =>
              participants.map(({ name, key }, index) => (
                <ParticipantItem key={`${key || "k-p"}-${index}`}>
                  <ActionInput>
                    <ParticipantNo>{`${index + 1}. `}</ParticipantNo>
                    <Input
                      name={key}
                      value={name}
                      onChange={onParticipantChange}
                      maxLength={25}
                      placeholder={placeholder}
                      required
                    />
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        removeParticipant(index);
                      }}
                      color="danger"
                      disabled={participants.length <= 1}
                      glow={false}
                    >
                      <Hidden size={["lg", "md"]}>
                        <Icon name="add" direction={45} size="small" />
                      </Hidden>
                      <Hidden size={["sm"]}>
                        <FormattedMessage id="actions.remove.normal" />
                      </Hidden>
                    </Button>
                  </ActionInput>
                </ParticipantItem>
              ))
            }
          </FormattedMessage>
        </ParticipantList>

        {cantAddMore ? (
          bookedOut || multiplier > 1 ? (
            ""
          ) : (
            <small>
              <FormattedMessage
                id="components.Booking.error.tooManyParticipants"
                values={{ count: maxParticipants }}
              />
            </small>
          )
        ) : (
          <Button color="success" onClick={addParticipant} glow={false}>
            <FormattedMessage id="components.Booking.addParticipant" />
          </Button>
        )}
      </Section>
    </Wrapper>
  );
};

Participants.propTypes = {
  participants: PropTypes.array.isRequired,
  participantsLeft: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  maxParticipants: PropTypes.number,
};

Participants.defaultProps = {
  maxParticipants: MAX_PARTICIPANT_COUNT,
};

export default injectIntl(Participants);
