import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { FormattedMessage } from "react-intl";
import { uniqBy } from "lodash";
import { Helmet } from "react-helmet";
import routes from "../../routes";

import { ApiClient } from "../../utils/Api";
import { presence, scrollTop } from "../../utils/common";
import { gradientColors } from "../../utils/formatters";
import {
  PageLayout,
  PageTitle,
  CardContainer,
  Row,
  Col,
  RichText,
  Heading3,
  Paragraph,
  Container,
  Button,
  Icon,
} from "../../components/";
import Gallery from "../../components/Gallery";
import Details from "./Details";
import Loading from "../../components/Loading";
import UserNav from "../../components/UserNav";
import Map from "../../components/Map";
import ProviderList from "./ProviderList";
import officialPartner from "../../assets/official-kikudoo-partner-pill.png";

const MIN_ZOOM = 6;
const MAX_ZOOM = 16;

export const locationsFromProviders = providers =>
  uniqBy(
    providers.flatMap(provider => [
      {
        ...provider.address,
        key: provider.id,
        providers: [provider],
      },
      ...(provider.addresses || []).map(address => ({
        ...address,
        key: [provider.id, address.id].join("-"),
        providers: [provider],
      })),
    ]),
    location => [location.lat, location.lng].join(),
  );

const aniBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;

  background-image: linear-gradient(-106deg, ${props =>
    props.backgroundColor && props.backgroundColor !== "#000000"
      ? gradientColors(props.backgroundColor, 12, false)
      : "#6f42c1, #e16c6c"});
  background-size: ${props => (props.backgroundColor ? "100% 100%" : "500% 500%")};
  transition: background-image 2s;
  // animation: ${aniBackground} 20s ease infinite;
`;

const LoadingWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FranchiseMap = styled(Map)`
  min-height: 600px;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
  box-shadow: ${props => props.theme.boxShadowSmall};
`;

const MapWrapper = styled.section`
  position: relative;
`;

const MapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 401;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    padding: 1rem;
    background-color: ${props => props.theme.white};
    box-shadow: ${props => props.theme.boxShadowSmall};
    border-radius: ${props => props.theme.borderRadius};
    text-align: center;
    opacity: 0.75;
    transition: opacity 0.25s;
  }

  &:hover > span {
    opacity: 0.75;
  }

  @media (${props => props.theme.tabletScreen}) {
    & > span {
      opacity: 0;
    }
  }
`;

const PartnerBadge = styled.aside`
  position: absolute;
  bottom: 0;
  right: -18px;
  transform: translateY(60%);
  z-index: 2;
  max-width: 200px;
  & > img {
    display: block;
    margin: 0 0 0 auto;
    max-width: 315px;
    min-width: 120px;
    width: 100%;
  }

  @media (${props => props.theme.tabletScreen}) {
    transform: translateY(33%);
    max-width: 100%;
  }
`;

const SearchCTA = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 412;

  i {
    vertical-align: middle;
    margin-right: 6px;
  }
`;

const Franchise = ({ match }) => {
  const [franchise, setFranchise] = useState(null);
  const [providers, setProviders] = useState(null);
  const [error, setError] = useState(null);
  const [mapEnabled, setMapEnabled] = useState(false);

  useEffect(() => {
    scrollTop();

    async function getFranchise(id) {
      try {
        const franchise = await new ApiClient().franchise(id);
        setFranchise(franchise);
      } catch (e) {
        setError("Not found");
      }
    }

    if (!match.params.id) {
      return;
    }

    getFranchise(match.params.id);
    return () => {
      setFranchise(null);
    };
  }, [match, setFranchise, setError]);

  useEffect(() => {
    if (!franchise) {
      return;
    }

    async function getProviders(id) {
      try {
        const providers = await new ApiClient().franchiseProviders(id);
        setProviders(providers);
      } catch (e) {
        setError("Not found");
      }
    }

    getProviders(franchise.id);

    return () => {
      setProviders(null);
    };
  }, [franchise, setProviders]);

  if (!franchise) {
    return (
      <LoadingWrapper>
        {error ? (
          <FormattedMessage id={error === 404 ? "notFound" : "error"} />
        ) : (
          <Loading text={<FormattedMessage id="loading" />} />
        )}
      </LoadingWrapper>
    );
  }

  const onProviderClick = provider => {
    if (!provider) {
      return;
    }
    const win = window.open(`/${provider.slug}`, "_blank");
    if (win && win.focus) {
      win.focus();
    }
  };

  const normalizedProviders = (providers || []).filter(
    provider => provider.address && provider.address.lat && provider.address.lng,
  );

  const locations = locationsFromProviders(normalizedProviders);

  return (
    <Wrapper backgroundColor={franchise.primaryColor}>
      <Helmet>
        <title>{franchise.name}</title>
        <link href={`https://kikudoo.com/loves/${franchise.slug}`} rel="canonical"></link>
      </Helmet>
      <UserNav />
      <PageLayout>
        <PageTitle
          title={franchise.name}
          subTitle={presence(franchise.subTitle) || <FormattedMessage id="pages.Franchise.intro" />}
          logo={franchise.logo}
        />
        <Container style={{ position: "relative" }}>
          <PartnerBadge onClick={() => window.open("https://kikudoo.com/pages/partners")}>
            <img src={officialPartner} alt={"Official Partner of kikudoo"} />
          </PartnerBadge>
        </Container>
        <CardContainer seemless={franchise.images.length > 0}>
          <Row>
            <Col size="md" count={8}>
              <>
                {franchise.images.length > 0 && <Gallery images={franchise.images} />}
                <RichText text={franchise.description || ""} />
              </>
            </Col>
            <Col size="md" count={4}>
              <Details franchise={franchise} />
            </Col>
          </Row>

          {!providers ? (
            <Loading />
          ) : (
            <>
              <Heading3>
                <FormattedMessage id="pages.Franchise.providers.title" />
              </Heading3>
              <Paragraph spaced>
                <FormattedMessage id="pages.Franchise.providers.intro" />
              </Paragraph>
              {uniqBy(normalizedProviders, p => p.address.lat).length > 10 && (
                <MapWrapper>
                  <SearchCTA>
                    <Button color="info" glow round to={`${routes.search}?term=${franchise.name}`}>
                      <Icon name="magnifying-glass" color="white" />
                      <FormattedMessage id="pages.Franchise.providers.search" />
                    </Button>
                  </SearchCTA>
                  {!mapEnabled && (
                    <MapOverlay onClick={() => setMapEnabled(true)}>
                      <FormattedMessage id="pages.SearchMap.activaMap"></FormattedMessage>
                    </MapOverlay>
                  )}
                  <FranchiseMap
                    options={{
                      minZoom: MIN_ZOOM,
                      maxZoom: MAX_ZOOM,
                    }}
                    defaultViewport={{ center: [51.165691, 10.451526], zoom: 6 }}
                    onMarkerClick={location => onProviderClick((location.providers || [])[0])}
                    locations={locations}
                  />
                </MapWrapper>
              )}
              <ProviderList
                providers={providers || []}
                onClick={onProviderClick}
                franchiseLogo={franchise.logo}
              />
            </>
          )}
        </CardContainer>
      </PageLayout>
    </Wrapper>
  );
};

Franchise.propTypes = {
  match: PropTypes.object.isRequired,
};

Franchise.defaultProps = {};

export default Franchise;
