import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const animationFirst = keyframes`
  0% {
    transform: scale(0);
    opacity: 0.25;
  }
  100% {
    transform: scale(1);
    opacity: 0.75;
    background: ${props => props.theme.success};
  }
`;

const animationThird = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(0);
    opacity: 0.25;
  }
`;

const animationSecond = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const Dots = styled.div`
  display: inline-block;
  position: relative;
  margin: 24px auto;
  width: 64px;
  height: 11px;

  div {
    position: absolute;
    top: 0;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${props => props.theme.yellow};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    opacity: 0.5;
  }

  div:nth-child(1) {
    left: 6px;
    animation: ${animationFirst} 0.6s infinite;
    background: ${props => props.theme.cyan};
  }

  div:nth-child(2) {
    left: 6px;
    animation: ${animationSecond} 0.6s infinite;
    background: ${props => props.theme.info};
  }

  div:nth-child(3) {
    left: 26px;
    animation: ${animationSecond} 0.6s infinite;
    background: ${props => props.theme.primary};
  }

  div:nth-child(4) {
    left: 45px;
    animation: ${animationThird} 0.6s infinite;
    background: ${props => props.theme.success};
  }
`;

const Text = styled.h4`
  display: block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-width: 150px;
  color: inherit;
`;

const Wrapper = styled.div`
  padding: 1rem;
  border-radius: 2.625rem;
  box-shadow: ${props => props.shadow && props.theme.boxShadowSmall};
  background-color: ${props => (props.transparent ? "transparent" : props.theme.white)};
  color: ${props => (props.transparent ? props.theme.white : props.theme.dark)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  ${({ small, theme }) =>
    small &&
    `
      padding: 0.5rem 1rem;

      ${Text} {
        font-size: ${theme.fontSizes.small};
        margin-top: 0.25rem;
      }

      ${Dots} {
        margin: 0.25rem 0;
      }
  `}

  @media (${props => props.theme.tabletScreen}) {
    border-radius: ${props => props.theme.borderRadius};
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
`;

const Loading = ({ text, className, transparent, small, shadow }) => (
  <Wrapper className={className} transparent={transparent} small={small} shadow={shadow}>
    <Dots>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Dots>
    {text && <Text>{text}</Text>}
  </Wrapper>
);

Loading.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  transparent: PropTypes.bool,
  className: PropTypes.string,
  small: PropTypes.bool,
  shadow: PropTypes.bool,
};

Loading.defaultProps = {
  transparent: false,
  small: false,
  shadow: true,
};

export default Loading;
