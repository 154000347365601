import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import withUser from "../../hoc/withUser";

import { Input, Button, Row, Col, Paragraph, Small } from "../../components";

const Wrapper = styled.article``;
const Intro = styled.span`
  color: ${props => props.theme.cyan};
`;
const Label = styled.label`
  display: block;
  text-align: left;
  font-size: ${props => props.theme.fontSizes.small};
  padding-left: 1rem;
`;

const Fields = styled.fieldset`
  border: 0;
`;

class WaitList extends React.Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      phone: "",
      answer: "",
      participantCount: 1,
      isLoading: false,
    };
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { course, event, createApplicant } = this.props;
    const { name, email, phone, answer, participantCount } = this.state;

    const applicant = {
      name,
      email,
      phone,
      answer,
      participantCount,
    };

    this.setState({ isLoading: true });

    createApplicant(course.provider.id, event.id, applicant);

    this.setState({ isLoading: false });
  };

  render() {
    const { user, applications, event } = this.props;
    const { name, email, phone, answer, participantCount, isLoading } = this.state;

    const isInvalid = !user && [name, email].find(s => !s || s === "") === "";
    const isAlreadyWaitListed = applications.find(a => a.eventId === event.id);
    const isAnswerRequired = event.waitListQuestion;

    return (
      <Wrapper>
        {isAlreadyWaitListed ? (
          <Intro>
            <FormattedMessage id="components.Course.waitList.alreadyListed" />
          </Intro>
        ) : (
          <>
            <Intro>
              <FormattedMessage id="components.Course.waitList.intro" />
            </Intro>
            <form id="participants" onSubmit={this.onSubmit}>
              {user ? (
                <Paragraph>
                  <Small>
                    <FormattedHTMLMessage
                      id="components.Course.waitList.user"
                      values={{ username: [user.firstName, user.lastName].join(" ") }}
                    />
                  </Small>
                </Paragraph>
              ) : (
                <Fields>
                  <FormattedMessage id="applicant.attributes.name">
                    {placeholder => (
                      <Input
                        name="name"
                        value={name}
                        onChange={this.onChange}
                        maxLength={180}
                        placeholder={placeholder}
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="applicant.attributes.email">
                    {placeholder => (
                      <Input
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        maxLength={180}
                        placeholder={placeholder}
                        type="email"
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="applicant.attributes.phone">
                    {placeholder => (
                      <Input
                        name="phone"
                        value={phone}
                        onChange={this.onChange}
                        maxLength={180}
                        placeholder={placeholder}
                        type="tel"
                      />
                    )}
                  </FormattedMessage>
                  {isAnswerRequired ? (
                    <div>
                      <FormattedHTMLMessage
                        id="components.Course.waitList.question"
                        values={{ question: event.waitListQuestion }}
                      />
                      <FormattedMessage id="applicant.attributes.answer">
                        {placeholder => (
                          <Input
                            name="answer"
                            value={answer}
                            onChange={this.onChange}
                            maxLength={250}
                            placeholder={placeholder}
                          />
                        )}
                      </FormattedMessage>
                    </div>
                  ) : (
                    ""
                  )}
                </Fields>
              )}
              <Row>
                <Col size="sm" count={8}>
                  <Label>
                    <FormattedMessage id="applicant.attributes.participantCount" />
                  </Label>
                </Col>
                <Col size="sm" count={4}>
                  <Input
                    name="participantCount"
                    value={participantCount}
                    onChange={this.onChange}
                    maxLength={25}
                    type="number"
                  />
                </Col>
              </Row>
              <Button type="submit" color="info" disabled={isInvalid} busy={isLoading} glow>
                <FormattedMessage id="components.Course.waitList.submit" />
              </Button>
            </form>
          </>
        )}
      </Wrapper>
    );
  }
}

WaitList.propTypes = {
  user: PropTypes.object,
  createApplicant: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  applications: PropTypes.array,
};

WaitList.defaultProps = {
  user: null,
  applications: [],
};

export default withUser()(WaitList);
