import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import routes, { routeWithParams } from "../../../../routes";
import { PageLayout, Overlay, Icon, Heading3, Paragraph } from "../../../../components";
import Loading from "../../../../components/Loading";
import { Wrapper, CancelButton } from "../Confirm/Confirm";
import { uniq } from "lodash";
import { titleImage } from "../../../../utils/formatters";
import SuccessMessage from "../../../../pages/Checkout/Success";

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;

const Success = ({ loadContract }) => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const [productContractId] = useState(params.id);
  const [product, setProduct] = useState(null);
  const [contract, setProductContract] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!productContractId) {
      return;
    }

    const getProductContract = async id => {
      try {
        const data = await loadContract(id);
        setProduct(data.product);
        setProductContract(data);
      } catch (e) {
        if (e.isAxiosError) {
          const status = e.response.status;
          return setError(status || 500);
        }
        setError(500);
      }
    };

    getProductContract(productContractId);
  }, [productContractId, loadContract]);

  const onCancel = () => {
    if (contract) {
      return history.push(
        routeWithParams(routes.course, {
          slug: contract.provider.slug,
          id: contract.event.course.id,
        }),
      );
    }

    history.push(routes.profile);
  };

  if (!contract) {
    return (
      <PageLayout>
        <Overlay blackout onClose={onCancel}>
          <Wrapper>
            <CancelButton onClick={onCancel}>
              <Icon name="add" direction={45} />
            </CancelButton>
            {error ? (
              "Error"
            ) : (
              <LoadingWrapper>
                <Loading text={<FormattedMessage id="components.Booking.loading.confirm" />} />
              </LoadingWrapper>
            )}
          </Wrapper>
        </Overlay>
      </PageLayout>
    );
  }

  const course = uniq(
    product.events.map(e => e.course),
    "id",
  )[0];

  return (
    <SuccessMessage course={course} image={course && titleImage(course)} onClose={onCancel}>
      <Heading3>{product.name}</Heading3>
      <Paragraph>
        <strong>{product.events.map(e => e.name).join(", ")}</strong>
      </Paragraph>
    </SuccessMessage>
  );
};

Success.propTypes = {
  loadContract: PropTypes.func.isRequired,
};

export default Success;
