import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  display: inline-block;
  color: ${props => props.theme.white};
  padding: ${props => (props.inverted ? "0.05rem" : "0.1rem 0.5rem")};
  font-size: 0.675rem;
  opacity: 1;
  border-radius: ${props => (props.inverted ? 0 : "1.5rem")};
  margin-bottom: 0;
  background-color: ${props => props.theme.primary};
  cursor: pointer;
  transition: transform 0.5s;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(32deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1));
    content: "";
    width: 100%;
    height: 100%;
    transition: opacity 0.5s;
    opacity: 1;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding: 0.1rem 0.5rem;
    font-size: ${props => props.theme.fontSizes.small};
    box-shadow: ${props => props.theme.boxShadowSmall};
  }
`;

const Image = styled.img`
  max-height: 1rem;
  min-height: 1rem;
  height: 1rem;
`;

const Category = ({ category, onClick, inverted, image }) => (
  <Wrapper
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      onClick(category);
    }}
    title={category.description}
    style={{
      backgroundColor: inverted ? "transparent" : category.color,
      color: inverted ? category.color : "white",
    }}
    inverted={inverted}
  >
    {image && <Image src={image} />}
    {category.name}
  </Wrapper>
);

Category.propTypes = {
  category: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
  image: PropTypes.string,
};

Category.defaultProps = {
  onClick: () => {},
  inverted: false,
  image: null,
};

export default Category;
