import { ApiClient } from "../../utils/Api";

export const ActionTypes = {
  PROVIDER_SUCCESS: "kikudoo/provider/success",
  PROVIDER_REQUEST: "kikudoo/provider/request",
  PROVIDER_FAILED: "kikudoo/provider/failed",
  PROVIDERS_SUCCESS: "kikudoo/providers/success",
};

export const providerRequest = () => ({
  type: ActionTypes.PROVIDER_REQUEST,
});

export const providerFailed = () => ({
  type: ActionTypes.PROVIDER_FAILED,
});

export const providerSuccess = provider => ({
  type: ActionTypes.PROVIDER_SUCCESS,
  payload: provider,
});

export const loadProvider = id => async (dispatch, getState) => {
  dispatch(providerRequest());
  const provider = await new ApiClient().provider(id);

  dispatch(providerSuccess(provider));
};

export const loadRecommendations = id => async () => {
  try {
    const recommendations = await new ApiClient().providerRecommendations(id);
    return recommendations;
  } catch (e) {
    console.error(e);
    return [];
  }
};
