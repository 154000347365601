import React from "react";
import { ApiClient } from "../../utils/Api";
import Provider from "../Provider/Provider";
import Loading from "../../components/Loading";

const EMPTY_PROVIDER = {
  name: "Anbietername",
  description: "Hier könnte eine Beschreibung stehen",
  images: [],
  logo: "",
  address: {},
};

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provider: null,
    };
  }

  async componentDidMount() {
    const { match } = this.props;

    if (match.params.url) {
      try {
        const provider = await new ApiClient().providerPreview(match.params.url);
        this.setState({ provider });
      } catch (e) {
        if (e.isAxiosError) {
          return this.setState({
            provider: {
              ...EMPTY_PROVIDER,
              name: "Leider nichts gefunden :(",
              description: "Hast du deine URL richtig angegeben?",
            },
          });
        }
        throw e;
      }
    }
  }

  render() {
    const { provider } = this.state;

    return provider ? <Provider provider={provider} courses={[]} /> : <Loading />;
  }
}

export default Preview;
