import { connect } from "react-redux";
import { createApplicant } from "../../store/actions/waitList";
import { getUserData } from "../../store/selectors/user";
import { getApplications } from "../../store/selectors/waitList";

import WaitList from "./WaitList";

const mapStateToProps = state => ({
  user: getUserData(state),
  applications: getApplications(state),
});

const mapDispatchToProps = {
  createApplicant,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaitList);
