export const getSearchParams = () =>
  window.location.search
    .replace("?", "")
    .split("&")
    .reduce((obj, p) => {
      const [key, value] = p.split("=");
      obj[key] = (decodeURIComponent(value) || "").replace(/\+/g, " ");
      return obj;
    }, {});

// Not yet implemented
export const setSearchParams = params => window.location.search;
