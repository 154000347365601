import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl } from "react-intl";
import { isNumber, uniqBy } from "lodash";

import routes, { toUrlParams } from "../../routes";

import Map from "../../components/Map";
import ContactForm from "../../components/ContactForm";
import Franchise from "../../components/Franchise";
import { InfoList, Link, SimpleFormat, Contact, Small, Icon } from "../../components";
import { makeHref, trackForward } from "./Provider";

const ZOOM = 16;
const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

const socialMediaLinks = provider => {
  const links = [];
  if (provider.socialMediaFacebookLink && provider.socialMediaFacebookLink !== "") {
    links.push(
      <SocialMediaLink
        key="facebook"
        onClick={() => trackForward(provider.slug)}
        href={makeHref(provider.socialMediaFacebookLink)}
        target="_blank"
      >
        <Icon name="Facebook" color="primary" />
        <span>Facebook</span>
      </SocialMediaLink>,
    );
  }

  if (provider.socialMediaInstagramLink && provider.socialMediaInstagramLink !== "") {
    links.push(
      <SocialMediaLink
        key="instagram"
        onClick={() => trackForward(provider.slug)}
        href={makeHref(provider.socialMediaInstagramLink)}
        target="_blank"
      >
        <Icon name="Instagram" color="primary" />
        <span>Instagram</span>
      </SocialMediaLink>,
    );
  }

  if (provider.socialMediaWhatsappLink && provider.socialMediaWhatsappLink !== "") {
    links.push(
      <SocialMediaLink
        key="Whatsapp"
        onClick={() => trackForward(provider.slug)}
        href={makeHref(provider.socialMediaWhatsappLink)}
        target="_blank"
      >
        <Icon name="Whatsapp" color="primary" />
        <span>Whatsapp</span>
      </SocialMediaLink>,
    );
  }

  return links;
};

export const Wrapper = styled.aside`
  background-color: ${props => props.theme.gray100};
  padding: 0 1rem 0.5rem 1rem;
  border-radius: ${props => props.theme.borderRadius};

  @media (${props => props.theme.tabletScreen}) {
    padding: 0 1rem 1rem 1rem;
  }
`;

const Address = styled.section`
  margin: 0rem 1rem 2rem;
  display: flex;
  cursor: pointer;
  flex-direction: column;

  & > div {
    border-radius: ${props => props.theme.borderRadius};
    min-height: 200px;
    flex: 1;
    overflow: hidden;
    transform: translateZ(0);
  }

  ${Small} {
    text-align: right;
  }

  @media (${props => props.theme.desktopScreen}) {
    margin-left: 0;
    margin-left: right;
  }
`;

const ProviderAddress = styled.address`
  font-style: normal;
`;

const ProviderAddresses = styled.div`
  text-align: right;
  margin-top: 1.5rem;
  font-family: ${props => props.theme.fontFamilyBase};
  color: ${props => props.theme.dark};
  cursor: pointer;

  & > ul {
    overflow: hidden;
    & > li {
      font-family: ${props => props.theme.fontFamilyMonospace};
      font-size: ${props => props.theme.fontSizes.small};
      ${Link} {
        color: ${props => props.theme.black};
      }
    }
  }
`;

const SocialMediaLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  & > i {
    margin-right: 0.5rem;
  }
`;

const Details = ({ provider, intl }) => {
  const socialMedias = socialMediaLinks(provider);
  const providerMarker = {
    key: "provider",
    lat: parseFloat(provider.address.lat),
    lng: parseFloat(provider.address.lng),
    providers: [{ name: "" }],
  };
  return (
    <Wrapper>
      {provider.franchises &&
        provider.franchises.map(franchise => (
          <Franchise key={franchise.slug} franchise={franchise} context="provider" />
        ))}
      <InfoList
        items={[
          {
            icon: "envelope",
            label: <FormattedMessage id="provider.attributes.contact" />,
            value: (
              <ContactForm
                provider={provider}
                subject={intl.formatMessage({ id: "pages.Provider.messageSubject" })}
              />
            ),
          },
          {
            icon: "mobile",
            label: <FormattedMessage id="provider.attributes.phone" />,
            value: provider.address.phone ? (
              <Link
                onClick={() => trackForward(provider.slug)}
                href={`tel:${provider.address.phone}`}
              >
                {provider.address.phone}
              </Link>
            ) : (
              "-"
            ),
          },
          (provider.website || provider.url) && {
            icon: "Search",
            label: <FormattedMessage id="provider.attributes.url" />,
            value: (
              <Link
                onClick={() => trackForward(provider.slug)}
                href={makeHref(provider.website || provider.url)}
                target="_blank"
              >
                {provider.name}
              </Link>
            ),
          },
          socialMedias.length > 0 && {
            icon: "share-nodes",
            label: <FormattedMessage id="provider.attributes.socialMedia" />,
            value: socialMedias,
          },
          provider.addressCombined && {
            icon: "location-dot",
            label: <FormattedMessage id="provider.attributes.address" />,
            value: (
              <>
                <ProviderAddress>
                  <SimpleFormat text={provider.addressCombined || "-"} />
                  <Link
                    href={`https://${
                      iOS ? "maps.apple.com/?q=" : "www.google.com/maps/search/?api=1&query="
                    }${provider.address.lat},${provider.address.lng}&z=15`}
                    target="_blank"
                  >
                    <Small>
                      <FormattedMessage id="pages.Provider.openWithMaps" />
                    </Small>
                  </Link>
                </ProviderAddress>
                {provider.addresses && provider.addresses.length > 0 && (
                  <ProviderAddresses>
                    <FormattedMessage id="pages.Provider.addresses" />
                    <ul>
                      {uniqBy(provider.addresses, ({ lat, lng }) => [lat, lng].join("")).map(
                        ({ street, postalCode, city, lat, lng }) => (
                          <li key={[lat, lng].join("")}>
                            <Link
                              href={`https://${
                                iOS
                                  ? "maps.apple.com/?q="
                                  : "www.google.com/maps/search/?api=1&query="
                              }${lat},${lng}&z=15`}
                              target="_blank"
                            >
                              {[street, [postalCode, city].join(" ").trim()]
                                .filter(Boolean)
                                .join(", ")}
                            </Link>
                          </li>
                        ),
                      )}
                    </ul>
                  </ProviderAddresses>
                )}
              </>
            ),
          },
        ].filter(Boolean)}
      />
      {isNumber(provider.address.lat) && isNumber(provider.address.lng) && (
        <Address
          onClick={() =>
            window.open(
              `${routes.search}?${toUrlParams({
                lat: provider.address.lat,
                lng: provider.address.lng,
                term: provider.name,
              })}`,
            )
          }
        >
          <Map
            defaultViewport={{
              center: [parseFloat(provider.address.lat), parseFloat(provider.address.lng)],
              zoom: ZOOM,
            }}
            locations={[providerMarker]}
            currentLocation={providerMarker}
            options={{
              minZoom: ZOOM,
              maxZoom: ZOOM,
              dragging: false,
              zoomControl: false,
            }}
          />
          <Small>
            <FormattedMessage id="pages.Provider.backToSearch" />
          </Small>
        </Address>
      )}
      {(provider.contacts || []).map((contact, i) => (
        <Contact key={contact.fullName + i} user={contact} provider={provider} />
      ))}
    </Wrapper>
  );
};

Details.propTypes = {
  provider: PropTypes.object.isRequired,
};

export default injectIntl(Details);
