import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrentProvider } from "../store/selectors/provider";
import { loadProvider } from "../store/actions/provider";

import Pages from "./Pages";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
});

const mapDispatchToProps = {
  loadProvider,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Pages),
);
