import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

const createMarkup = text => ({ __html: text });

const Wrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol,
  ul {
    list-style: decimal;
    padding-left: 2rem;
  }

  ul {
    list-style: disc;
  }

  strong {
    color: ${props => props.theme.black};
  }

  blockquote {
    border: 0 solid #ccc;
    border-left-width: 0.3em;
    margin-left: 0.3em;
    padding-left: 0.6em;
  }

  .attachment {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: auto;
    text-align: center;
  }

  .attachment-gallery {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    & > action-text-attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2 action-text-attachment,
    &.attachment-gallery--4 action-text-attachment {
      flex-basis: 50%;
      max-width: 50%;
    }

    .attachment__caption {
      visibility: hidden;
    }

    & .attachment:hover {
      .attachment__caption {
        visibility: visible;
      }
    }
  }

  .attachment--file {
    display: inline-block;
    border-radius: ${props => props.theme.borderRadiusSmall};
    background-color: ${props => props.theme.light};
    color: ${props => props.theme.black};
    padding: 0.5rem 1rem;
    width: auto;

    a {
      color: inherit;
      text-decoration: none;
    }

    .attachment__caption {
      display: inline;
      margin: 0 0.5rem;
      font-size: ${props => props.theme.fontSizes.default};

      .attachment__size {
        display: inline;
      }
    }
  }

  .attachment__caption {
    margin: 0.5rem 0;
    text-align: center;
    font-size: ${props => props.theme.fontSizes.tiny};

    span:before {
      display: none;
    }

    .attachment__size {
      display: none;
      color: ${props => props.theme.gray600};
      font-size: ${props => props.theme.fontSizes.small};
      margin-left: 0.5rem;
    }
  }

  img {
    width: 100%;
    max-width: 800px;
    border-radius: ${props => props.theme.borderRadiusSmall};
  }

  video {
    width: 100%;
    border-radius: ${props => props.theme.borderRadiusSmall};
    box-shadow: ${props => props.theme.boxShadowSmall};
  }
`;

const RichText = ({ text, parseLinks }) => {
  const history = useHistory();

  const dynamicLinkHandler = event => {
    if (!parseLinks) {
      return;
    }

    if (event.target.nodeName.toLowerCase() !== "a") {
      return;
    }

    const href = event.target.getAttribute("href");
    const origin = window.location.origin.replace(/(http(s?)):\/\//, "");
    if (!href) {
      return;
    }

    event.preventDefault();
    if (href.match(/(http(s?)):\/\//) && !href.match(window.location.origin)) {
      return window.open(href, "_blank").focus();
    }

    return history.push(href.replace(new RegExp(`(^.*)(${origin})`), ""));
  };
  return <Wrapper onClick={dynamicLinkHandler} dangerouslySetInnerHTML={createMarkup(text)} />;
};

RichText.propTypes = {
  text: PropTypes.string,
  parseLinks: PropTypes.bool,
};

RichText.defaultProps = {
  text: "",
  parseLinks: true,
};

export default RichText;
