import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  z-index: 9;

  @media (${props => props.theme.tabletScreen}) {
    left: 2%;
    width: 96%;
  }
`;

const Blackout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.33);
  z-index: -1;
`;

const Overlay = ({ children, blackout, onClose, className }) => (
  <Wrapper className={className}>
    {children}
    {blackout && <Blackout onClick={onClose} />}
  </Wrapper>
);

Overlay.propTypes = {
  children: PropTypes.node,
  blackout: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

Overlay.defaultProps = {
  blackout: false,
  onClose: () => {},
  className: "",
  children: "",
};

export default Overlay;
