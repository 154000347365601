import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { isObject, find, filter } from "lodash";
import { useOnClickOutside } from "../utils/hooks";

import Icon from "./Icon";

const Title = styled.span`
  border-radius: 2rem;
  background-color: ${props => props.theme.light};
  color: ${props => props.theme.dark};
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.theme.light};
  box-shadow: ${props => props.theme.boxShadowInset};
  font-weight: 400;
  line-height: 1.5;
  font-size: 100%;
  display: block;
  cursor: pointer;
  max-width: 200px;
  min-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: border 1s;
  &:hover {
    color: ${props => props.theme.black};
    border-color: ${props => props.theme.primary};
  }
`;

const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  border-radius: 2rem;
  padding: 0.15rem;

  &:hover {
    ${Title} {
      color: ${props => props.theme.black};
      border-color: ${props => props.theme.primary};
    }
  }
`;

const Options = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => props.theme.primary};
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  list-style: none;
  display: flex;
  flex-direction: column;
  z-index: 11;
  min-width: 100%;
`;

const Option = styled.li`
  margin: 0.25rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: ${props => (props.active ? props.theme.black : props.theme.dark)};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.black};
  }
`;

const CheckBox = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: ${props => props.theme.borderRadiusSmall};
  border: 1px solid ${props => props.theme.dark};
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.span`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  display: inline-block;
`;

const SelectPill = ({ className, name, items, direction, defaultItems, onChange }) => {
  const [open, setOpen] = useState(false);
  const [activeItems, setActiveItems] = useState(defaultItems || items);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpen(false));

  const toggleItem = item => {
    setActiveItems(currentActiveItems => {
      const activeItem = find(currentActiveItems, item);
      const items = activeItem
        ? filter(currentActiveItems, i => i !== activeItem)
        : currentActiveItems.concat(item);

      onChange(items);
      return items;
    });
  };

  return (
    <Wrapper className={className} ref={ref}>
      <Title onClick={() => setOpen(!open)}>
        {(activeItems || []).length === 1 ? (
          isObject(activeItems[0]) ? (
            activeItems[0].name
          ) : (
            activeItems[0]
          )
        ) : (activeItems || []).length > 0 ? (
          <span>
            {activeItems.length} {name}
          </span>
        ) : (
          name
        )}
      </Title>
      {open && (
        <Options>
          {items.map(item => (
            <Option
              key={isObject(item) ? item.id : item}
              onClick={() => toggleItem(item)}
              active={find(activeItems, item)}
            >
              <CheckBox>
                {find(activeItems, item) && <Icon name="check" size="small" color="info" />}
              </CheckBox>
              {isObject(item) ? item.name : item}
              {item.color && <Dot style={{ backgroundColor: item.color }} />}
            </Option>
          ))}
        </Options>
      )}
    </Wrapper>
  );
};

SelectPill.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.array,
  defaultItems: PropTypes.array,
  direction: PropTypes.string,
  onChange: PropTypes.func,
};

SelectPill.defaultProps = {
  className: "",
  items: [],
  direction: "down",
  onChange: () => {},
  defaultItems: null,
};

export default SelectPill;
