import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const HEADING_ELEMENTS = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
};

const HEADING_FONT_SIZES = {
  1: "3rem",
  2: "2rem",
  3: "1.75rem",
  4: "1.5rem",
  5: "1.2rem",
};

const HEADING_FONT_SIZES_SM = {
  1: "2.4rem",
  2: "1.6rem",
  3: "1.3rem",
  4: "1.1rem",
  5: "1rem",
};

const Element = styled.div`
  font-family: "Quicksand";
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: ${props => props.color || props.theme.gray900};
  font-size: ${props => HEADING_FONT_SIZES_SM[props.level]};

  @media (${props => props.theme.tabletScreen}) {
    font-size: ${props => HEADING_FONT_SIZES[props.level]};
  }
`;

const Heading = ({ children, className, color, level }) => (
  <Element as={HEADING_ELEMENTS[level] || "h1"} level={level} color={color} className={className}>
    {children}
  </Element>
);

Heading.propTypes = {
  level: PropTypes.number,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Heading.defaultProps = {
  level: 1,
  color: null,
};

export default Heading;
