import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData, getToken, isLoading } from "../../store/selectors/user";
import { getCurrentProvider } from "../../store/selectors/provider";
import { signup } from "../../store/actions/user";

import SignupForm from "./SignupForm";

const mapStateToProps = state => ({
  userData: getUserData(state),
  provider: getCurrentProvider(state),
  token: getToken(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {
  signup,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SignupForm),
);
