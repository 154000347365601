import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.fieldset`
  border: none;
  margin: 0;
  display: block;
`;

const Slider = styled.input`
  width: 100%;
  accent-color: ${props => props.theme.info};
  border: none;
`;
const SliderDisplay = styled.p`
  text-align: center;
  color: ${props => props.theme.black};
`;

const RangeSlider = ({ className, defaultValue, from, to, format, onChange }) => {
  const [value, setValue] = useState(defaultValue || to);

  const handleChange = v => {
    setValue(v);
  };

  return (
    <Wrapper className={className}>
      <Slider
        type="range"
        min={from}
        max={to}
        defaultValue={value}
        onChange={({ target: { value } }) => handleChange(value)}
        onMouseUp={() => onChange(value)}
        onTouchEnd={() => onChange(value)}
      />
      <SliderDisplay>{format(value)}</SliderDisplay>
    </Wrapper>
  );
};

RangeSlider.propTypes = {
  defaultValue: PropTypes.number,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  foramt: PropTypes.func,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

RangeSlider.defaultProps = {
  className: "",
  defaultValue: null,
  onChange: () => {},
  format: v => v,
};

export default RangeSlider;
