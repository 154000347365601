import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate, injectIntl } from "react-intl";
import styled from "styled-components/macro";
import { NavLink, useHistory } from "react-router-dom";
import routes, { routeWithParams } from "../../routes";
import { orderBy } from "lodash";
import { isMobileView } from "../../theme";

import { gradientColors } from "../../utils/formatters";
import UserForm from "../../components/UserForm";
import UserNav from "../../components/UserNav";
import Footer from "../../components/Footer";
import {
  Avatar,
  Link,
  PageLayout,
  PageTitle,
  CardContainer,
  Heading,
  Paragraph,
  Button,
  Separator,
  Loading,
  Small,
  ListItems,
  ListItem,
  ListItemName,
} from "../../components/";
import Authenticate from "./Authenticate";
import Booking from "./Booking";
import Contract from "./Contract";
import OnDemandBooking from "./OnDemandBooking";
import Icon from "../../components/Icon";
import TabNav from "../../components/TabNav";

const NAV_ITEMS = [
  { key: "bookings", icon: "ticket" },
  { key: "onDemand", icon: "play-circle" },
  { key: "contracts", icon: "file-signature" },
  { key: "providers", icon: "child-reaching" },
  { key: "account", icon: "circle-user" },
];

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;

  background-image: linear-gradient(-108deg, ${props => gradientColors(props.theme.primary)});
`;

const SpacedText = styled.p`
  max-width: 75%;
`;

const ProfileWrapper = styled(CardContainer)`
  margin-top: 1rem;
  padding: 1.5rem 1rem 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (${props => props.theme.tabletScreen}) {
    padding: 4rem;
  }
`;

const SpacedContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SpacedButton = styled(Button)`
  display: flex;
  align-items: center;

  & > i {
    margin-right: 0.5rem;
  }
`;

const SearchCTA = styled.p`
  color: ${props => props.theme.black};
`;

const ContentWrapper = styled.section`
  display: flex;
  width: 100%;
`;

const Content = styled.div`
  flex: 4;

  @media (${props => props.theme.tabletScreen}) {
    padding: 1rem 1rem 1rem 2rem;
  }
`;

const ContentHeader = styled.header`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  button {
    display: flex;
    background-color: ${props => props.theme.white};
  }
  button h4 {
    margin-left: 1rem;
    margin-bottom: 0;
  }
`;

const Navigation = styled.aside`
  flex: 1;
  padding: 0.5rem 0;
  min-width: 240px;
`;

const AccountSummary = styled.footer`
  padding: 1rem;
  border-top: 1px solid ${props => props.theme.light};
  color: ${props => props.theme.gray600};
  font-size: ${props => props.theme.fontSizes.small};
  text-align: center;

  @media (${props => props.theme.tabletScreen}) {
    padding: 2.5rem 1rem;
    margin-top: 5rem;
  }
`;

const WelcomeMessage = styled(SpacedText)`
  display: none;
  @media (${props => props.theme.tabletScreen}) {
    display: block;
  }
`;

const Profile = ({
  userData,
  token,
  isLoading,
  provider,
  providers,
  bookings,
  isLoadingBookings,
  booking,
  logout,
  loadBookings,
  updateCurrentBooking,
  contracts,
  loadContracts,
  cancelContract,
  intl,
}) => {
  const [current, setCurrent] = useState(isMobileView ? null : NAV_ITEMS[0].key);
  const history = useHistory();

  useEffect(() => {
    if (token) {
      loadBookings();
      loadContracts();
    }
  }, [token, loadBookings, loadContracts]);

  const onBookingSet = booking => {
    updateCurrentBooking(booking);
    return history.push(
      routeWithParams(routes.course, { slug: booking.provider.slug, id: booking.course.id }),
    );
  };

  const onDemandBookings = (bookings || []).filter(booking => booking.onDemand);
  const fullName = userData && [userData.firstName, userData.lastName].join(" ");
  const badges = {
    bookings: (bookings || []).length || (userData || {}).bookingCount,
    onDemand: onDemandBookings.length,
    contracts: (userData || {}).productContractCount,
    providers: Object.keys(providers || {}).length,
  };
  const navItems = NAV_ITEMS.map(({ key, icon }) => ({
    key,
    name: <FormattedMessage id={`pages.Profile.nav.${key}`} />,
    badge: badges[key] || null,
    icon: icon,
  }));

  const contentFor = key => {
    switch (key) {
      case "account":
        return <UserForm />;
      case "bookings":
        return (
          <>
            {isLoadingBookings ? (
              <Loading transparent />
            ) : bookings && bookings.length === 0 ? (
              <Paragraph>
                <FormattedMessage id="pages.Profile.noBookings" />
              </Paragraph>
            ) : (
              orderBy(bookings || [], "createdAt", "desc").map(booking => (
                <Booking key={booking.id} booking={booking} setBooking={onBookingSet} />
              ))
            )}
          </>
        );
      case "onDemand":
        return (
          <>
            {isLoadingBookings ? (
              <Loading transparent />
            ) : onDemandBookings && onDemandBookings.length === 0 ? (
              <Paragraph>
                <FormattedMessage id="pages.Profile.noBookings" />
              </Paragraph>
            ) : (
              onDemandBookings &&
              orderBy(onDemandBookings || [], "createdAt", "desc").map(booking => (
                <OnDemandBooking key={booking.id} booking={booking} />
              ))
            )}
          </>
        );
      case "contracts":
        return contracts && contracts.length < 1 ? (
          <Paragraph>
            <FormattedMessage id="pages.Profile.noBookings" />
          </Paragraph>
        ) : (
          orderBy(contracts || [], "createdAt", "asc").map(contract => (
            <Contract
              key={contract.id}
              contract={contract}
              cancel={() =>
                window.confirm(intl.formatMessage({ id: "confirm" })) && cancelContract(contract.id)
              }
            />
          ))
        );
      case "providers":
        return (
          <ListItems>
            {providers ? (
              Object.keys(providers).map(id => (
                <Link
                  as={NavLink}
                  to={routeWithParams(routes.provider, {
                    slug: providers[id].slug,
                  })}
                  target="_blank"
                  key={id}
                >
                  <ListItem card>
                    {providers[id].logo && (
                      <Avatar small style={{ backgroundImage: `url('${providers[id].logo}')` }} />
                    )}
                    <ListItemName>
                      {providers[id].name}
                      <br />
                      <Small>
                        <FormattedMessage
                          id="pages.Profile.providerVisitedAt"
                          values={{
                            date: (
                              <FormattedDate
                                month="long"
                                day="2-digit"
                                value={providers[id].visitedAt}
                              />
                            ),
                          }}
                        />
                      </Small>
                    </ListItemName>
                  </ListItem>
                </Link>
              ))
            ) : (
              <SearchCTA>
                <FormattedMessage id="pages.Profile.noProviders" />
                <br />
                <br />
                <Button to={routes.search} color="primary">
                  <FormattedMessage id="pages.Profile.searchCTA" />
                </Button>
                <br />
                <br />
                <Button to={routes.searchOnline} color="warning">
                  <FormattedMessage id="pages.Profile.searchOnlineCTA" />
                </Button>
              </SearchCTA>
            )}
          </ListItems>
        );
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <UserNav />
      <PageLayout>
        <PageTitle
          title={
            fullName ? (
              <FormattedMessage id="salutation" values={{ name: userData.firstName }} />
            ) : (
              "Login"
            )
          }
          subTitle={<FormattedMessage id="pages.Profile.profile" />}
        />
        <ProfileWrapper>
          <WelcomeMessage as="section">
            <Heading level={2}>
              <FormattedMessage id="kikudooProfile" />
              <br />
              <Small>
                <FormattedMessage id="pages.Profile.hello" />
              </Small>
            </Heading>
          </WelcomeMessage>
          {!userData || !token ? (
            <Authenticate />
          ) : (
            <SpacedContent>
              <ContentWrapper>
                <Navigation style={{ display: isMobileView && current ? "none" : "block" }}>
                  <TabNav
                    items={navItems}
                    defaultItem={isMobileView ? null : NAV_ITEMS[0].key}
                    onClick={key => setCurrent(key)}
                  />

                  <Separator />

                  <SpacedButton color="danger" onClick={logout} outline>
                    <Icon name="SignOut" size="small" />
                    <FormattedMessage id="actions.logout" />
                  </SpacedButton>
                </Navigation>
                {current && (
                  <Content>
                    <ContentHeader>
                      {isMobileView ? (
                        <Button onClick={() => setCurrent(null)}>
                          <Icon name="chevron-up" direction="left" />
                          <Heading level={4}>
                            <FormattedMessage id={`pages.Profile.nav.${current}`} />
                          </Heading>
                        </Button>
                      ) : (
                        <Heading level={4}>
                          <FormattedMessage id={`pages.Profile.nav.${current}`} />
                        </Heading>
                      )}
                    </ContentHeader>
                    {contentFor(current)}
                  </Content>
                )}
              </ContentWrapper>
              <AccountSummary>
                <SpacedText style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <FormattedMessage id="pages.Profile.userSummary" />
                </SpacedText>
              </AccountSummary>
            </SpacedContent>
          )}
        </ProfileWrapper>
      </PageLayout>
      <Footer />
    </Wrapper>
  );
};

Profile.propTypes = {
  provider: PropTypes.object,
  providers: PropTypes.object,
  booking: PropTypes.object,
  userData: PropTypes.object,
  token: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  loadBookings: PropTypes.func.isRequired,
  bookings: PropTypes.array,
  isLoadingBookings: PropTypes.bool.isRequired,
  contracts: PropTypes.array,
  loadContracts: PropTypes.func.isRequired,
  cancelContract: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

Profile.defaultProps = {
  courses: [],
  contracts: [],
};

export default injectIntl(Profile);
