import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { injectIntl } from "react-intl";
import Marker from "react-leaflet-enhanced-marker";
import { empty } from "../../utils/common";

import wordmark from "../../assets/kikudoo-wordmark.png";
import theme from "../../theme.js";

const MAX_PROVIDERS = 3;
const ZOOM_SIZES = {
  near: 48,
  normal: 32,
  far: 24,
};
const NAME_ZOOM = 14;

const getSizeFromZoom = zoom => {
  if (zoom > 16) {
    return ZOOM_SIZES.near;
  }
  if (zoom > 10) {
    return ZOOM_SIZES.normal;
  }
  return ZOOM_SIZES.far;
};

const MarkerWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    z-index: 2;
  }
`;

const MarkerNameList = styled.ul`
  position: absolute;
  left: 100%;
  padding-left: 0.4rem;
  margin-top: -50%;
`;

const MarkerName = styled.li`
  margin-bottom: 0.5rem;
  border-radius: 4px;
  background-color: ${() => theme.primary};
  color: ${() => theme.white};
  box-shadow: ${() => theme.boxShadowLifted};
  padding: 0.2rem 0;
  position: relative;
  cursor: pointer;

  max-width: 0;
  padding-left: 0;
  padding-right: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: transform 0.5s, padding-left 0.15s, padding-right 0.15s, max-width 0.3s;

  /* &:after {
    position: absolute;
    right: 100%;
    top: 0;
    content: "Öffnen";
    background-color: ${() => theme.white};
    color: ${() => theme.primary};
    font-size: ${() => theme.fontSizes.small};
    border-radius: 4px;
    padding: 0.275rem 0.3rem;
    transition: right 0.5s ease-in-out;
  } */

  &:hover {
    max-width: 350px;
    transform: scale(1.04);
    z-index: 2;
    &:after {
      right: 0;
    }
  }
`;

const MarkerDot = styled.div`
  border-radius: 50%;
  background-color: ${() => theme.pink};
  opacity: ${props => (props.focus ? "0.7" : "0.3")};
  width: 18px;
  height: 14px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
`;

const Wrapper = styled.article`
  border-radius: 50%;
  background-color: ${() => theme.white};
  box-shadow: 0 0.7rem 0.7rem -0.3rem rgba(0,0,0,0.4);
  background-image: url('${props => props.src || wordmark}');
  background-position: center center;
  background-size: cover;
  width: ${props => getSizeFromZoom(props.zoom) * (props.highlight ? 1.25 : 1)}px;
  height: ${props => getSizeFromZoom(props.zoom) * (props.highlight ? 1.25 : 1)}px;
  border: 3px solid ${props => (props.highlight || props.focus ? theme.info : theme.white)};
  z-index: ${props => (props.focus ? 2 : 1)};
  position: relative;


  &:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid ${props =>
      props.highlight || props.focus ? theme.info : theme.white};
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    margin-left: -0.5rem;
  }

  &:hover {
    z-index: 2;
    ${MarkerName} {
      max-width: 240px;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }
  }

  ${MarkerName} {
    max-width: ${props => (props.focus || props.zoom >= NAME_ZOOM ? "240px" : "0")};
    padding-left: ${props => (props.focus || props.zoom >= NAME_ZOOM ? "0.4rem" : "0")};
    padding-right: ${props => (props.focus || props.zoom >= NAME_ZOOM ? "0.4rem" : "0")};
    background-color: ${props => props.theme[props.focus ? "pink" : "primary"]};
  }
`;

const LocationMarker = ({ location, zoom, focus, onClick, onNameClick, intl }) => {
  const provider = location.providers && location.providers.length === 1 && location.providers[0];
  const logo = provider && provider.logo;
  const highlight = provider && !provider.external;
  // Due to double fire issue on Safari, we have to use this quick fix
  // https://github.com/Leaflet/Leaflet/issues/7255
  let lastClick = null;

  if (!location || empty(location.providers)) {
    return "";
  }

  const MarkerContent = () => (
    <MarkerWrapper style={{ zIndex: focus ? 2 : 0 }}>
      <Wrapper src={logo || null} zoom={zoom} highlight={highlight} focus={focus} theme={theme}>
        <MarkerNameList theme={theme}>
          {location.providers
            .sort((p0, p1) => (p0.external ? 1 : -1))
            .slice(0, MAX_PROVIDERS)
            .map(({ id, name, slug }) => name !== "" && <MarkerName key={id}>{name}</MarkerName>)}
          {location.providers.length > MAX_PROVIDERS && (
            <MarkerName key="more-providers">
              {intl.formatMessage(
                {
                  id: "pages.SearchMap.moreProviders",
                },
                { count: location.providers.length - MAX_PROVIDERS },
              )}
            </MarkerName>
          )}
        </MarkerNameList>
      </Wrapper>
      <MarkerDot focus={focus} />
    </MarkerWrapper>
  );

  return (
    <Marker
      position={location}
      eventHandlers={{
        click: e => {
          // Workaround for double click event issue on mobile
          if (lastClick && lastClick >= Date.now() - 200) {
            return;
          }
          lastClick = Date.now();
          onClick(location);
        },
      }}
      icon={<MarkerContent />}
    />
  );
};

LocationMarker.propTypes = {
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onNameClick: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  highlight: PropTypes.bool,
};

LocationMarker.defaultProps = {
  highlight: false,
};

export default injectIntl(LocationMarker);
