import React, { useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import routes from "../../routes";

import {
  Input,
  Button,
  Alert,
  CardContainer,
  PageLayout,
  PageTitle,
  Paragraph,
  Separator,
} from "../../components/";
import UserNav from "../../components/UserNav";
import { gradientColors } from "../../utils/formatters";
import Footer from "../../components/Footer";

const Form = styled.form``;

const Actions = styled.div`
  display: flex;
  & > button {
    flex: 1;
  }
`;

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;

  background-image: linear-gradient(-108deg, ${props => gradientColors(props.theme.primary)});
`;

const Content = styled(CardContainer)`
  margin-top: 1rem;
  padding: 1rem;

  @media (${props => props.theme.tabletScreen}) {
    padding: 4rem;
  }
`;

const FormWrapper = styled.div`
  max-width: 480px;
  margin-top: 2rem;
`;

const ResetPassword = ({ intl, isLoading, resetPassword, token, userData }) => {
  const [email, setEmail] = useState("");
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    setFailed(false);

    try {
      await resetPassword(email);
      setSuccess(true);
    } catch (e) {
      setFailed(true);
    }
  };

  if (token && userData) {
    return <Redirect to={routes.profile} />;
  }

  return (
    <Wrapper>
      <UserNav />
      <PageLayout>
        <PageTitle title={<FormattedMessage id="pages.ResetPassword.title" />} />
        {success ? (
          <Content>
            <Paragraph spaced>
              <FormattedMessage id="pages.ResetPassword.success" />
            </Paragraph>
          </Content>
        ) : (
          <Content>
            <Paragraph spaced>
              <FormattedMessage id="pages.ResetPassword.intro" />
            </Paragraph>
            <FormWrapper>
              <Form onSubmit={onSubmit}>
                {failed && (
                  <Alert type="danger">
                    <FormattedMessage id="pages.ResetPassword.failed" />
                  </Alert>
                )}
                <Input
                  name="email"
                  type="email"
                  value={email}
                  placeholder={intl.formatMessage({ id: "user.email" })}
                  onChange={({ target: { value } }) => setEmail(value)}
                  hasError={failed}
                  required
                />
                <Actions>
                  <Button color="success" type="submit" busy={isLoading} glow>
                    <FormattedMessage id="actions.resetPassword" />
                  </Button>
                </Actions>
              </Form>
            </FormWrapper>
            <Separator />
            <NavLink to={routes.profile}>
              <FormattedMessage id="actions.login" />
              {" / "}
              <FormattedMessage id="actions.signup" />
            </NavLink>
          </Content>
        )}
      </PageLayout>
      <Footer />
    </Wrapper>
  );
};

ResetPassword.propTypes = {
  token: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ResetPassword);
