import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";

import { ApiClient } from "../../utils/Api";
import { gradientColors } from "../../utils/formatters";
import UserNav from "../../components/UserNav";
import Footer from "../../components/Footer";
import {
  PageLayout,
  PageTitle,
  CardContainer,
  Icon,
  Paragraph,
  Input,
  Button,
} from "../../components/";

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;

  background-image: linear-gradient(-108deg, ${props => gradientColors(props.theme.primary)});
`;

const Form = styled.form`
  max-width: 400px;
  margin: 2rem auto;
  display: block;
`;

const ProfileWrapper = styled(CardContainer)`
  margin-top: 1rem;
  padding: 1rem;
  text-align: center;
  flex: 1;

  p {
    text-align: center;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding: 4rem;
  }
`;

const IconWrapper = styled.div`
  margin: 2rem auto;
  text-align: center;
`;

const ConfirmError = () => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    new ApiClient().newConfirmation({ email });
    setSent(true);
  };

  return (
    <Wrapper>
      <UserNav />
      <PageLayout>
        <PageTitle title={<FormattedMessage id="pages.ConfirmError.title" />} subTitle={""} />
        <ProfileWrapper>
          <IconWrapper>
            <Icon name="add" direction={45} size="huge" color="danger" />
          </IconWrapper>
          {sent ? (
            <Paragraph>
              <FormattedMessage id="pages.ConfirmError.resend" />
            </Paragraph>
          ) : (
            <>
              <Paragraph>
                <FormattedMessage id="pages.ConfirmError.intro" />
              </Paragraph>
              <Paragraph>
                <Form onSubmit={handleSubmit}>
                  <Input
                    name="email"
                    required
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Button type="submit" color="primary">
                    <FormattedMessage id="pages.ConfirmError.resendLink" />
                  </Button>
                </Form>
              </Paragraph>
            </>
          )}
        </ProfileWrapper>
      </PageLayout>
      <Footer />
    </Wrapper>
  );
};

export default ConfirmError;
