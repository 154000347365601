import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate, FormattedNumber } from "react-intl";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

import { currencyFormat } from "../../utils/formatters";
import routes, { routeWithParams } from "../../routes";
import { Card, Button, Alert, Row, Col, Mono, Badge, Icon, RichText } from "../../components/";

const Label = styled.span`
  margin-right: 1rem;
  &:after {
    content: ":";
  }
`;

const BookingCard = styled(Card)`
  margin-bottom: 1rem;
`;

const Value = styled.span`
  color: ${props => props.theme.black};
  white-space: nowrap;
`;

const NumberValue = styled(Mono)`
  color: ${props => props.theme.black};
`;

const TextRight = styled.section`
  text-align: right;
  margin-bottom: 0.5rem;
`;

const EventHint = styled.section`
  margin-top: 0.5rem;
  font-size: ${props => props.theme.fontSizes.small};
  padding: 0.05rem 1rem;
  background-color: ${props => props.theme.gray100};
  border-radius: ${props => props.theme.borderRadius};
  max-width: 480px;
  max-height: 480px;
  overflow: auto;
`;

const Booking = ({ booking, setBooking }) => (
  <BookingCard
    header={
      <>
        <span>
          <FormattedMessage id="booking.one" />
          <strong>{`: ${booking.billingNo}`}</strong>
        </span>
        <aside>
          <Badge color={booking.status === "paid" ? "success" : "warning"}>
            <FormattedMessage id={`booking.attributes.statuses.${booking.status}`} />
          </Badge>
          <small>
            <FormattedMessage
              id="pages.Profile.bookingDate"
              values={{ date: <FormattedDate value={booking.paidAt || booking.createdAt} /> }}
            />
          </small>
        </aside>
      </>
    }
  >
    {booking.productContract && (
      <Row>
        <Col count={12}>
          <Label>
            <FormattedMessage id="contract.one" />
          </Label>
          <Value>
            {booking.productContract.product.name}
            <small style={{ marginLeft: "0.25rem" }}>
              <em>
                <FormattedMessage
                  id={`product.attributes.typeOfs.${booking.productContract.product.typeOf}`}
                />
              </em>
            </small>
          </Value>
        </Col>
      </Row>
    )}
    {booking.course && (
      <>
        <Row>
          <Col count={12}>
            <Label>
              <FormattedMessage id="course.one" />
            </Label>
            <NavLink
              to={routeWithParams(routes.course, {
                slug: booking.provider.slug,
                id: booking.course.id,
              })}
            >
              <Value>{booking.course.name}</Value>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Col count={12}>
            <Label>
              <FormattedMessage id="event.one" />
            </Label>
            <NavLink
              to={routeWithParams(routes.course, {
                slug: booking.provider.slug,
                id: booking.course.id,
              })}
            >
              <Value>{booking.event.name}</Value>
            </NavLink>
          </Col>
        </Row>
      </>
    )}
    <Row>
      <Col count={6}>
        <Label>
          <FormattedMessage id="booking.attributes.participants" />
        </Label>
        <Value>{booking.participants.map(({ name }) => name).join(", ")}</Value>
      </Col>
      <Col count={6}>
        <TextRight>
          <Label>
            <FormattedMessage id="booking.attributes.costs" />
          </Label>
          <NumberValue>
            {booking.costs > 0 ? (
              <FormattedNumber value={booking.costs} {...currencyFormat(booking)} />
            ) : (
              "-"
            )}
          </NumberValue>
          {booking.billUrl && (
            <p>
              <Button color="primary" outline size="small" to={booking.billUrl} target="_blank">
                <Icon name="file-invoice" /> <FormattedMessage id="booking.attributes.billUrl" />
              </Button>
            </p>
          )}
        </TextRight>
      </Col>
    </Row>
    {booking.event.hint && (
      <Row>
        <Col>
          <FormattedMessage id="event.attributes.hint" />
          <EventHint>
            <RichText text={booking.event.hint || ""} />
          </EventHint>
        </Col>
      </Row>
    )}
    {booking.status !== "paid" && (
      <Alert>
        <FormattedMessage id="pages.Profile.ongoingBooking" />
        <br />
        <Button onClick={() => setBooking(booking)}>
          <FormattedMessage id="pages.Profile.toOngoingBooking" />
        </Button>
      </Alert>
    )}
  </BookingCard>
);

Booking.propTypes = {
  booking: PropTypes.object.isRequired,
  setBooking: PropTypes.func.isRequired,
};

export default Booking;
