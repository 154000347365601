import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  flex: 1;
  min-width: 240px;
  @media (${props => props.theme.tabletScreen}) {
    margin-top: 0.5rem;
  }
`;

const Course = styled.li`
  display: flex;
  min-width: 220px;
  max-width: 480px;
  margin-right: 1rem;
  color: ${props => props.theme.black};
  align-items: center;

  &:before {
    content: "";
    display: inline-block;
    min-width: 0.5rem;
    min-height: 0.5rem;
    margin-right: 0.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: ${props => props.theme.info};
  }
`;

const Courses = styled.ol`
  margin: 0;
  padding: 0;
  max-width: 100%;
`;

const Events = ({ product }) => {
  return (
    <Wrapper>
      {!product.eventOnly && (product.events || []).length > 1 && (
        <Courses>
          <li>
            <FormattedMessage id="components.Booking.moreEvents" />
          </li>
          {product.events.map(e => (
            <Course key={e.id}>{e.name}</Course>
          ))}
        </Courses>
      )}
    </Wrapper>
  );
};

Events.propTypes = {
  product: PropTypes.object.isRequired,
};

Events.defaultProps = {};

export default Events;
