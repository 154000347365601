import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${props => props.theme.white};
  background-clip: border-box;
  border-radius: 0.5rem;
  box-shadow: ${props => props.theme.boxShadowSmall};
  min-width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: ${props => props.theme.light};

  &:first-child {
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
  }
`;

const Body = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;
`;

const Card = ({ header, children, className }) => (
  <Wrapper className={className}>
    {header && <Header>{header}</Header>}
    <Body>{children}</Body>
  </Wrapper>
);

Card.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  flipped: false,
};

export default Card;
