import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { useFade } from "../utils/hooks";
import { FormattedMessage } from "react-intl";

import Loading from "../components/Loading";
import { MiniBrand } from "../components";
import minibrand from "../assets/kikudoo-wordmark.png";

export const Wrapper = styled.div`
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${props => props.theme.dark};
  background-color: ${props => props.theme.light};
  overflow: hidden;
  z-index: 9;
  pointer-events: none;

  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0 1s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const LoadingOverlay = ({ show, text, icon }) => {
  const [showLoading, setShowLoading, fadeProps] = useFade(show);

  useEffect(() => setShowLoading(show), [show, setShowLoading]);

  if (!showLoading) {
    return "";
  }

  return (
    <Wrapper {...fadeProps}>
      <MiniBrand style={{ backgroundImage: `url('${icon || minibrand}')` }} />
      <Loading small text={text || <FormattedMessage id="loading" />} />
    </Wrapper>
  );
};

LoadingOverlay.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

Loading.defaultProps = {
  icon: null,
  text: null,
};

export default LoadingOverlay;
