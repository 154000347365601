import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProvider } from "../store/selectors/provider";

const withProvider = () => WrappedComponent => {
  const ApplyProvider = ({ provider, ...props }) => {
    return <WrappedComponent provider={provider} {...props} />;
  };

  ApplyProvider.propTypes = {
    provider: PropTypes.object,
  };

  const mapStateToProps = state => ({
    provider: getCurrentProvider(state),
  });

  return connect(mapStateToProps)(ApplyProvider);
};

export default withProvider;
