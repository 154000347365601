import React from "react";
import { FormattedMessage } from "react-intl";
import { darken, adjustHue, parseToHsl, lighten, desaturate } from "polished";

export const CURRENCY_FORMAT = { style: "currency", currency: "EUR" };
export const currencyFormat = entity => ({
  ...CURRENCY_FORMAT,
  currency: (entity || {}).currency || CURRENCY_FORMAT.currency,
});

const asYear = number => number > 48 || (number > 6 && number % 6 === 0);

// Very simple prettify of decimals like:
// 1.5 -> 1 1/2
const prettyDecimal = number => {
  if (number % 1 === 0) {
    return number;
  }

  const parts = String(number).split(".");
  const string = [];
  if (number > 1) {
    string.push(parts[0]);
  }
  string.push(String.fromCharCode(189));
  return string.join(" ");
};

export const formatAddress = address =>
  ["street", "postalCode", "city", "description"]
    .filter(key => address[key] && address[key] !== "")
    .map(key => address[key])
    .join(", ");

export const formatPhone = number => {};

export const formatNonHTML = content => (content || "").replace(/(<([^>]+)>)/gi, "");

export const formatMailto = email =>
  `javascript:window.open(atob('${window.btoa("mailto:" + email)}'), '_self');`;

export const formatEmail = email =>
  [
    email.substr(0, Math.min(email.indexOf("@"), 4)),
    email.substr(email.indexOf("@"), email.length - 1),
  ].join(email.indexOf("@") > 4 ? "..." : "");

export const formatAgeSpan = (ageMin, ageMax, short = false) => {
  const displayMethod = [asYear(ageMin) ? "year" : "month", asYear(ageMax) ? "year" : "month"].join(
    "-",
  );

  if (ageMin === ageMax) {
    return (
      <FormattedMessage
        id={`course.attributes.ageSpan.${displayMethod.match("month") ? "Month" : "Year"}`}
        values={{ age: displayMethod.match("month") ? ageMin : ageMin / 12 }}
      />
    );
  }

  switch (displayMethod) {
    case "year-month":
      if (ageMax === 0) {
        return (
          <FormattedMessage
            id={`course.attributes.fromAge.Year${short ? "Short" : ""}${
              ageMin === 12 ? ".Singular" : ""
            }`}
            values={{ ageMin: prettyDecimal(ageMin / 12) }}
          />
        );
      } else if (ageMin === 0) {
        return (
          <FormattedMessage
            id={`course.attributes.toAge.Month${short ? "Short" : ""}`}
            values={{ ageMax: ageMax }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id={`course.attributes.ageSpan.MonthToMonth${short ? "Short" : ""}`}
            values={{ ageMin: ageMin, ageMax: ageMax }}
          />
        );
      }
    case "month-month":
      if (ageMax === 0) {
        return (
          <FormattedMessage
            id={`course.attributes.fromAge.Month${short ? "Short" : ""}`}
            values={{ ageMin: ageMin }}
          />
        );
      } else if (ageMin === 0) {
        return (
          <FormattedMessage
            id={`course.attributes.toAge.Month${short ? "Short" : ""}`}
            values={{ ageMax: ageMax }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id={`course.attributes.ageSpan.MonthToMonth${short ? "Short" : ""}`}
            values={{ ageMin: ageMin, ageMax: ageMax }}
          />
        );
      }

    case "month-year":
      if (ageMax === 0) {
        return (
          <FormattedMessage
            id={`course.attributes.fromAge.Month${short ? "Short" : ""}`}
            values={{ ageMin: ageMin }}
          />
        );
      } else if (ageMin === 0) {
        return (
          <FormattedMessage
            id={`course.attributes.toAge.Year${short ? "Short" : ""}${
              ageMax === 12 ? ".Singular" : ""
            }`}
            values={{ ageMax: prettyDecimal(ageMax / 12) }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id={`course.attributes.ageSpan.MonthToYear${short ? "Short" : ""}`}
            values={{ ageMin: ageMin, ageMax: prettyDecimal(ageMax / 12) }}
          />
        );
      }
    default:
      if (ageMax === 0) {
        return (
          <FormattedMessage
            id={`course.attributes.fromAge.Year${short ? "Short" : ""}${
              ageMin === 12 ? ".Singular" : ""
            }`}
            values={{ ageMin: prettyDecimal(ageMin / 12) }}
          />
        );
      } else if (ageMin === 0) {
        return (
          <FormattedMessage
            id={`course.attributes.toAge.Year${short ? "Short" : ""}${
              ageMax === 12 ? ".Singular" : ""
            }`}
            values={{ ageMax: prettyDecimal(ageMax / 12) }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id={`course.attributes.ageSpan.YearToYear${short ? "Short" : ""}`}
            values={{ ageMin: prettyDecimal(ageMin / 12), ageMax: prettyDecimal(ageMax / 12) }}
          />
        );
      }
  }
};

export const titleImage = (obj, rendition = "large") => {
  if (window.innerWidth <= 768 && rendition === "large") {
    rendition = "display";
  }

  if (!obj) {
    return null;
  }

  if (obj.titleImage) {
    return obj.titleImage[rendition];
  }

  return obj.images && obj.images[0] && obj.images[0][rendition];
};

export const truncate = (str, length = 120) =>
  str.length > length ? `${str.substring(0, length)}...` : str;

export const normalizeColor = (
  color,
  maxLightness = 0.66,
  maxSaturation = 0.76,
  minLightness = 0.2,
) => {
  const hlsColor = parseToHsl(color);
  if (hlsColor.lightness > maxLightness) {
    color = darken(hlsColor.lightness - maxLightness, color);
  }
  if (hlsColor.saturation > maxSaturation) {
    color = desaturate(hlsColor.saturation - maxSaturation, color);
  }
  if (hlsColor.lightness < minLightness) {
    color = lighten(minLightness - hlsColor.lightness, color);
  }

  return color;
};

export const gradientColors = (color, variants = 33, normalize = true) => {
  if (normalize) {
    color = normalizeColor(color);
  }
  return [adjustHue(-variants, color), color, adjustHue(variants, color)].join(", ");
};

const textareaElement = document.createElement("textarea");
export const decodeHtml = string => {
  if (!string) {
    return "";
  }

  textareaElement.innerHTML = string;
  return (textareaElement.value || "").trim();
};
