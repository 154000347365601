import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import routes, { routeWithParams } from "../../routes";

import { Category } from "../../components";

const franchiseCategory = franchise => ({
  id: franchise.slug,
  name: franchise.name,
  color: franchise.color,
  description: "",
});

// Open the provider page from list or makler click
const onFranchiseClick = slug => {
  const win = window.open(routeWithParams(routes.franchise, { id: slug }), "_blank");
  if (win && win.focus) {
    win.focus();
  }
};

const Wrapper = styled.aside`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  & > div {
    margin-right: 0.5rem;
  }
`;

const Categories = ({ course }) => (
  <Wrapper>
    {course.franchise && (
      <Category
        key={course.franchise.slug}
        category={franchiseCategory(course.franchise)}
        // image={course.franchise.logo}
        onClick={() => onFranchiseClick(course.franchise.slug)}
      />
    )}
    {(course.categories || []).slice(0, course.franchise ? 4 : 5).map(category => (
      <Category key={category.name} category={category} />
    ))}
  </Wrapper>
);

Categories.propTypes = {
  course: PropTypes.object.isRequired,
};

Categories.defaultProps = {};

export default Categories;
