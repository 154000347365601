import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import routes, { routeWithParams } from "../../routes";

import { scrollTop } from "../../utils/common";
import templates from "./templates";
import { PageLayout, PageTitle } from "../../components/";

const Courses = ({ provider, courses, loadCourses, isLoading }) => {
  const Template = templates[provider.template || "standard"];
  const history = useHistory();

  // Scroll on top of page
  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    loadCourses(provider.id);
  }, [provider, loadCourses]);

  return (
    <PageLayout>
      <Helmet>
        <title>{provider.name}</title>
        <link href={`https://kikudoo.com/${provider.slug}/courses`} rel="canonical" />
        <meta property="og:url" content={`https://kikudoo.com/${provider.slug}/courses`} />
      </Helmet>
      <PageTitle
        title={provider.name}
        subTitle={"Alle Angebote"}
        // contact={provider.contacts[0]}
        logo={provider.logo}
        clean={provider.template === "clean"}
        onProviderClick={() => {
          history.push(
            routeWithParams(routes.provider, {
              slug: provider.slug,
            }),
          );
        }}
      />
      <Template provider={provider} courses={courses || []} isLoading={isLoading} />
    </PageLayout>
  );
};

Courses.propTypes = {
  provider: PropTypes.object.isRequired,
  courses: PropTypes.array,
  isLoading: PropTypes.bool,
};

Courses.defaultProps = {
  courses: [],
  isLoading: false,
};

export default Courses;
