import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";

import { ApiClient } from "../../../utils/Api";

import Details from "../Details";
import Gallery from "../../../components/Gallery";
import CourseList from "../../../components/CourseList";
import Recommendations from "../Recommendations";

import {
  PageContent,
  CardContainer,
  Row,
  Col,
  Link,
  RichText,
  Separator,
  Small,
  Loading,
} from "../../../components/";

const makeHref = url => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);

const trackForward = id => new ApiClient().providerForwarded(id);

const ExternalIntro = styled.article`
  width: 100%;
  margin: 1rem 0 10rem;
  @media (${props => props.theme.desktopScreen}) {
    width: 85%;
    margin: 2rem 0 10rem;
  }
`;

const ExternalLink = styled(Link)`
  display: block;
  margin-top: 1.5rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const Provider = ({ provider, courses, recommendations }) => (
  <PageContent transparent>
    <CardContainer seemless={provider.images.length > 0}>
      <Row>
        <Col size="md" count={8}>
          {provider.external ? (
            <ExternalIntro>
              <FormattedMessage
                id="pages.Provider.externalDescription.intro"
                values={{ name: provider.name }}
              />
              <br />
              {provider.address.phone && (
                <FormattedMessage
                  id="pages.Provider.externalDescription.phone"
                  values={{ name: provider.name, phone: provider.address.phone }}
                />
              )}
              <br />
              <ExternalLink
                as={Link}
                href={makeHref(provider.website)}
                onClick={() => trackForward(provider.slug)}
                target="_blank"
              >
                <FormattedMessage id="pages.Provider.externalDescription.link" />
              </ExternalLink>
              <Small>
                <FormattedMessage id="pages.Provider.externalDescription.linkWarning" />
              </Small>
              <Separator />
              <FormattedMessage id="pages.Provider.externalDescription.yourPage" />
            </ExternalIntro>
          ) : provider.justCreated && provider.description === "" ? (
            <ExternalIntro>
              <FormattedMessage
                id="pages.Provider.justCreatedDescription"
                values={{ provider: provider.name }}
              />
            </ExternalIntro>
          ) : (
            <>
              {provider.images.length > 0 && <Gallery images={provider.images} />}

              <RichText text={provider.description || ""} />
            </>
          )}
        </Col>
        <Col size="md" count={4}>
          <Details provider={provider} />
        </Col>
      </Row>
      {courses.length > 0 ? (
        <CourseList courses={courses} />
      ) : (
        provider.courseCount !== courses.length && (
          <LoadingWrapper>
            <Loading text={<FormattedMessage id="pages.Provider.loadingCourses" />} small />
          </LoadingWrapper>
        )
      )}

      <Recommendations recommendations={recommendations} />
    </CardContainer>
  </PageContent>
);

Provider.propTypes = {
  provider: PropTypes.object.isRequired,
  courses: PropTypes.array,
  recommendations: PropTypes.array,
};

Provider.defaultProps = {
  courses: [],
  recommendations: [],
};

export default Provider;
