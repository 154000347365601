import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedTime, FormattedMessage, FormattedNumber, FormattedDate } from "react-intl";
import { truncate, find, includes } from "lodash";
import { currencyFormat } from "../../utils/formatters";
import { isPast, closestTo, isEqual, isBefore, isAfter } from "date-fns";
import { Mono, Icon } from "../../components";
import { DEFAULT_TIMEZONE } from "../../utils/calc";

const openTab = url => {
  const win = window.open(url, "_blank");
  if (win && win.focus) {
    win.focus();
  }
};

const Description = styled.p`
  max-width: 95%;
`;

const Wrapper = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.gray100};
  border: 2px solid ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowSmall};
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  transition: transform 1s, box-shadow 1s, border 1s, max-height 1s;

  &:hover {
    transform: scale(1.02);
    box-shadow: ${props => props.theme.boxShadowLifted};
    border: 2px solid ${props => props.theme.info};
  }

  font-size: ${props => props.theme.fontSizes.tiny};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Avatar = styled.div`
  min-width: 50px;
  min-height: 100%;
  max-width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${props => props.theme.borderRadius} 0 0 ${props => props.theme.borderRadius};
  background-color: white;
  margin-right: 0.25rem;
`;

const Timing = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 0.2rem 0.25rem;
  border-radius: 2rem;
  background-color: ${props => props.theme.info};
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.fontSizes.tiny};
  font-family: ${props => props.theme.fontFamilyMonospace};
  line-height: ${props => props.theme.fontSizes.tiny};
`;

const Title = styled.strong`
  margin-top: 0.5rem;
  color: ${props => props.theme.black};
  font-size: ${props => props.theme.fontSizes.small};
  display: inline-block;
`;

const Price = styled(Mono)`
  display: inline-block;
  width: 100%;
  text-align: right;
  color: ${props => props.theme.black};
`;

const Hint = styled.p`
  color: ${props => props.theme[props.danger ? "danger" : "info"]};
  margin: 0;
`;

const Content = styled.aside`
  flex: 1;
`;

const OnlineBadge = styled.div`
  border-radius: 50%;
  background-color: ${props => props.theme[props.color || "warning"]};
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Address = styled.p`
  color: ${props => props.theme.black};
`;

const Event = ({ event, categories, day }) => {
  const { provider, course } = event;

  const startDates = event.startDates.map(d => new Date(d));
  const endDates = event.endDates.map(d => new Date(d));
  const startDate = startDates[0];
  const currentStartDate = closestTo(day, startDates);
  const currentEndDate = closestTo(day, endDates);

  const categoryIds = categories.map(({ id }) => id);

  const matchingCategory = find(course.categories || [], category =>
    includes(categoryIds, category.id),
  );

  if (!matchingCategory) {
    return "";
  }

  // If we request a whole week, we can't really determine if the event really started
  // when continuous and start date is set
  if (event.typeOf === "continuous") {
    if (
      (event.startDates && isAfter(Date.parse(event.startDate), day)) ||
      (event.endDate && isBefore(Date.parse(event.endDate), day))
    ) {
      return "";
    }
  }

  const showPrice = false;
  return (
    <Wrapper
      onClick={() => openTab(`/${provider.slug}/courses/${course.id}`)}
      style={{
        opacity: isPast(currentStartDate) || event.bookedOut ? 0.5 : 1,
        borderColor: matchingCategory ? matchingCategory.color : "white",
      }}
    >
      <Info>
        <OnlineBadge color={course.flexibleLocation ? "warning" : "info"}>
          <Icon
            name={course.flexibleLocation ? "Computer" : "Location"}
            size="small"
            color="white"
          />
        </OnlineBadge>
        {provider.logo && <Avatar style={{ backgroundImage: `url('${provider.logo}')` }} />}
        <Content>
          <Title>{truncate(course.name, { length: 30 })}</Title>
          <Description>{truncate(provider.name, { length: 30 })}</Description>
          {!course.flexibleLocation && course.addressCombined && (
            <Address>{course.addressCombined}</Address>
          )}

          {event.participantsLeft < 3 && !event.bookedOut && (
            <Hint>
              <FormattedMessage
                id="components.Schedule.onlyFewLeft"
                values={{ count: event.participantsLeft }}
              />
            </Hint>
          )}
          {event.typeOf === "block" && !isEqual(startDate, currentStartDate) && (
            <Hint danger>
              <FormattedMessage
                id={`components.Schedule.${isPast(startDate) ? "startedAt" : "startsAt"}`}
                values={{
                  date: (
                    <FormattedDate weekday="short" day="numeric" month="short" value={startDate} />
                  ),
                }}
              />
            </Hint>
          )}
          {event.bookedOut ? (
            <Hint danger>
              <FormattedMessage id="components.Schedule.bookedOut" />
            </Hint>
          ) : (
            showPrice && (
              <Price>
                {course.priceFrom ? (
                  <span>
                    {course.priceTo && course.priceFrom !== course.priceTo && (
                      <FormattedMessage id="priceFrom" />
                    )}
                    <FormattedNumber value={course.priceFrom / 100} {...currencyFormat(course)} />
                  </span>
                ) : course.priceFrom !== course.priceTo ? (
                  <span>
                    <FormattedMessage id="priceFrom" />
                    <FormattedNumber value={0} {...currencyFormat(course)} />
                  </span>
                ) : (
                  <FormattedMessage id="components.Course.free" />
                )}
              </Price>
            )
          )}
        </Content>
      </Info>
      <Timing style={{ backgroundColor: matchingCategory ? matchingCategory.color : null }}>
        <FormattedTime value={currentStartDate} timeZone={DEFAULT_TIMEZONE} />
        {" - "}
        <FormattedTime value={currentEndDate} timeZone={DEFAULT_TIMEZONE} />{" "}
        {event.typeOf === "continuous" && <Icon name="Repeat" size="tiny" />}
      </Timing>
    </Wrapper>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired,
  day: PropTypes.instanceOf(Date).isRequired,
  categories: PropTypes.array,
};

Event.defaultProps = {
  categories: [],
};

export default Event;
