import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentProvider } from "../../store/selectors/provider";
import { getCurrentCourse } from "../../store/selectors/courses";
import { loadCourse } from "../../store/actions/courses";
import CourseCardPreview from "./CourseCardPreview";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
  course: getCurrentCourse(state),
});

const mapDispatchToProps = {
  loadCourse,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CourseCardPreview),
);
