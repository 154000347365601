import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { compact } from "lodash";

const Street = styled.span``;
const City = styled.span``;
const Description = styled.p``;

const Wrapper = styled.address`
  display: flex;
  flex-direction: column;

  ${({ inline }) =>
    inline &&
    `
      flex-direction: row;

      ${Street} {
        &:after {
          content: ", ";
          white-space: pre;
        }
      }

      & > span:last-child {
        &:after {
          display: none;
        }
      }
    `}
`;

const Address = ({ className, address, inline }) => {
  const street = address.street || "";
  const city = compact([address.postalCode, address.city]).join(" ");

  return (
    <Wrapper className={className} inline={inline}>
      {street !== "" && <Street>{street}</Street>}
      {city !== "" && <City>{city}</City>}
      {!inline && address.description && address.description !== "" && (
        <Description>{address.description}</Description>
      )}
    </Wrapper>
  );
};

Address.propTypes = {
  className: PropTypes.string,
  address: PropTypes.object,
  inline: PropTypes.bool,
};

Address.defaultProps = {
  className: "",
  address: {},
  inline: false,
};

export default Address;
