import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Icon from "../../components/Icon";

export const DEFAULT_SPACES = [
  { key: "family", name: "Familie", icon: "family", color: "#fad4e6" },
  { key: "creative", name: "Kreatives", icon: "paintbrush-pencil", color: "#e5f4eb" },
  { key: "education", name: "Fortbildung", icon: "graduation-cap", color: "#f8dede" },
  { key: "cooking", name: "Backen & Kochen", icon: "cupcake", color: "#e5f4eb" },
  { key: "lifestyle", name: "Freizeit & Lifestyle", icon: "lights-holiday", color: "#fad4e6" },
  { key: "language", name: "Lernen & Sprachen", icon: "lightbulb-on", color: "#f8dede" },
  { key: "music", name: "Musik & Tanzen", icon: "music-note", color: "#dff4f9" },
  { key: "sport", name: "Sport & Fitness", icon: "person-running", color: "#e5f4eb" },
  { key: "health", name: "Gesundheit & Ernährung", icon: "spa", color: "#fae195" },
  { key: "travel", name: "Reisen & Entdecken", icon: "bench-tree", color: "#fae195" },
];

const Wrapper = styled.ol`
  list-style: none;
  display: flex;
  padding: 1rem;
  width: 100%;
  flex-wrap: nowrap;
  overflow: auto;
  max-width: calc(1200px + 2rem);
  margin-left: auto;
  margin-right: auto;

  & > li {
    flex: 1;
    min-width: 80px;
  }

  @media (${props => props.theme.tabletScreen}) {
    & > li {
      min-width: 100px;
    }
  }
  @media (${props => props.theme.desktopScreen}) {
    & > li {
      min-width: 120px;
    }
  }
`;

const SpaceIcon = styled.span`
  border-radius: ${props => props.theme.borderRadius};
  color: ${props => props.theme.dark};
  font-size: 1rem;
  min-width: 3rem;
  min-height: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.white};

  @media (${props => props.theme.tabletScreen}) {
    font-size: 1.25rem;
    min-width: 4rem;
    min-height: 4rem;
  }
`;

const SpaceName = styled.strong`
  display: inline-block;
  margin-top: 0.5rem;
  text-align: center;
  font-weight: normal;
  color: ${props => props.theme.dark};
  font-size: ${props => props.theme.fontSizes.tiny};

  @media (${props => props.theme.tabletScreen}) {
    font-size: ${props => props.theme.fontSizes.small};
  }
`;

const Space = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${props => props.theme.white};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:not(.active) {
    ${SpaceIcon} {
      background-color: ${props => props.theme.white}!important;
    }
  }

  @media (${props => props.theme.tabletScreen}) {
    &:not(:hover):not(.active) {
      ${SpaceIcon} {
        background-color: ${props => props.theme.white}!important;
      }
    }
  }
`;

const Spaces = ({ spaces, onClick }) => {
  if (spaces.length === 0) {
    return "";
  }

  return (
    <Wrapper>
      {spaces.map(space => (
        <li key={space.key} onClick={() => onClick(space)}>
          <Space className={space.active && "active"}>
            <SpaceIcon style={{ backgroundColor: space.color }}>
              <Icon name={space.icon} />
            </SpaceIcon>
            <SpaceName>{space.name}</SpaceName>
          </Space>
        </li>
      ))}
    </Wrapper>
  );
};

Spaces.propTypes = {
  spaces: PropTypes.array,
  onClick: PropTypes.func.isRequired,
};

Spaces.defaultProps = {
  spaces: DEFAULT_SPACES,
};

export default Spaces;
