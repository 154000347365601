import { ApiClient } from "../../utils/Api";
import { getToken } from "../selectors/user";

export const ActionTypes = {
  APPLICATIONS_SUCCESS: "kikudoo/applications/success",
  APPLICATIONS_REQUEST: "kikudoo/applications/request",
  APPLICATIONS_FAILED: "kikudoo/applications/failed",
  APPLICANT_SUCCESS: "kikudoo/applicant/success",
  APPLICANT_REQUEST: "kikudoo/applicant/request",
  APPLICANT_FAILED: "kikudoo/applicant/failed",
};

export const applicantRequest = () => ({
  type: ActionTypes.APPLICANT_REQUEST,
});

export const applicantFailed = () => ({
  type: ActionTypes.APPLICANT_FAILED,
});

export const applicantSuccess = eventId => ({
  type: ActionTypes.APPLICANT_SUCCESS,
  payload: eventId,
});

export const createApplicant = (providerId, eventId, applicant) => async (dispatch, getState) => {
  dispatch(applicantRequest());

  try {
    await new ApiClient(getToken(getState())).createApplicant(providerId, eventId, applicant);
  } catch (e) {
    dispatch(applicantFailed());
    throw e;
  }

  dispatch(applicantSuccess(eventId));
};

export const applicationsRequest = () => ({
  type: ActionTypes.APPLICATIONS_REQUEST,
});

export const applicationsFailed = () => ({
  type: ActionTypes.APPLICATIONS_FAILED,
});

export const applicationsSuccess = applications => ({
  type: ActionTypes.APPLICATIONS_SUCCESS,
  payload: applications,
});

export const loadApplications = () => async (dispatch, getState) => {
  dispatch(applicationsRequest());

  try {
    const applications = await new ApiClient(getToken(getState())).applications();
    dispatch(applicationsSuccess(applications));
  } catch (e) {
    dispatch(applicationsFailed());
    throw e;
  }
};
