import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import routes, { routeWithParams } from "../routes";
import { FormattedMessage } from "react-intl";

const Wrapper = styled.a`
  color: ${props => props.theme.dark};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;

  padding: 0.5rem 0.5rem 0;
  margin-bottom: 0.5rem;
`;

const Logo = styled.img`
  max-height: 60px;
  margin-right: 0.5rem;
`;

const Text = styled.span`
  min-width: 200px;
`;

const Franchise = ({ franchise, context }) => {
  return (
    <Wrapper href={routeWithParams(routes.franchise, { id: franchise.slug })} target="_blank">
      {franchise.logo && <Logo src={franchise.logo} title={franchise.name} />}
      <Text>
        {context === "course" ? (
          <FormattedMessage
            id="components.Course.franchise"
            values={{ franchise: franchise.name }}
          />
        ) : (
          <FormattedMessage id="pages.Provider.franchise" values={{ franchise: franchise.name }} />
        )}
        <br />
        <small>
          <FormattedMessage id="moreAboutSpecific" values={{ name: franchise.name }} />
        </small>
      </Text>
    </Wrapper>
  );
};

Franchise.propTypes = {
  franchise: PropTypes.object.isRequired,
  context: PropTypes.string,
};

Franchise.defaultProps = {
  context: "course",
};

export default Franchise;
