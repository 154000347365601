import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Provider from "./Provider";
import { getCurrentProvider } from "../../store/selectors/provider";
import { getCourses } from "../../store/selectors/courses";
import { loadCourses } from "../../store/actions/courses";
import { loadRecommendations } from "../../store/actions/provider";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
  courses: getCourses(state),
});

const mapDispatchToProps = {
  loadCourses,
  loadRecommendations,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Provider));
