import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Room from "./Room";
import { getCurrentProvider } from "../../store/selectors/provider";
import { getToken, getUserData } from "../../store/selectors/user";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
  token: getToken(state),
  userData: getUserData(state),
});

export default withRouter(connect(mapStateToProps)(Room));
