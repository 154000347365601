import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Icon from "./Icon";
import { Label } from "./";

const Wrapper = styled.ul`
  margin: 0 0 0.25rem 0;
  color: ${props => props.theme.gray500};
  padding: 0;
  border-radius: ${props => props.theme.borderRadius};

  @media (${props => props.theme.tabletScreen}) {
    margin: 0 0 1.25rem 0;
    padding: 0;
  }
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0.15rem;
  border-bottom: 1px solid ${props => props.theme.gray300};

  &:last-child {
    border-bottom: 0;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding: 1.2rem 0.15rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  color: ${props => props.theme.black};
  margin-left: auto;
`;

const Value = styled.span`
  color: ${props => props.theme.black};
  font-family: ${props => props.theme.fontFamilyMonospace};
`;

const InfoList = ({ items }) => (
  <Wrapper>
    {items.map(({ icon, label, value }, i) => (
      <Item key={`${icon}-${i}`}>
        {icon && <Icon name={icon} />}
        <Content>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </Content>
      </Item>
    ))}
  </Wrapper>
);

InfoList.propTypes = {
  items: PropTypes.array,
};

export default InfoList;
