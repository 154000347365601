import React, { useState } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import styled from "styled-components/macro";

import LoginForm from "../../components/LoginForm";
import SignupForm from "../../components/SignupForm";
import { Link, Button, Heading2 } from "../../components/";

const Wrapper = styled.article`
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;

  @media (${props => props.theme.tabletScreen}) {
    max-width: 360px;
  }
`;

const CTA = styled(Link)`
  display: block;
  margin-top: 1rem;
  cursor: pointer;
`;

const SignupCTA = styled(Button)`
  display: block;
  margin: 1.5rem auto;
  cursor: pointer;
  border-color: transparent;
`;

const FormWrapper = styled.section`
  margin: 20% 0;
`;

const SignupHint = styled.p`
  margin-top: 2rem;
  text-align: center;
`;

const LoginHeading = styled(Heading2)`
  display: block;

  @media (${props => props.theme.tabletScreen}) {
    display: none;
  }
`;

const ProvicerIncentive = styled.div`
  background-color: ${props => props.theme.info};
  color: ${props => props.theme.white};
  padding: 1rem;
  border-radius: ${props => props.theme.borderRadius};
  text-align: center;
  font-weight: bold;
`;

const Authentication = () => {
  const [flow, setFlow] = useState("login");

  const nextFlow = flow === "login" ? "signup" : "login";

  return (
    <Wrapper>
      {flow === "signup" && (
        <ProvicerIncentive>
          <FormattedHTMLMessage id="pages.Profile.provider?" />
        </ProvicerIncentive>
      )}
      <LoginHeading>
        <FormattedMessage id={`pages.Profile.new${flow}`} />
      </LoginHeading>
      <FormWrapper>{flow === "login" ? <LoginForm /> : <SignupForm />}</FormWrapper>
      <SignupHint>
        <small>
          <FormattedMessage id={`pages.Profile.${nextFlow}?`} />
        </small>
        {flow === "login" ? (
          <SignupCTA glow color="warning" onClick={() => setFlow(nextFlow)}>
            <FormattedMessage id={"pages.Profile.becomeMember"} />
          </SignupCTA>
        ) : (
          <CTA onClick={() => setFlow(nextFlow)}>
            <FormattedMessage id={`actions.${nextFlow}`} />
          </CTA>
        )}
      </SignupHint>
    </Wrapper>
  );
};

export default Authentication;
