import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getVisited } from "../../store/selectors/provider";
import {
  getCenter,
  getAddress,
  getResults,
  getMetaData,
  isLoading,
  getTerm,
  getFlexibleLocation,
  getListView,
} from "../../store/selectors/search";
import {
  changeAddress,
  searchProviders,
  clearAddress,
  changeListView,
  clearSearch,
} from "../../store/actions/search";

import SearchMap from "./SearchMap";

const mapStateToProps = state => ({
  visitedProviders: getVisited(state),
  center: getCenter(state),
  address: getAddress(state),
  results: getResults(state),
  metadata: getMetaData(state),
  term: getTerm(state),
  isLoading: isLoading(state),
  flexibleLocation: getFlexibleLocation(state),
  listView: getListView(state),
});

const mapDispatchToProps = {
  clearAddress,
  changeAddress,
  searchProviders,
  changeListView,
  clearSearch,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchMap));
