import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { CardContainer, PageContent } from "../../../components";
import Content from "../Content";
import Recommendations from "../Recommendations";

const ContentContainer = styled.div``;

const Course = ({ provider, course, recommendations }) => {
  return (
    <PageContent transparent>
      <CardContainer seemless={course.images.length > 0}>
        <ContentContainer>
          <Content provider={provider} course={course} />
          <Recommendations recommendations={recommendations} />
        </ContentContainer>
      </CardContainer>
    </PageContent>
  );
};

Course.propTypes = {
  course: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  recommendations: PropTypes.array,
};

Course.defaultProps = {
  recommendations: [],
};

export default Course;
