import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData, getToken, isLoading } from "../../store/selectors/user";
import { login } from "../../store/actions/user";

import LoginForm from "./LoginForm";

const mapStateToProps = state => ({
  userData: getUserData(state),
  token: getToken(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {
  login,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LoginForm),
);
