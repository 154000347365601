import { connect } from "react-redux";
import { getUserData } from "../../store/selectors/user";

import ContactForm from "./ContactForm";

const mapStateToProps = state => ({
  customer: getUserData(state),
});

export default connect(mapStateToProps)(ContactForm);
