import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Heading1, Paragraph, Button, Link } from "../../components";
import wordmark from "../../assets/kikudoo-wordmark.png";

import routes, { routeWithParams } from "../../routes";

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: radial-gradient(
    ellipse at center,
    rgba(255, 254, 234, 0.5) 0%,
    rgba(255, 254, 234, 1) 35%,
    #b7e8eb 100%
  );
`;

const textAnimation = keyframes`
  0% {
    background-position:0% 50%;
  }
  50% {
    background-position:100% 50%;
  }
  100% {
    background-position:0% 50%;
  }
`;

const Title = styled(Heading1)`
  font-weight: bold;
  background: linear-gradient(
    270deg,
    ${props => props.theme.blue},
    ${props => props.theme.yellow},
    ${props => props.theme.pink},
    ${props => props.theme.green},
    ${props => props.theme.red},
    ${props => props.theme.blue},
    ${props => props.theme.yellow}
  );
  background-size: 1400% 1400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${textAnimation} 55s ease infinite;

  @media (${props => props.theme.tabletScreen}) {
    font-size: 5.5em;
  }
`;

const Logo = styled.img`
  width: 120px;
`;

const Slogan = styled.article`
  text-align: center;
  padding: 2rem;
`;

const Providers = styled.ul`
  list-style: none;
  margin-top: 1rem;
`;

const Provider = styled.li``;

const ProviderTeaser = styled(Paragraph)`
  max-width: 500px;
  text-align: center;
  margin-top: 4rem;
  border-top: 1px dashed rgba(0, 0, 0, 0.05);
  padding-top: 1rem;
  font-size: ${props => props.theme.fontSizes.small};
`;

class LandingPage extends React.Component {
  componentDidMount() {}

  render() {
    const { providers, user } = this.props;
    const providerIds = Object.keys(providers);
    return (
      <Wrapper>
        <Link href="https://kikudoo.com/?ref=app">
          <Logo src={wordmark} alt="Logo" />
        </Link>
        <Slogan>
          <Title>kikudoo</Title>
          <Paragraph>Kurse einfach machen</Paragraph>
          <Paragraph>
            <Button as="a" href={routes.search} color="primary" size="large">
              <FormattedMessage id="pages.LandingPage.searchButton" />
            </Button>
          </Paragraph>
          {user && (
            <Paragraph>
              <FormattedMessage
                id="pages.LandingPage.salutation"
                values={{ name: [user.firstName, user.lastName].join(" ") }}
              />
              <br />
              <small>
                <Link as={NavLink} to={routes.profile}>
                  <FormattedMessage id="actions.toProfile" />
                </Link>
              </small>
            </Paragraph>
          )}
        </Slogan>
        {providerIds.length > 0 && (
          <aside>
            <FormattedMessage id="pages.LandingPage.visited" />
            <Providers>
              {providerIds.map(id => (
                <Provider key={id}>
                  <Link
                    as={NavLink}
                    to={routeWithParams(routes.provider, {
                      slug: providers[id].slug,
                    })}
                  >
                    {providers[id].name}
                  </Link>
                </Provider>
              ))}
            </Providers>
          </aside>
        )}
        <ProviderTeaser>
          <FormattedMessage id="pages.LandingPage.provider" />
          <br />
          <Link href="https://kikudoo.com/anbieter?ref=app">https://kikudoo.com/anbieter</Link>
        </ProviderTeaser>
      </Wrapper>
    );
  }
}

LandingPage.propTypes = {
  providers: PropTypes.object,
  user: PropTypes.object,
};

LandingPage.defaultProps = {
  providers: {},
  user: null,
};

export default LandingPage;
