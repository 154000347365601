import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from "react-intl";
import { currencyFormat } from "../../utils/formatters";
import { DEFAULT_TIMEZONE, isToday } from "../../utils/calc";
import { EVENT_TYPES } from "../../utils/courses";

import WaitList from "../../components/WaitList";
import Icon from "../../components/Icon";
import { Button, Mono, PriceOption } from "../../components";

const PRODUCT_MODEL = "Product";
const PRODUCT_TYPE_OF = {
  SUBSCRIPTION: "subscription",
  MEMBERSHIP: "membership",
  PLAN: "plan",
};

const Count = styled.span`
  text-align: center;
  color: ${props => (props.onlyOne ? props.theme.warning : props.theme.success)};
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-left: auto;
  font-size: ${props => props.theme.fontSizes.large};

  flex: 1;
  min-width: 100px;

  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 0.5rem;
  }
`;

const BookedOutWrapper = styled.div`
  text-align: center;
  flex: 1;
`;

const BookedOut = styled.span`
  color: ${props => props.theme.danger};
`;

const Wrapper = styled.section`
  background-color: ${props => props.theme.gray200};
  border-radius: ${props => props.theme.borderRadius};
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 500px;
  margin: 0 auto;

  button:last-child,
  a:last-child {
    flex: 1;
    min-width: 50%;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding: 1rem;
  }
`;

const ProtectedHint = styled.strong`
  color: ${props => props.theme.danger};
  display: block;
  margin: 0 auto 0.4rem;
  text-align: center;
  min-width: 100%;
`;

const PriceOptionName = styled.span`
  text-align: left;
  padding-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PriceOptionPrice = styled(Mono)`
  white-space: nowrap;
  & > span:not(:first-child) {
    margin-left: 0.5em;
  }
`;

const PriceOptionPriceWrap = styled.div`
  display: flex;
  align-items: center;

  ${PriceOptionPrice} {
    margin-left: 0.5rem;
  }
`;

const PriceOptionType = styled.small`
  vertical-align: middle;
  margin-left: 0.25rem;
`;

const Products = styled.section`
  min-width: 100%;
  display: flex;
  flex-direction: column;
`;

const ProductsTitle = styled.strong`
  margin-top: 1rem;
  display: block;
  color: ${props => props.theme.black};
  text-align: right;
  margin-bottom: 0.25rem;
`;

const PriceOptionPeriod = styled.small`
  display: block;
  font-size: ${props => props.theme.fontSizes.tiny};
`;

const PriceOptionNameInfo = styled.small`
  font-size: ${props => props.theme.fontSizes.tiny};
  margin-left: 0.5rem;
`;

const PriceOptionDescription = styled.small`
  font-size: ${props => props.theme.fontSizes.tiny};
  margin-right: 0.25rem;
  display: none;

  & > i {
    vertical-align: middle;
  }

  @media (${props => props.theme.tabletScreen}) {
    display: inline;
  }
`;

const productIntervalInfo = product => {
  if (product.intervalCount < 2 && product.intervalType) {
    return <FormattedMessage id={`per.${product.intervalType}`} />;
  }

  return (
    <FormattedMessage
      id={`every.${product.intervalType}`}
      values={{ count: product.intervalCount }}
    />
  );
};

const PriceOptions = ({ event, course, isBookable, onClick, showPriceOptions }) => {
  const priceOptions = event.priceOptions || [];
  const products = event.products || [];

  const [currentPriceOption, setPriceOption] = useState(priceOptions[0] || products[0] || {});

  const showParticipantInfo = event.typeOf !== EVENT_TYPES.onDemand;

  useEffect(() => {
    const priceOptions = event.priceOptions || [];
    const products = event.products || [];
    setPriceOption(priceOptions[0] || products[0] || {});
  }, [event]);

  return (
    <Wrapper>
      {event.participantsLeft > 0 && event.isProtected && (
        <ProtectedHint>
          <Icon name="lock" color="danger" size="small" />{" "}
          {event.protectedUntil ? (
            <FormattedMessage
              id="components.Course.protectedUntil"
              values={{
                date: isToday(new Date(event.protectedUntil)) ? (
                  <FormattedTime
                    value={Date.parse(event.protectedUntil)}
                    timeZone={DEFAULT_TIMEZONE}
                  />
                ) : (
                  <FormattedDate
                    month="short"
                    day="numeric"
                    value={Date.parse(event.protectedUntil)}
                  />
                ),
              }}
            />
          ) : (
            <FormattedMessage id="components.Course.protected" />
          )}
        </ProtectedHint>
      )}
      {isBookable && event.participantsLeft > 0 ? (
        <>
          {showParticipantInfo &&
            (event.participantsLeft <= 3 ||
              event.participantsLeft / event.participantCount < 0.4) && (
              <Count onlyOne={event.participantsLeft === 1}>
                <FormattedMessage
                  id={`components.Course.${
                    event.participantsLeft > 1 ? "placesLeft" : "onlyOneLeft"
                  }`}
                  values={{ count: event.participantsLeft }}
                />
              </Count>
            )}

          {(showPriceOptions ||
            priceOptions.filter(p => p.name !== "Standard").filter(p => p.typeOf === "standard")
              .length > 0 ||
            course.priceFrom !== course.priceTo ||
            products.length > 0) &&
            priceOptions
              .filter(p => p.typeOf === "standard")
              .map(priceOption => (
                <PriceOption
                  key={priceOption.id}
                  selected={currentPriceOption && priceOption.id === currentPriceOption.id}
                  onClick={() => setPriceOption(priceOption)}
                >
                  <PriceOptionName>
                    {priceOption.name}
                    {(priceOption.participantMultiplier || 1) > 1 && (
                      <PriceOptionNameInfo>
                        <FormattedMessage
                          id="course.attributes.forParticiants"
                          values={{ count: priceOption.participantMultiplier }}
                        />
                      </PriceOptionNameInfo>
                    )}
                  </PriceOptionName>
                  {priceOption.description && (
                    <PriceOptionDescription title={priceOption.description}>
                      <Icon name="info-circle" size="small" />
                    </PriceOptionDescription>
                  )}
                  <PriceOptionPrice>
                    <FormattedNumber
                      value={priceOption.price / 100}
                      {...currencyFormat(priceOption)}
                    />
                  </PriceOptionPrice>
                </PriceOption>
              ))}

          {products.length > 0 && (
            <Products>
              {priceOptions.length > 0 && (
                <ProductsTitle>
                  <FormattedMessage id="components.Course.products" />
                </ProductsTitle>
              )}
              {products.map(product => (
                <PriceOption
                  key={product.id}
                  selected={currentPriceOption && product.id === currentPriceOption.id}
                  onClick={() => setPriceOption(product)}
                >
                  <PriceOptionName>{product.name}</PriceOptionName>
                  {product.typeOf === PRODUCT_TYPE_OF.PLAN ? (
                    <PriceOptionPriceWrap>
                      <PriceOptionPrice>
                        <FormattedNumber
                          value={product.priceTotal / 100}
                          {...currencyFormat(product)}
                        />
                      </PriceOptionPrice>
                      <PriceOptionPrice>
                        <PriceOptionPeriod>
                          <FormattedNumber
                            value={product.price / 100}
                            {...currencyFormat(product)}
                          />
                          <PriceOptionType>{productIntervalInfo(product)}</PriceOptionType>
                        </PriceOptionPeriod>
                        <PriceOptionPeriod>
                          <span>{product.periodCount * product.intervalCount + " "}</span>
                          <FormattedMessage
                            id={`${product.intervalType}.${
                              product.periodCount > 1 ? "many" : "one"
                            }`}
                          />{" "}
                          <FormattedMessage id="contract.attributes.period" />
                        </PriceOptionPeriod>
                      </PriceOptionPrice>
                    </PriceOptionPriceWrap>
                  ) : (
                    <PriceOptionPrice>
                      <FormattedNumber value={product.price / 100} {...currencyFormat(product)} />
                      <PriceOptionType>{productIntervalInfo(product)}</PriceOptionType>
                      {product.periodCount && (
                        <PriceOptionPeriod>
                          <span>{product.periodCount * product.intervalCount + " "}</span>
                          <FormattedMessage
                            id={`${product.intervalType}.${
                              product.periodCount > 1 ? "many" : "one"
                            }`}
                          />{" "}
                          <FormattedMessage id="contract.attributes.period" />
                        </PriceOptionPeriod>
                      )}
                    </PriceOptionPrice>
                  )}
                </PriceOption>
              ))}
            </Products>
          )}
          <Button
            disabled={!isBookable}
            color="primary"
            onClick={() => onClick(currentPriceOption)}
            glow
          >
            <FormattedMessage
              id={`actions.${currentPriceOption.model === PRODUCT_MODEL ? "subscribe" : "book"}`}
            />
          </Button>
        </>
      ) : (
        <BookedOutWrapper>
          {event.waitList ? (
            <WaitList event={event} course={course} />
          ) : (
            <BookedOut>
              <FormattedMessage
                id={`components.Course.${event.participantsLeft > 0 ? "notBookable" : "bookedOut"}`}
              />
            </BookedOut>
          )}
        </BookedOutWrapper>
      )}
    </Wrapper>
  );
};

PriceOptions.propTypes = {
  event: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  isBookable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  showPriceOptions: PropTypes.bool,
};

PriceOptions.defaultProps = {
  showPriceOptions: false,
};

export default PriceOptions;
