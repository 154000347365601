import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import CourseCard from "../../components/CourseCard";
import ProviderCard from "../../components/ProviderCard";
import { Container } from "../../components";

export const Wrapper = styled.ol`
  margin-top: 0.5rem;
  list-style: none;
  display: flex;
  padding: 1rem 0;
  min-width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 1rem;
    justify-content: start;
  }
  @media (${props => props.theme.desktopScreen}) {
    height: auto;
    padding: 1rem;
  }
`;

export const Result = styled.li`
  flex: 0 0;
  min-width: 100%;
  margin: 0 auto 1rem;
  position: relative;

  @media (${props => props.theme.tabletScreen}) {
    min-width: 50%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0;
    margin-left: 0;

    &:last-child {
      min-width: 50%;
      padding-right: 0;
    }
  }

  @media (${props => props.theme.desktopScreen}) {
    min-width: 33.3%;
    margin-bottom: 2rem;
    &:last-child {
      min-width: 33.3%;
      max-width: 33.3%;
    }
  }

  @media (${props => props.theme.hugeScreen}) {
    min-width: 25%;
    &:last-child {
      min-width: 25%;
      max-width: 25%;
    }
  }
`;

const MoreResults = styled.p`
  padding: 0.5rem;
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowLifted};
  border-radius: ${props => props.theme.borderRadius};
  max-width: 640px;
  text-align: center;
  margin: 0 auto 3rem;
  width: 90%;
  color: ${props => props.theme.info};
`;

const ResultsList = ({ results, onCategoryClick, hasMore }) => {
  if (results.length === 0) {
    return "";
  }

  return (
    <Container>
      <Wrapper>
        {results.map(result => (
          <Result key={`${result.modelName}-${result.id}`}>
            {result.modelName === "Provider" ? (
              <ProviderCard provider={result} listView={false} onCategoryClick={onCategoryClick} />
            ) : (
              <CourseCard
                course={result}
                listView={false}
                onCategoryClick={onCategoryClick}
                provider={result.provider}
              />
            )}
          </Result>
        ))}
      </Wrapper>
      {hasMore && (
        <MoreResults>
          <FormattedMessage id="pages.SearchmoreResults" />
        </MoreResults>
      )}
    </Container>
  );
};

ResultsList.propTypes = {
  results: PropTypes.array.isRequired,
  onCategoryClick: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
};

ResultsList.defaultProps = {
  hasMore: false,
};

export default ResultsList;
