import { connect } from "react-redux";
import { changeListView } from "../../store/actions/ui";
import { getListView } from "../../store/selectors/ui";

import CourseList from "./CourseList";

const mapStateToProps = state => ({
  listView: getListView(state),
});

const dispatchToProps = {
  changeListView,
};

export default connect(mapStateToProps, dispatchToProps)(CourseList);
