import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const SIZES = {
  tiny: 0.5625,
  small: 0.875,
  default: 1.375,
  large: 1.75,
  huge: 3,
};

const DIRECTIONS = {
  up: 0,
  right: 90,
  down: 180,
  left: 270,
};

const SPACING = "0.5em";

const directions = direction => {
  if (isNaN(parseFloat(direction))) {
    return DIRECTIONS[direction];
  }
  return parseFloat(direction);
};

const margins = spaced => {
  if (!spaced) {
    return;
  }

  switch (spaced) {
    case "left":
      return { marginLeft: SPACING };
    case "right":
      return { marginRight: SPACING };
    case "top":
      return { marginTop: SPACING };
    case "bottom":
      return { marginBottom: SPACING };
    case "x":
      return { marginLeft: SPACING, marginRight: SPACING };
    case "y":
      return { marginTop: SPACING, marginBottom: SPACING };
    default:
      return { margin: SPACING };
  }
};

const Wrapper = styled.i`
  color: ${props => props.theme[props.color] || props.color || "inherit"};
  transform: rotate(${props => directions(props.direction)}deg);
  transition: color 0.25s, transform 0.25s;
  font-size: ${props => SIZES[props.size] || SIZES.default}rem;
`;

const Icon = ({ name, color, size, direction, className, spaced }) => {
  return (
    <Wrapper
      color={color}
      direction={direction}
      size={size}
      style={margins(spaced)}
      className={`fal fa-${(name || "").toLowerCase()} ${className}`}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  spaced: PropTypes.string,
};

Icon.defaultProps = {
  size: "default",
  direction: 0,
  className: "",
  spaced: null,
};

export default Icon;
