export const ActionTypes = {
  LOCALE_CHANGE: "ui/language/change",
  LIST_VIEW_CHANGE: "ui/list_view/change",
};

export const changeLocale = locale => ({
  type: ActionTypes.LOCALE_CHANGE,
  payload: locale,
});

export const changeListView = listView => ({
  type: ActionTypes.LIST_VIEW_CHANGE,
  payload: listView,
});
