import { getApiClient } from "../../utils/Api";
import { getCenter } from "../selectors/search";
import { omitBy, isNil } from "lodash";

export const ActionTypes = {
  SEARCH_SUCCESS: "kikudoo/search/success",
  SEARCH_REQUEST: "kikudoo/search/request",
  SEARCH_FAILED: "kikudoo/search/failed",
  SEARCH_CLEARED: "kikudoo/search/cleared",
  ADDRESS_CHANGED: "kikudoo/search/change_address",
  ADDRESS_CLEARED: "kikudoo/search/clear_address",
  LIST_VIEW_CHANGED: "kikudoo/list_view/changed",
  COURSE_SEARCH_SUCCESS: "kikudoo/course_search/success",
  SET_FILTERS: "kikudoo/filters/set",
  SET_FILTER: "kikudoo/filter/set",
};

export const changeAddress = (address, lat, lng) => ({
  type: ActionTypes.ADDRESS_CHANGED,
  payload: {
    address,
    lat,
    lng,
  },
});

export const changeListView = state => ({
  type: ActionTypes.LIST_VIEW_CHANGED,
  payload: state,
});

export const clearAddress = (address, lat, lng) => ({
  type: ActionTypes.ADDRESS_CLEARED,
});

const searchRequest = locations => ({
  type: ActionTypes.SEARCH_REQUEST,
  payload: locations,
});

const searchFailed = () => ({
  type: ActionTypes.SEARCH_FAILED,
});

const searchSuccess = result => ({
  type: ActionTypes.SEARCH_SUCCESS,
  payload: result,
});

const courseSearchSuccess = result => ({
  type: ActionTypes.COURSE_SEARCH_SUCCESS,
  payload: result,
});

export const clearSearch = () => ({
  type: ActionTypes.SEARCH_CLEARED,
});

export const searchProviders = (term, radius = 5, searchCenter = null, flexible = true) => async (
  dispatch,
  getState,
) => {
  if (flexible) {
    return searchCourses(term);
  }

  dispatch(searchRequest(true));

  const center = searchCenter || getCenter(getState());
  if (!center) {
    return dispatch(searchFailed());
  }

  return getApiClient()
    .searchLocations(center, radius, term)
    .then(result => {
      // If result is undefined, the request got canceled
      if (!result) {
        return;
      }

      dispatch(searchSuccess(result));
    })
    .catch(e => {
      dispatch(searchFailed());
      throw e;
    });
};

export const searchCourses = (
  term = "",
  page = 1,
  searchCenter = null,
  filters = { flexibleLocation: false },
) => async (dispatch, getState) => {
  dispatch(searchRequest(false));

  return getApiClient()
    .search(term, page, {
      ...(searchCenter || {}),
      ...omitBy(filters, isNil),
    })
    .then(result => {
      // If result is undefined, the request got canceled
      if (!result) {
        return;
      }
      dispatch(courseSearchSuccess(result));
    })
    .catch(e => {
      dispatch(searchFailed());
      throw e;
    });
};

export const setFilters = filters => ({
  type: ActionTypes.SET_FILTERS,
  payload: filters,
});

export const setFilter = filter => ({
  type: ActionTypes.SET_FILTER,
  payload: filter,
});
