import styled from "styled-components/macro";
import { darken, lighten } from "polished";
import { COLOR_VARIATION } from "../theme";

export { default as Address } from "./Address";
export { default as Button } from "./Button";
export { default as Contact } from "./Contact";
export { default as Category } from "./Category";
export { default as RichText } from "./RichText";
export { default as SimpleFormat } from "./SimpleFormat";
export { default as InfoList } from "./InfoList";
export { default as Overlay } from "./Overlay";
export { default as FlipableContent } from "./FlipableContent";
export { default as Icon } from "./Icon";
export { default as Card } from "./Card";
export { default as Loading } from "./Loading";
export { default as Heading } from "./Heading";
export { default as PageTitle } from "./PageTitle";
export { default as Toggle } from "./Toggle";
export { default as FloatingContainer } from "./FloatingContainer";

export const ContactAvatar = styled.div`
  min-width: 50px;
  min-height: 50px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-color: ${props => props.theme.gray200};
  background-image: url('${props => props.avatar}');
`;

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  @media (${props => props.theme.tabletScreen}) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (${props => props.theme.desktopScreen}) {
    max-width: 1140px;
  }

  @media (${props => props.theme.hugeScreen}) {
    max-width: 1400px;
  }
`;

export const CardContainer = styled(Container)`
  position: relative;
  border-radius: ${props => props.theme.borderRadiusLarge} ${props => props.theme.borderRadiusLarge}
    0 0;
  background-color: ${props => props.theme.white};
  box-shadow: 0 2.4rem 8rem -2rem rgba(59, 106, 140, 0.22);
  padding: ${props => (props.seemless ? "0" : "1rem")} 1rem 1rem;
  overflow: hidden;
  flex: 1;

  &:after {
    content: "";
    padding-top: 33%;
    float: left;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding: 1.25rem;
  }

  @media (${props => props.theme.desktopScreen}) {
    padding: 2rem;
  }
`;

export const Link = styled.a`
  color: ${props => props.theme[props.muted ? "dark" : "primary"]};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Heading1 = styled.h1`
  font-family: "Quicksand";
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: ${props => props.theme.gray900};
  font-size: 2rem;
  word-break: break-word;
  hyphens: auto;

  @media (${props => props.theme.tabletScreen}) {
    font-size: 3rem;
  }
`;

export const Heading2 = styled.h2`
  font-family: "Quicksand";
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: ${props => props.theme.gray900};
  font-size: 1.5rem;
  word-break: break-word;
  hyphens: auto;
  @media (${props => props.theme.tabletScreen}) {
    font-size: 2rem;
  }
`;

export const Heading3 = styled.h3`
  font-family: "Quicksand";
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: ${props => props.theme.gray900};
  font-size: 1.75rem;
  word-break: break-word;
`;

export const Heading4 = styled.h4`
  font-family: "Quicksand";
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: ${props => props.theme.gray900};
  font-size: 1.5rem;
  word-break: break-word;
`;

export const Heading5 = styled.h5`
  font-family: "Quicksand";
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: 0.3rem;
  line-height: 1.4;
  color: ${props => props.theme.gray900};
  font-size: 1.1rem;
  word-break: break-word;
`;

export const Paragraph = styled.p`
  margin-bottom: 1rem;
  word-break: break-word;
  color: ${props => (props.dark ? props.theme.black : "inherit")};
  text-align: ${props => props.align || "inherit"};
  font-family: ${props => (props.mono ? props.theme.fontFamilyMonospace : "inherit")};

  @media (${props => props.theme.tabletScreen}) {
    max-width: ${props => (props.spaced ? "75%" : "initial")};
  }

  strong {
    color: ${props => props.theme.black};
  }
`;

export const Small = styled.small`
  font-size: 80%;
  strong {
    color: ${props => props.theme.black};
  }
`;

export const Mono = styled.span`
  font-family: ${props => props.theme.fontFamilyMonospace};
`;

export const Label = styled.label`
  color: ${props => props.theme.gray600};
  letter-spacing: 0.05em;
  margin-bottom: 0.4rem;
  display: inline-block;
`;

export const Badge = styled.span`
  padding: 0.275rem 0.4rem;
  color: ${props => props.theme.white};
  letter-spacing: 0.05em;
  font-size: ${props => props.theme.fontSizes.tiny};
  text-transform: uppercase;
  border-radius: ${props => props.theme.borderRadiusSmall};
  vertical-align: baseline;
  background-color: ${props => props.theme[props.color]};
  margin: 0 0.5rem;
  white-space: nowrap;
`;

export const Alert = styled.span`
  display: inline-block;
  padding: 0.5rem 0.75rem;
  margin: 0.75rem auto;
  background-color: ${props => lighten(0.15, props.theme[props.type || "warning"])};
  color: ${props => props.theme.black};
  border-radius: ${props => props.theme.borderRadiusSmall};
  box-shadow: ${props => props.theme.boxShadowSmall} ${props => props.theme.danger};
  font-size: ${props => props.theme.fontSizes.small};
`;

export const ColorBreak = styled.hr`
  display: block;
  border: 0;
  margin: 0;
  background: linear-gradient(
    to right,
    #17a2b8 0%,
    #74cee4 15%,
    #20c997 30%,
    #6fc191 40%,
    #f5be1b 55%,
    #fb6f3b 70%,
    #e16c6c 87%,
    #c389ce 100%
  );
  height: 3px;
  min-width: 100%;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.theme.black};
  background-color: ${props => props.theme.light};
  background-clip: padding-box;
  border: 1px solid ${props => props.theme.gray100};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 1rem;
  font-size: 100%;
  box-shadow: ${props => props.theme.boxShadowInset}

  &:focus {
    font-size: 100%;
    outline: none;
    border-color: ${props => props.theme.primary};
  }
  
  ${({ hasError, theme }) =>
    hasError &&
    `
    border: 1px solid ${theme.danger};
    box-shadow: 0 0 0 30px ${lighten(0.33, theme.danger)} inset !important;
  `}
`;

export const BigInput = styled(Input)`
  font-size: ${props => props.theme.fontSizes.large};
  color: ${props => props.theme.black};
  padding: 0.75rem 1rem;
  outline: 0

  &:active,
  &:focus {
    outline: 0
    border-color: ${props => props.theme.cyan};
    font-size: ${props => props.theme.fontSizes.large};
  }
`;

export const ActionInput = styled.div`
  display: flex;
  align-items: center;
  ${Input} {
    flex: 4;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  @media (${props => props.theme.tabletScreen}) {
    button {
      flex: 1;
    }
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
  position: relative;

  ${Input} {
    margin-bottom: 0;
  }
`;

export const InputError = styled.span`
  color: ${props => props.theme.danger};
  font-size: ${props => props.theme.fontSizes.tiny};
  line-height: 100%;
  position: absolute;
  bottom: 0.25rem;
  right: 0.5rem;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: ${props => props.theme.fontSizes.default};
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.theme.dark};
  background-color: ${props => props.theme.light};
  background-clip: padding-box;
  border: 1px solid ${props => props.theme.light};
  border-radius: ${props => props.theme.borderRadius};
  height: auto;
  overflow: auto;
  resize: vertical;
  display: block;
  margin-bottom: 1rem;
  &:focus {
    font-size: 100%;
    border-color: ${props => props.theme.primary};
  }
  &:active {
    border-color: ${props => props.theme.primary};
  }
`;

export const Select = styled.select`
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.theme.black};
  background-color: ${props => props.theme.light};
  background-clip: padding-box;
  border: 1px solid ${props => (props.hasError ? props.theme.red : props.theme.gray100)};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 1rem;
  font-size: 100%;
  box-shadow: ${props => props.theme.boxShadowInset}
  /* Safari/Webkit fixes */
  text-indent: 0.5rem;
  height: 42px;


  &:focus {
    font-size: 100%;
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

export const PageLayout = styled.div`
  min-height: calc(100vh - 4rem);
  word-break: break-word;
  overflow: hidden;

  @media (${props => props.theme.tabletScreen}) {
    min-height: calc(100vh - 6.5rem);
    display: flex;
    flex-direction: column;
  }
`;

export const PageContent = styled.div`
  background-color: ${props => props.theme.white};
  border-radius: ${props => props.theme.borderRadiusHuge} ${props => props.theme.borderRadiusHuge} 0
    0;
  flex: 1;

  @media (${props => props.theme.tabletScreen}) {
    background-color: ${props => (props.transparent ? "transparent" : props.theme.white)};
    border-radius: ${props => props.theme.borderRadiusSmall}
      ${props => props.theme.borderRadiusSmall} 0 0;
  }
`;

export const PageContentStandard = styled(PageContent)`
  position: relative;
  border-radius: 0;
  padding-top: ${props => (props.seemless ? 0 : "1rem")};

  @media (${props => props.theme.tabletScreen}) {
    padding-top: 2rem;
    border-radius: 0;
    &:before {
      position: absolute;
      top: -1vw;
      right: 0;
      z-index: 1;
      border-color: transparent transparent ${props => props.theme.white} transparent;
      border-style: solid;
      border-width: 0 0 1vw 100vw;
      content: "";
      transform: translateY(1px);
    }
  }
`;

export const Avatar = styled.div`
  background-image: url('${props => props.src}');
  background-color: ${props => props.theme.white};
  background-position: center center;
  background-size: cover;
  width: ${props => (props.small ? "60px" : "120px")};
  height: ${props => (props.small ? "60px" : "120px")};
  border-radius: ${props => props.theme.borderRadius};
  flex-shrink: 0;
`;

export const ProviderAvatar = styled.div`
  flex: 1;
  min-width: 68px;
  max-width: 68px;
  min-height: 68px;
  margin-right: 1rem;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
`;

export const Separator = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${props => props.theme.gray300};
  width: 25%;
  margin: 1rem 0 1.5rem;
`;

export const Hidden = styled.div`
  display: ${props => (props.size.includes("sm") ? "none" : props.display || "block")};
  @media (${props => props.theme.tabletScreen}) {
    display: ${props => (props.size.includes("md") ? "none" : props.display || "block")};
  }
  @media (${props => props.theme.desktopScreen}) {
    display: ${props => (props.size.includes("lg") ? "none" : props.display || "block")};
  }
`;

const sizes = {
  sm: 576,
  md: 768,
  lg: 992,
};

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.4rem;
  margin-left: -0.4rem;

  @media (${props => props.theme.tabletScreen}) {
    margin-right: -1rem;
    margin-left: -1rem;
  }
`;

export const Col = styled.div`
  flex-basis: 100%;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;

  @media (min-width: ${props => sizes[props.size || "md"]}px) {
    max-width: ${props => (props.count ? (props.count / 12) * 100 : 100)}%;
    flex: 0 0 ${props => (props.count ? (props.count / 12) * 100 : 100)}%;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

export const PriceOption = styled.button`
  padding: 0.25rem 0.875rem 0.25rem 2.5rem;
  margin-bottom: 0.5rem;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  font-size: ${props => props.theme.fontSizes.small};
  cursor: pointer;
  position: relative;
  border: none;
  outline: none;

  background-color: ${props => (props.selected ? props.theme.info : "transparent")};
  border-radius: ${props => props.theme.borderRadiusLarge};
  box-shadow: ${props => (props.selected ? "0 0.4rem 1.05rem -0.3rem rgba(0,0,0,0.4)" : "none")};
  color: ${props => (props.selected ? props.theme.white : props.theme.dark)};

  transition: color 0.3s, background-color 0.3s;
  &:hover {
    background-color: ${props => darken(COLOR_VARIATION, props.theme.info)};
    color: ${props => props.theme.white};
    &:before {
      border-color: ${props => props.theme.white};
      border-width: 4px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0.875rem;
    margin-right: 0.5rem;
    width: 0.825rem;
    height: 0.825rem;
    border-radius: 50%;
    transition: border-color 0.3s, border-width 0.5s;
    border: ${props => (props.selected ? "4px" : "1px")} solid
      ${props => (props.selected ? props.theme.white : props.theme.dark)};
  }
`;

export const ListItems = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    &::hover {
      text-decoration: none;
    }
  }

  ${({ card, theme }) =>
    card &&
    ` 
      background-color: ${theme.light};
      border-radius: ${theme.borderRadius};
      padding: 0.5rem;
  `}
`;

export const ListItemName = styled.p`
  margin-left: 1rem;
  font-size: ${props => props.theme.fontSizes.large};
  color: ${props => props.theme.dark};

  a {
    color: ${props => props.theme.dark};
    text-decoration: none;
    &::hover {
      text-decoration: none;
    }
  }
`;

export const MiniBrand = styled.div`
  width: 6rem;
  height: 6rem;
  border: 0.5rem solid ${props => props.theme.white};
  opacity: 1;
  transition: opacity 0.5s;
  background-color: ${props => props.theme.white};
  border-radius: ${props => props.theme.borderRadius};
  background-size: contain;
  background-position: center;
  margin-bottom: 1rem;
`;
