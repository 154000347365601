import React from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { Input, Button, Col, Row, Paragraph, Alert } from "..";

const Form = styled.form``;

const ParagraphWithLink = styled(Paragraph)`
  a {
    color: ${props => props.theme.primary};
  }
`;

const Actions = styled.div`
  display: flex;
  margin-top: 1.5rem;
  & > button {
    flex: 1;
  }
`;

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
    };
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ errors: {} });

    const { signup } = this.props;
    const { firstName, lastName, email, password, role, phone } = this.state;
    try {
      await signup({ firstName, lastName, email, password, role, phone });
    } catch (e) {
      this.setState({ errors: e.response.data });
    }
  };

  render() {
    const { intl, isLoading } = this.props;
    const { firstName, lastName, email, phone, password, errors } = this.state;

    return (
      <Form onSubmit={this.onSubmit} autocomplete="on">
        {Object.keys(errors).length > 0 && (
          <p>
            <Alert type="danger">
              <FormattedMessage id="components.SignupForm.failed" />
            </Alert>
            <br />
          </p>
        )}
        <Row>
          <Col count={6}>
            <Input
              name="firstName"
              value={firstName}
              placeholder={intl.formatMessage({ id: "user.firstName" })}
              onChange={this.onChange}
              hasError={errors.firstName}
              required
            />
          </Col>
          <Col count={6}>
            <Input
              name="lastName"
              value={lastName}
              placeholder={intl.formatMessage({ id: "user.lastName" })}
              onChange={this.onChange}
              hasError={errors.lastName}
              required
            />
          </Col>
        </Row>
        <Input
          name="phone"
          type="tel"
          value={phone}
          placeholder={intl.formatMessage({ id: "user.phone" })}
          onChange={this.onChange}
          hasError={errors.phone}
        />
        <Input
          name="email"
          type="email"
          value={email}
          placeholder={intl.formatMessage({ id: "user.email" })}
          onChange={this.onChange}
          hasError={errors.email}
          required
        />
        <Input
          name="password"
          type="password"
          value={password}
          placeholder={intl.formatMessage({ id: "user.password" })}
          onChange={this.onChange}
          hasError={errors.password}
          required
        />
        <ParagraphWithLink>
          <small>
            <FormattedHTMLMessage id="pages.Profile.terms" />
          </small>
        </ParagraphWithLink>
        <Actions>
          <Button color="success" type="submit" busy={isLoading}>
            <FormattedMessage id="actions.signup" />
          </Button>
        </Actions>
      </Form>
    );
  }
}

SignupForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  signup: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SignupForm);
