import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";

const Title = styled.strong`
  display: block;
`;

const Text = styled.span`
  display: none;
  font-size: ${props => props.theme.fontSizes.small};
  line-height: ${props => props.theme.fontSizes.small};
  @media (${props => props.theme.tabletScreen}) {
    display: inline-block;
  }
`;

const Wrapper = styled.article`
  position: fixed;
  top: 3rem;
  left: 0;
  background-color: ${props => props.theme.info};
  padding: 0.15rem 0.3rem;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.boxShadow};
  transform: rotate(2deg);
  max-width: 240px;
  z-index: 11;
  text-align: center;

  & > a {
    text-decoration: none;
    color: ${props => props.theme.white};
  }

  @media (${props => props.theme.tabletScreen}) {
    padding: 0.25rem 0.5rem;
    left: 2%;
    top: 5rem;
  }
`;

export default () => (
  <Wrapper>
    <a href="https://kikudoo.com/anbieter?ref=search">
      <Title>
        <FormattedMessage id="pages.SearchMap.incentive.title" />
      </Title>
      <Text>
        <FormattedMessage id="pages.SearchMap.incentive.text" />
      </Text>
    </a>
  </Wrapper>
);
