import { createSelector } from "reselect";
import { CACHE_TTL } from "../index";

export const getProvider = state => state.provider;
export const getCurrentProvider = state => state.provider.current;
export const getVisited = state => state.provider.visited;

export const isLoading = createSelector(
  getProvider,
  provider => provider.isLoading,
);

export const isExpired = createSelector(
  getProvider,
  provider => {
    const { updatedAt } = provider;

    return !provider.current || !updatedAt || Date.now() - updatedAt > CACHE_TTL;
  },
);
