import React from "react";
import { withRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { createContract, login } from "../../../../store/actions/user";
import { getUserData } from "../../../../store/selectors/user";

import Confirm from "./Confirm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
const mapStateToProps = state => ({
  userData: getUserData(state),
});

const mapDispatchToProps = { createContract, login };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(props => (
    <Elements stripe={stripePromise}>
      <Confirm {...props} />
    </Elements>
  )),
);
