import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedRelative } from "react-intl";

const dateFromDays = days => {
  const d = new Date();
  d.setDate(d.getDate() - days);
  return d;
};

const Wrapper = styled.aside`
  color: ${props => props.theme.black};
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowSmall};
  border-radius: 2rem;
  padding: 4px 10px;
  text-decoration: none;
  margin-left: auto;
  font-size: ${props => props.theme.fontSizes.tiny};
  @media (${props => props.theme.tabletScreen}) {
    font-size: ${props => props.theme.fontSizes.small};
  }
`;

const SinceDays = ({ className, days }) => (
  <Wrapper className={className}>
    <FormattedMessage
      id="components.ProviderInfo.since"
      values={{
        timeAgo:
          days > 30 ? (
            <FormattedRelative value={dateFromDays(days || 1)} />
          ) : (
            <FormattedMessage id="recently" />
          ),
      }}
    />
  </Wrapper>
);

SinceDays.propTypes = {
  days: PropTypes.number,
};

SinceDays.defaultProps = {
  days: 0,
};

export default SinceDays;
