export default {
  profile: "/me",
  confirmSuccess: "/me/confirm/success",
  confirmError: "/me/confirm/error",
  resetPassword: "/me/password-reset",
  editPassword: "/me/password-edit/:resetToken",
  provider: "/:slug",
  courses: "/:slug/courses",
  courseCardPreview: "/:slug/courses/:id/course-card-preview",
  course: "/:slug/courses/:id",
  blog: "/:slug/blog",
  post: "/:slug/blog/:id",
  terms: "/:slug/terms",
  privacyPolicy: "/:slug/privacy_policy",
  room: "/:slug/rooms/:id/:roomToken",
  search: "/search",
  searchMap: "/search-map",
  preview: "/preview/:url",
  franchise: "/loves/:id",
  franchiseMap: "/loves/:id/map",
  franchiseSchedule: "/loves/:id/schedule",
  event: "/:slug/courses/:courseId/:id",
  booking: {
    checkout: "/:slug/checkout/:id",
    checkoutRedirect: "/:slug/checkout-redirect/:id",
  },
  contracts: {
    list: "/me/contracts",
    show: "/me/contracts/:id",
    new: "/me/contracts/:id/confirm",
    success: "/me/contracts/:product_id/:id/success",
    cancel: "/me/contracts/:product_id/:id/cancel",
  },
};

export const routeWithParams = (url, params = {}, urlParams = {}) => {
  const route = Object.entries(params || {}).reduce((acc, [key, value]) => {
    const search = new RegExp(`:${key}`);
    return acc.replace(search, value);
  }, url);

  const urlParamsString = Object.entries(urlParams)
    .map(p => p.join("="))
    .join("&");

  return route + (urlParamsString !== "" ? "?" + urlParamsString : "");
};

export const toUrlParams = params =>
  Object.keys(params)
    .map(function(key) {
      return key + "=" + params[key];
    })
    .join("&");
