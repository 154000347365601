import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { presence } from "../../utils/common";
import routes, { routeWithParams } from "../../routes";
import {} from "../../components";
import lovesIcon from "../../assets/kikudoo-loves.png";

const Wrapper = styled.li`
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.gray100};
  color: ${props => props.theme.white};
  cursor: pointer;
  min-width: 90%;
  max-width: 90%;
  min-height: 100%;
  margin-right: 0.5rem;
  position: relative;
  border: 2px solid ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowSmall};
  transition: transform 1s, box-shadow 1s, height 0.5s;

  &:hover {
    transform: scale(1.02);
    box-shadow: ${props => props.theme.boxShadowLifted};
  }

  @media (${props => props.theme.tabletScreen}) {
    max-width: 18rem;
    min-width: 18rem;
  }

  @media (${props => props.theme.desktopScreen}) {
    min-width: 100%;
    min-height: 10rem;
    margin-top: 1rem;

    &:before {
      position: static;
      flex: 1;
      min-width: 150px;
    }
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
`;

const Logo = styled.div`
  background-position: center;
  background-size: cover;
  background-color: ${props => props.theme.light};
  flex: 0 0 8rem;
  min-height: 100%;
`;

const Text = styled.p`
  padding: 0.5rem 0.5rem 0.1rem;
  flex: 1;
  color: ${props => props.theme.white};
`;

const Badge = styled.img`
  width: 72px;
  height: 72px;
  position: absolute;
  top: -15px;
  left: -15px;
`;

export default ({ franchise }) => (
  <Wrapper style={{ backgroundColor: presence(franchise.primaryColor) || "initial" }}>
    <a href="https://kikudoo.com/pages/partners" target="_blank" rel="noopener noreferrer">
      <Badge src={lovesIcon} />
    </a>
    <Link
      href={`${routeWithParams(routes.franchise, { id: franchise.slug })}?ref=searchIncentive`}
      target="_blank"
    >
      <Logo style={{ backgroundImage: `url('${franchise.logo}')` }} />
      <Text>
        {franchise.name}
        <br />
        <small>
          <FormattedMessage id="pages.SearchMap.franchise" values={{ franchise: franchise.name }} />
        </small>
      </Text>
    </Link>
  </Wrapper>
);
