import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  FormattedMessage,
  FormattedDate,
  injectIntl,
  intlShape,
  FormattedTime,
  FormattedNumber,
} from "react-intl";
import { Paragraph, Heading3, Icon } from "../../components";
import { currencyFormat } from "../../utils/formatters";
import { EVENT_TYPES } from "../../utils/courses";
import { DEFAULT_TIMEZONE } from "../../utils/calc";

const Wrapper = styled.header`
  margin-bottom: 2rem;
  margin-right: 2rem;

  ${Paragraph} {
    display: none;
    @media (${props => props.theme.tabletScreen}) {
      display: block;
    }
  }
`;

const Heading = styled(Heading3)`
  font-size: 1.25rem;
  margin: 0;

  small {
    font-size: ${props => props.theme.fontSizes.small};
  }
  @media (${props => props.theme.tabletScreen}) {
    font-size: 1.75rem;

    small {
      font-size: 80%;
    }
  }
`;

const AuthStatus = styled.span`
  margin-left: 0.5rem;
  vertical-align: top;
`;

const PriceOption = styled.span`
  color: ${props => props.theme.info};
  span {
    margin-left: 0.5rem;
  }
`;

const EventAdditionalInfo = styled.span`
  display: block;
  color: ${props => props.theme.info};
  font-family: ${props => props.theme.fontFamilyBase};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
`;

const HeaderImage = styled.figure`
  display: block;
  margin: 0 0.3rem 0 0;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  background-size: cover;
  background-position: center;
  border-radius: ${props => props.theme.borderRadius};

  @media (${props => props.theme.tabletScreen}) {
    margin: 0 1rem 0 0;
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    min-height: 4rem;
  }
`;

const Title = ({ course, event, intl, unlocked, priceOption, image }) => {
  const getAppointmentsInfo = () => {
    switch (event.typeOf) {
      case EVENT_TYPES.block: {
        return (
          <Paragraph mono>
            <strong>{event.name}</strong>
            {", "}
            <FormattedDate month="long" day="2-digit" value={Date.parse(event.startDate)} />
            {event.appointments && event.appointments.length > 1 ? (
              <span>
                {" - "}
                <FormattedDate month="long" day="2-digit" value={Date.parse(event.endDate)} />
                {` (${event.appointments.length} ${intl.formatMessage({
                  id: "appointments.many",
                })})`}
              </span>
            ) : (
              <span>
                {", "}
                <FormattedTime value={Date.parse(event.startDate)} timeZone={DEFAULT_TIMEZONE} />
                {" - "}
                <FormattedTime value={Date.parse(event.endDate)} timeZone={DEFAULT_TIMEZONE} />
              </span>
            )}
          </Paragraph>
        );
      }
      case EVENT_TYPES.flexible:
        return <Paragraph mono>{event.description}</Paragraph>;
      case EVENT_TYPES.continuous:
        if (event.appointments && event.appointments.length > 0) {
          return (
            <Paragraph mono>
              <strong>{event.name}</strong>
              {", "}
              {event.appointments.map(({ startDate, endDate }, i) => (
                <span key={startDate}>
                  <FormattedDate weekday="short" value={Date.parse(startDate)} />
                  {". "}
                  <FormattedTime value={Date.parse(startDate)} timeZone={DEFAULT_TIMEZONE} />
                  -
                  <FormattedTime value={Date.parse(endDate)} timeZone={DEFAULT_TIMEZONE} />
                  {i < event.appointments.length - 1 && ", "}
                </span>
              ))}
              <EventAdditionalInfo>
                {event.endDate && (
                  <FormattedMessage
                    id="components.Course.period"
                    values={{
                      endDate: (
                        <FormattedDate
                          month="short"
                          day="numeric"
                          value={Date.parse(event.endDate)}
                        />
                      ),
                      startDate: (
                        <FormattedDate
                          month="short"
                          day="numeric"
                          value={Date.parse(event.startDate)}
                        />
                      ),
                    }}
                  />
                )}
              </EventAdditionalInfo>
            </Paragraph>
          );
        }
        return <Paragraph mono>{event.description}</Paragraph>;
      default:
        return "";
    }
  };
  return (
    <Wrapper>
      <Header>
        {image && <HeaderImage style={{ backgroundImage: `url('${image}')` }} />}
        <Heading>
          <FormattedMessage id="components.Booking.title" tagName="strong" />
          <br />
          <small>
            <span>
              {course.name}, {event.name}
            </span>
            {event.isProtected && (
              <AuthStatus>
                <Icon
                  name={unlocked ? "Unlock" : "Lock"}
                  color={unlocked ? "success" : "danger"}
                  title={<formatMessage id="components.Booking.password.title" />}
                />
              </AuthStatus>
            )}
          </small>
          {priceOption && (
            <small>
              <br />
              <PriceOption selected>
                <span>{priceOption.name}</span>
                <span>{"-"}</span>
                <FormattedNumber value={priceOption.price / 100} {...currencyFormat(priceOption)} />
              </PriceOption>
            </small>
          )}
        </Heading>
      </Header>

      {event && getAppointmentsInfo()}
    </Wrapper>
  );
};

Title.propTypes = {
  course: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  priceOption: PropTypes.object,
  unlocked: PropTypes.bool,
  intl: intlShape.isRequired,
  image: PropTypes.string,
};

Title.defaultProps = {
  unlocked: false,
  priceOption: null,
  image: null,
};

export default injectIntl(Title);
