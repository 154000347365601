import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Button, Input, Paragraph, Textarea, Icon } from "../";
import Overlay from "../Overlay";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { ApiClient } from "../../utils/Api";

const Wrapper = styled.div`
  background-color: ${props => props.theme.white};
  border-radius: ${props => props.theme.borderRadius};
  min-height: 100%;
  max-width: 480px;
  min-width: 300px;
  text-align: left;
  position: relative;
  padding: 1rem;
  box-shadow: ${props => props.theme.boxShadowLifted};
  font-family: ${props => props.theme.fontFamilyBase};
  color: ${props => props.theme.dark};
`;

const Fields = styled.fieldset`
  border: 0;
  padding: 0;
  margin-bottom: 0.5rem;
`;

const Error = styled.p`
  color: ${props => props.theme.danger};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Success = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5%;
  text-align: center;
  z-index: 1;

  & > i {
    margin-bottom: 1rem;
  }
`;

const CancelButton = styled.button`
  border: 0;
  padding: 0.4rem 0.5rem;
  background-color: ${props => props.theme.white};
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: ${props => props.theme.gray500};
  cursor: pointer;
  z-index: 2;
  &:hover {
    color: ${props => props.theme.black};
  }
`;

const ExistingCustomer = styled.p`
  font-size: ${props => props.theme.fontSizes.small}
  margin-top: 1rem;
  margin-bottom: 1rem;
  strong {
    color: ${props => props.theme.black};
  }
`;

const FormOverlay = styled(Overlay)`
  top: 1rem;
  position: fixed;

  @media (${props => props.theme.desktopScreen}) {
    top: auto;
    position: absolute;
  }
`;

const ToggleButton = styled(Button)`
  margin-left: auto;
`;

const ContactForm = ({ provider, user, subject, customer, cta, buttonProps }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!customer) {
      setName("");
      setEmail("");
      setPhone("");
      return;
    }
    setName([customer.firstName, customer.lastName].join(" "));
    setEmail(customer.email);
    setPhone(customer.phone);
  }, [customer, setName, setEmail, setPhone]);

  const onSubmit = async e => {
    e.preventDefault();

    setIsLoading(true);
    setError(null);
    const message = {
      subject,
      content,
      from: email,
      to: user ? user.id : null,
      customer: {
        firstName: name.split(" ")[0],
        lastName: name.replace(name.split(" ")[0], "").trim(),
        email,
        phone,
      },
    };

    try {
      await new ApiClient().providerMessage(provider.id, message);
      setSuccess(true);
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  return (
    <>
      <ToggleButton
        onClick={e => {
          e.preventDefault();
          setOpen(true);
        }}
        size="small"
        color="primary"
        {...buttonProps}
      >
        <FormattedMessage id={cta || "components.ConctacFrom.cta"} />
      </ToggleButton>
      {open && (
        <FormOverlay blackout onClose={() => setOpen(false)}>
          <Wrapper>
            <CancelButton onClick={() => setOpen(false)}>
              <Icon name="add" direction={45} />
            </CancelButton>
            <Paragraph style={{ maxWidth: "95%" }}>
              <FormattedMessage
                id="components.ConctacFrom.intro"
                values={{ provider: provider.name }}
              />
            </Paragraph>
            {success && (
              <Success>
                <Icon name="check" size="huge" color="success" />
                <Paragraph>
                  <FormattedMessage id="components.ConctacFrom.success" />
                </Paragraph>
                <Button onClick={() => setOpen(false)} color="primary">
                  <FormattedMessage id="components.ConctacFrom.close" />
                </Button>
              </Success>
            )}
            <form id="contact-form" onSubmit={onSubmit}>
              <Fields>
                {customer ? (
                  <ExistingCustomer>
                    <FormattedHTMLMessage
                      id="components.ConctacFrom.existingUser"
                      values={{ username: [customer.firstName, customer.lastName].join(" ") }}
                    />
                  </ExistingCustomer>
                ) : (
                  <>
                    <FormattedMessage id="applicant.attributes.name">
                      {placeholder => (
                        <Input
                          name="name"
                          value={name}
                          onChange={({ target: { value } }) => setName(value)}
                          maxLength={180}
                          placeholder={placeholder}
                          required
                        />
                      )}
                    </FormattedMessage>
                    <FormattedMessage id="applicant.attributes.email">
                      {placeholder => (
                        <Input
                          name="email"
                          value={email}
                          onChange={({ target: { value } }) => setEmail(value)}
                          maxLength={180}
                          placeholder={placeholder}
                          type="email"
                          required
                        />
                      )}
                    </FormattedMessage>
                  </>
                )}
                <FormattedMessage id="applicant.attributes.phone">
                  {placeholder => (
                    <Input
                      name="phone"
                      value={phone}
                      onChange={({ target: { value } }) => setPhone(value)}
                      maxLength={180}
                      placeholder={placeholder}
                      type="tel"
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="components.ConctacFrom.message">
                  {placeholder => (
                    <Textarea
                      name="content"
                      value={content}
                      onChange={({ target: { value } }) => setContent(value)}
                      maxLength={500}
                      placeholder={placeholder}
                      rows={4}
                      required
                    />
                  )}
                </FormattedMessage>
              </Fields>
              {error && (
                <Error>
                  <FormattedMessage id="components.ConctacFrom.error" />
                </Error>
              )}
              <Button type="submit" color="info" disabled={isLoading} busy={isLoading} glow>
                <FormattedMessage id="components.ConctacFrom.submit" />
              </Button>
            </form>
          </Wrapper>
        </FormOverlay>
      )}
    </>
  );
};

ContactForm.propTypes = {
  provider: PropTypes.object.isRequired,
  user: PropTypes.object,
  subject: PropTypes.string,
  customer: PropTypes.object,
  cta: PropTypes.string,
  buttonProps: PropTypes.object,
};

ContactForm.defaultProps = {
  cta: null,
  user: null,
  subject: null,
  buttonProps: {},
};

export default ContactForm;
