import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedDate } from "react-intl";
import { Icon, Container, Link } from ".";
import { sortBy } from "lodash";
import { truncate } from "../utils/formatters";

const HasMore = styled.aside`
  margin-bottom: 2.5rem;
  text-align: center;
  & > a {
    color: ${props => props.theme.black};
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 1rem;
  }
`;

const RatingsContainer = styled(Container)`
  position: relative;
  max-height: ${props => (props.open ? "400px" : 0)};
  transition: max-height 0.25s ease-in-out;

  display: flex;
  flex-direction: column;
  margin-bottom: ${props => (props.open ? "1rem" : "-1rem")};
  margin-top: 1rem;

  @media (${props => props.theme.desktopScreen}) {
    max-height: ${props => (props.open ? "800px" : 0)};
  }

  ${({ clean, theme }) =>
    clean &&
    `
        border-radius: ${theme.borderRadius};
        background-color: ${theme.light};
        
    `}
`;

const Badge = styled.div`
  display: block;
  padding: 0.25rem 0.5rem;
  border-radius: ${props => props.theme.borderRadius};
  font-size: ${props => props.theme.fontSizes.small};
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.black};
  box-shadow: ${props => props.theme.boxShadowSmall};
  border: 0;
  position: relative;
`;

const CountBadge = styled(Badge)`
  border-radius: 2rem;
  position: absolute;
  bottom: 0.25rem;
  left: 1rem;
  z-index: 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;

const RatingsList = styled.ol`
  list-style: none;
  margin: 0;
  margin-bottom: 2rem;
  overflow: auto;
  column-count: 1;
  column-gap: 1rem;
  border-radius: ${props => props.theme.borderRadius};

  @media (${props => props.theme.tabletScreen}) {
    column-count: 3;
    padding: 1rem;
  }

  @media (${props => props.theme.desktopScreen}) {
    column-count: 3;
    padding: 1rem;
  }
`;

const RatingsItem = styled.li`
  display: flex;
  margin-bottom: 1.5rem;
  flex: 1;
  break-inside: avoid-column;
`;

const RatingBadge = styled(Badge)`
  margin: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
`;

const BadgeText = styled.span`
  margin: 0 0.5rem;
`;

const BadgeDate = styled.span`
  position: absolute;
  right: -0.5rem;
  bottom: -0.5rem;
  background-color: ${props => props.theme.info};
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.fontSizes.tiny};
  border-radius: 2rem;
  padding: 3px 6px;
  white-space: nowrap;
  opacity: 0.8;

  max-width: 120%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RatingText = styled.p`
  margin: 4px 1rem 0 0.5rem;
  min-width: 65px;
`;

const CourseName = styled.small`
  color: ${props => props.theme.dark};
  font-style: italic;
  display: block;
  opacity: 0.75;
`;

const Ratings = ({ entity, provider }) => {
  const [open, setOpen] = useState(false);
  const [hasRatings, setHasRatings] = useState((entity.ratings || []).length > 0);

  useEffect(() => {
    setHasRatings((entity.ratings || []).length > 0);
  }, [entity, setHasRatings]);

  if (entity.heartsCount < 1) {
    return "";
  }

  const hasMore = entity.publicRatingsCount > (entity.ratings || []).length;
  const publicUrl = `/public/de/providers/${provider.id}/ratings${
    entity === provider ? "" : `?course_id=${entity.id}`
  }`;

  return (
    <RatingsContainer open={open} clean={provider.template === "clean"}>
      <CountBadge onClick={() => hasRatings && setOpen(!open)}>
        <Icon name="heart" color="danger" size="small" />
        <BadgeText>
          <FormattedMessage id="pages.Course.ratings" values={{ count: entity.heartsCount }} />
        </BadgeText>
        {hasRatings && (
          <Icon name="chevron-up" color="black" size="small" direction={open ? "up" : "down"} />
        )}
      </CountBadge>

      <RatingsList>
        {sortBy(entity.ratings || [], ({ createdAt }) => createdAt)
          .reverse()
          .map(({ text, createdAt, name, course }, index) => (
            <RatingsItem key={createdAt + index}>
              <RatingBadge>
                {text && text !== "" ? (
                  <>
                    <Icon name="quote-right" color="black" size="small" />
                    <RatingText>
                      {text}
                      {course && <CourseName>{course.name}</CourseName>}
                    </RatingText>
                  </>
                ) : (
                  <RatingText>
                    <Icon name="heart" color="danger" size="small" />
                    {course && <CourseName>{course.name}</CourseName>}
                  </RatingText>
                )}

                <BadgeDate>
                  {name && <strong>{`${truncate(name, 12)}, `}</strong>}
                  {<FormattedDate month="short" day="2-digit" value={Date.parse(createdAt)} />}
                </BadgeDate>
              </RatingBadge>
            </RatingsItem>
          ))}
      </RatingsList>
      {hasMore && (
        <HasMore>
          <small>
            <FormattedMessage
              id="pages.Course.ratingsMore"
              values={{ count: entity.publicRatingsCount - (entity.ratings || []).length }}
            />
          </small>
          <br />
          <Link href={publicUrl} target="_blank">
            <FormattedMessage id="pages.Course.allRatings" />
          </Link>
        </HasMore>
      )}
    </RatingsContainer>
  );
};

Ratings.propTypes = {
  entity: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
};

export default Ratings;
