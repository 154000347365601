import React from "react";
import PropTypes from "prop-types";

import { Heading } from "../../components";
import CourseList from "../../components/CourseList";

import { FormattedMessage } from "react-intl";

const Recommendations = ({ recommendations }) => {
  if ((recommendations || []).length <= 0) {
    return "";
  }

  return (
    <section>
      <Heading level={3}>
        <FormattedMessage id="pages.Course.recommendations" />
      </Heading>
      <CourseList courses={recommendations} />
    </section>
  );
};

Recommendations.propTypes = {
  recommendations: PropTypes.array,
};

Recommendations.defaultProps = {
  recommendations: [],
};

export default Recommendations;
