import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  border-radius: 2rem;
  padding: 0.15rem;
  max-width: 100px;
  width: 100%;
  transition: max-width 0.5s;

  ${({ expanded }) =>
    expanded &&
    `
        max-width: 300px;
    `}

  @media (${props => props.theme.tabletScreen}) {
    max-width: 400px;
    ${({ expanded }) =>
      expanded &&
      `
        max-width: 600px;
    `}
  }

  input {
    margin-bottom: 0;
    border-radius: 2rem 0 0 2rem;
    transition: background-color 0.5s;

    ${({ expanded }) =>
      !expanded &&
      `
        background-color: transparent;
    `}
  }

  button {
    border-radius: 0 2rem 2rem 0;
    transition: padding 0.5s, border-color: 0.5s;

    ${({ expanded }) =>
      !expanded &&
      `
        border-color: transparent;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    `}
  }

  .hide-collapsed {
    display: ${props => (props.expanded ? "initial" : "none")};
  }
`;

const FloatingContainer = ({ className, children, defaultExpanded }) => {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded, setExpanded]);

  return (
    <Wrapper className={className} onClick={() => setExpanded(true)} expanded={expanded}>
      {children}
    </Wrapper>
  );
};

FloatingContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
};

FloatingContainer.defaultProps = {
  className: "",
  defaultExpanded: false,
};

export default FloatingContainer;
