import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getLocale } from "../../store/selectors/ui";
import { changeLocale } from "../../store/actions/ui";
import { getUserData } from "../../store/selectors/user";
import { getCurrentBooking } from "../../store/selectors/bookings";
import { getCurrentProvider } from "../../store/selectors/provider";

import UserNav from "./UserNav";

const mapStateToProps = state => ({
  userData: getUserData(state),
  booking: getCurrentBooking(state),
  provider: getCurrentProvider(state),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  changeLocale,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNav));
