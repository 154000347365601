import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate } from "react-intl";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

import routes, { routeWithParams } from "../../routes";
import { Card, Button, Badge } from "../../components/";

const Label = styled.span`
  margin-right: 1rem;
  &:after {
    content: ":";
  }
`;

const BookingCard = styled(Card)`
  margin-bottom: 1rem;
`;

const Value = styled.span`
  color: ${props => props.theme.black};
  white-space: nowrap;
`;

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  button {
    min-width: 50%;
  }

  aside {
    margin-bottom: 0.5rem;
  }
`;

const Booking = ({ booking }) => {
  const room = {
    id: booking.onDemand.roomId,
    token: booking.onDemand.roomToken,
    validUntil: booking.onDemand.validUntil,
    validDays: booking.onDemand.validDays,
  };
  const valid = room.validDays === 0 || Date.parse(room.validUntil) >= Date.now();

  return (
    <BookingCard
      header={
        <>
          <span>
            <FormattedMessage id="booking.one" />
            <strong>{`: ${booking.billingNo}`}</strong>
          </span>
          <aside>
            <Badge color={booking.status === "paid" ? "success" : "warning"}>
              <FormattedMessage id={`booking.attributes.statuses.${booking.status}`} />
            </Badge>
            <small>
              <FormattedMessage
                id="pages.Profile.bookingDate"
                values={{ date: <FormattedDate value={booking.paidAt || booking.createdAt} /> }}
              />
            </small>
          </aside>
        </>
      }
    >
      <Info>
        <aside>
          <Label>
            <FormattedMessage id="course.one" />
          </Label>
          <NavLink
            to={routeWithParams(routes.course, {
              slug: booking.provider.slug,
              id: booking.course.id,
            })}
          >
            <Value>{booking.course.name}</Value>
          </NavLink>
          <p>
            {booking.event.name}
            {room.validDays > 0 && (
              <>
                <br />
                <FormattedMessage
                  id="pages.Profile.onDemandValidUntil"
                  values={{ date: <FormattedDate value={room.validUntil} /> }}
                />
              </>
            )}
          </p>
        </aside>
        {valid ? (
          <Button
            color="info"
            glow
            to={routeWithParams(routes.room, {
              slug: booking.provider.slug,
              id: room.id,
              roomToken: room.token,
            })}
          >
            <FormattedMessage id="pages.Profile.onDemandLink" />
          </Button>
        ) : (
          <small>
            <FormattedMessage id="pages.Profile.expiredOnDemandLink" />
          </small>
        )}
      </Info>
    </BookingCard>
  );
};

Booking.propTypes = {
  booking: PropTypes.object.isRequired,
  setBooking: PropTypes.func.isRequired,
};

export default Booking;
