import { createSelector } from "reselect";
import { CACHE_TTL } from "../index";

export const getUser = state => state.user;
export const getUserData = state => state.user && state.user.userData;
export const getToken = state => state.user && state.user.token;

export const getUserEvents = createSelector(getUserData, userData => (userData || {}).events);
export const getBookedEventIds = createSelector(
  getUserEvents,
  events => (events || {}).booked || [],
);

export const isLoading = createSelector(getUser, user => user.isLoading);

export const isExpired = createSelector(getUser, user => {
  const { updatedAt } = user;

  return !user.userData || !updatedAt || Date.now() - updatedAt > CACHE_TTL;
});

export const getUserContracts = createSelector(getUser, user => {
  return user.contracts;
});
