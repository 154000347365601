import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { truncate, formatAddress, currencyFormat, decodeHtml } from "../utils/formatters";
import routes, { routeWithParams } from "../routes";
import Icon from "./Icon";
import { Paragraph, Mono, Category } from ".";
import { Header, Title, Categories, Content, Details, DetailItem, Wrapper } from "./CourseCard";

const descriptionLength = () => (window.innerWidth > 500 ? 240 : 140);

const SubTitle = styled.span`
  display: block;
  margin-top: -0.5rem;
  margin-bottom: 0.25rem;
`;

const TypeDetails = styled.span`
  font-size: ${props => props.theme.fontSizes.small};
`;

const TypeMeta = styled.span`
  font-family: ${props => props.theme.fontFamilyMonospace};
`;

const TypeBadges = styled.aside`
  position: absolute;
  top: -1.5rem;
  right: -0.5rem;
  padding: 0.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TypeBadge = styled.div`
  padding: 0.25rem 0.5rem;
  margin-left: 0.75rem;
  border-radius: 0.75rem;
  font-size: ${props => props.theme.fontSizes.small};
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowSmall};
  z-index: 2;
  text-align: right;
  max-width: 148px;
  align-self: flex-start;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: max-width 0.15s;

  ${TypeDetails} {
    display: none;
  }

  &:hover {
    padding: 0.25rem 0.5rem;
    max-width: 200px;
    ${TypeDetails} {
      margin-top: 0.5rem;
      display: block;
    }
    ${TypeMeta} {
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      padding: 0.25rem 0.5rem;
      background-color: ${props => props.theme.white};
      color: ${props => props.theme.info};
      border-radius: ${props => props.theme.borderRadius};
    }
  }

  ${({ online, theme }) =>
    online &&
    `
    color: ${theme.info};
    background-color: ${theme.gray100};
    &:hover {
      padding: 0.25rem 0.5rem;
    }
    ${TypeDetails} {
      display: none;
    }
  `}

  ${({ rating, theme }) =>
    rating &&
    `
    color: ${theme.dark};
    background-color: ${theme.gray100};

    ${TypeDetails} {
      display: none;
    }
  `}

  ${({ bookable, theme }) =>
    bookable &&
    `
    position: absolute;
    bottom: 0;
    right: -0.5rem;
    transform: translateY(50%);
    font-size: ${theme.fontSizes.tiny};
    color: ${theme.white};
    background-color: ${theme.success};
  `}

`;

const ProviderName = styled.span`
  font-size: 2em;
  font-family: "Quicksand";
  font-weight: 400;
  letter-spacing: 0.05em;
  color: ${props => props.theme.white};
  text-align: center;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
`;

const ProviderLogo = styled.figure`
  position: absolute;
  top: 6%;
  left: 6%;
  width: 88%;
  height: 88%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  border-radius: ${props => props.theme.borderRadius};
`;

const ProviderCategories = styled(Categories)`
  position: absolute;
  top: 0;
  left: 0;
`;

const ProvidereCard = ({ provider, listView, onCategoryClick }) => (
  <Wrapper listView={listView}>
    <NavLink to={routeWithParams(routes.provider, { slug: provider.slug })} target="_blank">
      <Header
        style={
          provider.logo
            ? {
                minHeight: !listView ? "200px" : "0",
                background: "white",
              }
            : {}
        }
      >
        {provider.logo ? (
          <ProviderLogo style={{ backgroundImage: `url('${provider.logo}')` }} />
        ) : (
          <ProviderName>{provider.name}</ProviderName>
        )}
        <ProviderCategories hasImage={listView || provider.image}>
          {provider.categories.slice(0, 5).map(category => (
            <Category key={category.name} category={category} onClick={onCategoryClick} />
          ))}
        </ProviderCategories>
      </Header>
      <Content>
        <header style={{ position: "relative" }}>
          <Title>{provider.name}</Title>
          {provider.subTitle && <SubTitle>{provider.subTitle}</SubTitle>}
          <Paragraph>{decodeHtml(truncate(provider.description, descriptionLength()))}</Paragraph>
          <TypeBadges>
            {provider.onlineCourseCount > 0 && (
              <TypeBadge online>
                <FormattedMessage id="provider.attributes.onlineCourses" />
              </TypeBadge>
            )}
            {provider.distance > 0 && provider.distance <= 100 && (
              <TypeBadge>
                <TypeMeta>{`${Math.round(provider.distance)} km`}</TypeMeta>
                <TypeDetails>
                  {formatAddress(provider.address || {}) || (
                    <FormattedMessage id="components.Course.noAddress" />
                  )}
                </TypeDetails>
              </TypeBadge>
            )}
          </TypeBadges>
        </header>
        <section>
          {provider.courseCount > 0 && (
            <Details>
              <DetailItem>
                <Icon name="users" size="small" />
                <Mono>
                  {provider.courseCount}{" "}
                  <FormattedMessage id={`course.${provider.courseCount > 1 ? "many" : "one"}`} />
                </Mono>
              </DetailItem>
              {(provider.priceFrom || provider.priceTo) && (
                <DetailItem>
                  <Icon name="tags" size="small" />
                  <Mono>
                    {provider.priceFrom > 0 ? (
                      <span>
                        {provider.priceTo && provider.priceFrom !== provider.priceTo && (
                          <FormattedMessage id="priceFrom" />
                        )}
                        <FormattedNumber
                          value={provider.priceFrom / 100}
                          {...currencyFormat(provider)}
                        />
                      </span>
                    ) : provider.priceFrom !== provider.priceTo ? (
                      <span>
                        <FormattedMessage id="priceFrom" />
                        <FormattedNumber value={0} {...currencyFormat(provider)} />
                      </span>
                    ) : (
                      <FormattedMessage id="components.Course.free" />
                    )}
                  </Mono>
                </DetailItem>
              )}
            </Details>
          )}
        </section>
      </Content>
    </NavLink>
  </Wrapper>
);

ProvidereCard.propTypes = {
  provider: PropTypes.object.isRequired,
  listView: PropTypes.bool,
  onCategoryClick: PropTypes.func,
};

ProvidereCard.defaultProps = {
  listView: false,
  onCategoryClick: null,
};

export default ProvidereCard;
