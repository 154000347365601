import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { routerMiddleware } from "connected-react-router";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import internalTracking from "./kikudooTracking";

export const CACHE_TTL = 3600;

export const configureStore = (history, middleware = []) => {
  const devtools =
    typeof window !== "undefined" &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

  const composeEnhancers = devtools || compose;
  const persistedReducer = persistReducer(
    {
      key: "kikudoo",
      storage,
      whitelist: ["ui", "user", "provider", "waitList", "search"],
    },
    rootReducer(history),
  );

  if (process.env.NODE_ENV === "production") {
    middleware.push(internalTracking);
  }

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...[thunk].concat(middleware), routerMiddleware(history))),
  );

  const persistor = persistStore(store, null, () => {
    // nothing to do yet.
  });

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./rootReducer", () =>
        store.replaceReducer(require("./rootReducer").default),
      );
    }
  }

  return { store, persistor };
};

export default configureStore;
