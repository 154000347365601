import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";
import { formatNonHTML } from "../../utils/formatters";
import { Small } from "../../components";
import { Title, Header, HeaderInfo } from "../../components/CourseCard";
import ProviderInfo from "../../components/ProviderInfo";

import routes, { routeWithParams } from "../../routes";

const PREVIEW_LENGTH = 250;
const Wrapper = styled.a`
  display: flex;
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 2rem;
  min-height: 320px;
  cursor: pointer;
  flex-direction: column;
  text-decoration: none;
  color: inherit;

  transition: transform 1s
  &:hover {
    transform: scale(1.025);
  }

  @media (${props => props.theme.tabletScreen}) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  flex-basis: 50%;
  min-width: 50%;
  padding: 1rem 0;
  @media (${props => props.theme.tabletScreen}) {
    padding: 1rem;
  }
`;

const Preview = styled.article`
  margin: 1rem 0;
`;

const KeepReading = styled.span`
  color: ${props => props.theme.primary};
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Post = ({ provider, post }) => {
  return (
    <Wrapper as={NavLink} to={routeWithParams(routes.post, { slug: provider.slug, id: post.slug })}>
      <Header
        style={
          post.image && {
            backgroundImage: `url(${post.image && post.image.display})`,
          }
        }
      >
        <HeaderInfo>
          <ProviderInfo
            provider={{ logo: post.author.avatar, name: post.author.fullName }}
            compact
          />
        </HeaderInfo>
      </Header>
      <Content>
        <Title>{post.title}</Title>
        <Preview>
          {formatNonHTML(post.content).substring(0, PREVIEW_LENGTH)}
          {post.content.length > PREVIEW_LENGTH && "..."}
        </Preview>
        <Footer>
          <KeepReading>
            <FormattedMessage id="pages.Blog.keepReading" />
          </KeepReading>
          <Small>
            <FormattedDate month="long" day="2-digit" value={Date.parse(post.publishedAt)} />
            {", "}
            <FormattedTime value={Date.parse(post.publishedAt)} />
          </Small>
        </Footer>
      </Content>
    </Wrapper>
  );
};

Post.propTypes = {
  provider: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
};

export default Post;
