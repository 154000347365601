import { createSelector } from "reselect";

export const getSearchState = state => state.search;

export const getResults = createSelector(getSearchState, state => state.results);

export const getTerm = createSelector(getSearchState, state => state.term);

export const getCenter = createSelector(getSearchState, state => state.center);

export const getAddress = createSelector(getSearchState, state => state.address);

export const isLoading = createSelector(getSearchState, state => state.isLoading);

export const getFlexibleLocation = createSelector(
  getSearchState,
  state => state.flexibleLocation === true,
);

export const getListView = createSelector(getSearchState, state => state.listView === true);

export const getMetaData = createSelector(getSearchState, state => state.metaData);

export const getCoursesMetaData = createSelector(getSearchState, state => state.coursesMetaData);

export const getCoursesResults = createSelector(getSearchState, state => state.coursesResults);

export const getFilters = createSelector(getSearchState, state => state.filters);
