import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from "react-intl";
import { camelCase } from "lodash";

import {
  titleImage,
  truncate,
  currencyFormat,
  formatAddress,
  decodeHtml,
} from "../utils/formatters";
import { preventEvent } from "../utils/common";
import {
  flexibleEvents,
  continuousEvents,
  onDemandEvents,
  courseTypes,
  EVENT_TYPES,
} from "../utils/courses";
import routes, { routeWithParams } from "../routes";
import Icon from "./Icon";
import ProviderInfo from "./ProviderInfo";
import { Paragraph, Mono, Category } from "./";
import { DEFAULT_TIMEZONE } from "../utils/calc";

const descriptionLength = () => (window.innerWidth > 500 ? 140 : 80);

export const Header = styled.div`
  width: 100%;
  min-height: ${props => (props.src ? "200px" : "0px")};
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-color: ${props => props.theme.white};
  border-radius: ${props => props.theme.borderRadius};
  background-image: linear-gradient(151deg, rgba(111, 66, 193, 0.18), rgba(225, 108, 108, 0.32));
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: "";
    padding-top: 75%;
  }
`;

export const HeaderInfo = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  max-width: 75%;
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: bold;
`;

export const Title = styled.strong`
  font-size: ${props => props.theme.fontSizes.default};
  color: ${props => props.theme.black};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-weight: bold;
  display: inline-block;
`;

export const Categories = styled.aside`
  padding: 0.25rem;

  & > div {
    margin-right: 0.5rem;
  }

  ${({ hasImage }) =>
    hasImage &&
    `
    position: absolute;
    top: 0;
    left: 0;
  `}
`;

export const Details = styled.ul`
  list-style: none;
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.dark};
  font-weight: lighter;
  margin: 0;
  padding: 0.5rem 0.5rem 0;
`;

export const DetailItem = styled.li`
  margin-bottom: 0.5em;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  & > span {
    margin-left: 0.75rem;
    vertical-align: middle;
  }

  & > i {
    margin-top: 2px;
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 1em;
  }
`;

export const Footer = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme.gray200};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 ${props => props.theme.borderRadius} ${props => props.theme.borderRadius};
`;

export const Info = styled.div`
  display: flex;
`;

export const Content = styled.div`
  margin-top: 0.5rem;
  font-size: ${props => props.theme.fontSizes.small};

  color: ${props => props.theme.gray600};

  @media (${props => props.theme.desktopScreen}) {
    font-size: ${props => props.theme.fontSizes.default};
  }
`;

export const Bookable = styled.strong`
  display: inline-block;
  text-align: center;
  color: ${props => props.theme.success};
  margin-top: 0.75rem;
`;

export const Wrapper = styled.div`
  color: ${props => props.theme.dark};
  margin-bottom: 2em;
  position: relative;

  @media (pointer: fine) {
    ${Categories} {
      display: none;
    }

    &:hover {
      ${Categories} {
        display: block;
      }
    }
  }

  & > a {
    text-decoration: none;
    color: inherit;
    display: ${props => (props.listView ? "flex" : "block")};
  }

  ${({ listView, theme }) =>
    listView &&
    `
      background-color: ${theme.white};
      margin-bottom: 0;

      ${Content} {
        flex: 3;
        display: flex;
        padding: 1rem;

        & > header,
        & > section {
          flex: 1;
        }
      }

      ${Details} {
        flex: 1;
        min-width: 150px;
        &:before {
          display: none;
        }
      }

      ${Header} {
        position: static;
        max-width: 140px;
        min-width: 80px;
        min-height: 0;
        border-radius: ${theme.borderRadius} 0 0 ${theme.borderRadius};
        flex: 1;
      }

      ${Info} {
        display: none;
      }

      ${Title} {
        font-size: 1.25rem;
      }

      ${Footer} {
        background-color: transparent;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      ${Bookable} {
        flex: 1;
      }

      ${Categories} {
        position: absolute;
      }
  `}
`;

const FranchiseLogo = styled.img`
  max-height: 50px;
  vertical-align: center;
  padding: 0.25rem;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
`;

const DateWrapper = styled.span`
  color: ${props => (props.past ? props.theme.danger : "inherit")};
`;

const TypeDetails = styled.span`
  font-size: ${props => props.theme.fontSizes.small};
`;

const TypeMeta = styled.span`
  font-family: ${props => props.theme.fontFamilyMonospace};
`;

const TypeBadges = styled.aside`
  position: absolute;
  top: -1.5rem;
  right: -0.5rem;
  padding: 0.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TypeBadge = styled.div`
  padding: 0.25rem 0.5rem;
  margin-left: 0.75rem;
  border-radius: 0.75rem;
  font-size: ${props => props.theme.fontSizes.small};
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowSmall};
  z-index: 2;
  text-align: right;
  max-width: 148px;
  align-self: flex-start;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: max-width 0.15s;

  ${TypeDetails} {
    display: none;
  }

  &:hover {
    padding: 0.25rem 0.5rem;
    max-width: 200px;
    ${TypeDetails} {
      margin-top: 0.5rem;
      display: block;
    }
    ${TypeMeta} {
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      padding: 0.25rem 0.5rem;
      background-color: ${props => props.theme.white};
      color: ${props => props.theme.info};
      border-radius: ${props => props.theme.borderRadius};
    }
  }

  ${({ online, theme }) =>
    online &&
    `
    color: ${theme.info};
    background-color: ${theme.gray100};
    &:hover {
      padding: 0.25rem 0.5rem;
    }
    ${TypeDetails} {
      display: none;
    }
  `}

  ${({ rating, theme }) =>
    rating &&
    `
    color: ${theme.dark};
    background-color: ${theme.gray100};

    ${TypeDetails} {
      display: none;
    }
  `}

  ${({ bookable, theme }) =>
    bookable &&
    `
    position: absolute;
    bottom: 0;
    right: -0.5rem;
    transform: translateY(50%);
    font-size: ${theme.fontSizes.tiny};
    color: ${theme.white};
    background-color: ${theme.success};
  `}

`;

const RatingsHeart = styled.div`
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
`;

const CourseCard = ({ course, listView, onCategoryClick, provider, courseRoute }) => {
  const types = courseTypes(course);
  const nonBlockEvent =
    flexibleEvents(course.events)[0] ||
    continuousEvents(course.events)[0] ||
    onDemandEvents(course.events)[0];

  const timingDetails = () => {
    const typeOf = types.length > 1 ? "mixed" : types[0];
    switch (typeOf) {
      case "mixed":
        return <FormattedMessage id="components.Course.mixedEvents" />;
      case EVENT_TYPES.flexible:
        return nonBlockEvent && nonBlockEvent.description ? (
          nonBlockEvent.description
        ) : (
          <FormattedMessage id="components.Course.flexibleEvents" />
        );
      case EVENT_TYPES.continuous:
        if (nonBlockEvent && (nonBlockEvent.appointments || []).length > 0) {
          return nonBlockEvent.appointments.map(({ startDate, endDate }) => (
            <span key={`date-${startDate}`}>
              <FormattedDate weekday="short" value={Date.parse(startDate)} />
              {", "}
              <FormattedTime value={Date.parse(startDate)} timeZone={DEFAULT_TIMEZONE} />
              -
              <FormattedTime value={Date.parse(endDate)} timeZone={DEFAULT_TIMEZONE} />
              <br />
            </span>
          ));
        }
        return nonBlockEvent && nonBlockEvent.description !== "" ? (
          nonBlockEvent.description
        ) : (
          <FormattedMessage id="components.Course.continousEvents" />
        );
      default:
        if (course.startDates && course.startDates.length > 1) {
          return (
            <span>
              <FormattedMessage id="components.Course.startDates" />
              {course.startDates
                .map(startDate => (
                  <DateWrapper past={Date.parse(startDate) < new Date()} key={startDate}>
                    <FormattedDate month="short" day="2-digit" value={Date.parse(startDate)} />
                  </DateWrapper>
                ))
                .reduce((prev, curr) => [prev, ", ", curr])}
            </span>
          );
        }

        if (course.startDate || course.startDates[0]) {
          return (
            <>
              <DateWrapper past={Date.parse(course.startDate || course.startDates[0]) < new Date()}>
                <FormattedDate
                  month="long"
                  day="2-digit"
                  value={Date.parse(course.startDate || course.startDates[0])}
                />
              </DateWrapper>
              {" - "}
              <FormattedDate month="long" day="2-digit" value={Date.parse(course.endDate)} />
            </>
          );
        }
        return "-";
    }
  };

  const imageSize = "display";
  const isBookable = course.isBookable && (course.events || []).length > 0;
  // TODO: Optimize participants left in search query
  // && (nonBlockEvent ||
  //   course.events.reduce((sum, { participantsLeft }) => sum + participantsLeft, 0) > 0);

  return (
    <Wrapper listView={listView}>
      <NavLink
        to={routeWithParams(courseRoute, { slug: course.provider.slug, id: course.id })}
        target={provider && "_blank"}
      >
        <Header
          style={
            titleImage(course, imageSize) && {
              backgroundImage: `url(${titleImage(course, imageSize)})`,
            }
          }
        >
          <Categories hasImage={listView || titleImage(course, "small")}>
            {course.categories.slice(0, 5).map(category => (
              <Category key={category.name} category={category} onClick={onCategoryClick} />
            ))}
          </Categories>

          {provider && (
            <HeaderInfo>
              <ProviderInfo provider={provider} compact />
            </HeaderInfo>
          )}
        </Header>
        <Content>
          <header style={{ position: "relative" }}>
            {course.franchise && (
              <FranchiseLogo src={course.franchise.logo} title={course.franchise.title} />
            )}
            <Title>{course.name}</Title>
            <Paragraph>
              {listView
                ? course.subTitle
                : decodeHtml(truncate(course.shortDescription, descriptionLength()))}
            </Paragraph>
            <TypeBadges>
              {course.flexibleLocation && (
                <TypeBadge online>
                  <FormattedMessage
                    id={`course.attributes.locationType.${camelCase(
                      course.locationType || "online",
                    )}`}
                  />
                </TypeBadge>
              )}
              {course.heartsCount >= 1 && (
                <TypeBadge rating>
                  <span>{`${course.heartsCount} `}</span>
                  <RatingsHeart>
                    <Icon name="heart" color="danger" size="small" />
                  </RatingsHeart>
                </TypeBadge>
              )}
              {!course.flexibleLocation && course.distance && (
                <TypeBadge onClick={preventEvent}>
                  <TypeMeta>{`${Math.round(course.distance)} km`}</TypeMeta>
                  <TypeDetails>
                    {formatAddress(course.address || {}) || (
                      <FormattedMessage id="components.Course.noAddress" />
                    )}
                  </TypeDetails>
                </TypeBadge>
              )}
            </TypeBadges>
          </header>
          <section>
            {(course.events || []).length > 0 && (
              <Details>
                <DetailItem>
                  <Icon name="calendar-day" size="small" />
                  <Mono>{timingDetails()}</Mono>
                </DetailItem>
                <DetailItem>
                  <Icon name="tags" size="small" />
                  <Mono>
                    {course.priceFrom ? (
                      <span>
                        {course.priceTo && course.priceFrom !== course.priceTo && (
                          <FormattedMessage id="priceFrom" />
                        )}
                        <FormattedNumber
                          value={course.priceFrom / 100}
                          {...currencyFormat(course)}
                        />
                      </span>
                    ) : course.priceFrom !== course.priceTo ? (
                      <span>
                        <FormattedMessage id="priceFrom" />
                        <FormattedNumber value={0} {...currencyFormat(course)} />
                      </span>
                    ) : (
                      <FormattedMessage id="components.Course.free" />
                    )}
                  </Mono>
                </DetailItem>
                {!nonBlockEvent && (
                  <DetailItem>
                    <Icon name="users" size="small" />
                    <Mono>
                      {course.participantCount < 2 ? (
                        <FormattedMessage
                          id={`course.attributes.${
                            course.participantCount ? "one" : "zero"
                          }Participant`}
                        />
                      ) : (
                        <FormattedMessage
                          id="course.attributes.participantMax"
                          values={{ count: course.participantCount }}
                        />
                      )}
                    </Mono>
                  </DetailItem>
                )}
              </Details>
            )}
            {isBookable && (
              <TypeBadge bookable>
                <FormattedMessage id="components.Course.bookable" />
              </TypeBadge>
            )}
          </section>
        </Content>
      </NavLink>
    </Wrapper>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  listView: PropTypes.bool,
  onCategoryClick: PropTypes.func,
  provider: PropTypes.object,
  courseRoute: PropTypes.string,
};

CourseCard.defaultProps = {
  listView: false,
  onCategoryClick: null,
  provider: null,
  courseRoute: routes.course,
};

export default CourseCard;
