import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData, getToken, isLoading } from "../../store/selectors/user";
import { updatePassword } from "../../store/actions/user";

import EditPassword from "./EditPassword";

const mapStateToProps = state => ({
  userData: getUserData(state),
  token: getToken(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {
  updatePassword,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPassword));
