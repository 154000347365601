import axios from "axios";
import applyConverters from "axios-case-converter";
import { format, addDays } from "date-fns";
import { snakeCase } from "lodash";

export const API_URL = process.env.REACT_APP_API_ENDPOINT;
const DEFAULT_CLIENT_KEY = "default";

// Keep record of initialized client
const memoizedClients = {};

// Cache buster
const cb = () => `?t=${Date.now()}`;

const getFingerprintContent = () => {
  const m = document.querySelector("meta[name=fgrpnt-token]");
  if (!m) {
    return;
  }
  return m.getAttribute("content");
};

export class ApiClient {
  constructor(token) {
    this.fingerprint = getFingerprintContent();
    this.client = applyConverters(
      axios.create({
        baseURL: API_URL,
      }),
    );

    // Make requests cancable
    ["get", "post", "put", "patch", "delete"].forEach(method => {
      this[method] = async (...args) => {
        // NOTE: handle a canceled request not as a failure.
        const response = await this.client[method](...args).catch(e => {
          if (axios.isCancel(e)) {
            console.warn(e.message);
            return {};
          }
          throw e;
        });
        return response.data;
      };
    });
    this.source = null;

    if (token) {
      this.client.interceptors.request.use(request => {
        request.headers.common.Authorization = token;
        return request;
      });
    }

    // --- CSRF Authentication
    // Set the token if present for API authentication
    if (this.fingerprint) {
      this.client.interceptors.request.use(request => {
        if (!request.headers.common["X-Fingerprint"]) {
          request.headers.common["X-Fingerprint"] = this.fingerprint;
        }
        return request;
      });
    }

    // Retrieve new authenticity token if present
    this.client.interceptors.response.use(
      response => {
        // NOTE: axios converts cases for headers
        this.fingerprint = response.headers["x-fingerprint"] || this.fingerprint;
        return response;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }

  cancableRequest = () => {
    if (this.source) {
      this.source.cancel("Request canceled due to new request.");
    }
    this.source = axios.CancelToken.source();

    return {
      cancelToken: this.source.token,
    };
  };

  // Provider end-points
  provider = id => this.get(`/providers/${id}`);
  providerTerms = id => this.get(`/providers/${id}/terms`);
  providerPrivacyPolicy = id => this.get(`/providers/${id}/privacy_policy`);
  providerForwarded = id => this.get(`/providers/${id}/forwarded${cb()}`);
  providerPreview = url => this.get(`/providers/preview?url=${url}`);
  providerLead = lead => this.post("/providers/lead", lead);
  providerMessage = (id, message) => this.post(`/providers/${id}/messages`, message);
  providerRecommendations = id => this.get(`/providers/${id}/recommendations`);

  // Courses
  courses = providerId => this.get(`/providers/${providerId}/courses`);
  course = (providerId, id, token) =>
    token
      ? this.get(`/providers/${providerId}/courses/${id}${cb()}&token=${token}`)
      : this.get(`/providers/${providerId}/courses/${id}${cb()}`);
  courseRecommendations = (providerId, id) =>
    this.get(`/providers/${providerId}/courses/${id}/recommendations`);

  // Event rooms (on-demand)
  room = (providerId, id, token) => this.get(`providers/${providerId}/rooms/${id}?token=${token}`);

  // Posts
  posts = providerId => this.get(`/providers/${providerId}/posts`);

  // Products
  product = id => this.get(`/products/${id}`);

  // Users / authentication
  login = credentials => this.client.post("/customers/login", { customer: credentials });
  logout = () => this.client.delete("/customers/logout");
  signup = customer => this.client.post("/customers/signup", { customer });
  customer = () => this.client.get(`/customers/me${cb()}`);
  payments = () => this.client.get(`/customers/payments${cb()}`);
  updateUser = customer => this.client.put(`/customers/${customer.id}`, { customer });
  newConfirmation = customer => this.client.post("/customers/confirmation", { customer });
  resetPassword = customer => this.client.post("/customers/password", { customer });
  updatePassword = customer => this.client.put("/customers/password", { customer });

  // Contracts
  contracts = () => this.get(`/customers/product_contracts${cb()}`);
  contract = id => this.get(`/customers/product_contracts/${id}}`);
  createContract = (productContract, customer) =>
    this.post("/customers/product_contracts/", { productContract, customer });
  cancelContract = id => this.delete(`/customers/product_contracts/${id}`);

  // Booking
  bookings = () => this.get(`/customers/bookings${cb()}`);
  createBooking = (booking, customer) =>
    this.post("/bookings", {
      booking,
      customer,
    });
  confirmBooking = (booking, paymentObject) =>
    this.post(`/bookings/${booking.id}/confirm`, {
      paymentObject: paymentObject,
    });
  validateBooking = id => this.get(`/bookings/${id}/validate`);
  cancelBooking = id => this.get(`/bookings/${id}/cancel`);
  authorizeBooking = booking => this.post("/bookings/authorize", { booking });
  booking = id => this.get(`/bookings/${id}`);
  bookingPayment = (id, type) => this.post(`/bookings/${id}/payments`, { payment: { type } });

  // Voucher
  voucher = (providerId, courseId, voucherCode) =>
    this.get(
      `/providers/${providerId}/courses/${courseId}/vouchers/${Buffer.from(voucherCode).toString(
        "base64",
      )}${cb()}`,
    );

  // Wait list
  applications = () => this.get(`/customers/applications${cb()}`);
  createApplicant = (providerId, eventId, applicant) =>
    this.post(`/providers/${providerId}/applicants`, { eventId, applicant });

  // Search
  searchLocations = (center, radius, term) =>
    this.get(
      `/search/locations/?lat=${center.lat}&lng=${center.lng}&radius=${radius}&term=${term}`,
      this.cancableRequest(),
    );
  searchCourses = (term, limit, offset, filters = {}) =>
    this.get(
      `/search/courses/?term=${term || ""}&limit=${limit || ""}&offset=${offset ||
        ""}&${Object.entries(filters)
        .map(f => `${snakeCase(f[0])}=${f[1]}`)
        .join("&")}`,
      this.cancableRequest(),
    );
  search = (term, page, filters = {}) =>
    this.get(
      `/search/all/?term=${term || ""}&page=${page || 1}&${Object.entries(filters)
        .map(f => `${snakeCase(f[0])}=${f[1]}`)
        .join("&")}`,
      this.cancableRequest(),
    );

  error = (message, data = {}) => this.post("/err", { exception: { message, data } });

  // Franchise
  franchise = id => this.get(`/franchises/${id}`);
  franchiseProviders = id => this.get(`/franchises/${id}/providers`);
  franchiseSchedule = (id, startDate = new Date(), endDate, center = {}) =>
    this.get(
      `/franchises/${id}/schedule?start_date=${format(startDate, "yyyy-MM-dd")}&end_date=${format(
        endDate || addDays(startDate, 7),
        "yyyy-MM-dd",
      )}&lat=${center.lat || ""}&lng=${center.lng || ""}`,
      this.cancableRequest(),
    );
}

export const getApiClient = accessToken => {
  if (accessToken) {
    if (!memoizedClients[accessToken]) {
      memoizedClients[accessToken] = new ApiClient(accessToken);
    }
    return memoizedClients[accessToken];
  }

  if (!memoizedClients[DEFAULT_CLIENT_KEY]) {
    memoizedClients[DEFAULT_CLIENT_KEY] = new ApiClient();
  }
  return memoizedClients[DEFAULT_CLIENT_KEY];
};

export const inlineRequest = (url, data, method = "post") => {
  const form = document.createElement("form");
  form.method = method;
  form.action = url;
  Object.keys(data).forEach(key => {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", key);
    input.setAttribute("value", data[key]);
    form.appendChild(input);
  });
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
