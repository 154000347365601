import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData, getToken, isLoading, getUserContracts } from "../../store/selectors/user";
import { getCurrentProvider, getVisited } from "../../store/selectors/provider";
import {
  getUserBookings,
  isLoading as isLoadingBookings,
  getCurrentBooking,
} from "../../store/selectors/bookings";
import { logout, loadContracts, cancelContract } from "../../store/actions/user";
import { loadBookings, updateCurrent as updateCurrentBooking } from "../../store/actions/bookings";

import Profile from "./Profile";

const mapStateToProps = state => ({
  userData: getUserData(state),
  token: getToken(state),
  isLoading: isLoading(state),
  provider: getCurrentProvider(state),
  providers: getVisited(state),
  bookings: getUserBookings(state),
  isLoadingBookings: isLoadingBookings(state),
  booking: getCurrentBooking(state),
  contracts: getUserContracts(state),
});

const mapDispatchToProps = {
  logout,
  loadBookings,
  updateCurrentBooking,
  loadContracts,
  cancelContract,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
