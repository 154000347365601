import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl } from "react-intl";

import { Small, ContactAvatar } from "./";
import ContactForm from "./ContactForm";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Info = styled.article`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const User = styled.div`
  display: flex;
`;

const Contact = ({ user, provider, course, intl }) => (
  <Wrapper>
    <User>
      <ContactAvatar avatar={user.avatar} />
      <Info>
        <strong>{user.fullName}</strong>
        {user.role && (
          <Small>
            <FormattedMessage id="components.Contact.roles.contact" />
          </Small>
        )}
      </Info>
    </User>
    <ContactForm
      provider={provider}
      user={user}
      cta="actions.contact"
      buttonProps={{ color: "danger" }}
      subject={
        course
          ? intl.formatMessage(
              { id: "pages.Course.messageSubject" },
              { course: course.name, id: course.id },
            )
          : intl.formatMessage({ id: "pages.Provider.contactSubject" })
      }
    />
  </Wrapper>
);

Contact.propTypes = {
  user: PropTypes.object.isRequired,
  provider: PropTypes.object,
  course: PropTypes.object,
};

Contact.defaultProps = {
  provider: null,
  course: null,
};

export default injectIntl(Contact);
