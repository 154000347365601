import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import ReactGA from "react-ga4";
import { xor } from "lodash";
import { NavLink } from "react-router-dom";
import routes from "../../routes";
import { scrollTop } from "../../utils/common";
import { CURRENCY_FORMAT } from "../../utils/formatters";

import { getSearchParams } from "../../utils/params";
import { DEFAULT_FILTERS } from "../../store/reducers/search";

import ResultsList from "./ResultsList";
import Spaces from "./Spaces";
import { Category, Heading3, Loading, Icon, Button } from "../../components";
import UserNav from "../../components/UserNav";
import RangeSlider from "../../components/RangeSlider";
import Checkbox from "../../components/Checkbox";
import FloatingInput from "../../components/FloatingInput";
import searchImage from "../../assets/search-icon.png";
import { SUGGESTIONS } from "../SearchMap/SearchMap";
import Address from "../SearchMap/Address";

import { DEFAULT_SPACES } from "./Spaces";

export const isSameLocation = (l0, l1) => l0 && l1 && l0.lat === l1.lat && l0.lng === l1.lng;

const MOST_USED = [
  { id: 1, name: "Baby", color: "#b30f38" },
  { id: 83, name: "Beratung", color: "#515c0e" },
  { id: 101, name: "Familie", color: "#8d2977" },
  { id: 12, name: "Geburtsvorbereitung", color: "#2f329b" },
  { id: 96, name: "Säugling", color: "#35202d" },
  { id: 14, name: "Yoga", color: "#772458" },
  { id: 85, name: "Workshop", color: "#105f69" },
  { id: 77, name: "Gesundheit", color: "#138070" },
  { id: 100, name: "Ernährungsberatung", color: "#bf6f1d" },
  { id: 79, name: "Beikostberatung", color: "#461dbf" },
  { id: 69, name: "Trageberatung", color: "#797604" },
  { id: 29, name: "Rückbildung", color: "#371a94" },
  { id: 55, name: "Tanzen", color: "#3c551a" },
  { id: 42, name: "Erziehungsbegleitung", color: "#4f5b1c" },
  { id: 70, name: "Stillberatung", color: "#40760e" },
  { id: 112, name: "Beckenboden", color: "#bf731d" },
  { id: 59, name: "Pilates", color: "#412987" },
  { id: 33, name: "Hebammen", color: "#2c3d8d" },
  { id: 123, name: "Babyschlaf", color: "#561029" },
  { id: 120, name: "Stoffwindelberatung", color: "#201dbf" },
  { id: 6, name: "Kreativität", color: "#b53759" },
];

const MAX_PRICE = 500;

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const SearchWrapper = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (${props => props.theme.tabletScreen}) {
  }

  @media (${props => props.theme.desktopScreen}) {
  }
`;

const NoResults = styled.p`
  padding: 1rem;
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadowLifted};
  border-radius: ${props => props.theme.borderRadius};
  max-width: 640px;
  text-align: center;
  margin: 2rem auto;
  width: 90%;
  color: ${props => props.theme.dark};
`;

const LoadingMore = styled(NoResults)`
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 1rem;
  max-width: 180px;

  & > div {
    border-radius: 4rem;
    padding: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem;
  position: relative;
  flex-shrink: 0;
  justify-content: center;
  flex-direction: column;

  ${({ sticky, theme }) =>
    sticky &&
    `
      position: sticky;
      top: 3rem;
      margin: 0 auto;
      border-radius: 2rem;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(8px);
      box-shadow: ${theme.boxShadowLifted};
      padding: 0.5rem;
      z-index: 11;
      width: calc(100% - 2rem);
      max-width: 480px;
    `};

  @media (${props => props.theme.tabletScreen}) {
    max-width: 800px;
  }
`;

const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  &:last-child {
    margin-top: 0.5rem;
  }
`;

const ActionDisplay = styled.aside`
  display: flex;
`;

const ResultsDisplay = styled.div`
  min-height: 50px;
  border-radius: 2rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 185px;
  padding: 0 0.5rem;
  line-height: 1rem;
  color: ${props => props.theme.black};
  cursor: pointer;

  & > div {
    padding: 0;
    margin-left: 0.5rem;
    & > div {
      margin: 0;
    }
  }
`;

const Intro = styled.div`
  padding: 0.5rem;
  text-align: center;
  color: ${props => props.theme.dark};
  max-width: 640px;
  margin: 0 auto 1rem;

  ${Heading3} {
    color: inherit;
  }

  @media (${props => props.theme.tabletScreen}) {
    max-width: 800px;
  }
`;

const SearchIcon = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;
  min-width: 60px;
  display: inline-block;
  margin: 0.25rem auto;

  @media (${props => props.theme.tabletScreen}) {
    max-width: 200px;
  }
`;

const Categories = styled.div`
  max-width: 480px;
  margin: 0 auto;

  & > div {
    margin: 0.125rem;
    font-size: ${props => props.theme.fontSizes.small};
    padding: 2px 6px;
    &:nth-of-type(1n + 10) {
      display: none;
    }
  }

  @media (${props => props.theme.tabletScreen}) {
    max-width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;

    & > div {
      margin: 0.25rem;
      font-size: ${props => props.theme.fontSizes.default};
      padding: 4px 12px;
      &:nth-of-type(1n) {
        display: inline-block;
      }
    }
  }
`;

const MapSearchLink = styled(NavLink)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 4;

  display: flex;
  align-items: center;
  color: ${props => props.theme.white};
  text-decoration: none;
  box-shadow: ${props => props.theme.boxShadow};
  background-color: ${props => props.theme.info};
  overflow: hidden;
  white-space: nowrap;

  width: auto;
  max-width: 54px;
  height: 54px;
  padding: 0.8rem;
  border-radius: 50%;
  transition: max-width 0.25s ease-out, border-radius 0.1s ease-out, height 0.25s;

  & > i {
    margin-right: 1rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    max-width: 320px;
    border-radius: 2rem;
    height: 48px;
    z-index: 11;
  }

  &:hover {
    max-width: 320px;
    border-radius: 2rem;
    height: 48px;
  }
`;

const AddressDisplay = styled.span`
  position: absolute;
  bottom: -1rem;
  right: 0;
  background-color: ${props => props.theme.info};
  box-shadow: ${props => props.theme.boxShadow};
  color: ${props => props.theme.white};
  font-family: ${props => props.theme.fontFamilyMonospace};
  padding: 0.125rem 0.5rem;
  border-radius: 5rem;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (${props => props.theme.tabletScreen}) {
    max-width: 300px;
  }
`;

// Prevent FloatingInputs fixed position on mobile
const SearchForm = styled(FloatingInput)`
  position: static !important;
  padding: 0.15rem !important;
`;

const Filters = styled.div`
  flex: 1;
  margin-top: 1rem;
  padding: 1rem;
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-wrap: wrap;

  @media (${props => props.theme.tabletScreen}) {
    max-height: 450px;
  }
`;

const Filter = styled.section`
  margin: 1rem 0;
  min-width: 300px;
  flex: 1;
  padding: 0;

  @media (${props => props.theme.tabletScreen}) {
    padding: 0 1rem;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  margin: 0.5rem 0;
  flex-direction: column;
  align-items: flex-start;
`;

const SpacesWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 4;

  background-color: ${props => props.theme.light};
  @media (${props => props.theme.tabletScreen}) {
    background-color: transparent;
  }
`;

const ActionsWrapper = styled.p`
  margin-top: 1rem;
`;

const SearchOnline = ({
  term,
  searchCourses,
  isLoading,
  results,
  metadata,
  intl,
  center,
  address,
  changeAddress,
  filters,
  setFilters,
  setFilter,
}) => {
  const params = getSearchParams() || {};

  const [stateTerm, setStateTerm] = useState(params.term || term || "");
  const [page, setPage] = useState(1);
  const [editFilters, setEditFilters] = useState(false);

  const onSearch = useCallback(
    stateTerm => {
      searchCourses(stateTerm, page, center, filters);
    },
    [searchCourses, page, center, filters],
  );

  const nextPage = () => {
    console.log("next page");
    if (!metadata || metadata.totalCount <= metadata.page) {
      console.log("no metadata or no page", metadata);
      return;
    }

    if (isLoading) {
      console.log("no next page while loading pls");
      return;
    }

    setPage(p => p + 1);
  };

  // Trigger new search
  const onCategoryClick = ({ name }) => {
    if (!name) {
      return;
    }

    setPage(1);
    setStateTerm(name);
    setEditFilters(false);

    ReactGA.event({
      category: "Category",
      action: name || "",
    });
  };

  // Initial set of center after incoming search request by params
  useEffect(() => {
    const params = getSearchParams() || {};
    const { address, lat, lng, space } = params;

    // Overwrite address if given by URL params
    if (parseFloat(lat) && parseFloat(lng)) {
      changeAddress(address, parseFloat(lat), parseFloat(lng));
    }

    // Overwrite cateogry spaces if given via URL params
    // e.g. ?space=creative
    if (space) {
      setFilter({ categorySpaces: [space] });
    }
  }, [changeAddress, setFilter]);

  // Search on Term change
  useEffect(() => {
    onSearch(stateTerm);
  }, [stateTerm, onSearch]);

  useEffect(() => {
    if (!metadata) {
      console.log("page reset because no metadata");
      return setPage(1);
    }

    console.log("page to ", metadata.page);

    setPage(metadata.page);
    return () => {
      console.log("page reset while cleanup");
      setPage(1);
    };
  }, [metadata]);

  // A new address was selected from auto complete field
  const onAddressSelect = (address, lat, lng) => {
    changeAddress(address, lat, lng);
    setEditFilters(false);
  };

  const onSpaceClick = space => {
    updateFilters("categorySpaces", [space.key]);
  };

  const resetSpaces = () => {
    setFilters({
      ...filters,
      categorySpaces: [],
    });
  };

  const onMaxPriceChange = value => {
    setFilter({
      maxPrice: value >= MAX_PRICE ? null : value,
    });
  };

  const noResults = results.length < 1 && metadata && !isLoading;
  const moreResults = metadata && metadata.totalCount > results.length;
  const spaces = filters.categorySpaces || [];

  const updateFilters = (key, value) => {
    setFilters({
      ...filters,
      [key]: xor(filters[key], value),
    });

    // Reset page when filters changed
    setPage(1);
    scrollTop();
  };

  return (
    <Wrapper>
      <UserNav />
      <SpacesWrapper>
        <Spaces
          spaces={DEFAULT_SPACES.map(s => ({
            ...s,
            active: spaces.includes(s.key),
          }))}
          onClick={onSpaceClick}
        />
      </SpacesWrapper>
      <SearchWrapper>
        <MapSearchLink
          to={routes.searchMap}
          style={
            !metadata
              ? {
                  maxWidth: "320px",
                  borderRadius: "2rem",
                  height: "48px",
                }
              : {}
          }
        >
          <Icon name="Map" size="large" />
          <FormattedMessage id="pages.Search.mapSearch" />
        </MapSearchLink>
        {(!metadata || noResults) && (
          <Intro>
            <SearchIcon src={searchImage} />
            <Heading3>
              <FormattedMessage id="pages.Search.title" />
            </Heading3>
            {false && (
              <section>
                <Categories>
                  {MOST_USED.map(category => (
                    <Category onClick={onCategoryClick} key={category.name} category={category} />
                  ))}
                </Categories>
              </section>
            )}
          </Intro>
        )}
        <Actions sticky>
          <ActionsRow>
            <SearchForm
              name="search"
              defaultValue={stateTerm}
              busy={isLoading}
              onSubmit={term => {
                setPage(1);
                setStateTerm(term);
              }}
              defaultExpanded={true}
              placeholder={intl.formatMessage({ id: "pages.SearchMap.all" })}
              suggestions={SUGGESTIONS}
            />
            <ActionDisplay>
              <ResultsDisplay>
                {isLoading ? (
                  <Loading />
                ) : (
                  <FormattedMessage
                    id="pages.SearchMap.results"
                    values={{ count: (metadata && metadata.totalCount) || results.length }}
                  />
                )}
              </ResultsDisplay>
              <Button
                color={editFilters ? "info" : "default"}
                onClick={() => setEditFilters(!editFilters)}
                style={{ opacity: 1 }}
                round
              >
                <Icon name="Filter" />
              </Button>
            </ActionDisplay>
          </ActionsRow>

          {editFilters ? (
            <ActionsRow>
              <Filters>
                <Filter>
                  <FormattedMessage id="pages.Search.filters.location" />
                  <Address
                    onClear={() => {
                      changeAddress();
                    }}
                    onSelect={(address, { lat, lng }) => onAddressSelect(address, lat, lng)}
                    address={address}
                    defaultExpanded
                  />
                </Filter>
                <Filter>
                  <FormattedMessage id="pages.Search.filters.price" />
                  <RangeSlider
                    onChange={onMaxPriceChange}
                    from={0}
                    to={MAX_PRICE}
                    format={v => (
                      <span>
                        <span>{v >= MAX_PRICE ? "> " : "max "}</span>
                        <FormattedNumber value={v} {...CURRENCY_FORMAT} maximumFractionDigits={0} />
                      </span>
                    )}
                    defaultValue={
                      ((filters || {}).maxPrice && parseInt(filters.maxPrice)) || MAX_PRICE
                    }
                  />
                </Filter>
                <Filter>
                  <FormattedMessage id="pages.Search.filters.age" />
                  <Checkboxes>
                    <Checkbox
                      active={(filters.ageGroups || []).includes("baby")}
                      onClick={() => updateFilters("ageGroups", ["baby"])}
                    >
                      <Icon name="baby" size="small" />
                      <FormattedMessage id="pages.Search.filters.ageBaby" />
                    </Checkbox>
                    <Checkbox
                      active={(filters.ageGroups || []).includes("child")}
                      onClick={() => updateFilters("ageGroups", ["child"])}
                    >
                      <Icon name="children" size="small" />
                      <FormattedMessage id="pages.Search.filters.ageChild" />
                    </Checkbox>
                    <Checkbox
                      active={(filters.ageGroups || []).includes("parents")}
                      onClick={() => updateFilters("ageGroups", ["parents"])}
                    >
                      <Icon name="person-dress" size="small" />
                      <FormattedMessage id="pages.Search.filters.ageParents" />
                    </Checkbox>
                  </Checkboxes>
                </Filter>
                <Filter>
                  <FormattedMessage id="pages.Search.filters.type" />
                  <Checkboxes>
                    <Checkbox
                      active={(filters.locationTypes || []).includes("online")}
                      onClick={() => updateFilters("locationTypes", ["online"])}
                    >
                      <Icon name="display" size="small" />
                      <FormattedMessage id="course.attributes.locationType.online" />
                    </Checkbox>
                    <Checkbox
                      active={center && (filters.locationTypes || []).includes("on_site")}
                      disabled={!center}
                      onClick={() => updateFilters("locationTypes", ["on_site"])}
                    >
                      <Icon name="location-dot" size="small" />
                      <FormattedMessage id="course.attributes.locationType.onSite" />
                    </Checkbox>
                    <Checkbox
                      active={(filters.locationTypes || []).includes("home", "by_arrangement")}
                      onClick={() => updateFilters("locationTypes", ["home", "by_arrangement"])}
                    >
                      <Icon name="Home" size="small" />
                      <FormattedMessage id="course.attributes.locationType.others" />
                    </Checkbox>
                  </Checkboxes>
                </Filter>
                <ActionsRow style={{ justifyContent: "space-between" }}>
                  <Button
                    size="small"
                    onClick={() => setFilters(DEFAULT_FILTERS)}
                    color="dark"
                    outline
                  >
                    <FormattedMessage id="actions.reset" />
                  </Button>
                  <Button onClick={() => setEditFilters(false)} color="info">
                    <FormattedMessage id="actions.apply" />
                  </Button>
                </ActionsRow>
              </Filters>
            </ActionsRow>
          ) : (
            <AddressDisplay onClick={() => setEditFilters(true)}>
              {center && center.lat && center.lng ? (
                address || <FormattedMessage id="pages.Search.yourPosition" />
              ) : (
                <FormattedMessage id="pages.Search.noPosition" />
              )}
            </AddressDisplay>
          )}
        </Actions>

        <ResultsList
          results={results}
          onCategoryClick={category => {
            if (
              !window.confirm(
                intl.formatMessage(
                  { id: "pages.SearchMap.searchCategory" },
                  { category: category.name },
                ),
              )
            ) {
              return;
            }
            onCategoryClick(category);
          }}
          hasMore={false}
        />
        {moreResults && (
          <LoadingMore as="div">
            {isLoading ? (
              <Loading />
            ) : (
              <Button onClick={nextPage}>
                <FormattedMessage id="pages.Search.loadMore" />
                <br />
                <Icon name="chevron-up" direction="down" />
              </Button>
            )}
          </LoadingMore>
        )}
        {noResults && (
          <NoResults>
            <FormattedMessage id="pages.Search.noResults" />
            {spaces.length > 0 && (
              <ActionsWrapper>
                <Button onClick={resetSpaces} color="info" outline>
                  <FormattedMessage id="pages.Search.resetFilters" />
                </Button>
              </ActionsWrapper>
            )}
          </NoResults>
        )}
      </SearchWrapper>
    </Wrapper>
  );
};

SearchOnline.propTypes = {
  visitedProviders: PropTypes.object,
  results: PropTypes.array,
  metadata: PropTypes.object,
  center: PropTypes.object,
  term: PropTypes.string,
  address: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,

  /* Dispatchable actions*/
  searchCourses: PropTypes.func.isRequired,
  changeAddress: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};

SearchOnline.defaultProps = {
  visitedProviders: null,
  results: [],
  metadata: null,
  term: "",
  center: null,
  address: null,
};

export default injectIntl(SearchOnline);
