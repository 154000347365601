import { createSelector } from "reselect";
import { CACHE_TTL } from "../index";

export const getBookingsState = state => state.bookings;

export const getCurrentBooking = createSelector(
  getBookingsState,
  state => state.current,
);

export const getUserBookings = createSelector(
  getBookingsState,
  state => state.userBookings,
);

export const isLoading = createSelector(
  getBookingsState,
  state => state.isLoading,
);

export const isExpired = createSelector(
  getBookingsState,
  state => {
    const { updatedAt } = state;

    return !state.current || !updatedAt || Date.now() - updatedAt > CACHE_TTL;
  },
);
