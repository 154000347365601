import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { darken, rgba, desaturate } from "polished";
import theme, { COLOR_VARIATION } from "../theme";
import { gradientColors } from "../utils/formatters";

const SIZES = {
  small: {
    y: "0.25rem",
    x: "0.5rem",
  },
  default: {
    y: "0.75rem",
    x: "1rem",
  },
  large: {
    y: "0.75rem",
    x: "1.75rem",
  },
};

const FONT_SITES = {
  small: "0.65625rem",
  default: theme.fontSizes.small,
  large: "1.25rem",
};

const backgroundColor = name => {
  switch (name) {
    case "default":
      return theme.light;
    case "disabled":
      return theme.gray300;
    default:
      return theme[name] || theme.light;
  }
};

const foregroundColor = name => {
  switch (name) {
    case "default":
      return theme.dark;
    case "disabled":
      return theme.gray500;
    case "white":
      return theme.black;
    case "light":
      return theme.black;
    default:
      return theme.white;
  }
};

const Wrapper = styled.button`
  display: inline-block;
  position: relative;
  word-break: normal;
  font-weight: 400;
  text-align: center;
  text-transform: none;
  letter-spacing: 0.1em;
  line-height: 1.5;
  text-decoration: none;
  font-size: ${props => FONT_SITES[props.size]};
  padding-top: ${props => SIZES[props.size].y};
  padding-bottom: ${props => SIZES[props.size].y};
  padding-left: calc(
    ${props => SIZES[props.size].x} + ${props => (props.busy ? "0.5rem" : "0rem")}
  );
  padding-right: calc(
    ${props => SIZES[props.size].x} - ${props => (props.busy ? "0.5rem" : "0rem")}
  );
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  color: ${props => foregroundColor(props.color)};
  background-color: ${props => desaturate(props.disabled ? 0.15 : 0, backgroundColor(props.color))};
  text-shadow: 0 1px 1px
    ${props => rgba(props.theme.dark, ["light", "default"].includes(props.color) ? 0 : 0.2)};
  border: 0;
  border-radius: ${props =>
    props.round
      ? "2rem"
      : props.size === "small"
      ? props.theme.borderRadiusSmall
      : props.theme.borderRadius};

  box-shadow: ${props =>
    props.glow && !props.disabled
      ? "0 0.3rem 1.3rem " + rgba(darken(COLOR_VARIATION, backgroundColor(props.color)), 0.33)
      : "none"};
  cursor: pointer;
  user-select: none;
  outline: none;
  word-break: normal;

  transition: padding-right 0.25s, padding-left 0.25s, box-shadow 0.5s, background-color 0.25s;
  background-image: linear-gradient(
    153deg,
    ${props => gradientColors(backgroundColor(props.color), 9, false)}
  );

  &:hover {
    background-color: ${props =>
      darken(props.disabled ? 0 : COLOR_VARIATION, backgroundColor(props.color))};
    box-shadow: ${props =>
      props.glow && !props.disabled
        ? "0 0.6rem 1.5rem " + rgba(darken(COLOR_VARIATION, backgroundColor(props.color)), 0.5)
        : "none"};
  }

  & > i {
    font-size: 1rem;
    vertical-align: middle;

    &:last-child {
      margin-left: 0.5rem;
    }

    &:first-child {
      margin-right: 0.5rem;
    }
  }

  ${({ outline, color }) =>
    outline &&
    ` 
      background-color: transparent;
      background-image: none;
      color: ${backgroundColor(color)};
      border: 1px solid ${backgroundColor(color)};
      box-shadow: none;
      text-shadow: none;
      &:hover {
        box-shadow: none;
        color: ${foregroundColor(color)};
      }
  `}
`;

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loading = styled.span`
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  margin-left: -1.5em;
  border: 2px solid ${props => props.theme.white};
  border-color: currentColor currentColor currentColor transparent;
  border-radius: 50%;
  height: 1em;
  width: 1em;
  animation: ${spinnerAnimation} 2s linear infinite;
`;

const Button = ({
  className,
  busy,
  children,
  color,
  size,
  type,
  to,
  disabled,
  onClick,
  round,
  outline,
  ...props
}) => (
  <Wrapper
    className={className}
    as={to ? "a" : "button"}
    disabled={disabled || busy}
    href={to}
    to={to}
    color={color}
    size={size}
    type={type}
    onClick={e => (disabled || busy ? e.preventDefault() : onClick && onClick(e))}
    busy={busy}
    round={round}
    outline={outline}
    {...props}
  >
    {busy && <Loading />}
    {children}
  </Wrapper>
);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  busy: PropTypes.bool,
  glow: PropTypes.bool,
  round: PropTypes.bool,
  outline: PropTypes.bool,
};

Button.defaultProps = {
  color: "default",
  size: "default",
  type: "button",
  disabled: false,
  busy: false,
  glow: true,
  round: false,
  outline: false,
};

export default Button;
