import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import routes, { routeWithParams } from "../../routes";

import Loading from "../../components/Loading";
import { PageLayout, PageTitle, Small } from "../../components/";

import templates from "./templates";
import { scrollTop } from "../../utils/common";
import { DEFAULT_TIMEZONE } from "../../utils/calc";

const Wrapper = styled(PageLayout)`
  display: flex;
  flex-direction: column;
`;

const LoadingWrapper = styled(Wrapper)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const PostPageTitle = styled(PageTitle)`
  header {
    margin-top: 5rem;
  }
`;

const PostInfo = styled(Small)`
  display: inline-block;
  text-align: center;
  @media (${props => props.theme.tabletScreen}) {
    text-align: left;
  }
`;

const Blog = ({ provider, posts, loadPosts, isLoading, match }) => {
  const Template = templates[provider.template || "standard"];
  const history = useHistory();

  useEffect(() => {
    if (isLoading || (posts && posts.length === provider.postCount)) {
      return;
    }

    async function getPosts(id) {
      await loadPosts(id);
    }

    getPosts(provider.id);
  }, [provider, posts, loadPosts, isLoading]);

  useEffect(() => {
    scrollTop();
  }, [match]);

  const currentPost = match.params.id && posts.find(p => p.slug === match.params.id);

  return (
    <Wrapper>
      <Helmet>
        <title>{currentPost ? currentPost.title : provider.name}</title>
        <link
          href={`https://kikudoo.com/${provider.slug}/blog/${currentPost ? currentPost.slug : ""}`}
          rel="canonical"
        />
        <meta
          property="og:url"
          content={`https://kikudoo.com/${provider.slug}/blog/${
            currentPost ? currentPost.slug : ""
          }`}
        />
      </Helmet>
      <PostPageTitle
        title={currentPost ? currentPost.title : provider.name}
        subTitle={currentPost ? "" : <FormattedMessage id="pages.Blog.subtitle" />}
        logo={provider.logo}
        clean={provider.template === "clean"}
        info={
          currentPost && (
            <PostInfo>
              <span>
                <FormattedDate
                  month="long"
                  day="2-digit"
                  value={Date.parse(currentPost.publishedAt)}
                />
                {", "}
                <FormattedTime
                  value={Date.parse(currentPost.publishedAt)}
                  timeZone={DEFAULT_TIMEZONE}
                />
              </span>
              <br />
              <FormattedMessage
                id="pages.Blog.postSub"
                values={{ user: currentPost.author.fullName }}
              />
            </PostInfo>
          )
        }
        onProviderClick={() => {
          history.push(
            routeWithParams(routes.provider, {
              slug: provider.slug,
            }),
          );
        }}
        onTitleClick={() => {
          history.push(
            routeWithParams(routes.blog, {
              slug: provider.slug,
            }),
          );
        }}
      />

      {isLoading ? (
        <LoadingWrapper>
          <Loading text={<FormattedMessage id="pages.Blog.loading" />} />
        </LoadingWrapper>
      ) : (
        <Template provider={provider} currentPost={currentPost} posts={posts} />
      )}
    </Wrapper>
  );
};

Blog.propTypes = {
  match: PropTypes.object.isRequired,
  loadPosts: PropTypes.func.isRequired,
  provider: PropTypes.object,
  posts: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
};

Blog.defaultProps = {
  posts: [],
};

export default Blog;
