import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import { Button, Icon } from ".";

const Content = styled.div`
  max-height: 4rem;
  overflow: hidden;
`;

const Wrapper = styled.section`
  max-height: 4rem;
  position: relative;

  padding-bottom: 0;
  transition: max-height 0.25s, padding-bottom 0.25s;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    content: "";
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);

    opacity: 1;
    transition: opacity 0.25s;
  }

  ${({ expanded }) =>
    expanded &&
    `
    max-height: 600px;
    padding-bottom: 2rem;
    &:after {
      opacity: 0;
    }

    ${Content} {
      max-height: 100%;
    }
  `}
`;

const ToggleButton = styled(Button)`
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 1;
  padding: 0.5rem;

  transition: bottom 0.25s;
`;

const ReadMoreText = ({ defaultExpanded, children }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Wrapper expanded={expanded}>
      <Content>{children}</Content>
      <ToggleButton onClick={() => setExpanded(!expanded)} round color="light">
        <Icon name="chevron-up" size="small" direction={expanded ? "up" : "down"} />
        <FormattedMessage id="showMore" />
      </ToggleButton>
    </Wrapper>
  );
};

ReadMoreText.propTypes = {
  defaultExpanded: PropTypes.bool,
};

ReadMoreText.defaultProps = {
  defaultExpanded: false,
};

export default ReadMoreText;
