import { connect } from "react-redux";
import { getCurrentProvider } from "../../store/selectors/provider";
import { getPosts, isLoading } from "../../store/selectors/posts";
import { loadPosts } from "../../store/actions/posts";
import Blog from "./Blog";

const mapStateToProps = state => ({
  provider: getCurrentProvider(state),
  posts: getPosts(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {
  loadPosts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Blog);
