import { REHYDRATE } from "redux-persist";
import { ActionTypes } from "../actions/search";
import { uniqBy } from "lodash";

export const DEFAULT_VIEWPORT = {
  center: {
    lat: 51.165691,
    lng: 10.451526,
  },
  zoom: 9,
};

export const DEFAULT_FILTERS = {
  locationTypes: ["on_site", "online", "home", "by_arrangement"],
  bookable: true,
  ageGroups: ["baby", "child", "parents"],
  categorySpaces: [],
};

export const initialState = Object.freeze({
  address: null,
  term: null,
  center: null,
  results: [],
  metaData: null,
  isLoading: false,
  listView: false,
  locations: null,
  coursesResults: [],
  coursesMetaData: null,
  filters: DEFAULT_FILTERS,
});

// Clear 'toddler' from persisted filter age groups
const clearedAgeGroups = rehydratedState =>
  ((rehydratedState || {}).filters || DEFAULT_FILTERS).ageGroups.filter(key => key !== "toddler");

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case ActionTypes.SEARCH_REQUEST: {
      return {
        ...state,
        isLoading: true,
        locations: payload,
      };
    }

    case ActionTypes.SEARCH_FAILED: {
      return {
        ...state,
        isLoading: false,
        metaData: null,
        coursesMetaData: null,
      };
    }

    case ActionTypes.SEARCH_CLEARED: {
      return {
        ...state,
        results: [],
        metaData: null,
        isLoading: false,
        coursesResults: [],
        coursesMetaData: null,
      };
    }

    case ActionTypes.SEARCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        term: payload.term,
        // Append results if search terms is same as before
        results: uniqBy(
          (payload.results || []).concat(payload.term === state.term ? state.results : []),
          "key",
        ),
        // Save all infos besides results for later display controls
        metaData: { ...payload, results: null },
      };
    }

    case ActionTypes.COURSE_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        term: payload.term,
        // Append results if offset is bigger than results before
        coursesResults:
          payload.page > ((state.coursesMetaData || {}).page || 1)
            ? (state.coursesResults || []).concat(payload.results)
            : payload.results || [],
        // Save all infos besides results for later display controls
        coursesMetaData: { ...payload, results: null },
      };
    }

    case ActionTypes.ADDRESS_CLEARED: {
      return {
        ...state,
        address: null,
        center: initialState.center,
        results: [],
      };
    }

    case ActionTypes.ADDRESS_CHANGED: {
      return {
        ...state,
        address: payload.address,
        center: { lat: payload.lat, lng: payload.lng },
      };
    }

    case ActionTypes.LIST_VIEW_CHANGED: {
      return {
        ...state,
        listView: payload,
      };
    }

    case ActionTypes.SET_FILTERS: {
      return {
        ...state,
        filters: payload,
      };
    }

    case ActionTypes.SET_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    }

    case REHYDRATE: {
      const rehydratedState = payload.search || {};

      return {
        ...initialState,
        ...rehydratedState,
        isLoading: false,
        listView: rehydratedState.center !== null ? rehydratedState.listView : true,
        metaData: null,
        coursesMetaData: null,
        filters: {
          ...((rehydratedState || {}).filters || DEFAULT_FILTERS),
          ageGroups: clearedAgeGroups(rehydratedState),
        },
      };
    }

    default:
      return state;
  }
};
