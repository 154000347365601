import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";

import CourseCard from "../CourseCard";
import { Heading, Icon } from "../";
import routes from "../../routes";
import Loading, { LoadingContainer } from "../Loading";

const byDate = (a, b) => {
  if (!a.startDate) {
    return -1;
  } else if (!b.startDate) {
    return 1;
  }
  return new Date(b.startDate) - new Date(a.startDate);
};

const Wrapper = styled.section`
  margin-top: 1rem;

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 4rem;
  }
`;

const CourseGroup = styled.section`
  width: 100vw;
  padding-bottom: 2rem;

  & > h3 {
    margin-bottom: 2rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-left: 0;
    width: 100%;
  }
`;

const Item = styled.li`
  flex: 0 0;
  min-width: 80%;
  margin-bottom: 1rem;
  margin-right: 1rem;
  scroll-snap-stop: always;
  scroll-snap-align: center;

  &:first-child {
    margin-left: 1rem;
  }

  &:last-child {
    min-width: calc(80% + 1rem);
    padding-right: 1rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    min-width: ${props => (props.listView ? "100%" : "50%;")};
    padding-left: 1rem;
    margin-right: 0;
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      min-width: ${props => (props.listView ? "100%" : "50%;")};
      padding-right: 0;
    }
  }

  @media (${props => props.theme.desktopScreen}) {
    min-width: ${props => (props.listView ? "100%" : "33.3%;")};
    margin-bottom: 2rem;
    &:last-child {
      min-width: ${props => (props.listView ? "100%" : "33.3%;")};
    }
  }

  @media (${props => props.theme.hugeScreen}) {
    min-width: ${props => (props.listView ? "100%" : "25%;")};
    margin-bottom: 2rem;
    &:last-child {
      min-width: ${props => (props.listView ? "100%" : "25%;")};
    }
  }
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  padding-top: 1rem;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  @media (${props => props.theme.tabletScreen}) {
    flex-wrap: wrap;
    overflow-x: visible;
  }
`;

const Sticky = styled.header`
  // position: sticky;
  top: 0;
  z-index: 1;
  // margin-bottom: 1rem;
  // margin-left: -0.75rem;

  & > h3 {
    padding: 0.3rem 0.5rem;
    background-color: ${props => props.theme.white};
    display: inline-block;
    border-radius: ${props => props.theme.borderRadius};
  }

  @media (${props => props.theme.tabletScreen}) {
    position: static;
    margin-left: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ViewToggles = styled.aside`
  display: none;
  @media (${props => props.theme.tabletScreen}) {
    display: block;
  }
`;

const ViewToggle = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  color: ${props => props.theme.dark};

  & > i {
    margin-left: 0.5rem;
  }

  &:hover {
    color: ${props => props.theme.info};
  }
`;

const SelectedCategory = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: ${props => props.theme.borderRadiusHuge};
  color: ${props => props.theme.white};
  cursor: pointer;
  box-shadow: ${props => props.theme.boxShadow};

  font-size: ${props => props.theme.fontSizes.large};
  display: block;

  @media (${props => props.theme.tabletScreen}) {
    margin-left: 1rem;
    display: inline-block;
  }

  & > i {
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
    vertical-align: middle;
  }
`;

const CourseList = ({
  courses,
  onCategoryClick,
  courseRoute,
  listView,
  changeListView,
  isLoading,
}) => {
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [category, setCategory] = useState(null);

  const groupedCourses = filteredCourses.reduce(function(o, c) {
    (o[c.group] = o[c.group] || []).push(c);
    return o;
  }, {});

  useEffect(() => {
    if (!category) {
      return setFilteredCourses(courses);
    }
    setFilteredCourses(
      courses.filter(course => (course.categories || []).map(c => c.id).includes(category.id)),
    );
  }, [courses, category, setFilteredCourses]);

  return (
    <Wrapper>
      <Header>
        <Heading level={2}>
          {category && (
            <SelectedCategory
              style={{ backgroundColor: category.color }}
              onClick={() => setCategory(null)}
            >
              <span>{category.name}</span>
              <Icon name="add" direction={45} color="white" />
            </SelectedCategory>
          )}
        </Heading>
        <ViewToggles>
          {listView ? (
            <ViewToggle onClick={() => changeListView(false)}>
              <FormattedMessage id="components.CourseList.cardsView" />
              <Icon name="Cards" />
            </ViewToggle>
          ) : (
            <ViewToggle onClick={() => changeListView(true)}>
              <FormattedMessage id="components.CourseList.listView" />
              <Icon name="bars" />
            </ViewToggle>
          )}
        </ViewToggles>
      </Header>
      {Object.keys(groupedCourses)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map(key => (
          <CourseGroup key={key || "ungrouped"}>
            {key && (
              <Sticky>
                <Heading level={3}>{key}</Heading>
              </Sticky>
            )}
            <List group={key && key !== ""}>
              {groupedCourses[key]
                .sort(byDate)
                .reverse()
                .map(course => (
                  <Item key={course.id} group={key && key !== ""} listView={listView}>
                    <CourseCard
                      course={course}
                      listView={listView}
                      courseRoute={courseRoute}
                      onCategoryClick={category => {
                        setCategory(category);
                        if (onCategoryClick) {
                          onCategoryClick(category);
                        }
                      }}
                    />
                  </Item>
                ))}
            </List>
          </CourseGroup>
        ))}

      {isLoading && (
        <LoadingContainer>
          <Loading text={<FormattedMessage id="pages.Provider.loadingCourses" />} />
        </LoadingContainer>
      )}
    </Wrapper>
  );
};

CourseList.propTypes = {
  courses: PropTypes.array,
  onCategoryClick: PropTypes.func,
  courseRoute: PropTypes.string,
  listView: PropTypes.bool,
  changeListView: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

CourseList.defaultProps = {
  courses: [],
  onCategoryClick: () => {},
  courseRoute: routes.course,
  listView: false,
  isLoading: false,
};

export default CourseList;
