import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import routes, { routeWithParams } from "../../../../routes";
import { PageLayout, Overlay, Icon, Heading3, Paragraph, Button } from "../../../../components";
import Loading from "../../../../components/Loading";
import { Wrapper, CancelButton } from "../Confirm/Confirm";

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;

const FailureMessage = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
`;

const Title = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem 0;

  & > i {
    margin-right: 1rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    flex-direction: row;
    margin: 0.5rem 0;
  }
`;

const Failure = ({ loadContract }) => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const [productContractId] = useState(params.id);
  const [product, setProduct] = useState(null);
  const [contract, setProductContract] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!productContractId) {
      return;
    }

    const getProductContract = async id => {
      try {
        const data = await loadContract(id);
        setProduct(data.product);
        setProductContract(data);
      } catch (e) {
        if (e.isAxiosError) {
          const status = e.response.status;
          return setError(status || 500);
        }
        setError(500);
      }
    };

    getProductContract(productContractId);
  }, [productContractId, loadContract]);

  const onCancel = () => {
    if (contract) {
      return history.push(
        routeWithParams(routes.course, {
          slug: contract.provider.slug,
          id: contract.event.course.id,
        }),
      );
    }

    history.push(routes.profile);
  };

  const onRetry = () => {
    if (contract) {
      return history.push(
        routeWithParams(
          routes.contracts.new,
          {
            id: product.id,
          },
          {
            from: window.location.pathname,
            courseId: contract.event.course.id,
            eventId: contract.event.id,
          },
        ),
      );
    }

    history.push(routes.profile);
  };

  return (
    <PageLayout>
      <Overlay blackout onClose={onCancel}>
        <Wrapper>
          <CancelButton onClick={onCancel}>
            <Icon name="add" direction={45} />
          </CancelButton>
          {error ? (
            "Error"
          ) : product ? (
            <FailureMessage>
              <Title>
                <Icon name="add" color="danger" size="huge" direction="45" />
                <Heading3>
                  <FormattedMessage id="components.Contract.failure.title" />
                </Heading3>
              </Title>

              <Paragraph>
                <FormattedMessage
                  id="components.Contract.failure.text"
                  values={{ product: product.name }}
                />
              </Paragraph>
              <Paragraph>
                <Button color="success" onClick={onRetry} glow>
                  <FormattedMessage id="components.Contract.failure.tryAgain" />
                </Button>
              </Paragraph>
              <Paragraph>
                <Button color="danger" onClick={onCancel} outline>
                  <FormattedMessage id="components.Contract.failure.cancel" />
                </Button>
              </Paragraph>
            </FailureMessage>
          ) : (
            <LoadingWrapper>
              <Loading text={<FormattedMessage id="components.Booking.loading.confirm" />} />
            </LoadingWrapper>
          )}
        </Wrapper>
      </Overlay>
    </PageLayout>
  );
};

Failure.propTypes = {
  loadContract: PropTypes.func.isRequired,
};

export default Failure;
