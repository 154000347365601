import React from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ButtonWrapper from "./ButtonWrapper";

// Custom component to wrap the PayPalButtons and show loading spinner
const PayPalButton = ({ booking, onSuccess, onCancel, clientId }) => {
  debugger;
  return (
    <PayPalScriptProvider
      options={{
        clientId,
        components: "buttons",
        currency: "EUR",
      }}
    >
      <ButtonWrapper booking={booking} onSuccess={onSuccess} onCancel={onCancel} />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
