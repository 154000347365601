import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData, getToken, isLoading } from "../../store/selectors/user";
import { resetPassword } from "../../store/actions/user";

import ResetPassword from "./ResetPassword";

const mapStateToProps = state => ({
  userData: getUserData(state),
  token: getToken(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {
  resetPassword,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
