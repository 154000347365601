import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { Input, Button, Alert, Row, Col, Separator, Heading4, Select } from "..";
import { COUNTRIES } from "../Booking/User";

const Form = styled.form``;
class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      street: "",
      postalCode: "",
      city: "",
      country: "",
      company: "",
      errors: null,
    };
  }

  componentDidMount() {
    const { userData } = this.props;
    if (userData) {
      this.setState(userData);
    }
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ errors: null });

    const { updateUser, userData } = this.props;
    const { email, firstName, lastName, phone, street, postalCode, city, country, company } = this.state;
    try {
      await updateUser({
        id: userData.id,
        email,
        firstName,
        lastName,
        phone,
        street,
        postalCode,
        city,
        country,
        company,
      });
    } catch (e) {
      if (e.isAxiosError) {
        return this.setState({ errors: e.response.data });
      }

      throw e;
    }
  };

  render() {
    const { intl, isLoading } = this.props;
    const {
      email,
      firstName,
      lastName,
      phone,
      errors,
      street,
      postalCode,
      city,
      country,
      company,
    } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        {errors && (
          <Alert type="danger">
            <FormattedMessage id="components.UserForm.failed" />
          </Alert>
        )}
        <Row>
          <Col count={6}>
            <Input
              name="firstName"
              type="text"
              value={firstName}
              placeholder={intl.formatMessage({ id: "user.firstName" })}
              onChange={this.onChange}
              hasError={errors && errors.firstName}
            />
          </Col>
          <Col count={6}>
            <Input
              name="lastName"
              type="text"
              value={lastName}
              placeholder={intl.formatMessage({ id: "user.lastName" })}
              onChange={this.onChange}
              hasError={errors && errors.lastName}
            />
          </Col>
        </Row>
        <Input
          name="phone"
          type="text"
          value={phone}
          placeholder={intl.formatMessage({ id: "user.phone" })}
          onChange={this.onChange}
          hasError={errors && errors.phone}
        />
        <Input
          name="email"
          type="email"
          value={email}
          placeholder={intl.formatMessage({ id: "user.email" })}
          onChange={this.onChange}
          hasError={errors && errors.email}
        />
        <Separator />
        <Heading4>
          <FormattedMessage id="user.address" />
        </Heading4>
        <Input
          name="company"
          value={company}
          placeholder={intl.formatMessage({ id: "user.company" })}
          onChange={this.onChange}
          hasError={errors && errors.company}
        />
        <Row>
          <Col>
            <Input
              name="street"
              type="street"
              value={street}
              placeholder={intl.formatMessage({ id: "user.street" })}
              onChange={this.onChange}
              hasError={errors && errors.street}
            />
          </Col>
        </Row>
        <Row>
          <Col count={4}>
            <Input
              name="postalCode"
              type="postalCode"
              value={postalCode}
              placeholder={intl.formatMessage({ id: "user.postalCode" })}
              onChange={this.onChange}
              hasError={errors && errors.postalCode}
            />
          </Col>
          <Col count={4}>
            <Input
              name="city"
              type="city"
              value={city}
              placeholder={intl.formatMessage({ id: "user.city" })}
              onChange={this.onChange}
              hasError={errors && errors.city}
            />
          </Col>
          <Col count={4}>
            <Select
              name="country"
              autocomplete="country"
              hasError={errors && errors.country}
              value={country || "DE"}
              placeholder={intl.formatMessage({ id: "user.country" })}
              onChange={this.onChange}
              required
            >
              {COUNTRIES.map(country => (
                <option key={country} value={country}>
                  {intl.formatMessage({ id: `countries.${country.toLowerCase()}` })}
                </option>
              ))}
            </Select>
          </Col>
        </Row>
        <Button color="success" type="submit" busy={isLoading}>
          <FormattedMessage id="actions.update.normal" />
        </Button>
      </Form>
    );
  }
}

UserForm.propTypes = {
  userData: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(UserForm);
