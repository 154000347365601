import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import routes from "../../routes";

import { gradientColors } from "../../utils/formatters";
import UserNav from "../../components/UserNav";
import Footer from "../../components/Footer";
import { PageLayout, PageTitle, CardContainer, Icon, Paragraph } from "../../components/";

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;

  background-image: linear-gradient(-108deg, ${props => gradientColors(props.theme.primary)});
`;

const ProfileWrapper = styled(CardContainer)`
  margin-top: 1rem;
  padding: 1rem;
  text-align: center;
  flex: 1;

  p {
    text-align: center;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (${props => props.theme.tabletScreen}) {
    padding: 4rem;
  }
`;

const IconWrapper = styled.div`
  margin: 2rem auto;
  text-align: center;
`;

const ConfirmSuccess = () => (
  <Wrapper>
    <UserNav />
    <PageLayout>
      <PageTitle title={<FormattedMessage id="pages.ConfirmSuccess.title" />} subTitle={""} />
      <ProfileWrapper>
        <IconWrapper>
          <Icon name="check" size="huge" color="success" />
        </IconWrapper>
        <Paragraph>
          <FormattedMessage id="pages.ConfirmSuccess.intro" />
        </Paragraph>
        <Paragraph>
          <NavLink to={routes.profile}>
            <FormattedMessage id="pages.ConfirmSuccess.toProfile" />
          </NavLink>
        </Paragraph>
      </ProfileWrapper>
    </PageLayout>
    <Footer />
  </Wrapper>
);

export default ConfirmSuccess;
