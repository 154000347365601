import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  transform: rotateY(${props => (props.flipped ? 0 : 180)}deg);
`;

const Card = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

const FlipableContent = ({ front, back, flipped }) => (
  <Wrapper>
    <Inner flipped={flipped}>
      <Card>{front}</Card>
      <Card>{back}</Card>
    </Inner>
  </Wrapper>
);

FlipableContent.propTypes = {
  front: PropTypes.node.isRequired,
  back: PropTypes.node.isRequired,
  flipped: PropTypes.bool,
};

FlipableContent.defaultProps = {
  flipped: false,
};

export default FlipableContent;
