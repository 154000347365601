import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import { ApiClient } from "../../utils/Api";
import ProviderInfo from "../../components/ProviderInfo";
import { PageLayout, PageContent, PageTitle, Container, RichText } from "../../components/";

const Wrapper = styled.article`
  padding: 1rem 0;
`;

class Terms extends React.Component {
  state = {
    terms: "",
  };

  async componentDidMount() {
    const { provider } = this.props;
    const terms = await new ApiClient().providerTerms(provider.id);

    this.setState({ ...terms });
  }

  render() {
    const { provider } = this.props;
    const { terms } = this.state;

    return (
      <PageLayout>
        <PageTitle
          title={provider.name}
          subTitle={<FormattedMessage id="pages.Terms.title" />}
          contact={provider.contacts[0]}
          // logo={provider.logo}
          // image={titleImage(provider)}
        />
        <PageContent>
          <Wrapper>
            <Container>
              <ProviderInfo provider={provider} />
              <RichText text={terms || ""} />
            </Container>
          </Wrapper>
        </PageContent>
      </PageLayout>
    );
  }
}

Terms.propTypes = {
  provider: PropTypes.object.isRequired,
};

export default Terms;
