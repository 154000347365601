import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import routes, { routeWithParams } from "../../routes";
import { useStripe } from "@stripe/react-stripe-js";
import { getSearchParams } from "../../utils/params";
import { titleImage } from "../../utils/formatters";

// Selectors
import { getCurrentBooking } from "../../store/selectors/bookings";
import { getCurrentProvider } from "../../store/selectors/provider";
// Dispatch actions
import { loadBooking, cancelBooking, confirmBooking } from "../../store/actions/bookings";

import { PageLayout, Overlay, Icon, Button, Heading3, Paragraph } from "../../components";
import Loading from "../../components/Loading";
import Success from "../Checkout/Success";
import { useRollbar } from "@rollbar/react";

const MAX_PAYMENT_CHECKS = 5;

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;

const Message = styled.p`
  padding: 2rem;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.boxShadow};
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
`;

const Checkout = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const rollbar = useRollbar();

  // Retrive booking id at first
  const { id } = useParams();

  // Get the booking if it is in state - on session
  const booking = useSelector(state => getCurrentBooking(state));
  const provider = useSelector(state => getCurrentProvider(state));

  // Propagate processing / confirming to prevent cancel
  const [confirming, setConfirming] = useState(false);

  // Final success / error state
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(null);

  // Confirm callback
  const onSuccess = useCallback(
    paymentIntent => {
      // Handle successful payment
      setConfirming(true);

      // If redirect and final check indicates success, confirm the booking and set state to success
      // to render success message to user
      dispatch(confirmBooking(booking, paymentIntent))
        .then(confirmedBooking => {
          if (confirmedBooking.id) {
            setSuccess(true);
          }

          setConfirming(false);
        })
        .catch(error => {
          rollbar.error("Error confirming booking", error, { booking });
          setConfirming(false);
        });
    },
    [booking, dispatch, rollbar],
  );

  // Initial booking fetch, it is either in store already or has to be fetched from api
  useEffect(() => {
    if (booking && booking.id === id) {
      // Don't fetch booking again
      return;
    }

    // Fetch booking if not in store
    dispatch(loadBooking(id)).catch(error => {
      if (error && error.isAxiosError) {
        setFailure(error.response.status);
      } else {
        setFailure("generic");
      }
      rollbar.error("Error loading booking", error, { bookingId: id });
    });
    return () => {};
  }, [booking, id, dispatch, rollbar]);

  // Run this effect to check the status and then confirm the booking
  useEffect(() => {
    if (!booking || success || confirming) {
      return;
    }

    // If this booking is succeded already, just show message
    if (booking.status === "paid") {
      return setSuccess(true);
    }

    const { redirect_status, payment_intent_client_secret } = getSearchParams() || {};
    let paymentCheckRetry = 0;

    const checkPaymentIntent = async clientSecret => {
      const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret);
      if (error) {
        rollbar.error("StripeError retrieving pamyent intent", error, { booking });
        return alert(intl.formatMessage({ id: "components.Booking.error.payment" }));
      } else if (
        paymentIntent &&
        (paymentIntent.status === "succeeded" || paymentIntent.status === "processing")
      ) {
        // Stripes payment intent succeded meanwhile, good to go!
        onSuccess(paymentIntent);
      } else if (paymentIntent && paymentIntent.status === "pending") {
        // If the payment wasn't processed within 15 seconds we assume it succeeded anyways
        if (paymentCheckRetry >= MAX_PAYMENT_CHECKS) {
          return onSuccess(paymentIntent);
        }
        window.setTimeout(() => checkPaymentIntent(payment_intent_client_secret), 5000);
        paymentCheckRetry++;
        console.log(`PaymentIntent not processed yet, retry (${paymentCheckRetry})...`);
      } else {
        // Unknown status?
        return alert(intl.formatMessage({ id: "components.Booking.error.payment" }));
      }
    };

    if (redirect_status === "canceled") {
      // Ignore?
      setFailure(redirect_status);
    } else if (redirect_status === "failed") {
      // Error message?
      setFailure(redirect_status);
    } else if (
      (redirect_status === "succeeded" ||
        redirect_status === "pending" ||
        redirect_status === "processing") &&
      payment_intent_client_secret
    ) {
      setConfirming(true);
      setFailure(null);

      checkPaymentIntent(payment_intent_client_secret);
    }

    return () => {};
  }, [booking, id, stripe, intl, dispatch, success, confirming, onSuccess, rollbar]);

  const onCancel = () => {
    dispatch(cancelBooking(booking.status === "paid" ? null : booking));

    return history.push(
      routeWithParams(routes.course, { slug: booking.provider.slug, id: booking.course.id }),
    );
  };

  if (!booking) {
    return (
      <PageLayout>
        <LoadingWrapper>
          {failure ? (
            <Message>
              <span style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}>
                <Icon name="add" color="danger" direction={45} />
                <span style={{ marginLeft: "1rem", padding: "4px 0" }}>
                  <FormattedMessage
                    id={`components.Booking.redirectFailure.${failure || "generic"}`}
                  />
                </span>
              </span>

              <Button color="info" to={routeWithParams(routes.courses, { slug: provider.slug })}>
                <FormattedMessage id="components.Booking.success.close" />
              </Button>
            </Message>
          ) : (
            <Loading text={<FormattedMessage id="loading" />} />
          )}
        </LoadingWrapper>
      </PageLayout>
    );
  }

  // Final state, show success message
  return (
    <PageLayout>
      {success ? (
        <Success image={titleImage(booking.course)} onClose={onCancel}>
          <Heading3>{booking.course.name}</Heading3>
          <Paragraph>
            <strong>{booking.event.name}</strong>
          </Paragraph>
        </Success>
      ) : (
        <Overlay blackout>
          {confirming ? (
            <LoadingWrapper>
              <Loading text={<FormattedMessage id="components.Booking.loading.confirm" />} />
            </LoadingWrapper>
          ) : (
            <LoadingWrapper>
              <Loading text={<FormattedMessage id="components.Booking.loading.payment" />} />
            </LoadingWrapper>
          )}
        </Overlay>
      )}
    </PageLayout>
  );
};

export default injectIntl(Checkout);
