import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { scrollTop } from "../utils/common";

import { ActionInput, Icon, Button } from "./";
import AutoSuggestInput from "./AutoSuggestInput";

export const isMobileView = window.innerWidth < 641;

const ClearButton = styled.span`
  cursor: pointer;
  user-select: none;
  word-break: normal;
  background-color: ${props => props.theme.light};
  padding: 0.5rem 0.25rem;
  display: none;
`;

const AutoSuggestInputFloating = styled(AutoSuggestInput)`
  flex: 4;
  input {
    margin-bottom: 0;
    border-radius: 2rem 0 0 2rem;
    background-color: ${props => props.theme.light};
    transition: background-color 0.5s;
    min-width: 80px;
  }
`;

const Wrapper = styled.div`
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  border-radius: 2rem;
  padding: 0.15rem;
  max-width: 100px;
  width: 100%;
  height: 100%;
  transition: max-width 0.5s;


  ${({ expanded }) =>
    expanded &&
    `
        max-width: 300px;
        ${ClearButton} {
          display: inline-block;
        }
        
    `}

  
  ${({ lifted }) =>
    lifted &&
    `
        transition: max-width 0.15s;
        position: fixed;
        padding: 0.75rem;
        top: 4rem;
        left: 0;
        width: 100%;
        max-width: 100%;
        z-index: 9999;
        translateZ: 999px;
        background-color: rgba(255, 255, 255, 0.85);
        backdrop-filter: blur(5px);
    `}

  @media (${props => props.theme.tabletScreen}) {
    max-width: 300px;
    ${({ expanded }) =>
      expanded &&
      `
        max-width: 400px;
    `}
  }

  button {
    border-radius: 0 2rem 2rem 0;
    transition: padding 0.5s, border-color: 0.5s;

    ${({ expanded }) =>
      !expanded &&
      `
        border-color: transparent;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    `}
  }
`;

const FloatingInput = ({
  className,
  onSubmit,
  onChange,
  name,
  defaultValue,
  placeholder,
  busy,
  defaultExpanded,
  suggestions,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [value, setValue] = useState(defaultValue || "");
  const [lifted, setLifted] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded, setExpanded]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue]);

  const submit = v => {
    setValue(v);
    onSubmit(v);
    setLifted(false);

    try {
      if (inputRef.current) {
        inputRef.current.blur();
      }

      if (document.activeElement) {
        document.activeElement.blur();
      }
    } catch (e) {
      console.warn("can't blur element", e);
    }
  };

  const change = v => {
    setValue(v);
    onChange(v);
  };

  return (
    <Wrapper
      className={className}
      onClick={() => setExpanded(true)}
      expanded={expanded}
      lifted={false && isMobileView && lifted}
    >
      <form
        onSubmit={e => {
          e.preventDefault();
          submit(value);
        }}
      >
        <ActionInput>
          <AutoSuggestInputFloating
            inputProps={{
              name,
              type: "text",
              placeholder,
              onFocus: e => {
                setLifted(true);
                scrollTop();
                e.target.select();
              },
              // onBlur: e => setLifted(false),
              ref: inputRef,
            }}
            defaultValue={value}
            onChange={v => change(v)}
            onSelect={v => submit(v)}
            defaultSuggestions={suggestions}
          />
          <ClearButton type="button" onClick={() => submit("")}>
            <Icon name="add" size="small" color="dark" direction={45} />
          </ClearButton>
          <Button type="submit" color="white">
            <Icon name="magnifying-glass" size="small" color="info" />
          </Button>
        </ActionInput>
      </form>
    </Wrapper>
  );
};

FloatingInput.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  busy: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  suggestions: PropTypes.array,
};

FloatingInput.defaultProps = {
  className: "",
  name: "value",
  defaultValue: "",
  placeholder: "",
  defaultExpanded: false,
  busy: false,
  onChange: () => {},
  suggestions: [],
};

export default FloatingInput;
