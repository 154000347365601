import { REHYDRATE } from "redux-persist";
import { ActionTypes } from "../actions/user";

export const USER_PROPERTIES = [
  "firstName",
  "lastName",
  "email",
  "password",
  "passwordConfirmation",
  "phone",
  "mobile",
  "street",
  "city",
  "postalCode",
  "country",
];

export const USER_TEMPLATE = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: "",
  street: "",
  city: "",
  postalCode: "",
  country: "",
};

export const initialState = Object.freeze({
  isLoading: false,
  updatedAt: null,
  userData: null,
  token: null,
  payments: null,
  contracts: [],
});

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case ActionTypes.LOGIN_REQUEST:
    case ActionTypes.SIGNUP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        updatedAt: null,
        userData: null,
        token: null,
      };
    }

    case ActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userData: payload.userData,
        token: payload.token,
        updatedAt: Date.now(),
      };
    }

    case ActionTypes.SIGNUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userData: payload,
        updatedAt: Date.now(),
      };
    }

    case ActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userData: null,
        updatedAt: null,
        token: null,
      };
    }

    case ActionTypes.LOGIN_FAILED:
    case ActionTypes.SIGNUP_FAILED: {
      return {
        ...state,
        isLoading: false,
        userData: null,
        updatedAt: null,
        token: null,
      };
    }

    case ActionTypes.UPDATE_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.UPDATE_USER_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.USER_SUCCESS:
    case ActionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userData: payload,
        updatedAt: Date.now,
      };
    }

    case ActionTypes.USER_SET_DATA: {
      return {
        ...state,
        isLoading: false,
        userData: payload,
        updatedAt: Date.now,
      };
    }

    case ActionTypes.CONTRACTS_SUCCESS: {
      return {
        ...state,
        contracts: payload,
      };
    }

    case REHYDRATE: {
      const rehydratedState = payload.user;
      return {
        ...rehydratedState,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
