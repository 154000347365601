import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import AutoSuggest from "react-autosuggest";

const Wrapper = styled.div`
  position: relative;
  
  input {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${props => props.theme.black};
    background-color: ${props => props.theme.light};
    background-clip: padding-box;
    border: 1px solid ${props => (props.hasError ? props.theme.red : props.theme.gray100)};
    border-radius: ${props => props.theme.borderRadius};
    margin-bottom: 1rem;
    font-size: 100%;
    box-shadow: ${props => props.theme.boxShadowInset}

    &:focus {
      font-size: 100%;
      outline: none;
      border-color: ${props => props.theme.primary};
    }
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    min-width: 100%;
    margin-top: 0.5rem;
    background-color: ${props => props.theme.white};
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.boxShadowLifted};
    top: 110%;
    max-height: 360px;
    overflow-y: scroll;
    z-index: 1;
    display: none;

    &.react-autosuggest__suggestions-container--open {
      display: block;
    }
    
    .react-autosuggest__suggestion {
      &:hover,
      &.react-autosuggest__suggestion--highlighted {
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.primary};
      }
    }
  }
`;

const Suggestion = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SuggestionName = styled.span``;

const SuggestionImage = styled.aside`
  min-width: 24px;
  min-height: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const AutoSuggestInput = ({
  className,
  defaultValue,
  defaultSuggestions,
  onChange,
  onSelect,
  inputProps,
}) => {
  const [value, setValue] = useState(defaultValue || "");
  const [suggestions, setSuggestions] = useState(defaultSuggestions);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue]);

  const getSuggestions = value =>
    defaultSuggestions.filter(s =>
      (s.value || s.name.toLowerCase()).includes(value.trim().toLowerCase()),
    );

  const getSuggestionValue = suggestion => suggestion.name;
  const renderSuggestion = suggestion => (
    <Suggestion>
      <SuggestionName>{suggestion.name}</SuggestionName>
      {suggestion.image && (
        <SuggestionImage style={{ backgroundImage: `url('${suggestion.image}` }} />
      )}
    </Suggestion>
  );

  return (
    <Wrapper className={className}>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          onChange(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestionValue }) => {
          onChange(suggestionValue);
          onSelect(suggestionValue);
        }}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          ...inputProps,
          value,
          onChange: (_, { newValue, method }) => {
            setValue(newValue);
            onChange(newValue);
          },
        }}
        highlightFirstSuggestion
      />
    </Wrapper>
  );
};

AutoSuggestInput.propTypes = {
  defaultValue: PropTypes.string,
  defaultSuggestions: PropTypes.array,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  // Dispatch / Callbacks
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

AutoSuggestInput.defaultProps = {
  defaultValue: "",
  defaultSuggestions: [],
  inputProps: {
    type: "text",
  },
  className: "",
};

export default AutoSuggestInput;
