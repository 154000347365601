import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./polyfills";
import React from "react";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";

import "./index.css";
import App from "./App";

import { configureStore } from "./store";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();
const configuredStore = configureStore(history);
const store = configuredStore.store;

hydrate(
  <Provider store={store}>
    <PersistGate loading={null} persistor={configuredStore.persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("app"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Simple GoogleAnalytics tracking
if (
  process.env.NODE_ENV === "production" &&
  document.cookie.includes("ga-disable-G-1LXQ71N5X6") === false
) {
  ReactGA.initialize("G-1LXQ71N5X6", { gaOptions: { anonymizeIp: true } });
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

  history.listen(location => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  });
}
