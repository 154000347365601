import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import routes, { routeWithParams } from "../../routes";

import { Icon, Button } from "../../components/";

const Wrapper = styled.nav`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
`;

const PostButton = styled(Button)`
  display: flex;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.boxShadow};
  margin-bottom: 2rem;
  cursor: pointer;
  color: inherit;
  align-items: center;
  text-decoration: none;

  color: ${props => props.theme.black};

  @media (${props => props.theme.tabletScreen}) {
    flex-direction: row;
  }

  & > span {
    white-space: nowrap;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;

    @media (${props => props.theme.tabletScreen}) {
      max-width: 240px;
    }
  }
  & > i {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

const Links = ({ currentPost, provider, posts }) => {
  const index = posts.indexOf(currentPost);
  const prevPost = currentPost && posts[index - 1];
  const nextPost = currentPost && posts[index + 1];

  return (
    <Wrapper>
      {prevPost ? (
        <PostButton
          as={NavLink}
          to={routeWithParams(routes.post, { slug: provider.slug, id: prevPost.slug })}
        >
          <Icon name="chevron-up" direction="left" size="small" />
          <FormattedMessage id="pages.Blog.prevPost" values={{ post: prevPost.title }} />
        </PostButton>
      ) : (
        <PostButton as={NavLink} to={routeWithParams(routes.blog, { slug: provider.slug })}>
          <Icon name="chevron-up" direction="left" size="small" />
          <FormattedMessage id="pages.Blog.back" />
        </PostButton>
      )}
      {nextPost && (
        <PostButton
          as={NavLink}
          to={routeWithParams(routes.post, { slug: provider.slug, id: nextPost.slug })}
        >
          <FormattedMessage id="pages.Blog.nextPost" values={{ post: nextPost.title }} />
          <Icon name="chevron-up" direction="right" size="small" />
        </PostButton>
      )}
    </Wrapper>
  );
};

Links.propTypes = {
  provider: PropTypes.object.isRequired,
  posts: PropTypes.array,
  currentPost: PropTypes.object,
};

Links.defaultProps = {
  posts: [],
  currentPost: null,
};

export default Links;
