import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Heading } from "../../components";
import ResultsList from "../Search/ResultsList";

import { FormattedMessage } from "react-intl";

const Wrapper = styled.section`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ResulstListWrapper = styled.div`
  padding-top: 0.5rem;
  background-color: ${props => props.theme.light};
  border-radius: ${props => props.theme.borderRadius};
`;

const Recommendations = ({ recommendations }) => {
  if ((recommendations || []).length <= 0) {
    return "";
  }

  return (
    <Wrapper>
      <Heading level={3}>
        <FormattedMessage id="pages.Provider.recommendations" />
      </Heading>
      <ResulstListWrapper>
        <ResultsList results={recommendations} onCategoryClick={() => {}} />
      </ResulstListWrapper>
    </Wrapper>
  );
};

Recommendations.propTypes = {
  recommendations: PropTypes.array,
};

Recommendations.defaultProps = {
  recommendations: [],
};

export default Recommendations;
