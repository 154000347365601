import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrentBooking, isLoading } from "../../store/selectors/bookings";
import { getUserData } from "../../store/selectors/user";
import { login } from "../../store/actions/user";
import { createBooking, cancelBooking } from "../../store/actions/bookings";

import Booking from "./Booking";

const mapStateToProps = state => ({
  booking: getCurrentBooking(state),
  userData: getUserData(state),
  isLoading: isLoading(state),
});

const mapDispatchToProps = {
  createBooking,
  cancelBooking,
  login,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Booking));
