import React from "react";
import PropTypes from "prop-types";
import CourseList from "../../../components/CourseList";
import { PageContent, CardContainer } from "../../../components/";

const Courses = ({ courses, isLoading }) => (
  <PageContent transparent>
    <CardContainer>
      <CourseList courses={courses} isLoading={isLoading} />
    </CardContainer>
  </PageContent>
);

Courses.propTypes = {
  courses: PropTypes.array,
  isLoading: PropTypes.bool,
};

Courses.defaultProps = {
  courses: [],
  isLoading: false,
};

export default Courses;
